import { snackbarUnstyledClasses } from "@mui/base";
import React, { useRef, useEffect, useState } from "react";
import { Group, Rect, Text, Transformer } from "react-konva";

export const ResizableText = ({
  shapeProps,
  text,
  isSelected,
  onSelect,
  onResize,
  onClick,
  onDoubleClick,
  enableConnectors,
  onDragStartShape,
  onDragMoveShape,
  onDragEndShape,
  draggable,
  onTransformEnd,
  listening = false,
  opacity,
}) => {
  const textGroupRef = useRef(null);
  const rectGroupRef = useRef(null);
  const transformerRef = useRef(null);
  const parentRef = useRef(null);
  // const [tvWidth, setTVWidth] = useState(width);
  // const [tvheight, setTVHeight] = useState(height);
  const [tvX, setTVX] = useState(shapeProps.x);
  const [tvY, setTVY] = useState(shapeProps.y);
  const textRef = useRef(null);
  const [textHeight, setTextHeight] = useState(20);

  const calculateTextHeight = (text, fontSize, fontFamily, width) => {
    // Create a temporary span element
    const tempText = document.createElement("span");

    tempText.textContent = text;
    tempText.style.fontSize = fontSize + "px";
    tempText.style.fontFamily = fontFamily; // Set desired font family
    tempText.style.visibility = "hidden";
    tempText.style.position = "absolute";
    tempText.style.width = width + "px"; // Set the width to enable text wrapping
    tempText.style.whiteSpace = "normal"; // Allow text to wrap

    // Append the temporary element to the body
    document.body.appendChild(tempText);

    // Measure the height of the temporary element
    const height = tempText.offsetHeight;
    // Remove the temporary element from the DOM
    document.body.removeChild(tempText);

    return height;
  };

  useEffect(() => {
    let height = calculateTextHeight(
      text,
      shapeProps.style.fontSize,
      shapeProps.style.fontFamily,
      shapeProps.width
    );
    setTextHeight(height);
  }, [text]);

  useEffect(() => {
    if (isSelected && transformerRef.current !== null) {
      transformerRef.current.nodes([textGroupRef.current]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  const transformer =
    isSelected || enableConnectors ? (
      <Transformer
        ref={transformerRef}
        rotateEnabled={false}
        keepRatio={true}
        flipEnabled={false}
        enabledAnchors={[
          "top-left",
          "top-center",
          "top-right",
          "middle-right",
          "middle-left",
          "bottom-left",
          "bottom-center",
          "bottom-right",
        ]}
        boundBoxFunc={(oldBox, newBox) => {
          newBox.width = Math.max(30, newBox.width);
          return newBox;
        }}
      />
    ) : null;

  // it's a hack to implement text box. Mostly becuase of transformation constraints in a group.
  // If we transform one group, all the child items will be snackbarUnstyledClasses, so textview was stretched.
  //   Again, had to create another group which is draggable and drag the complete component

  const calculateYPosition = () => {
    if (shapeProps.type === "rect") {
      if (shapeProps.style.textAlignment === "top") {
        return shapeProps.y + 4;
      } else if (shapeProps.style.textAlignment === "center") {
        return shapeProps.y + shapeProps.height / 2 - textHeight / 2;
      } else if (shapeProps.style.textAlignment === "bottom") {
        return shapeProps.y + shapeProps.height - textHeight - 4;
      }
    } else {
      return shapeProps.y;
    }
  };

  return (
    <Text
      {...shapeProps}
      ref={textRef}
      padding={2}
      // fontFamily="Tahoma"
      text={text}
      y={calculateYPosition()}
      //height={textHeight}
      opacity={opacity}
      // fontSize={11} //{shapeProps.fontSize}
      //verticalAlign="top"
      align={shapeProps.style.textHorizontalAlignment || "center"}
      fill={
        shapeProps.style && shapeProps.style.fontColor
          ? shapeProps.style.fontColor
          : "black"
      }
      fontSize={
        shapeProps.style && shapeProps.style.fontSize
          ? shapeProps.style.fontSize
          : 12
      }
      fontFamily={
        shapeProps.style && shapeProps.style.fontFamily
          ? shapeProps.style.fontFamily
          : "Tahoma"
      }
      fontStyle={
        shapeProps.style && shapeProps.style.fontWeight
          ? shapeProps.style.fontWeight
          : "normal"
      }
      wrap="word"
      onClick={(e) => {
        console.log("Text Field Clicked");
        e.cancelBubble = true;
        onSelect();
      }}
      onDblClick={(e) => {
        onDoubleClick({ ...shapeProps });
      }}
      listening={listening}
    />
  );
};
