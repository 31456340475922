/* This method is not used for now
export const getClosestPoints = (rect1Props, rect2Props) => {
  const rect1CenterX = rect1Props.x + rect1Props.width / 2;
  const rect1CenterY = rect1Props.y + rect1Props.height / 2;
  const rect2CenterX = rect2Props.x + rect2Props.width / 2;
  const rect2CenterY = rect2Props.y + rect2Props.height / 2;

  const slope = (rect2CenterY - rect1CenterY) / (rect2CenterX - rect1CenterX);

  const rect1LeftEdgeY = rect1CenterY + slope * (rect1Props.x - rect1CenterX);
  const rect1RightEdgeY = rect1CenterY + slope * (rect1Props.x + rect1Props.width - rect1CenterX);
  const rect1TopEdgeX = rect1CenterX + (rect1Props.y - rect1CenterY) / slope;
  const rect1BottomEdgeX = rect1CenterX + (rect1Props.y + rect1Props.height - rect1CenterY) / slope;

  const rect2LeftEdgeY = rect2CenterY + slope * (rect2Props.x - rect2CenterX);
  const rect2RightEdgeY = rect2CenterY + slope * (rect2Props.x + rect2Props.width - rect2CenterX);
  const rect2TopEdgeX = rect2CenterX + (rect2Props.y - rect2CenterY) / slope;
  const rect2BottomEdgeX = rect2CenterX + (rect2Props.y + rect2Props.height - rect2CenterY) / slope;

  const intersectionPoints = [];

  if (rect1Props.y <= rect1LeftEdgeY && rect1LeftEdgeY <= rect1Props.y + rect1Props.height) {
    intersectionPoints.push({ x: rect1Props.x, y: rect1LeftEdgeY, rect: "rect1" });
  }
  if (rect1Props.y <= rect1RightEdgeY && rect1RightEdgeY <= rect1Props.y + rect1Props.height) {
    intersectionPoints.push({ x: rect1Props.x + rect1Props.width, y: rect1RightEdgeY, rect: "rect1" });
  }
  if (rect1Props.x <= rect1TopEdgeX && rect1TopEdgeX <= rect1Props.x + rect1Props.width) {
    intersectionPoints.push({ x: rect1TopEdgeX, y: rect1Props.y, rect: "rect1" });
  }
  if (rect1Props.x <= rect1BottomEdgeX && rect1BottomEdgeX <= rect1Props.x + rect1Props.width) {
    intersectionPoints.push({ x: rect1BottomEdgeX, y: rect1Props.y + rect1Props.height, rect: "rect1" });
  }

  if (rect2Props.y <= rect2LeftEdgeY && rect2LeftEdgeY <= rect2Props.y + rect2Props.height) {
    intersectionPoints.push({ x: rect2Props.x, y: rect2LeftEdgeY, rect: "rect2" });
  }
  if (rect2Props.y <= rect2RightEdgeY && rect2RightEdgeY <= rect2Props.y + rect2Props.height) {
    intersectionPoints.push({ x: rect2Props.x + rect2Props.width, y: rect2RightEdgeY, rect: "rect2" });
  }
  if (rect2Props.x <= rect2TopEdgeX && rect2TopEdgeX <= rect2Props.x + rect2Props.width) {
    intersectionPoints.push({ x: rect2TopEdgeX, y: rect2Props.y, rect: "rect2" });
  }
  if (rect2Props.x <= rect2BottomEdgeX && rect2BottomEdgeX <= rect2Props.x + rect2Props.width) {
    intersectionPoints.push({ x: rect2BottomEdgeX, y: rect2Props.y + rect2Props.height, rect: "rect2" });
  }

  if (intersectionPoints.length >= 4) {
    const distance02 = Math.sqrt(
      (intersectionPoints[0].x - intersectionPoints[2].x) ** 2 +
        (intersectionPoints[0].y - intersectionPoints[2].y) ** 2
    );
    const distance03 = Math.sqrt(
      (intersectionPoints[0].x - intersectionPoints[3].x) ** 2 +
        (intersectionPoints[0].y - intersectionPoints[3].y) ** 2
    );
    const distance12 = Math.sqrt(
      (intersectionPoints[1].x - intersectionPoints[2].x) ** 2 +
        (intersectionPoints[1].y - intersectionPoints[2].y) ** 2
    );
    const distance13 = Math.sqrt(
      (intersectionPoints[1].x - intersectionPoints[3].x) ** 2 +
        (intersectionPoints[1].y - intersectionPoints[3].y) ** 2
    );

    if (distance02 <= distance03 && distance02 <= distance12 && distance02 <= distance13) {
      return [
        { x: intersectionPoints[0].x, y: intersectionPoints[0].y, rect: intersectionPoints[0].rect },
        { x: intersectionPoints[2].x, y: intersectionPoints[2].y, rect: intersectionPoints[2].rect },
      ];
    } else if (distance03 <= distance02 && distance03 <= distance12 && distance03 <= distance13) {
      return [
        { x: intersectionPoints[0].x, y: intersectionPoints[0].y, rect: intersectionPoints[0].rect },
        { x: intersectionPoints[3].x, y: intersectionPoints[3].y, rect: intersectionPoints[3].rect },
      ];
    } else if (distance12 <= distance02 && distance12 <= distance03 && distance12 <= distance13) {
      return [
        { x: intersectionPoints[1].x, y: intersectionPoints[1].y, rect: intersectionPoints[1].rect },
        { x: intersectionPoints[2].x, y: intersectionPoints[2].y, rect: intersectionPoints[2].rect },
      ];
    } else {
      return [
        { x: intersectionPoints[1].x, y: intersectionPoints[1].y, rect: intersectionPoints[1].rect },
        { x: intersectionPoints[3].x, y: intersectionPoints[3].y, rect: intersectionPoints[3].rect },
      ];
    }
  }

  return null;
};
*/

export const closestPointtail = (shape, point, type) => {
  var arrowEndX = type == "head" ? point[point.length - 4] : point[2];
  var arrowEndY = type == "head" ? point[point.length - 3] : point[3];

  // console.log("Get Closest Point ",shape.id)
  if (shape.type == "rect") {
    var rect = shape;

    let centerX = rect.x + rect.width / 2;
    let centerY = rect.y + rect.height / 2;
    var newX = 0;
    var newY = 0;
    var yDistance = Math.abs(arrowEndY - centerY);
    var xDistance = Math.abs(arrowEndX - centerX);

    // if (rect.x < arrowEndX  && rect.x + rect.width > arrowEndX) {
    if (between(arrowEndX, centerX - yDistance, centerX + yDistance)) {
      let tanTheta =
        Math.abs(centerY - arrowEndY) / Math.abs(arrowEndX - centerX);
      if (rect.y > arrowEndY) {
        let heightOfSmallTraingle = Math.abs(rect.y - arrowEndY);
        let baseOfSmallTriangle = heightOfSmallTraingle / tanTheta;
        if (centerX < arrowEndX) {
          newX = arrowEndX - baseOfSmallTriangle;
        } else {
          newX = arrowEndX + baseOfSmallTriangle;
        }
        //newY = arrowEndY + heightOfSmallTraingle;
        //newY will always be in top line
        newY = rect.y;
        return [newX, newY];
      } else {
        let heightOfSmallTraingle = rect.height / 2; //  Math.abs(arrowEndY - rect.y - rect.height);
        let baseOfSmallTriangle = heightOfSmallTraingle / tanTheta;
        if (centerX > arrowEndX) {
          newX = centerX - baseOfSmallTriangle;
        } else {
          newX = centerX + baseOfSmallTriangle;
        }

        //newY = arrowEndY - heightOfSmallTraingle;
        //newY will always be in bottom line
        newY = rect.y + rect.height;
        return [newX, newY];
      }
    } else if (between(arrowEndY, centerY - xDistance, centerY + xDistance)) {
      if (type == "head") {
        console.log("Head there");
      }
      let tanTheta =
        Math.abs(arrowEndX - centerX) / Math.abs(centerY - arrowEndY);
      if (rect.x < arrowEndX) {
        let heightOfSmallTraingle = Math.abs(arrowEndX - rect.x - rect.width);
        let baseOfSmallTriangle = heightOfSmallTraingle / tanTheta;

        //newX = arrowEndX - heightOfSmallTraingle;
        //newX will always be in right edge
        newX = rect.x + rect.width;

        if (centerY > arrowEndY) {
          newY = arrowEndY + baseOfSmallTriangle;
        } else {
          newY = arrowEndY - baseOfSmallTriangle;
        }

        if (newX < 0) newX = 1;
        if (newY < 0) newY = 1;
        return [newX, newY];
      } else {
        let heightOfSmallTraingle = Math.abs(rect.x - arrowEndX);
        let baseOfSmallTriangle = heightOfSmallTraingle / tanTheta;
        //newX = arrowEndX + heightOfSmallTraingle;
        //newX will always be in left edge
        newX = rect.x;
        if (centerY > arrowEndY) {
          newY = arrowEndY + baseOfSmallTriangle;
        } else {
          newY = arrowEndY - baseOfSmallTriangle;
        }
        if (newX < 0) newX = 1;
        if (newY < 0) newY = 1;
        return [newX, newY];
      }
    } else {
      if (arrowEndX > rect.x + rect.width) {
        if (arrowEndY > rect.y + rect.height) {
          return [rect.x + rect.width, rect.y + rect.height];
        } else {
          return [rect.x + rect.width, rect.y];
        }
      } else {
        if (arrowEndY > rect.y + rect.height) {
          return [rect.x, rect.y + rect.height];
        } else {
          return [rect.x, rect.y];
        }
      }
    }
  } else if (shape.type == "circle") {
    var vX = arrowEndX - shape.x;
    var vY = arrowEndY - shape.y;
    var magV = Math.sqrt(vX * vX + vY * vY);
    var aX = shape.x + (vX / magV) * shape.radius;
    var aY = shape.y + (vY / magV) * shape.radius;

    return [aX, aY];
  }
};

export const getDotLocation = (rect, dotId) => {
  let width = rect.width;
  let height = rect.height;

  dotId = dotId.replace(rect.id + "-", "");
  if (dotId === "top-dot") {
    return [rect.x + rect.width / 2, rect.y];
  } else if (dotId === "bottom-dot") {
    return [rect.x + rect.width / 2, rect.y + rect.height];
  } else if (dotId === "left-dot") {
    return [rect.x, rect.y + rect.height / 2];
  } else if (dotId === "right-dot") {
    return [rect.x + rect.width, rect.y + rect.height / 2];
  } else if (dotId === "left-top-dot") {
    return [rect.x, rect.y + rect.height / 4];
  } else if (dotId === "left-bottom-dot") {
    return [rect.x, rect.y + (3 * rect.height) / 4];
  } else if (dotId === "right-top-dot") {
    return [rect.x + rect.width, rect.y + rect.height / 4];
  } else if (dotId === "right-bottom-dot") {
    return [rect.x + rect.width, rect.y + (3 * rect.height) / 4];
  } else if (dotId === "top-left-dot") {
    return [rect.x, rect.y];
  } else if (dotId === "top-left-center-dot") {
    return [rect.x + rect.width / 4, rect.y];
  } else if (dotId === "top-center-dot") {
    return [rect.x + rect.width / 2, rect.y];
  } else if (dotId === "top-right-center-dot") {
    return [rect.x + (3 * rect.width) / 4, rect.y];
  } else if (dotId === "top-right-dot") {
    return [rect.x + rect.width, rect.y];
  } else if (dotId === "bottom-left-dot") {
    return [rect.x, rect.y + rect.height];
  } else if (dotId === "bottom-left-center-dot") {
    return [rect.x + rect.width / 4, rect.y + rect.height];
  } else if (dotId === "bottom-center-dot") {
    return [rect.x + rect.width / 2, rect.y + rect.height];
  } else if (dotId === "bottom-right-center-dot") {
    return [rect.x + (3 * rect.width) / 4, rect.y + rect.height];
  } else if (dotId === "bottom-right-dot") {
    return [rect.x + rect.width, rect.y + rect.height];
  }
};

export const closestPoint = (rect, point, type) => {
  var arrowEndX = type == "head" ? point[point.length - 4] : point[2];
  var arrowEndY = type == "head" ? point[point.length - 3] : point[3];

  let centerX = rect.x + rect.width / 2;
  let centerY = rect.y + rect.height / 2;

  let top = [centerX, rect.y];
  let bottom = [centerX, rect.y + rect.height];

  let left = [rect.x, centerY];
  let right = [rect.x + rect.width, centerY];

  let distances = {
    top: Math.sqrt(
      Math.pow(arrowEndX - top[0], 2) + Math.pow(arrowEndY - top[1], 2)
    ),
    bottom: Math.sqrt(
      Math.pow(arrowEndX - bottom[0], 2) + Math.pow(arrowEndY - bottom[1], 2)
    ),
    left: Math.sqrt(
      Math.pow(arrowEndX - left[0], 2) + Math.pow(arrowEndY - left[1], 2)
    ),
    right: Math.sqrt(
      Math.pow(arrowEndX - right[0], 2) + Math.pow(arrowEndY - right[1], 2)
    ),
  };

  // Find the nearest point
  let nearestPoint = Object.keys(distances).reduce((a, b) =>
    distances[a] < distances[b] ? a : b
  );

  if (nearestPoint == "top") {
    return top;
  } else if (nearestPoint == "bottom") {
    return bottom;
  } else if (nearestPoint == "left") {
    return left;
  } else if (nearestPoint == "right") {
    return right;
  }

  let xDistance = Math.abs(arrowEndX - centerX);
  let yDistance = Math.abs(arrowEndY - centerY);
  let tanTheta = yDistance / xDistance;
  let rectInternalTan = rect.height / rect.width;
  let contactX, contactY;
  if (arrowEndX >= centerX && arrowEndY < centerY) {
    if (tanTheta > rectInternalTan) {
      console.log("Quadrant 1a ", type);
      // Quadrant 1a
      //tantheta is here is ydis/xdis
      contactY = rect.y;
      contactX = centerX;
    } else {
      console.log("Quadrant 1b ", type);
      // Quadrant 1b
      contactY = rect.y;
      contactX = centerX;
    }
  } else if (arrowEndX > centerX && arrowEndY >= centerY) {
    // Quadrant 2
    if (tanTheta < rectInternalTan) {
      console.log("Quadrant 2a ", type);
      // Quadrant 2a
      contactY = centerY;
      contactX = rect.x + rect.width;
    } else {
      console.log("Quadrant 2b ", type);
      // Quadrant 2b
      contactY = rect.y + rect.height;
      contactX = centerX;
    }
    // contactX = centerX - rect.width / 2;
    // contactY = centerY + tanTheta * (rect.width / 2);
  } else if (arrowEndX < centerX && arrowEndY > centerY) {
    // Quadrant 3
    if (tanTheta > rectInternalTan) {
      console.log("Quadrant 3a ", type);
      // Quadrant 3a
      contactY = centerY;
      contactX = rect.x;
    } else {
      // Quadrant 3b
      console.log("Quadrant 3b ", type);
      contactY = centerY;
      contactX = rect.x;
    }
    // contactX = centerX - rect.width / 2;
    // contactY = centerY - tanTheta * (rect.width / 2);
  } else if (arrowEndX < centerX && arrowEndY < centerY) {
    // Quadrant 4
    if (tanTheta < rectInternalTan) {
      console.log("Quadrant 4a ", type);
      // Quadrant 4a
      contactY = rect.y;
      contactX = centerX;
    } else {
      // Quadrant 4b
      console.log("Quadrant 4b ", type);
      contactY = rect.y;
      contactX = centerX;
    }
    // contactX = centerX + rect.width / 2;
    // contactY = centerY - tanTheta * (rect.width / 2);
  } else {
    // On the axis or at the center
    // contactX = centerX;
    // contactY = centerY;
  }

  return [contactX, contactY];
};

//Handling only for rect for now
/////Removing older logic to make it simple
// export const closestPoint = (rect, point, type) => {

//   var arrowEndX = type == "head" ? point[point.length - 4] : point[2];
//   var arrowEndY = type == "head" ? point[point.length - 3] : point[3];
//   let centerX = rect.x + rect.width / 2;
//   let centerY = rect.y + rect.height / 2;

//   let xDistance = Math.abs(arrowEndX - centerX);
//   let yDistance = Math.abs(arrowEndY - centerY);

//   let tanTheta = yDistance / xDistance;
//   // console.log(tanTheta);

//   let rectInternalTan = rect.height / rect.width;

//   let contactX, contactY;

//   if (arrowEndX >= centerX && arrowEndY < centerY) {
//     // Quadrant 1
//     if (tanTheta > rectInternalTan) {
//       // Quadrant 1a
//       //tantheta is here is ydis/xdis
//       contactY = rect.y;
//       contactX = centerX + rect.height / 2 / tanTheta;
//     } else {
//       // Quadrant 1b
//       contactX = rect.x + rect.width;
//       contactY = rect.y + (rect.height / 2 - (rect.width / 2) * tanTheta);
//     }
//   } else if (arrowEndX > centerX && arrowEndY >= centerY) {
//     // Quadrant 2
//     if (tanTheta < rectInternalTan) {
//       // Quadrant 2a
//       contactX = rect.x + rect.width;
//       contactY = rect.y + (rect.height / 2 + (rect.width / 2) * tanTheta);
//     } else {
//       // Quadrant 2b
//       contactY = rect.y + rect.height;
//       contactX = centerX + rect.height / 2 / tanTheta;
//     }
//     // contactX = centerX - rect.width / 2;
//     // contactY = centerY + tanTheta * (rect.width / 2);
//   } else if (arrowEndX < centerX && arrowEndY > centerY) {
//     // Quadrant 3
//     if (tanTheta > rectInternalTan) {
//       // Quadrant 3a
//       contactY = rect.y + rect.height;
//       contactX = centerX - rect.height / 2 / tanTheta;
//     } else {
//       // Quadrant 3b
//       contactX = rect.x;
//       contactY = centerY + (rect.width / 2) * tanTheta;
//     }
//     // contactX = centerX - rect.width / 2;
//     // contactY = centerY - tanTheta * (rect.width / 2);
//   } else if (arrowEndX < centerX && arrowEndY < centerY) {
//     // Quadrant 4
//     if (tanTheta < rectInternalTan) {
//       // Quadrant 4a
//       contactX = rect.x;
//       contactY = centerY - (rect.width / 2) * tanTheta;
//     } else {
//       // Quadrant 4b
//       contactX = rect.x + rect.width / 2 - rect.height / 2 / tanTheta;
//       contactY = rect.y;
//     }
//     // contactX = centerX + rect.width / 2;
//     // contactY = centerY - tanTheta * (rect.width / 2);
//   } else {
//     // On the axis or at the center
//     // contactX = centerX;
//     // contactY = centerY;
//   }

//   return [contactX, contactY];
// };

const between = (x, min, max) => {
  return x >= min && x <= max;
};

export const getEdgeRects = (comp) => {
  if (comp.type == "arrow") {
    let points = comp.points;
    return {
      x: points[0],
      y: points[1],
      width: Math.abs(points[points.length - 2] - points[0]),
      height: Math.abs(points[points.length - 1] - points[1]),
    };
  } else if (comp.type == "rect" || comp.type == "image") {
    return {
      x: comp.x,
      y: comp.y,
      width: comp.width,
      height: comp.height,
    };
  } else if (comp.type == "circle") {
    return {
      x: comp.x,
      y: comp.y,
      width: comp.width,
      height: comp.height,
    };
  }
  console.log(
    "Edge Rect Definition not provided for Component Type ",
    comp.type
  );
  return null;
};

export const haveIntersection = (r1, r2) => {
  return !(
    r2.x > r1.x + r1.width ||
    r2.x + r2.width < r1.x ||
    r2.y > r1.y + r1.height ||
    r2.y + r2.height < r1.y
  );
};

export const getSelectedEntities = (selectionArea, components) => {
  const selectedConnections = components.filter((comp) => {
    if (comp.type == "self_arrow") {
      return false;
    } else {
      if (comp.type !== "arrow") {
        const edgeRect = getEdgeRects(comp);
        return haveIntersection(selectionArea, edgeRect);
      } else {
        return false;
      }
    }
  });
  return selectedConnections.map((comps) => comps.id);
};

export const updateComponentsScale = (currentScale, components) => {
  components.forEach((comp) => {
    if (comp.type == "rect" || comp.type == "image") {
      comp.x = comp.x * currentScale;
      comp.y = comp.y * currentScale;
      comp.width = comp.width * currentScale;
      comp.height = comp.height * currentScale;
    } else if (comp.type == "circle") {
      comp.radius = comp.radius * currentScale;
      comp.x = comp.x * currentScale;
      comp.y = comp.y * currentScale;
    } else if (comp.type == "arrow") {
      var updatedPoints = [];
      comp.points.forEach((point) => {
        updatedPoints.push(point * currentScale);
      });

      comp.points = updatedPoints;
    }
  });
};

// export const closestPoint = (pathNode, point) => {
//   var pathLength = 4, // (VW) replaces pathNode.getTotalLength(),
//     precision = 8,
//     best,
//     bestLength,
//     bestDistance = Infinity;

//   // linear scan for coarse approximation
//   for (
//     var scan, scanLength = 0, scanDistance;
//     scanLength <= pathLength;
//     scanLength += precision
//   ) {
//     if (
//       (scanDistance = distance2(
//         (scan = pathNode.getPointAtLength(scanLength)),
//         point
//       )) < bestDistance
//     ) {
//       best = scan;
//       bestLength = scanLength;
//       bestDistance = scanDistance;
//     }
//   }

//   // binary search for precise estimate
//   precision /= 2;
//   while (precision > 0.5) {
//     var before, after, beforeLength, afterLength, beforeDistance, afterDistance;
//     if (
//       (beforeLength = bestLength - precision) >= 0 &&
//       (beforeDistance = distance2(
//         (before = pathNode.getPointAtLength(beforeLength)),
//         point
//       )) < bestDistance
//     ) {
//       best = before;
//       bestLength = beforeLength;
//       bestDistance = beforeDistance;
//     } else if (
//       (afterLength = bestLength + precision) <= pathLength &&
//       (afterDistance = distance2(
//         (after = pathNode.getPointAtLength(afterLength)),
//         point
//       )) < bestDistance
//     ) {
//       best = after;
//       bestLength = afterLength;
//       bestDistance = afterDistance;
//     } else {
//       precision /= 2;
//     }
//   }

//   best = { x: best.x, y: best.y }; // (VW) converted to object instead of array, personal choice
//   best.distance = Math.sqrt(bestDistance);
//   return best;
// };

// const distance2 = (p, point) => {
//   var dx = p.x - point.x, // (VW) converter to object from array
//     dy = p.y - point.y;
//   return dx * dx + dy * dy;
// };
