const Images = {
  rect: require("../res/rect.png"),
  arrow: require("../res/arrow.png"),
  circle: require("../res/circle.png"),
  sql: require("../res/sql.png"),
  new: require("../res/new.png"),
  select_icon: require("../res/select_hand.png"),
  plus_icon: require("../res/plus.png"),
};

export default Images;
