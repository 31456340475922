import uuid from "react-uuid";

export const menuItems = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Services",
    url: "/services",
    submenu: [
      {
        title: "web design",
        url: "web-design",
      },
      {
        title: "web development",
        url: "web-dev",
        submenu: [
          {
            title: "Frontend",
            url: "frontend",
          },
          {
            title: "Backend",
            submenu: [
              {
                title: "NodeJS",
                url: "node",
              },
              {
                title: "PHP",
                url: "php",
              },
            ],
          },
        ],
      },
      {
        title: "SEO",
        url: "seo",
      },
    ],
  },
  {
    title: "About",
    url: "/about",
    submenu: [
      {
        title: "Who we are",
        url: "who-we-are",
      },
      {
        title: "Our values",
        url: "our-values",
      },
    ],
  },
];

export const predefinedShapes = [
  {
    label: "Rectangle",
    type: "rect",
    key: "rect",
  },
  {
    label: "Arrow",
    type: "arrow",
    key: "arrow",
  },
  {
    label: "Queue",
    type: "queue",
    key: "queue",
  },
  // {
  //   label: "Circle",
  //   type: "circle",
  // },
  // {
  //   label: "New Component",
  //   type: "new_component",
  //   key: "new_component",
  // },
  // {
  //   label: "SQL",
  //   type: "editableText",
  // },
  // {
  //   label: "Component List",
  //   type: "component_list",
  //   key: "component_list",
  // },
];

export const AccessType = {
  OWNER: "owner",
  EDITOR: "editor",
  VIEW_ONLY: "viewonly",
  NO_ACCESS: "noacess",
  DELETED: "deleted",
  ACCESS_REQUESTED: "pending",
  EDIT_ACCESS_REQUESTED: "editaccessrequested",
};
