import React, { useState, useEffect } from "react";
import { setCookie, getCookie } from "../Helper/CookieManager";
import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { Button } from "react-bootstrap";
import { login } from "../Helper/DataService";
import googleIcon from "../res/google-icon.png";
import movingGif from "../res/moving-flow.gif";
import staticDiagram from "../res/static-diagram.jpeg";
import happyIcon from "../res/happy.png";
import sadIcon from "../res/sad.png";
import dflog from "../res/df.webp";
import backgroundImage from "../res/bgimage.jpg";
import introGif from "../res/intro.gif";
import loginThinkIcon from "../res/login-think-icon.png";
import loginCmplexFlow from "../res/login-complex-flow.png";
import loginConfused from "../res/login-confused.png";
import loginHappy from "../res/login-happy.png";
import loginConnectedBoxes from "../res/login-connected-boxes.png";
import Footer from "./Footer";
// import dflog from "../res/df.jpeg";
// import loginRightBG from "../res/right-bg.png";

const Login = ({ onLogin, onLoginSuccess }) => {
  // const [cookies, setCookie] = useCookies(['loggedIn']);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const firebaseConfig = {
    apiKey: "AIzaSyCR7VXtp2Scsw_Dmdwv8nHHjocWOzj8iys",
    authDomain: "draw-ede3f.firebaseapp.com",
    projectId: "draw-ede3f",
    storageBucket: "draw-ede3f.appspot.com",
    messagingSenderId: "327012255639",
    appId: "1:327012255639:web:eacf3a4f5dbe0f2ffca6d2",
    measurementId: "G-6TXQWZEZ47",
  };

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const db = getFirestore(app);

  const handleGoogleLogin = (response) => {
    // Extract the user's ID token from the Google response
    const idToken = response._tokenResponse.idToken;

    const displayName = response.user.displayName;

    login(idToken).then((res) => {
      setCookie("acessToken", res.accessToken, 30, false);
      setCookie("email", res.user.email, 30, false);
      setCookie("userId", res.user.customerId, 30, false);
      setCookie("displayName", displayName, 30, false);
      setShowLoginPopup(false);
      // Redirect the user to the home page
      //window.location.href = '/';
      onLoginSuccess();
    });
  };

  // const testLogin = () => {

  //   // Extract the user's ID token from the Google response
  //   // const idToken = response._tokenResponse.idToken;
  //   // login(idToken).then((res) => {
  //   //   setCookie('acessToken', res.accessToken, 30, false )
  //   //   setCookie('email', res.user.email, 30, false )
  //   //   setCookie('userId', res.user.customerId, 30, false )
  //   //   setShowLoginPopup(false)
  //   //   // Redirect the user to the home page
  //   //   window.location.href = '/';

  //   // })
  //     setCookie('acessToken', "res.accessToken", 30, false )
  //     setCookie('email', "res.user.email", 30, false )
  //     setCookie('userId', "res.user.customerId", 30, false )
  //     setShowLoginPopup(false)
  //     // Redirect the user to the home page
  //     window.location.href = '/';

  // }

  const googleProvider = new GoogleAuthProvider();
  const signInWithGoogle = async () => {
    try {
      const res = await signInWithPopup(auth, googleProvider);
      handleGoogleLogin(res);
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  useEffect(() => {
    const loggedInCookie = getCookie("email");
    if (!loggedInCookie) {
      setShowLoginPopup(true);
    }
  }, [showLoginPopup]);

  const popupStyle = {
    textAlign: "center",
    // padding: "20px",
    // backgroundColor: "#f9f9f9",
    // backgroundColor: "#f5f5f5",
    // border: "2px solid #4c3fdc",
    // borderRadius: "10px",
    // maxWidth: "400px",
  };

  const buttonStyle = {
    margin: "20px",
    fontSize: "16px",
    backgroundColor: "white",
    color: "black",
    width: "220px",
    alignItems: "center",
    marginBottom: "10px",
    display: "flex",
    flexDirection: "row",
    // border: "2px solid #ccc",
    // border: "1px solid #4c3fdc",
    cursor: "pointer",
  };

  const heading1Style = {
    fontSize: "44px",
    fontWeight: "bold", // Adding the fontWeight property for bold text
    marginBottom: "10px",
    color: "black",
    textAlign: "left",
    marginLeft: "10px",
  };

  const heading2Style = {
    fontSize: "30px",
    fontWeight: "60-", // Adding the fontWeight property for bold text
    marginBottom: "10px",
    color: "black",
    textAlign: "left",
    marginLeft: "10px",
  };

  const heading3Style = {
    fontSize: "24px",
    fontWeight: "500", // Adding the fontWeight property for bold text
    color: "#000",
    lineHeight: "1.5",
    marginBottom: "20px",
    textAlign: "left",
    marginLeft: "10px",
  };

  const googleSignInButton = () => {
    return (
      <div
        style={{
          display: "block",
          textAlign: "center",
        }}
      >
        <button onClick={signInWithGoogle} className="google-btn">
          {/* <span className="google-icon"></span> */}
          <img
            style={{
              width: "25px",
              height: "25px",
              marginRight: "10px",
            }}
            src={googleIcon}
            alt="Google Icon"
          />
          <span className="google-text">Sign in with Google</span>
        </button>
      </div>
    );
  };

  const backgroundImageStyle = {
    width: windowWidth,
    height: windowHeight,
    position: "fixed",
    top: "0",
    left: "0",
    zIndex: "-1",
  };

  const containerStyle = {
    position: "relative",
    width: windowWidth,
    height: windowHeight,
    overflow: "hidden",
  };

  const loginButtonStyle = {
    position: "absolute",
    right: "50px",
    bottom: "25%",
  };

  const leftBGStyle = {
    width: windowWidth * 0.5,
    height: "100%",
    // backgroundImage: `url(${loginRightBG})`, // Use the imported image variable
  };

  const rightBGStyle = {
    width: windowWidth * 0.5,
    margin: "20px",
    backgroundColor: "#E4E1FB",
    borderRadius: "10px",
  };

  const easyDesignIcon = {
    position: "relative",
    marginTop: "50px",
    marginLeft: windowWidth * 0.6,
    width: windowWidth * 0.25,
    height: windowHeight * 0.25,
    zIndex: "1",
  };

  const complexDesignIcon = {
    position: "relative",
    marginTop: "10%",
    marginLeft: "15%",
    width: "30%",
    height: windowHeight * 0.3,
    zIndex: "20",
  };

  const happyPersonStyle = {
    marginTop: -1 * windowHeight * 0.33 - 44,
    marginLeft: "20px",
  };

  const confusedPersonStyle = {
    marginTop: "-40%",
    marginLeft: "20%",
  };

  const centerContainer = {
    marginTop: "5%",
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    // height: "100vh", // This sets the container to take up the full viewport height
  };

  const flowIcon = {
    width: "64px",
    height: "64px",
    marginLeft: "auto",
    marginRight: "auto",
  };

  const leftToptextStyle = {
    marginTop: "10%",

    textAlign: "center",
    marginLeft: "auto", // Set marginLeft to "auto" to horizontally center
    marginRight: "auto", // Set marginRight to "auto" to horizontally center
    fontSize: "50px", // Set font size to 40px
    fontWeight: "bold", // Make the font bold
    fontFamily: "Rowdies, sans-serif", // Use the "Rowdies" font or your preferred font-family
    /* Other CSS properties as needed */
  };

  const rightToptextStyle = {
    // marginTop: "20%",
    marginBottom: "32px",
    paddingLeft: "20px",
    paddingLeft: "20px",
    textAlign: "center",
    marginLeft: "auto", // Set marginLeft to "auto" to horizontally center
    marginRight: "auto", // Set marginRight to "auto" to horizontally center
    fontSize: "40px", // Set font size to 40px
    fontWeight: "bold", // Make the font bold
    fontFamily: "Rowdies, sans-serif", // Use the "Rowdies" font or your preferred font-family
    /* Other CSS properties as needed */
  };

  const leftSecondtextStyle = {
    // marginTop: "10px",
    textAlign: "center",
    marginLeft: "auto", // Set marginLeft to "auto" to horizontally center
    marginRight: "auto", // Set marginRight to "auto" to horizontally center
    fontSize: "30px", // Set font size to 40px
    fontWeight: "bold", // Make the font bold
    fontFamily: "Rowdies, sans-serif", // Use the "Rowdies" font or your preferred font-family
    /* Other CSS properties as needed */
  };

  const loginContainer = {
    marginTop: "-100px",
    backgroundColor: "white",
    width: "65%",
    height: "100px",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.2)", // Add a shadow for the 3D effect

    borderBottomLeftRadius: "10px", // Round bottom-left corner
    borderBottomRightRadius: "10px", // Round bottom-right corner
  };

  return (
    <div style={{ overflow: "scroll", height: windowHeight }}>
      <div
        style={{
          width: "100%",
          height: windowHeight - 44,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div style={leftBGStyle}>
          <div style={leftToptextStyle}>Confused?</div>
          <div style={leftSecondtextStyle}>With Complicated Flow</div>
          <img
            src={loginCmplexFlow}
            style={complexDesignIcon}
            alt="Background"
          />
          <img
            src={loginConfused}
            style={confusedPersonStyle}
            alt="Background"
          />
        </div>
        <div style={rightBGStyle}>
          <div style={centerContainer}>
            <img src={dflog} style={flowIcon} alt="Background" />
          </div>
          <div style={rightToptextStyle}>
            Try new way of defining and understanding the flows
          </div>

          <img src={loginThinkIcon} style={easyDesignIcon} alt="Background" />

          <img src={loginHappy} style={happyPersonStyle} alt="Background" />
          <div style={centerContainer}>
            <div style={loginContainer}>
              <div
                style={{
                  ...rightToptextStyle,
                  fontSize: "20px",
                  marginBottom: "0px",
                  // marginBottom: "8px",
                  fontWeight: "normal",
                }}
              >
                Login
              </div>
              {googleSignInButton()}
              <div
                style={{
                  ...rightToptextStyle,
                  fontSize: "10px",
                  marginBottom: "8px",
                  fontWeight: "normal",
                }}
              >
                By Singing in you agree to{" "}
                <a href="/terms-of-use">terms of use</a>
              </div>
            </div>
          </div>
        </div>

        {/* <div style={loginButtonStyle}>{googleSignInButton()}</div> */}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          height: "44px",
        }}
      >
        <Footer />
        {/* For any queries, Please reach out to us @{" "}
        <a href="mailto:support@drawfuse.com"> support@drawfuse.com </a> */}
      </div>
    </div>
  );

  //   return (
  //     <div>
  //       {showLoginPopup && (
  //         <div
  //           //className="login-popup-container"
  //           style={{ backgroundColor: "white", width: "100%", height: "100%" }}
  //         >
  //           {/* <div> */}

  //           <div>
  //             <div style={{ display: "flex", alignItems: "center" }}>
  //               {/* <div className="logo-div" style={{ marginRight: "20px" }}> */}

  //               {/* <h1>FLO</h1> */}
  //               {/* </div> */}
  //               {/* <div style={popupStyle}>
  //                 <h1 style={heading1Style}>Welcome to DrawFuse</h1>
  //               </div> */}
  //               <div>{googleSignInButton()}</div>
  //             </div>
  //             <div style={{ display: "flex", justifyContent: "center" }}>
  //               <img
  //                 src={dflog}
  //                 style={{
  //                   // display: "inline-block",
  //                   width: "50px",
  //                   height: "50px",
  //                   // marginLeft: "24px",
  //                   // marginRight: "10px",
  //                 }}
  //               />
  //               {/* <h2
  //                 style={{
  //                   display: "inline-block",
  //                   ...heading2Style,
  //                   paddingTop: "10px",
  //                 }}
  //               >
  //                 Unlocking Clarity: The Power of Animated Flows in Understanding
  //                 System Architecture
  //               </h2> */}
  //               {/* <h3 style={heading3Style}>
  //                 Power your ideas flow tool—where creativity meets functionality.
  //                 Craft dynamic, animating visuals effortlessly, transforming
  //                 concepts into interactive masterpieces.
  //                 <br />
  //                 Navigating static system diagrams can be bewildering—lines and
  //                 boxes convey an incomplete picture, leaving confusion in their
  //                 wake.
  //                 <br />
  //               </h3> */}
  //               <div
  //                 style={{
  //                   position: "relative",
  //                   margin: "20px",
  //                   display: "flex",
  //                   alignContent: "center",
  //                 }}
  //               >
  //                 {/* <div> */}
  //                 <img
  //                   src={sadIcon}
  //                   alt="Sad Icon"
  //                   style={{
  //                     width: "20%",
  //                     marginRight: "20px",
  //                     top: "20px",
  //                     left: "20px",
  //                     zIndex: 2,
  //                   }}
  //                 />
  //                 <img
  //                   src={staticDiagram}
  //                   alt="Static Diagram"
  //                   style={{
  //                     width: "50%",
  //                     border: "1px solid #ccc",
  //                     position: "relative",
  //                     zIndex: 1,
  //                   }}
  //                 />
  //                 {/* </div> */}

  //                 {/* <div style={{ marginTop: "80px" }}>
  //                   <img
  //                     src={introGif}
  //                     alt="Moving GIF"
  //                     style={{
  //                       width: "50%",
  //                       border: "1px solid #ccc",
  //                       marginRight: "20px",
  //                     }}
  //                   />
  //                   <img
  //                     src={happyIcon}
  //                     alt="Happy Icon"
  //                     style={{
  //                       width: "200px",
  //                       height: "200px",
  //                     }}
  //                   />
  //                 </div> */}
  //               </div>

  //               {/* <h3 style={heading3Style}>
  //                 Immerse in animated flows! Witnessing system components interact
  //                 in real-time grants unparalleled clarity, transforming
  //                 complexity into comprehension.
  //                 <br />
  //                 Animated flows dissolve complexity, offering insights
  //                 unattainable through static depictions, empowering optimization
  //                 and foresight.
  //               </h3> */}
  //               <div style={{ display: "flex", flexDirection: "column" }}>
  //                 {/* <div style={{ position: "relative", marginBottom: "20px" }}>
  //                   <img
  //                     src={sadIcon}
  //                     alt="Sad Icon"
  //                     style={{
  //                       width: "200px",

  //                       top: "20px",
  //                       left: "20px",
  //                       zIndex: 2,
  //                     }}
  //                   />
  //                   <img
  //                     src={staticDiagram}
  //                     alt="Static Diagram"
  //                     style={{
  //                       width: "40%",
  //                       border: "1px solid #ccc",
  //                       position: "relative",
  //                       zIndex: 1,
  //                     }}
  //                   />
  //                 </div> */}
  //                 {/* <div style={{ display: "flex", flexDirection: "row" }}> */}
  //                 {/* <div style={{ position: "relative", margin: "20px" }}>
  //                   <img
  //                     src={introGif}
  //                     alt="Moving GIF"
  //                     style={{
  //                       width: "40%",
  //                       border: "1px solid #ccc",
  //                       marginRight: "20px",
  //                     }}
  //                   />
  //                   <img
  //                     src={happyIcon}
  //                     alt="Happy Icon"
  //                     style={{
  //                       width: "200px",
  //                       height: "200px",
  //                     }}
  //                   />
  //                 </div> */}
  //               </div>
  //               {/* <h1 Please sign in to continue</h1> */}
  //               <div
  //                 style={{
  //                   width: "100%",
  //                   display: "flex",
  //                   flexDirection: "column",
  //                   alignItems: "center",
  //                   textAlign: "center",
  //                 }}
  //               >
  //                 {/* <button onClick={signInWithGoogle} class="google-btn">

  //                   <img
  //                     style={{
  //                       width: "20px",
  //                       height: "20px",
  //                       marginRight: "10px",
  //                     }}
  //                     src={googleIcon}
  //                   />
  //                   <span class="google-text">Sign in with Google</span>
  //                 </button> */}

  //                 {/* <div style={buttonStyle}>
  //                       <img
  //                         style={{
  //                           width: "20px",
  //                           height: "20px",
  //                           marginLeft: "20px",
  //                           marginRight: "20px",
  //                           marginTop: "10px",
  //                           marginBottom: "10px",
  //                         }}
  //                         src={googleIcon}
  //                       />
  //                       <div onClick={signInWithGoogle}>Sign in with Google</div>
  //                     </div> */}
  //               </div>
  //             </div>
  //             <div style={{ marginTop: "80px" }}>
  //               <img
  //                 src={introGif}
  //                 alt="Moving GIF"
  //                 style={{
  //                   width: "50%",
  //                   border: "1px solid #ccc",
  //                   marginRight: "20px",
  //                 }}
  //               />
  //               <img
  //                 src={happyIcon}
  //                 alt="Happy Icon"
  //                 style={{
  //                   width: "200px",
  //                   height: "200px",
  //                 }}
  //               />
  //             </div>
  //             {/* </div> */}
  //           </div>
  //           {/* </div> */}
  //         </div>
  //       )}
  //     </div>
  //   );
};

export default Login;
