import React from "react";

function AboutUsPage() {
  return (
    <div style={{ margin: "20px" }}>
      <header>
        {/* Include your website's header/navigation component here */}
      </header>
      <main>
        <section id="about-us">
          <h1>About Us</h1>
          <p>
            Welcome to DrawFuse.com, your destination for creating stunning
            diagrams and flowcharts with ease.
          </p>

          <h2>Our Mission</h2>
          <p>
            Our mission is to empower individuals and organizations to visualize
            their ideas and processes effectively through intuitive diagramming
            tools and step-by-step flows.
          </p>

          <h2>Why Choose DrawFuse?</h2>
          <ul>
            <li>Easy-to-use diagram creation tools</li>
            <li>Seamless integration of step-by-step flows</li>
            <li>Efficient collaboration and sharing features</li>
            <li>Exceptional customer support</li>
            {/* Add more reasons as needed */}
          </ul>

          <h2>Our Team</h2>
          <p>
            We are a passionate and dedicated team of professionals committed to
            delivering the best diagramming and flowcharting experience. Meet
            our team members:
          </p>
          <ul>
            <li>
              <strong>John Doe</strong> - CEO
            </li>
            <li>
              <strong>Jane Smith</strong> - CTO
            </li>
            <li>
              <strong>Mary Johnson</strong> - Product Manager
            </li>
            {/* Include information about other team members */}
          </ul>
        </section>
      </main>
      <footer>{/* Include your website's footer component here */}</footer>
    </div>
  );
}

export default AboutUsPage;
