import * as React from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import { set } from "lodash";
import { forwardRef, useImperativeHandle } from "react";

export const AutohideSnackbar = forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState(null);

  useImperativeHandle(ref, () => ({
    showToast(message) {
      showToast(message);
    },
  }));

  const showToast = (message) => {
    setToastMessage(message);
    setOpen(true);
  };

  const handleClick = React.useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    setToastMessage(null);
  };

  return (
    <div>
      <Snackbar
        open={open && toastMessage !== null}
        autoHideDuration={2000}
        onClose={handleClose}
        message={toastMessage}
      />
    </div>
  );
});
