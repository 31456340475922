import React, { useState } from "react";

const AlertPopup = ({ message, buttonTitle1, buttonTitle2, button1Action, button2Action  }) => {
  

  return  (
    <div className="confirmation-popup-container">
      <div className="confirmation-popup">
        <p>{message}</p>
        <div className="confirmation-popup-buttons">
        {buttonTitle1 &&
          <button className="confirmation-popup-confirm" onClick={button1Action}>
            {buttonTitle1}
          </button>
        }
        {buttonTitle2 &&
          <button className="confirmation-popup-cancel" onClick={button2Action}>
            {buttonTitle2}
          </button>
        }
        </div>
      </div>
    </div>
  ) 
};

export default AlertPopup;

