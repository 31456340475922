import { style } from "@mui/system";
import React, { useEffect, useRef } from "react";

import { Transformer, Circle } from "react-konva";
import { EditableText } from "./TextComponents/EditableText";
import uuid from "react-uuid";

const RETURN_KEY = 13;
const ESCAPE_KEY = 27;

const ECircle = ({
  shapeProps,
  scale,
  zIndex,
  isSelected,
  onSelect,
  onDragStartShape,
  onDragMoveShape,
  onDragEndShape,
  onToggleEdit,
  onDoubleClick,
  onTextChange,
  onResize,
}) => {
  const shapeRef = useRef();
  const trRef = useRef();

  useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  const propsForText = () => {
    let props = { ...shapeProps };
    props.x = shapeProps.x - 2 * shapeProps.radius;
    props.y = shapeProps.y - 2 * shapeProps.radius;
    props.stroke = "transparent";
    props.fill = "transparent";
    return props;
  };

  const handleEscapeKeys = (e) => {
    if ((e.keyCode === RETURN_KEY && !e.shiftKey) || e.keyCode === ESCAPE_KEY) {
      onToggleEdit(shapeProps);
    }
  };

  const textComp = (x) => {
    return (
      <EditableText
        shapeProps={propsForText()}
        draggable={false}
        onDoubleClick={onDoubleClick}
        onClick={(e) => {
          onSelect(shapeProps.id);
        }}
        text={shapeProps.text}
        isEditing={shapeProps.isEditingText}
        onTextChange={onTextChange}
        onSelect={(e) => {
          onSelect(shapeProps.id);
        }}
        onToggleEdit={(model) => {
          onToggleEdit(model);
        }}
        // stroke="red"
        // strokeWidth={4}
        //onClick={onSelect}
      />
    );
  };

  return (
    <React.Fragment>
      <Circle
        onClick={(e) => {
          onSelect(shapeProps.id);
        }}
        ref={shapeRef}
        onDblClick={(e) => onDoubleClick(shapeProps)}
        {...shapeProps}
        draggable
        onDragStart={(e) => {
          onDragStartShape({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onDragMove={(e) => {
          onDragMoveShape({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onDragEnd={(e) => {
          onDragEndShape({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onTransformEnd={(e) => {
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          // we will reset it back
          node.scaleX(1);
          node.scaleY(1);
          onResize({
            ...shapeProps,
            x: node.x(),
            y: node.y(),
            // set minimal value
            width: Math.max(5, node.width() * scaleX),
            height: Math.max(node.height() * scaleY),
          });
        }}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          zIndex={shapeProps.zIndex}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
      {shapeProps.enableConnectors && (
        <Circle
          {...shapeProps}
          stroke="red"
          strokeWidth={2}
          shadowColor="green"
          shadowEnabled={true}
          strokeHitEnabled={true}
          hitStrokeWidth={2}
          shadowForStrokeEnabled={true}
          dashOffset={2}
          onKeyDown={handleEscapeKeys}
        />
      )}
      {textComp("start")}
    </React.Fragment>
  );
};

export default ECircle;
