export const setCookie = (name, value, days, isJson) => {
  let expires = "";
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  if (isJson) {
    document.cookie =
      name +
      "=" +
      encodeURIComponent(JSON.stringify(value)) +
      expires +
      "; path=/";
  } else {
    document.cookie =
      name + "=" + encodeURIComponent(value) + expires + "; path=/";
  }
};

// Function to read the cookie
export const getCookie = (name, isJson) => {
  let cookieName = name + "=";
  let cookieArray = document.cookie.split(";");
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) == " ") {
      cookie = cookie.substring(1, cookie.length);
    }
    if (cookie.indexOf(cookieName) == 0) {
      if (isJson) {
        let data = decodeURIComponent(
          cookie.substring(cookieName.length, cookie.length)
        );
        if (data != "") {
          return JSON.parse(data);
        } else {
          return data;
        }
      } else {
        return decodeURIComponent(
          cookie.substring(cookieName.length, cookie.length)
        );
      }
    }
  }
  return null;
};

export const deleteCookie = (name) => {
  document.cookie = name + "=; Max-Age=-99999999;";
};

export const updateLoginStatus = (status) => {
  let cookieName = "is_loggedin";

  if (!status) {
    deleteCookie(cookieName);
  } else {
    setCookie(cookieName, true, 30, false);
  }
};

export const getLoginCookie = () => {
  let cookieName = "is_loggedin";
  return getCookie(cookieName, false);
};
