import React, { useEffect, useState } from "react";
import { TextField, Button } from "@mui/material";
import deletesvg from "../res/delete.svg";
import Typography from "@mui/material/Typography";
import { forwardRef, useImperativeHandle } from "react";

const KeyValueForm = forwardRef(({ initialValues, onSave }, ref) => {
  const [pairs, setPairs] = useState([]);

  useEffect(() => {
    // Update the local state (pairs) when initialValues prop changes
    setPairs(initialValues);
  }, [initialValues]);

  useImperativeHandle(ref, () => ({
    addRow() {
      addRow();
    },
  }));

  const handleKeyChange = (index, newValue) => {
    const updatedPairs = [...pairs];
    updatedPairs[index].key = newValue;
    setPairs(updatedPairs);
  };

  const handleValueChange = (index, newValue) => {
    const updatedPairs = [...pairs];
    updatedPairs[index].value = newValue;
    setPairs(updatedPairs);
  };

  const handleSaveClick = () => {
    onSave(pairs);
  };

  const addRow = () => {
    setPairs([...pairs, { key: "", value: "" }]);
  };

  const handleDelete = (index) => {
    const updatedPairs = [...pairs];
    updatedPairs.splice(index, 1);
    setPairs(updatedPairs);
    onSave(pairs);
  };

  return (
    <div className="kvf-container">
      {pairs.map((pair, index) => (
        <div key={index} className="kvf-card">
          {/* <div className="kvf-card-header"> */}

          <TextField
            label="Key"
            className="kvf-card-textfield"
            value={pair.key}
            onChange={(e) => handleKeyChange(index, e.target.value)}
            // variant="outlined"
          />
          {/* </div> */}

          <TextField
            label="Value"
            style={{
              textAlign: "start",
              // paddingTop: "20px",
              marginTop: "12px",

              // border: "1px solid #C8C8C8",
            }}
            multiline
            variant="outlined" // You can choose a different variant if needed
            value={pair.value}
            onChange={(e) => handleValueChange(index, e.target.value)}
            in
            putProps={{
              style: {
                textAlign: "left", // Set the text alignment to left
              },
            }}
          />
          {/* <textarea
            className="text-view-left-aligned"
            rows="4"
            cols="50"
            InputProps={{
              startAdornment: (
                <Typography className="MuiTypography-alignLeft">
                  Left-aligned Text
                </Typography>
              ),
            }}
            placeholder="Value"
            value={pair.value}
            onChange={(e) => handleValueChange(index, e.target.value)}
          /> */}
          <button
            // variant="outlined"
            // color="error"
            onClick={() => handleDelete(index)}
            className="kvf-delete-button"
          >
            <img src={deletesvg} />
          </button>
        </div>
      ))}
      {/* <button onClick={addRow} className="kvf-button">
        Add Row
      </button>*/}

      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <button
          onClick={addRow}
          className="kvf-button"
          style={{ margin: "10px" }}
        >
          Add new row
        </button>

        <button
          onClick={handleSaveClick}
          className="kvf-button"
          style={{ margin: "10px" }}
        >
          Save
        </button>
      </div>
    </div>
  );
});

export default KeyValueForm;
