import { useState, useEffect } from 'react';

function VariantEditor({ flowId, selectedVariant, variables, onCloseVariantEditor, onSaveVariantEditor }) {
  const [newVariantVariable, setNewVariantVariable] = useState('');
  const [variantVariableList, setVariantVariantList] = useState([]);

  const [selectedVariable, setSelectedVariable] = useState('');
const [newVariantVariableValue, setNewVariantVariableValue] = useState('');

useEffect(() => {
  if (selectedVariant.variables) {
    setVariantVariantList(selectedVariant.variables)
  }
  
}, []);


const handleVariableSelect = (event) => {
  setSelectedVariable(event.target.value);
  setNewVariantVariableValue('');
};

const handleVariantValueChange = (event) => {
  setNewVariantVariableValue(event.target.value);
};


  const handleVariantChange = (event) => {
    setNewVariantVariable(event.target.value);
  };

  const handleAddVariant = () => {
    
    setVariantVariantList([...variantVariableList, { name: selectedVariable, value: newVariantVariableValue }]);
    setNewVariantVariable('');
  };

  
  const handleSave = () => {
    onSaveVariantEditor(flowId, selectedVariant.id ,variantVariableList);
    onCloseVariantEditor();
  };

  return (
    <div className="VariantEditor__Overlay">
      <div className="VariantEditor">
        <div className="VariantEditor__Header">
          <h2>Variants</h2>
          {/* <h2>{selectedVariant.label}</h2> */}
          <button className="VariantEditor__CloseButton" onClick={onCloseVariantEditor}>
            Close
          </button>
        </div>
        <div className="VariantEditor__Content">
          <div className="VariantEditor__VariantList">
            {variantVariableList.map((variantVariable, variantIndex) => (
              <div key={variantIndex} className="VariantEditor__VariantCard">
                <div className="VariantEditor__VariantCardHeader">
                  <div>{variantVariable.name}</div>
                  <div>{variantVariable.value}</div>
                  <button
                    className="VariantEditor__DeleteButton"
                    onClick={() => {
                      const newVariantList = [...variantVariableList];
                      newVariantList.splice(variantIndex, 1);
                      setVariantVariantList(newVariantList);
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className="VariantEditor__AddVariant">
            <select value={selectedVariable} onChange={handleVariableSelect}>
              <option value="">Select Variable</option>
              {variables.map((variable, index) => (
                <option key={index} value={variable.name}>
                  {variable.name}
                </option>
              ))}
            </select>
            {selectedVariable && (
              <input type="text" value={newVariantVariableValue} onChange={handleVariantValueChange} />
            )}
            <button onClick={handleAddVariant} disabled={!selectedVariable}>
              Add Variant
            </button>
          </div>
        </div>
        <div className="VariantEditor__Footer">
          <button onClick={handleSave}>Save</button>
        </div>
      </div>
    </div>
  );
  


  // return (
  //   <div className="VariantEditor__Overlay">
  //     <div className="VariantEditor">
  //       <div className="VariantEditor__Header">
  //         <h2>Variants</h2>
  //         <h2>{selectedVariant}</h2>
  //         <button className="VariantEditor__CloseButton" onClick={onCloseVariantEditor}>
  //           Close
  //         </button>
  //       </div>
  //       <div className="VariantEditor__Content">
  //         <div className="VariantEditor__VariantList">
  //           {variantVariableList.map((variantVariable, variantIndex) => (
  //             <div key={variantIndex} className="VariantEditor__VariantCard">
  //               <div className="VariantEditor__VariantCardHeader">
  //                 <div>{variantVariable.name}</div>
  //                 <button
  //                   className="VariantEditor__DeleteButton"
  //                   onClick={() => {
  //                     const newVariantList = [...variantVariableList];
  //                     newVariantList.splice(variantIndex, 1);
  //                     setVariantVariantList(newVariantList);
  //                   }}
  //                 >
  //                   Delete
  //                 </button>
  //               </div>
             
  //             </div>
  //           ))}
  //         </div>
  //         <div className="VariantEditor__AddVariant">
  //           <select value={newVariantVariable} onChange={handleVariantChange}>
  //             <option value="">Select Variable</option>
  //             {variables.map((variable, index) => (
  //               <option key={index} value={variable.name}>
  //                 {variable.name}
  //               </option>
  //             ))}
  //           </select>
  //           <button onClick={handleAddVariant}>Add Variant</button>
  //         </div>
  //       </div>
  //       <div className="VariantEditor__Footer">
  //         <button onClick={handleSave}>Save</button>
  //       </div>
  //     </div>
  //   </div>
  // );
    

  // return (
  //   <div className="VariantEditor__Overlay">
  //     <div className="VariantEditor">
  //       <div className="VariantEditor__Header">
  //         <h2>Variants</h2>
  //         <h2>{selectedVariant}</h2>
  //         <button className="VariantEditor__CloseButton" onClick={onCloseVariantEditor}>
  //           Close
  //         </button>
  //       </div>
  //       <div className="VariantEditor__Content">
  //         <div className="VariantEditor__VariantList">
  //           {variantVariableList.map((variantVariable, variantIndex) => (
  //             <div key={variantIndex} className="VariantEditor__VariantCard">
  //               <div className="VariantEditor__VariantCardHeader">
  //                 <div>{variantVariable.title}</div>
  //                 {/* <input
  //                   type="text"
  //                   value={variantVariable.title}
  //                   onChange={(event) => {
  //                     const newVariantList = [...variantVariableList];
  //                     newVariantList[variantIndex].title = event.target.value;
  //                     setVariantVariantList(newVariantList);
  //                   }}
  //                 /> */}
  //                 <button
  //                   className="VariantEditor__DeleteButton"
  //                   onClick={() => {
  //                     const newVariantList = [...variantVariableList];
  //                     newVariantList.splice(variantIndex, 1);
  //                     setVariantVariantList(newVariantList);
  //                   }}
  //                 >
  //                   Delete
  //                 </button>
  //               </div>
  //               <div className="VariantEditor__VariableList">
  //                 {variables.map((variable, variableIndex) => (
  //                   <div key={variableIndex} className="VariantEditor__VariableCard">
  //                     <span>{variables[variableIndex].name}</span>
  //                     <input
  //                       type="text"
  //                       value={variable.value}
  //                       onChange={(event) => handleVariableChange(variantIndex, variableIndex, event)}
  //                     />
  //                   </div>
  //                 ))}
  //               </div>
  //             </div>
  //           ))}
  //         </div>
  //         <div className="VariantEditor__AddVariant">
  //                   <input type="text" value={newVariantVariable} onChange={handleVariantChange} />
  //                   <button onClick={handleAddVariant}>Add Variant</button>
  //                 </div>
  //               </div>
  //               <div className="VariantEditor__Footer">
  //                 <button onClick={handleSave}>Save</button>
  //               </div>
  //             </div>
  //           </div>
  //         );
}

export default VariantEditor;