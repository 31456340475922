import React, { useState, useEffect } from "react";
import { Rect, Transformer, Group, Image } from "react-konva";
import { EditableText } from "./TextComponents/EditableText";
import { Circle } from "react-konva";
import uuid from "react-uuid";
import { getDotSize } from "../Helper/Common";
import { Info } from "@mui/icons-material";
import { set } from "lodash";
import { debounce } from "lodash";
import ArrowCircle from "./../res/arrow_circle_right.svg";
import { Image as KonvaImage } from "react-konva";
import API_GATEWAY_IMAGE from "../res/tags/api_gateway.webp";
import DB_IMAGE from "../res/tags/db.webp";
import MONGO_IMAGE from "../res/tags/mongo.webp";
import REDIS_IMAGE from "../res/tags/redis.webp";
import NODE_IMAGE from "../res/tags/nodejs.webp";
import PYTHON_IMAGE from "../res/tags/python.webp";
import SQL_IMAGE from "../res/tags/sql.webp";
import KUBE_IMAGE from "../res/tags/kube.webp";
import KAFKA_TOPIC_IMAGE from "../res/tags/kafka-topic.webp";

import KAFKA_PRODUCER_IMAGE from "../res/tags/kafka-producer.webp";
import KAFKA_CONSUMER_IMAGE from "../res/tags/kakfa-consumer.webp";
import JAVA_IMAGE from "../res/tags/java.webp";

const Rectangle = ({
  shapeProps,
  zIndex,
  scale,
  isSelected,
  onSelect,
  onDragStartShape,
  onDragMoveShape,
  onDragEndShape,
  onToggleEdit,
  onClickArrowDraw,
  onDoubleClick,
  onTextChange,
  onResize,
  connectableDot,
  dragHoveringShapes,
  showPlayground,
  enableStageEditing,
  onInfoClick,
  animate,
  disabled,
  isCustomizingStep,
  shouldHide,
  showConnectors,
  updateConnectableDot,
  getComponentDetails,
  creatingNewArrow,
  enableConnectorForShape,
}) => {
  const rectRef = React.useRef();
  const trRef = React.useRef();

  const [update, setUpdate] = useState(0);
  const [mouseHover, setMouseHover] = useState(false);

  const [showingConnector, setShowingConnector] = useState(showConnectors);
  const topDotId = shapeProps.id + "-top-dot";
  const bottomDotId = shapeProps.id + "-bottom-dot";

  const leftDotId = shapeProps.id + "-left-dot";
  const leftTopCenterDotId = shapeProps.id + "-left-top-dot";
  const leftBottomCenterDotId = shapeProps.id + "-left-bottom-dot";

  const rightDotId = shapeProps.id + "-right-dot";
  const rightTopCenterDotId = shapeProps.id + "-right-top-dot";
  const rightBottomCenterDotId = shapeProps.id + "-right-bottom-dot";

  const topLeftDotId = shapeProps.id + "-top-left-dot";
  const topLeftCenterDotId = shapeProps.id + "-top-left-center-dot";
  const topCenterDotId = shapeProps.id + "-top-center-dot";
  const topRightCenterDotId = shapeProps.id + "-top-right-center-dot";
  const topRightDotId = shapeProps.id + "-top-right-dot";

  const bottomLeftDotId = shapeProps.id + "-bottom-left-dot";
  const bottomLeftCenterDotId = shapeProps.id + "-bottom-left-center-dot";
  const bottomCenterDotId = shapeProps.id + "-bottom-center-dot";
  const bottomRightCenterDotId = shapeProps.id + "-bottom-right-center-dot";
  const bottomRightDotId = shapeProps.id + "-bottom-right-dot";
  const [hoverDot, setHoverDot] = useState(null);
  const [listenMouseOverEvents, setListenMouseOverEvents] = useState(false);

  const dotType = "connectingDot";

  const [svgImage, setSvgImage] = useState(null);
  const [animationRequestId, setAnimationRequestId] = useState(null);

  const [textPosition, setTextPosition] = useState({ x: 0, y: 0 });
  let isClickInProgress = false;
  const [updatedShapeProps, setUpdatedShapeProps] = useState(shapeProps);

  const [arrowImageLoaded, setArrowImageLoaded] = useState(false);

  // Load the arrow image
  useEffect(() => {
    // const img = new window.Image();
    // img.src = ArrowCircleImage;
    // img.onload = () => {
    //   setArrowImageLoaded(true);
    // };
  }, []);

  useEffect(() => {
    // console.log("Creating New Arrow", creatingNewArrow);
    setListenMouseOverEvents(creatingNewArrow);
  }, [creatingNewArrow]);

  const animateRect = () => {
    const rect = rectRef.current;
    if (rect) {
      const dashOffset = (rect.dashOffset() + 0.5) % 20; // Adjust the speed and dash pattern as needed
      rect.dashOffset(dashOffset);
      rect.getLayer().batchDraw();
    }

    setAnimationRequestId(requestAnimationFrame(animateRect));
  };

  const isBetween = (num, one, two) => {
    return num >= one && num <= two;
  };
  useEffect(() => {
    if (
      enableConnectorForShape &&
      enableConnectorForShape.id == shapeProps.id
    ) {
      let px = enableConnectorForShape.x;
      let py = enableConnectorForShape.y;
      const diffMargin = 20;
      let xStartDiff = Math.abs(px - shapeProps.x);
      let xEndDiff = Math.abs(px - (shapeProps.x + shapeProps.width));
      let yStartDiff = Math.abs(py - shapeProps.y);
      let yEndDiff = Math.abs(py - (shapeProps.y + shapeProps.height));
      let h16 = shapeProps.y + shapeProps.height / 6;
      let h26 = shapeProps.y + (2 * shapeProps.height) / 6;
      let h36 = shapeProps.y + (3 * shapeProps.height) / 6;
      let h46 = shapeProps.y + (4 * shapeProps.height) / 6;
      let h56 = shapeProps.y + (5 * shapeProps.height) / 6;

      let w16 = shapeProps.x + shapeProps.width / 6;
      let w26 = shapeProps.x + (2 * shapeProps.width) / 6;
      let w36 = shapeProps.x + (3 * shapeProps.width) / 6;
      let w46 = shapeProps.x + (4 * shapeProps.width) / 6;
      let w56 = shapeProps.x + (5 * shapeProps.width) / 6;

      let maxX = shapeProps.x + shapeProps.width;
      let maxY = shapeProps.y + shapeProps.height;
      let newDot = null;
      if (xStartDiff < diffMargin) {
        if (isBetween(py, shapeProps.y, h16)) {
          newDot = topLeftDotId;
        } else if (isBetween(py, h16, h26)) {
          newDot = leftTopCenterDotId;
        } else if (isBetween(py, h26, h46)) {
          newDot = leftDotId;
        } else if (isBetween(py, h46, h56)) {
          newDot = leftBottomCenterDotId;
        } else if (isBetween(py, h56, maxY)) {
          newDot = bottomLeftDotId;
        }
      } else if (xEndDiff < diffMargin) {
        if (isBetween(py, shapeProps.y, h16)) {
          newDot = topRightDotId;
        } else if (isBetween(py, h16, h26)) {
          newDot = rightTopCenterDotId;
        } else if (isBetween(py, h26, h46)) {
          newDot = rightDotId;
        } else if (isBetween(py, h46, h56)) {
          newDot = rightBottomCenterDotId;
        } else if (isBetween(py, h56, maxY)) {
          newDot = bottomRightDotId;
        }
      } else if (yStartDiff < diffMargin) {
        if (isBetween(px, shapeProps.x, w16)) {
          newDot = topLeftDotId;
        } else if (isBetween(px, w16, w26)) {
          newDot = topLeftCenterDotId;
        } else if (isBetween(px, w26, w46)) {
          newDot = topCenterDotId;
        } else if (isBetween(px, w46, w56)) {
          newDot = topRightCenterDotId;
        } else if (isBetween(px, w56, maxX)) {
          newDot = topRightCenterDotId;
        }
      } else if (yEndDiff < diffMargin) {
        if (isBetween(px, shapeProps.x, w16)) {
          newDot = bottomLeftDotId;
        } else if (isBetween(px, w16, w26)) {
          newDot = bottomLeftCenterDotId;
        } else if (isBetween(px, w26, w46)) {
          newDot = bottomCenterDotId;
        } else if (isBetween(px, w46, w56)) {
          newDot = bottomRightCenterDotId;
        } else if (isBetween(px, w56, maxX)) {
          newDot = bottomRightDotId;
        }
      }
      if (newDot != hoverDot) {
        setHoverDot(newDot);
      }
    }
  }, [enableConnectorForShape]);

  useEffect(() => {
    // setUpdatedShapeProps(shapeProps);
    setUpdatedShapeProps({
      ...updatedShapeProps,
      isEditingText: shapeProps.isEditingText,
    });
  }, [shapeProps.isEditingText]);

  useEffect(() => {
    setUpdatedShapeProps({
      ...updatedShapeProps,
      tags: shapeProps.tags,
    });
  }, [shapeProps.tags]);

  useEffect(() => {
    setShowingConnector(showConnectors);
    if (!showConnectors) {
      setHoverDot(null);
    }
  }, [showConnectors]);

  useEffect(() => {
    // setUpdatedShapeProps(shapeProps);
    if (shapeProps.connectedArrows != updatedShapeProps.connectedArrows) {
      setUpdatedShapeProps({
        ...updatedShapeProps,
        connectedArrows: shapeProps.connectedArrows,
      });
    }
  }, [shapeProps.connectedArrows]);

  useEffect(() => {
    if (shapeProps.x != updatedShapeProps.x) {
      setUpdatedShapeProps({
        ...updatedShapeProps,
        x: shapeProps.x,
      });
    }
  }, [shapeProps.x]);

  useEffect(() => {
    if (shapeProps.y != updatedShapeProps.y) {
      setUpdatedShapeProps({
        ...updatedShapeProps,
        y: shapeProps.y,
      });
    }
  }, [shapeProps.y]);

  useEffect(() => {
    if (shapeProps.style != updatedShapeProps.style) {
      setUpdatedShapeProps({
        ...updatedShapeProps,
        style: shapeProps.style,
      });
    }
  }, [shapeProps.style]);

  useEffect(() => {
    if (animate) {
      if (!animationRequestId) {
        setAnimationRequestId(requestAnimationFrame(animateRect));
      }
    } else {
      if (animationRequestId) {
        cancelAnimationFrame(animationRequestId);
        setAnimationRequestId(null);
      }
    }
  }, [animate]);

  const opacity = () => {
    if (shouldHide) {
      return showPlayground ? 0.0 : 0.2;
    } else {
      return 1;
    }
  };

  // Load SVG image as a data URL
  useEffect(() => {
    const svgContent = `
      <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0C3.59 0 0 3.59 0 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zm1 12H7V7h2v5zm0-7H7V5h2v2z"
        fill="#000000"
      />
    </svg>
    `;
    const svgDataUrl = `data:image/svg+xml;base64,${btoa(svgContent)}`;

    const img = new window.Image();
    img.src = svgDataUrl;
    img.onload = () => {
      setSvgImage(img);
    };
  }, []);

  const dot = (x, y, id) => {
    return (
      <Group>
        <Circle
          x={x}
          y={y}
          id={id}
          shapeId={updatedShapeProps.id}
          shapeType={updatedShapeProps.type}
          type={dotType}
          radius={hoverDot == id ? getDotSize() : getDotSize() / 2}
          fill={id == hoverDot ? "#32CD32E6" : "#646161E6"}
          draggable={false}
          onMouseDown={() => {
            onClickArrowDraw(updatedShapeProps, id, { x: x, y: y });
          }}
        />
        {/* <Circle
          x={x}
          y={y}
          id={id}
          radius={getDotSize() * 2}
          fill={"#FFFFFF00"}
          onMouseDown={() => {
            onClickArrowDraw(updatedShapeProps, id, { x: x, y: y });
          }}
        /> */}
      </Group>
    );
  };

  useEffect(() => {
    if (showConnectors) {
      updateConnectableDot(hoverDot);
    }
  }, [hoverDot]);

  const points = () => {
    const x = updatedShapeProps.x;
    const y = updatedShapeProps.y;
    const width = updatedShapeProps.width;
    const height = updatedShapeProps.height;
    const topCenterDot = dot(x + width / 2, y, topCenterDotId);
    const bottomCenterDot = dot(x + width / 2, y + height, bottomCenterDotId);
    const rightDot = dot(x + width, y + height / 2, rightDotId);
    const leftDot = dot(x, y + height / 2, leftDotId);

    return (
      <Group>
        {topCenterDot}
        {bottomCenterDot}
        {leftDot}
        {rightDot}
      </Group>
    );
  };

  const targetRectPoints = () => {
    const x = updatedShapeProps.x;
    const y = updatedShapeProps.y;
    const width = updatedShapeProps.width;
    const height = updatedShapeProps.height;
    const topCenterDot = dot(x + width / 2, y, topCenterDotId);
    const bottomCenterDot = dot(x + width / 2, y + height, bottomCenterDotId);

    // Top dots
    const topLeftDot = dot(x, y, topLeftDotId);
    const topLeftCenterDot = dot(x + width / 4, y, topLeftCenterDotId);

    const topRightCenterDot = dot(x + (3 * width) / 4, y, topRightCenterDotId);
    const topRightDot = dot(x + width, y, topRightDotId);

    // Bottom dots
    const bottomLeftDot = dot(x, y + height, bottomLeftDotId);
    const bottomLeftCenterDot = dot(
      x + width / 4,
      y + height,
      bottomLeftCenterDotId
    );

    const bottomRightCenterDot = dot(
      x + (3 * width) / 4,
      y + height,
      bottomRightCenterDotId
    );
    const bottomRightDot = dot(x + width, y + height, bottomRightDotId);

    // Left and right dots
    const leftDot = dot(x, y + height / 2, leftDotId);
    const leftTopCenterDot = dot(x, y + height / 4, leftTopCenterDotId);
    const leftBottomCenterDot = dot(
      x,
      y + (3 * height) / 4,
      leftBottomCenterDotId
    );
    const rightDot = dot(x + width, y + height / 2, rightDotId);
    const rightTopCenterDot = dot(
      x + width,
      y + height / 4,
      rightTopCenterDotId
    );
    const rightBottomCenterDot = dot(
      x + width,
      y + (3 * height) / 4,
      rightBottomCenterDotId
    );

    return (
      <Group>
        {topLeftDot}
        {topLeftCenterDot}
        {topCenterDot}
        {topRightCenterDot}
        {topRightDot}
        {bottomLeftDot}
        {bottomLeftCenterDot}
        {bottomCenterDot}
        {bottomRightCenterDot}
        {bottomRightDot}
        {leftDot}
        {rightDot}
        {leftTopCenterDot}
        {leftBottomCenterDot}
        {rightTopCenterDot}
        {rightBottomCenterDot}
      </Group>
    );
  };

  React.useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([rectRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected, zIndex]);

  const propsForText = () => {
    return updatedShapeProps;

    let props = { ...updatedShapeProps };
    props.x = 0; //textPosition.x;
    props.y = textPosition.y;

    return props;
  };

  const textComp = (x) => {
    return (
      <EditableText
        shapeProps={propsForText()}
        draggable={false}
        onDoubleClick={onDoubleClick}
        // onDoubleClick={(e) => console.log(e)}
        onClick={(e) => {
          //e.stopPropagation()

          onSelect(updatedShapeProps.id);
        }}
        listening={false}
        opacity={opacity()}
        text={updatedShapeProps.text}
        isEditing={updatedShapeProps.isEditingText}
        onTextChange={(model, newText) => {
          setUpdatedShapeProps({
            ...updatedShapeProps,
            text: newText,
          });
          onTextChange(model, newText);
        }}
        // onSelect={(e) => {
        //   console.log("Text click");
        //   //e.stopPropagation()
        //   onSelect(updatedShapeProps.id);
        // }}
        onToggleEdit={(model) => {
          onToggleEdit(model);
        }}
        onDragStartShape={onDragStartShape}
        onDragMoveShape={(shape) => {
          // setTextPosition({ x: shape.x, y: shape.y }); // Adjust the position as needed
          // onDragMoveShape(shape);
        }}
        onDragEndShape={onDragEndShape}
      />
    );
  };

  const transformer = () => {
    return (
      <Transformer
        ref={trRef}
        // zIndex={20}
        listening={enableStageEditing}
        rotateEnabled={false}
        centeredScaling={false}
        keepRatio={false}
        enabledAnchors={[
          "top-left",
          "top-right",
          "bottom-left",
          "bottom-right",
        ]}
        boundBoxFunc={(oldBox, newBox) => {
          // limit resize
          if (newBox.width < 5 || newBox.height < 5) {
            return oldBox;
          }
          return newBox;
        }}
      />
    );
  };

  const updateMouseHover = (state) => {
    // console.log("Mouse Hover Changed", mouseHover);
    if (mouseHover != state) {
      setMouseHover(state);
    }
  };

  useEffect(() => {
    // console.log("Mouse Hover Changed", mouseHover);
  }, [mouseHover]);

  const InfoIcon = () => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0C3.59 0 0 3.59 0 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zm1 12H7V7h2v5zm0-7H7V5h2v2z"
        fill="#000000"
      />
    </svg>
  );

  const infoIconView = (id) => {
    console.log("Show infoIconView", showPlayground, updatedShapeProps.info);
    return (
      showPlayground &&
      updatedShapeProps.info !== undefined &&
      updatedShapeProps.info.length > 0 && (
        <Image
          onClick={() => {
            onInfoClick(id);
          }}
          image={svgImage}
          x={updatedShapeProps.x + updatedShapeProps.width - 20}
          y={updatedShapeProps.y + 4}
          width={16}
          height={16}
        />
      )
    );
  };

  const debounce1 = (func, delay) => {
    let timeoutId;

    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const handleDragMove = (e) => {
    // debounce1((e) => {
    onDragMoveShape({
      ...updatedShapeProps,
      x: Math.round(e.target.x()),
      y: Math.round(e.target.y()),
    });
    // }, 200)(e);

    //if (e.target.isDragging()) {
    setUpdatedShapeProps({
      ...updatedShapeProps,
      x: Math.round(e.target.x()),
      y: Math.round(e.target.y()),
      isDragging: true,
    });
    //}
  };

  const rectangleView = () => {
    return (
      <Group
      // onMouseEnter={(e) => {
      //   console.log(listenMouseOverEvents);
      //   e.evt.stopPropagation(); // Stop the event from bubbling further

      //   if (listenMouseOverEvents) {
      //     console.log("Entering ", shapeProps.id);
      //     if (mouseHover != true) updateMouseHover(true);
      //   }
      // }}
      // onMouseOut={(e) => {
      //   if (listenMouseOverEvents) {
      //     e.evt.stopPropagation(); // Stop the event from bubbling further

      //     console.log("Leaving ", shapeProps.id);
      //     if (mouseHover) updateMouseHover(false);
      //   }
      // }}
      >
        {updatedShapeProps.multipleInstance &&
          [...Array(2)].map((_, i) => (
            <Rect
              key={`${updatedShapeProps.id}-${i}`} // Ensure unique keys for each Rect
              // className="component"
              scaleX={scale}
              scaleY={scale}
              listening={false}
              draggable={!isCustomizingStep}
              {...updatedShapeProps}
              text={""}
              x={updatedShapeProps.x + 5 * (2 - i)}
              y={updatedShapeProps.y + 5 * (2 - i)}
              stroke={
                updatedShapeProps.style && updatedShapeProps.style.border
                  ? updatedShapeProps.style.border
                  : "black"
              }
              strokeWidth={
                updatedShapeProps.style &&
                updatedShapeProps.style.borderWidth != null
                  ? updatedShapeProps.style.borderWidth
                  : 0.5
              }
              fill={
                disabled
                  ? "gray"
                  : updatedShapeProps.style && updatedShapeProps.style.fill
                  ? updatedShapeProps.style.fill
                  : "white"
              }
              opacity={opacity()}
            />
          ))}
        <Rect
          key={updatedShapeProps.id}
          // className="component"
          scaleX={scale}
          scaleY={scale}
          opacity={opacity()}
          listening={enableStageEditing}
          draggable={!isCustomizingStep}
          dash={animate ? [(10, 10)] : [(0, 0)]} // Adjust the dash pattern as needed
          // perfectDrawEnabled={false}
          // onMouseDown={(e) => {
          //   // handle mousedown event
          //   console.log("Mouse down", e);
          //   e.evt.stopPropagation();
          //   onSelect(updatedShapeProps.id);
          // }}
          onClick={(e) => {
            if (e.evt.button < 2) {
              setUpdatedShapeProps({
                ...updatedShapeProps,
                isDragging: false,
                isSelected: true,
              });
              e.cancelBubble = true;
              onSelect(updatedShapeProps.id);
            }
          }}
          // onTap={(e) => {
          //   // e.evt.stopPropagation();
          //   console.log(e);
          //   onSelect(updatedShapeProps.id);
          // }}
          ref={rectRef}
          {...updatedShapeProps}
          onDblClick={(e) => {
            if (!isCustomizingStep) {
              setUpdatedShapeProps({
                ...updatedShapeProps,
                isEditingText: true,
              });

              onDoubleClick({ ...updatedShapeProps });
            }
          }}
          onDragStart={(e) => {
            // onDragStartShape({
            //   ...updatedShapeProps,
            //   x: e.target.x(),
            //   y: e.target.y(),
            // });
          }}
          onDragMove={handleDragMove}
          onDragEnd={(e) => {
            setUpdatedShapeProps({
              ...updatedShapeProps,
              x: e.target.x(),
              y: e.target.y(),
              isDragging: false,
            });
            onDragEndShape({
              ...updatedShapeProps,
              x: e.target.x(),
              y: e.target.y(),
            });
          }}
          stroke={
            updatedShapeProps.style && updatedShapeProps.style.border
              ? updatedShapeProps.style.border
              : "black"
          }
          strokeWidth={
            updatedShapeProps.style &&
            updatedShapeProps.style.borderWidth != null
              ? updatedShapeProps.style.borderWidth
              : 0.5
          }
          // opacity={disabled ? 0.1 : 1}

          fill={
            disabled
              ? "gray"
              : updatedShapeProps.style && updatedShapeProps.style.fill
              ? updatedShapeProps.style.fill
              : "white"
          }
          fontSize={
            updatedShapeProps.style && updatedShapeProps.style.fontSize
              ? updatedShapeProps.style.fontSize
              : 32
          }
          fontFamily={
            updatedShapeProps.style && updatedShapeProps.style.fontFamily
              ? updatedShapeProps.style.fontFamily
              : "Arial"
          }
          fontStyle={
            updatedShapeProps.style && updatedShapeProps.style.fontWeight
              ? updatedShapeProps.style.fontWeight
              : "normal"
          }
          onTransform={(e) => {
            // setUpdate(update+1)
            const node = rectRef.current;
            const scaleX = node.scaleX();
            const scaleY = node.scaleY();

            // we will reset it back
            node.scaleX(1);
            node.scaleY(1);

            setUpdatedShapeProps({
              ...updatedShapeProps,
              x: node.x(),
              y: node.y(),
              // set minimal value
              width: Math.max(5, node.width() * scaleX),
              height: Math.max(node.height() * scaleY),
            });

            onResize({
              ...updatedShapeProps,
              x: node.x(),
              y: node.y(),
              // set minimal value
              width: Math.max(5, node.width() * scaleX),
              height: Math.max(node.height() * scaleY),
            });
          }}
          onTransformEnd={(e) => {
            // transformer is changing scale of the node
            // and NOT its width or height
            // but in the store we have only width and height
            // to match the data better we will reset scale on transform end
            const node = rectRef.current;
            const scaleX = node.scaleX();
            const scaleY = node.scaleY();

            // we will reset it back
            node.scaleX(1);
            node.scaleY(1);

            setUpdatedShapeProps({
              ...updatedShapeProps,
              x: node.x(),
              y: node.y(),
              // set minimal value
              width: Math.max(5, node.width() * scaleX),
              height: Math.max(node.height() * scaleY),
            });

            onResize({
              ...updatedShapeProps,
              x: node.x(),
              y: node.y(),
              // set minimal value
              width: Math.max(5, node.width() * scaleX),
              height: Math.max(node.height() * scaleY),
            });
          }}
        />
      </Group>
    );
  };

  let counter = 0;

  const icon = (type) => {
    const imageObj = new window.Image();
    switch (type) {
      case "API_GATEWAY":
        imageObj.src = API_GATEWAY_IMAGE;
        break;
      case "DB":
        imageObj.src = DB_IMAGE;
        break;
      case "MONGO":
        imageObj.src = MONGO_IMAGE;
        break;
      case "REDIS":
        imageObj.src = REDIS_IMAGE;
        break;
      case "NODE":
        imageObj.src = NODE_IMAGE;
        break;
      case "PYTHON":
        imageObj.src = PYTHON_IMAGE;
        break;
      case "SQL":
        imageObj.src = SQL_IMAGE;
        break;
      case "KUBE":
        imageObj.src = KUBE_IMAGE;
        break;
      case "KAFKA_TOPIC":
        imageObj.src = KAFKA_TOPIC_IMAGE;
        break;
      case "API_GATEWAY":
        imageObj.src = API_GATEWAY_IMAGE;
        break;
      case "KAFKA_PRODUCER":
        imageObj.src = KAFKA_PRODUCER_IMAGE;
        break;
      case "KAFKA_CONSUMER":
        imageObj.src = KAFKA_CONSUMER_IMAGE;
        break;
      case "JAVA":
        imageObj.src = JAVA_IMAGE;
        break;
      default:
        return null;
    }

    return imageObj;
  };

  return (
    <React.Fragment key={updatedShapeProps.id + "-fragment"}>
      {updatedShapeProps.enableConnectors && (
        <Rect
          key={updatedShapeProps.id + "-connector"}
          perfectDrawEnabled={false}
          {...updatedShapeProps}
          stroke="red"
          strokeWidth={1}
          draggable={!isCustomizingStep}
          listening={enableStageEditing}
        />
      )}
      {rectangleView()}
      {textComp(enableConnectorForShape)}
      {(isSelected || mouseHover || updatedShapeProps.enableConnectors) &&
        transformer()}

      {isSelected && points()}
      {enableConnectorForShape &&
        enableConnectorForShape.id == shapeProps.id &&
        targetRectPoints()}
      {infoIconView(updatedShapeProps.id)}

      {updatedShapeProps.tags &&
        updatedShapeProps.tags.map((tag, index) => (
          <React.Fragment key={index}>
            {icon(tag) && (
              <Image
                onClick={() => {}}
                image={icon(tag)}
                x={shapeProps.x + shapeProps.width - 40 - index * 40}
                y={shapeProps.y + shapeProps.height - 40}
                width={36}
                height={36}
              />
            )}
          </React.Fragment>
        ))}
    </React.Fragment>
  );
};

const arePropsEqual = (prevProps, nextProps) => {
  return false;
  // Compare only the variables that should trigger re-rendering
  // return (
  //    prevProps.updatedShapeProps.x === nextProps.updatedShapeProps.x
  //    && prevProps.updatedShapeProps.y === nextProps.updatedShapeProps.y
  //    && prevProps.updatedShapeProps.width === nextProps.updatedShapeProps.width
  //    && prevProps.updatedShapeProps.height === nextProps.updatedShapeProps.height
  //   // Add additional comparisons as needed
  //   // prevProps.someProp === nextProps.someProp &&
  //   // prevProps.anotherProp === nextProps.anotherProp
  // );
};

export default Rectangle;
// export default React.memo(Rectangle, arePropsEqual);
