import React, { useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

function ListItemWithDropdown({ optionsIcon, step, handleMenuItemClick }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuClick = (action) => {
    // You can access the 'step' specific to this item
    console.log("Action:", action, "Step:", step);
    handleMenuItemClick(action, step);
    // Close the menu
    handleMenuClose();
  };
  const menuItemStyle = {
    "&:hover": {
      backgroundColor: "transparent",
    },
  };

  return (
    <div>
      {/* <Button
        onClick={handleMenuClick}
        style={{
          cursor: "pointer",
        }}
      > */}

      <img
        onClick={(e) => {
          e.stopPropagation();
          handleMenuClick(e);
        }}
        src={optionsIcon}
        alt="Options"
        style={{
          position: "relative",
          // top: "-4px",
          width: "16px",
          // marginRight: "20px",
          marginLeft: "20px",
          marginBottom: "4px",
          right: "-1px",
          top: "-2px",
          height: "16px",
          border: "1px solid #4c3fdc",
          borderBottomLeftRadius: "4px",
          borderBottomRightRadius: "4px",
        }}
      />
      {/* </Button> */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {/* <MenuItem onClick={() => menuClick("ANIMATE_STEP")}>Animate</MenuItem> */}
        <MenuItem
          onClick={() => menuClick("CREATE_NEW_STEP_ABOVE")}
          // className={menuItemStyle}
        >
          Insert Step Above
        </MenuItem>
        <MenuItem onClick={() => menuClick("MOVE_UP")}>Move up</MenuItem>
        <MenuItem onClick={() => menuClick("MOVE_DOWN")}>Move Down</MenuItem>
        <MenuItem onClick={() => menuClick("DELETE_STEP")}>
          Delete Step
        </MenuItem>
      </Menu>
    </div>
  );
}

export default ListItemWithDropdown;
