import React, { useRef, useEffect, useState } from "react";
import { Image as KonvaImage } from "react-konva";

import { Transformer, Rect, Group, Circle } from "react-konva";
import uuid from "react-uuid";
import { Info } from "@mui/icons-material";
import { getDotSize } from "../Helper/Common";
import { EditableText } from "./TextComponents/EditableText";

const ImageComp = ({
  shapeProps,
  zIndex,
  scale,
  isSelected,
  onSelect,
  onDragStartShape,
  onDragMoveShape,
  onDragEndShape,
  onToggleEdit,
  onClickArrowDraw,
  onDoubleClick,
  onTextChange,
  onResize,
  connectableDot,
  dragHoveringShapes,
  showPlayground,
  enableStageEditing,
  onInfoClick,
  animate,
  disabled,
  isCustomizingStep,
  shouldHide,
  showConnectors,
  updateConnectableDot,
  getComponentDetails,
  creatingNewArrow,
  enableConnectorForShape,
}) => {
  const imageRef = React.useRef();
  const trRef = React.useRef();
  // const [mouseHover, setMouseHover] = useState(false);
  // const topDotId = shapeProps.id + "-top-dot";
  // const bottomDotId = shapeProps.id + "-bottom-dot";
  // const leftDotId = shapeProps.id + "-left-dot";
  // const rightDotId = shapeProps.id + "-right-dot";
  // const dotType = "connectingDot";
  const [mouseHover, setMouseHover] = useState(false);

  const [showingConnector, setShowingConnector] = useState(showConnectors);
  const topDotId = shapeProps.id + "-top-dot";
  const bottomDotId = shapeProps.id + "-bottom-dot";

  const leftDotId = shapeProps.id + "-left-dot";
  const leftTopCenterDotId = shapeProps.id + "-left-top-dot";
  const leftBottomCenterDotId = shapeProps.id + "-left-bottom-dot";

  const rightDotId = shapeProps.id + "-right-dot";
  const rightTopCenterDotId = shapeProps.id + "-right-top-dot";
  const rightBottomCenterDotId = shapeProps.id + "-right-bottom-dot";

  const topLeftDotId = shapeProps.id + "-top-left-dot";
  const topLeftCenterDotId = shapeProps.id + "-top-left-center-dot";
  const topCenterDotId = shapeProps.id + "-top-center-dot";
  const topRightCenterDotId = shapeProps.id + "-top-right-center-dot";
  const topRightDotId = shapeProps.id + "-top-right-dot";

  const bottomLeftDotId = shapeProps.id + "-bottom-left-dot";
  const bottomLeftCenterDotId = shapeProps.id + "-bottom-left-center-dot";
  const bottomCenterDotId = shapeProps.id + "-bottom-center-dot";
  const bottomRightCenterDotId = shapeProps.id + "-bottom-right-center-dot";
  const bottomRightDotId = shapeProps.id + "-bottom-right-dot";
  const [hoverDot, setHoverDot] = useState(null);
  const [listenMouseOverEvents, setListenMouseOverEvents] = useState(false);
  const [updatedShapeProps, setUpdatedShapeProps] = useState(shapeProps);

  const dotType = "connectingDot";

  useEffect(() => {
    // console.log("Creating New Arrow", creatingNewArrow);
    setListenMouseOverEvents(creatingNewArrow);
  }, [creatingNewArrow]);

  const dot = (x, y, id) => {
    return (
      <Group>
        <Circle
          x={x}
          y={y}
          id={id}
          shapeId={updatedShapeProps.id}
          shapeType={updatedShapeProps.type}
          type={dotType}
          radius={hoverDot == id ? getDotSize() : getDotSize() / 2}
          fill={id == hoverDot ? "#32CD32E6" : "#646161E6"}
          draggable={false}
          onMouseDown={() => {
            onClickArrowDraw(updatedShapeProps, id, { x: x, y: y });
          }}
        />
        {/* <Circle
          x={x}
          y={y}
          id={id}
          radius={getDotSize() * 2}
          fill={"#FFFFFF00"}
          onMouseDown={() => {
            onClickArrowDraw(updatedShapeProps, id, { x: x, y: y });
          }}
        /> */}
      </Group>
    );
  };

  const isBetween = (num, one, two) => {
    return num >= one && num <= two;
  };
  useEffect(() => {
    if (
      enableConnectorForShape &&
      enableConnectorForShape.id == shapeProps.id
    ) {
      if (enableConnectorForShape) {
      }
      let px = enableConnectorForShape.x;
      let py = enableConnectorForShape.y;
      const diffMargin = 20;
      let xStartDiff = Math.abs(px - shapeProps.x);
      let xEndDiff = Math.abs(px - (shapeProps.x + shapeProps.width));
      let yStartDiff = Math.abs(py - shapeProps.y);
      let yEndDiff = Math.abs(py - (shapeProps.y + shapeProps.height));
      let h16 = shapeProps.y + shapeProps.height / 6;
      let h26 = shapeProps.y + (2 * shapeProps.height) / 6;
      let h36 = shapeProps.y + (3 * shapeProps.height) / 6;
      let h46 = shapeProps.y + (4 * shapeProps.height) / 6;
      let h56 = shapeProps.y + (5 * shapeProps.height) / 6;

      let w16 = shapeProps.x + shapeProps.width / 6;
      let w26 = shapeProps.x + (2 * shapeProps.width) / 6;
      let w36 = shapeProps.x + (3 * shapeProps.width) / 6;
      let w46 = shapeProps.x + (4 * shapeProps.width) / 6;
      let w56 = shapeProps.x + (5 * shapeProps.width) / 6;

      let maxX = shapeProps.x + shapeProps.width;
      let maxY = shapeProps.y + shapeProps.height;

      if (xStartDiff < diffMargin) {
        if (isBetween(py, shapeProps.y, h16)) {
          setHoverDot(topLeftDotId);
        } else if (isBetween(py, h16, h26)) {
          setHoverDot(leftTopCenterDotId);
        } else if (isBetween(py, h26, h46)) {
          setHoverDot(leftDotId);
        } else if (isBetween(py, h46, h56)) {
          setHoverDot(leftBottomCenterDotId);
        } else if (isBetween(py, h56, maxY)) {
          setHoverDot(bottomLeftDotId);
        }
      } else if (xEndDiff < diffMargin) {
        if (isBetween(py, shapeProps.y, h16)) {
          setHoverDot(topRightDotId);
        } else if (isBetween(py, h16, h26)) {
          setHoverDot(rightTopCenterDotId);
        } else if (isBetween(py, h26, h46)) {
          setHoverDot(rightDotId);
        } else if (isBetween(py, h46, h56)) {
          setHoverDot(rightBottomCenterDotId);
        } else if (isBetween(py, h56, maxY)) {
          setHoverDot(bottomRightDotId);
        }
      } else if (yStartDiff < diffMargin) {
        if (isBetween(px, shapeProps.x, w16)) {
          setHoverDot(topLeftDotId);
        } else if (isBetween(px, w16, w26)) {
          setHoverDot(topLeftCenterDotId);
        } else if (isBetween(px, w26, w46)) {
          setHoverDot(topCenterDotId);
        } else if (isBetween(px, w46, w56)) {
          setHoverDot(topRightCenterDotId);
        } else if (isBetween(px, w56, maxX)) {
          setHoverDot(topRightCenterDotId);
        }
      } else if (yEndDiff < diffMargin) {
        if (isBetween(px, shapeProps.x, w16)) {
          setHoverDot(bottomLeftDotId);
        } else if (isBetween(px, w16, w26)) {
          setHoverDot(bottomLeftCenterDotId);
        } else if (isBetween(px, w26, w46)) {
          setHoverDot(bottomCenterDotId);
        } else if (isBetween(px, w46, w56)) {
          setHoverDot(bottomRightCenterDotId);
        } else if (isBetween(px, w56, maxX)) {
          setHoverDot(bottomRightDotId);
        }
      }
    }
  }, [enableConnectorForShape]);

  const targetRectPoints = () => {
    const x = updatedShapeProps.x;
    const y = updatedShapeProps.y;
    const width = updatedShapeProps.width;
    const height = updatedShapeProps.height;
    const topCenterDot = dot(x + width / 2, y, topCenterDotId);
    const bottomCenterDot = dot(x + width / 2, y + height, bottomCenterDotId);

    // Top dots
    const topLeftDot = dot(x, y, topLeftDotId);
    const topLeftCenterDot = dot(x + width / 4, y, topLeftCenterDotId);

    const topRightCenterDot = dot(x + (3 * width) / 4, y, topRightCenterDotId);
    const topRightDot = dot(x + width, y, topRightDotId);

    // Bottom dots
    const bottomLeftDot = dot(x, y + height, bottomLeftDotId);
    const bottomLeftCenterDot = dot(
      x + width / 4,
      y + height,
      bottomLeftCenterDotId
    );

    const bottomRightCenterDot = dot(
      x + (3 * width) / 4,
      y + height,
      bottomRightCenterDotId
    );
    const bottomRightDot = dot(x + width, y + height, bottomRightDotId);

    // Left and right dots
    const leftDot = dot(x, y + height / 2, leftDotId);
    const leftTopCenterDot = dot(x, y + height / 4, leftTopCenterDotId);
    const leftBottomCenterDot = dot(
      x,
      y + (3 * height) / 4,
      leftBottomCenterDotId
    );
    const rightDot = dot(x + width, y + height / 2, rightDotId);
    const rightTopCenterDot = dot(
      x + width,
      y + height / 4,
      rightTopCenterDotId
    );
    const rightBottomCenterDot = dot(
      x + width,
      y + (3 * height) / 4,
      rightBottomCenterDotId
    );

    return (
      <Group>
        {topLeftDot}
        {topLeftCenterDot}
        {topCenterDot}
        {topRightCenterDot}
        {topRightDot}
        {bottomLeftDot}
        {bottomLeftCenterDot}
        {bottomCenterDot}
        {bottomRightCenterDot}
        {bottomRightDot}
        {leftDot}
        {rightDot}
        {leftTopCenterDot}
        {leftBottomCenterDot}
        {rightTopCenterDot}
        {rightBottomCenterDot}
      </Group>
    );
  };
  const points = () => {
    const x = shapeProps.x;
    const y = shapeProps.y;
    const width = shapeProps.width;
    const height = shapeProps.height;
    const topDot = dot(x + width / 2, y, topDotId);
    const leftDot = dot(x, y + height / 2, leftDotId);
    const bottomDot = dot(x + width / 2, y + height, bottomDotId);
    const rigtDot = dot(x + width, y + height / 2, rightDotId);
    return (
      <Group>
        {topDot}
        {leftDot}
        {bottomDot}
        {rigtDot}
      </Group>
    );
  };

  useEffect(() => {
    setShowingConnector(showConnectors);
    if (!showConnectors) {
      setHoverDot(null);
    }
  }, [showConnectors]);

  useEffect(() => {
    // setUpdatedShapeProps(shapeProps);
    if (shapeProps.connectedArrows != updatedShapeProps.connectedArrows) {
      setUpdatedShapeProps({
        ...updatedShapeProps,
        connectedArrows: shapeProps.connectedArrows,
      });
    }
  }, [shapeProps.connectedArrows]);

  useEffect(() => {
    if (shapeProps.x != updatedShapeProps.x) {
      setUpdatedShapeProps({
        ...updatedShapeProps,
        x: shapeProps.x,
      });
    }
  }, [shapeProps.x]);

  useEffect(() => {
    if (shapeProps.y != updatedShapeProps.y) {
      setUpdatedShapeProps({
        ...updatedShapeProps,
        y: shapeProps.y,
      });
    }
  }, [shapeProps.y]);

  useEffect(() => {
    if (showConnectors) {
      updateConnectableDot(hoverDot);
    }
  }, [hoverDot]);

  const updateMouseHover = (state) => {
    if (mouseHover != state) {
      setMouseHover(state);
    }
  };

  const opacity = () => {
    if (shouldHide) {
      return showPlayground ? 0.0 : 0.2;
    } else {
      return 1;
    }
  };

  React.useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([imageRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected, zIndex]);

  const transformer = () => {
    return (
      <Transformer
        ref={trRef}
        // zIndex={10}
        listening={enableStageEditing}
        rotateEnabled={false}
        centeredScaling={false}
        keepRatio={false}
        enabledAnchors={[
          "top-left",
          "top-right",
          "bottom-left",
          "bottom-right",
        ]}
        boundBoxFunc={(oldBox, newBox) => {
          // limit resize
          if (newBox.width < 5 || newBox.height < 5) {
            return oldBox;
          }
          return newBox;
        }}
      />
    );
  };

  useEffect(() => {
    const img = new window.Image();
    img.onload = () => {
      // Access the KonvaImage node and set its image
      if (imageRef.current) {
        imageRef.current.image(img);
      }
    };

    if (shapeProps.imageUrl != null) {
      img.src = shapeProps.imageUrl;
    }
  }, []);

  const imageView = () => {
    return (
      <KonvaImage
        key={shapeProps.id}
        scaleX={scale}
        scaleY={scale}
        draggable={!isCustomizingStep}
        listening={enableStageEditing}
        onClick={(e) => {
          // console.log("Image Clicked");
          if (e.evt.button < 2) {
            onSelect(shapeProps.id);
          }
        }}
        // onTap={(e) => {
        //   // console.log(e);
        //   onSelect(shapeProps.id);
        // }}
        ref={imageRef}
        opacity={opacity()}
        {...shapeProps}
        onDblClick={(e) => {
          onDoubleClick({ ...shapeProps });
        }}
        onDragMove={(e) => {
          onDragMoveShape({
            ...shapeProps,
            x: Math.round(e.target.x()),
            y: Math.round(e.target.y()),
          });
        }}
        onDragEnd={(e) => {
          onDragEndShape({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        stroke={
          shapeProps.style && shapeProps.style.border
            ? shapeProps.style.border
            : "black"
        }
        strokeWidth={
          shapeProps.style && shapeProps.style.borderWidth != null
            ? shapeProps.style.borderWidth
            : 0.5
        }
        // opacity={disabled ? 0.1 : 1}
        onMouseEnter={(e) => {
          // console.log("Entering")
          updateMouseHover(true);
          const container = e.target.getStage().container();
          container.style.cursor = "pointer";
        }}
        onMouseLeave={(e) => {
          if (!dragHoveringShapes || dragHoveringShapes.length === 0) {
            // console.log("leaving")
            updateMouseHover(false);
            const container = e.target.getStage().container();
            container.style.cursor = "default";
          }
        }}
        onTransform={(e) => {
          // setUpdate(update+1)
          const node = imageRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          // we will reset it back
          node.scaleX(1);
          node.scaleY(1);
          onResize({
            ...shapeProps,
            x: node.x(),
            y: node.y(),
            // set minimal value
            width: Math.max(5, node.width() * scaleX),
            height: Math.max(node.height() * scaleY),
          });
        }}
        onTransformEnd={(e) => {
          const node = imageRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          // we will reset it back
          node.scaleX(1);
          node.scaleY(1);
          onResize({
            ...shapeProps,
            x: node.x(),
            y: node.y(),
            // set minimal value
            width: Math.max(5, node.width() * scaleX),
            height: Math.max(node.height() * scaleY),
          });
        }}
      />
    );
  };

  const propsForText = () => {
    let margin = 0;
    let y = shapeProps.y + shapeProps.height + margin;

    // console.log(textPosition.x, textPosition.y);
    let props = { ...shapeProps };
    props.y = y;
    props.height = shapeProps.style.textHeight; //shapeProps.extraHeightForText - margin;

    return props;
  };
  const textComp = () => {
    return (
      <EditableText
        shapeProps={propsForText()}
        draggable={false}
        listening={!shapeProps.isEditingText}
        onDoubleClick={(e) => {
          onDoubleClick({ ...shapeProps });
        }}
        text={shapeProps.text}
        isEditing={shapeProps.isEditingText}
        onTextChange={onTextChange}
        onSelect={(e) => {
          // e.stopPropagation();
          onSelect(shapeProps.id);
        }}
        borderWidth={3}
      />
    );
  };
  return (
    <React.Fragment key={shapeProps.id + "-fragment"}>
      {shapeProps.enableConnectors && (
        <Rect
          key={shapeProps.id + "-connector"}
          perfectDrawEnabled={false}
          {...shapeProps}
          height={shapeProps.height + 40}
          stroke="red"
          strokeWidth={1}
          listening={enableStageEditing}
        />
      )}

      {imageView()}
      {textComp()}

      {isSelected && transformer()}
      {isSelected && points()}
      {enableConnectorForShape &&
        enableConnectorForShape.id == shapeProps.id &&
        targetRectPoints()}
    </React.Fragment>
  );
};

export default ImageComp;
// export default React.memo(Rectangle, arePropsEqual);
