import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Dropdown } from "react-bootstrap";

const EmailInput = forwardRef(({ updateEmails }, ref) => {
  const [emails, setEmails] = useState([]);
  const [newEmail, setNewEmail] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  useImperativeHandle(ref, () => ({
    handleAddEmail,
  }));

  const handleNewEmailChange = (e) => {
    setNewEmail(e.target.value);
    setShowDropdown(false);
    // if (e.target.value.includes("@")) {
    //   setSuggestions([
    //     `${e.target.value}gmail.com`,
    //     `${e.target.value}yahoo.com`,
    //     `${e.target.value}hotmail.com`,
    //   ]);
    //   setShowDropdown(true);
    // } else {
    //   setSuggestions([]);
    // }
  };

  const handleSuggestionClick = (email) => {
    setEmails([...emails, email]);
    setNewEmail("");
    setShowDropdown(false);
  };

  const handleAddEmail = (tappedShapedButton = false) => {
    let newlyAddedEmail = newEmail;
    if (!tappedShapedButton) {
      if (newEmail !== "" && !emails.includes(newEmail)) {
        setEmails([...emails, newEmail]);
        setNewEmail("");
        setShowDropdown(false);
      }
    } else {
      setNewEmail("");
      setShowDropdown(false);
      return newlyAddedEmail;
    }
  };

  const handleRemoveEmail = (email) => {
    setEmails(emails.filter((e) => e !== email));
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleAddEmail();
    }
  };

  useEffect(() => {
    updateEmails(emails);
  }, [emails, updateEmails]);

  return (
    <div>
      <div style={{ position: "relative" }}>
        <input
          style={{ marginLeft: "5px", width: "100%" }}
          type="email"
          placeholder="Email address"
          value={newEmail}
          onChange={handleNewEmailChange}
          onKeyDown={handleKeyDown}
        />
        {showDropdown &&
          suggestions.map((email) => (
            <Dropdown.Item
              key={email}
              onClick={() => handleSuggestionClick(email)}
            >
              {email}
            </Dropdown.Item>
          ))}
      </div>

      <div>
        {emails.map((email) => (
          <div key={email} className="email-bubble">
            <span>{email}</span>
            <button
              type="button"
              className="remove-button"
              onClick={() => handleRemoveEmail(email)}
            >
              x
            </button>
          </div>
        ))}
      </div>
    </div>
  );
});

export default EmailInput;

// import React, { useState, useEffect } from "react";

// import { Button, Dropdown, DropdownButton } from "react-bootstrap";

// /**
//  * Represents an email input component.
//  * @param {Object} props - The component props.
//  * @param {Function} props.updateEmails - The function to update the list of emails.
//  * @returns {JSX.Element} The email input component.
//  */
// function EmailInput({ updateEmails }) {
//   const [emails, setEmails] = useState([]);
//   const [newEmail, setNewEmail] = useState("");
//   const [suggestions, setSuggestions] = useState([]);
//   const [showDropdown, setShowDropdown] = useState(false);

//   const handleNewEmailChange = (e) => {
//     setNewEmail(e.target.value);
//     setShowDropdown(false);
//     // if (e.target.value.includes("@")) {
//     //   setSuggestions([
//     //     `${e.target.value}gmail.com`,
//     //     `${e.target.value}yahoo.com`,
//     //     `${e.target.value}hotmail.com`,
//     //   ]);
//     //   setShowDropdown(true);
//     // } else {
//     //   setSuggestions([]);
//     // }
//   };

//   const handleSuggestionClick = (email) => {
//     setEmails([...emails, email]);
//     setNewEmail("");
//     setShowDropdown(false);
//   };

//   const handleAddEmail = () => {
//     if (newEmail !== "" && !emails.includes(newEmail)) {
//       setEmails([...emails, newEmail]);
//       setNewEmail("");
//       setShowDropdown(false);
//     }
//   };

//   const handleRemoveEmail = (email) => {
//     setEmails(emails.filter((e) => e !== email));
//   };

//   const handleKeyDown = (e) => {
//     if (e.key === "Enter") {
//       handleAddEmail();
//     }
//   };

//   useEffect(() => {
//     updateEmails(emails);
//   }, [emails]);

//   return (
//     <div>
//       <div style={{ position: "relative" }}>
//         <input
//           style={{ marginLeft: "5px", width: "100%" }}
//           type="email"
//           placeholder="Email address"
//           value={newEmail}
//           onChange={handleNewEmailChange}
//           onKeyDown={handleKeyDown}
//         />
//         {showDropdown &&
//           suggestions.map((email) => (
//             <Dropdown.Item
//               key={email}
//               onClick={() => handleSuggestionClick(email)}
//             >
//               {email}
//             </Dropdown.Item>
//           ))}
//       </div>

//       <div>
//         {emails.map((email) => (
//           <div key={email} className="email-bubble">
//             <span>{email}</span>
//             <button
//               type="button"
//               className="remove-button"
//               onClick={() => handleRemoveEmail(email)}
//             >
//               x
//             </button>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }

// export default EmailInput;
