export const insertableImages = () => {
  return {
    General: [
      {
        title: "User",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/user.svg",
      },
      {
        title: "Computer",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/computer-svgrepo-com.svg",
      },
      {
        title: "Mobile",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/iphone-style-smartphone-material-svgrepo-com.svg",
      },
    ],
    Database: [
      {
        title: "SQL",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/sql-file-format-symbol-svgrepo-com.svg",
      },
      {
        title: "MongoDB",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/MongoDB_Logomark_SpringGreen.svg",
      },
      {
        title: "PostgreSQL",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/postgres.svg",
      },
      {
        title: "ElasticSearch",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/elasticsearch-svgrepo-com.svg",
      },
      {
        title: "Solr",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/solr-svgrepo-com.svg",
      },

      {
        title: "Redis",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/redis-svgrepo-com.svg",
      },
      {
        title: "Aserospike",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/aerospike-icon-svgrepo-com.svg",
      },
      {
        title: "Apache Cassandra",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/apachecassandra-svgrepo-com.svg",
      },
    ],
    Queue: [
      {
        title: "Kafka",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/kafka-icon-svgrepo-com.svg",
      },
      {
        title: "AWS SQS",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/aws-sqs-svgrepo-com.svg",
      },
      {
        title: "RabbitMQ",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/rabbitmq-icon-svgrepo-com.svg",
      },
    ],

    "Programming Language": [
      {
        title: "Python",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/python.svg",
      },
      {
        title: "Javascript",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/js-svgrepo-com.svg",
      },

      {
        title: "HTML",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/html-tag-svgrepo-com.svg",
      },
      {
        title: "GoLang",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/go-svgrepo-com.svg",
      },
      {
        title: "Java",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/java-svgrepo-com.svg",
      },
      {
        title: "Kotlin",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/kotlin-svgrepo-com.svg",
      },
      {
        title: "Swift",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/swift-svgrepo-com.svg",
      },
    ],
    AWS: [
      {
        title: "Amazon ElastiCache",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/aws/Arch_Amazon-ElastiCache_16.svg",
      },
      {
        title: "Amazon Route 53",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/aws/Arch_Amazon-Route-53_16.svg",
      },
      {
        title: "Amazon MQ",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/aws/Arch_Amazon-MQ_16.svg",
      },
      {
        title: "Amazon Simple Notification Service",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/aws/Arch_Amazon-Simple-Notification-Service_16.svg",
      },
      {
        title: "Amazon Simple Queue Service",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/aws/Arch_Amazon-Simple-Queue-Service_16.svg",
      },
      {
        title: "Amazon EKS Distro",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/aws/Arch_Amazon-EKS-Distro_16.svg",
      },
      {
        title: "Amazon Managed Streaming for Apache Kafka",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/aws/Arch_Amazon-Managed-Streaming-for-Apache-Kafka_16.svg",
      },
      {
        title: "Amazon DynamoDB",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/aws/Arch_Amazon-DynamoDB_16.svg",
      },
      {
        title: "Amazon CloudFront",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/aws/Arch_Amazon-CloudFront_16.svg",
      },
      {
        title: "AWS Network Firewall",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/aws/Arch_AWS-Network-Firewall_16.svg",
      },
      {
        title: "Amazon EventBridge",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/aws/Arch_Amazon-EventBridge_16.svg",
      },
      {
        title: "AWS Client VPN",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/aws/Arch_AWS-Client-VPN_16.svg",
      },
      {
        title: "Amazon Redshift",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/aws/Arch_Amazon-Redshift_16.svg",
      },
      {
        title: "Amazon GuardDuty",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/aws/Arch_Amazon-GuardDuty_16.svg",
      },
      {
        title: "Virtual Private Cloud (VPC)",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/aws/Virtual-private-cloud-VPC_32.svg",
      },
      {
        title: "AWS PrivateLink",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/aws/Arch_AWS-PrivateLink_16.svg",
      },
      {
        title: "AWS WAF",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/aws/Arch_AWS-WAF_16.svg",
      },
      {
        title: "Amazon RDS on VMware",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/aws/Arch_Amazon-RDS-on-VMware_16.svg",
      },
      {
        title: "Amazon Elastic Kubernetes Service",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/aws/Arch_Amazon-Elastic-Kubernetes-Service_16.svg",
      },
      {
        title: "AWS Glue Elastic Views",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/aws/Arch_AWS-Glue-Elastic-Views_16.svg",
      },
      {
        title: "Amazon Virtual Private Cloud",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/aws/Arch_Amazon-Virtual-Private-Cloud_16.svg",
      },
      {
        title: "AWS Data Pipeline",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/aws/Arch_AWS-Data-Pipeline_16.svg",
      },
      {
        title: "AWS Secrets Manager",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/aws/Arch_AWS-Secrets-Manager_16.svg",
      },
      {
        title: "AWS Glue",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/aws/Arch_AWS-Glue_16.svg",
      },
      {
        title: "Amazon API Gateway",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/aws/Arch_Amazon-API-Gateway_16.svg",
      },
      {
        title: "Amazon MemoryDB for Redis",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/aws/Arch_Amazon-MemoryDB-for-Redis_16.svg",
      },
      {
        title: "Amazon DocumentDB",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/aws/Arch_Amazon-DocumentDB_16.svg",
      },
      {
        title: "Elastic Load Balancing",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/aws/Arch_Elastic-Load-Balancing_16.svg",
      },
      {
        title: "AWS Cloud",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/aws/AWS-Cloud_32_Dark.svg",
      },
      {
        title: "Amazon Aurora",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/aws/Arch_Amazon-Aurora_16.svg",
      },
      {
        title: "Amazon Quantum Ledger Database",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/aws/Arch_Amazon-Quantum-Ledger-Database_16.svg",
      },
      {
        title: "Amazon RDS",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/aws/Arch_Amazon-RDS_16.svg",
      },
      {
        title: "AWS Lambda",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/aws/Arch_AWS-Lambda_16.svg",
      },
      {
        title: "Auto Scaling Group",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/aws/Auto-Scaling-group_32.svg",
      },
      {
        title: "Amazon Managed Blockchain",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/aws/Arch_Amazon-Managed-Blockchain_16.svg",
      },
      {
        title: "Amazon EC2",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/aws/Arch_Amazon-EC2_16.svg",
      },
      {
        title: "Amazon EC2 Auto Scaling",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/aws/Arch_Amazon-EC2-Auto-Scaling_16.svg",
      },
      {
        title: "Private Subnet",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/aws/Private-subnet_32.svg",
      },
      {
        title: "Public Subnet",
        url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/aws/Public-subnet_32.svg",
      },
    ],
  };
};
