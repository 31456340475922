import React, { useState, useEffect, useRef } from "react";
import uuid from "react-uuid";
import { Button, Card } from "react-bootstrap";
import { Label } from "react-konva";
import { Modal } from "react-bootstrap";
import { getCookie, setCookie } from "../Helper/CookieManager";

import {
  getDiagrams,
  searchDiagrams,
  createDiagram,
  delistItem,
} from "../Helper/DataService";
import deletesvg from "../res/delete.png";

const ChooseDiagram = ({
  userId,
  onChooseDiagram,
  chooseFromExisting,
  onDismissView,
  currentlyDiagramId,
}) => {
  const [showExitingDiagrams, setShowExitingDiagrams] =
    useState(chooseFromExisting);
  const [allDiagrams, setAllDiagrams] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [itemToDelist, setItemToDelist] = useState(null);
  const popupRef = useRef(null);

  const createNewDiagram = () => {
    createDiagram({ title: "Unnamed diagram" }, userId).then((res) => {
      let diagram = res.data;
      onChooseDiagram(diagram.diagramId);
    });
  };

  const chooseExistingDiagram = () => {
    setShowExitingDiagrams(true);
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
    searchDiagrams(userId, event.target.value, 0, 50).then((res) => {
      setAllDiagrams(res.data);
    });
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        // User clicked outside the popup, close it
        onDismissView();
        setShowExitingDiagrams(false);
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  function formatDate(dateString) {
    const options = {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const date = new Date(dateString);
    return date.toLocaleString("en-US", options).replace(/,/g, ""); // Remove comma after the day
  }

  const existingDiagrams = () => {
    let diagramsComponent = [];
    allDiagrams.forEach((data) => {
      diagramsComponent.push(
        <Card
          key={data.diagram.id}
          style={{
            width: `calc((100% - 40px)/3)`,
            height: "100px",
            margin: "5px",
            // alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div
                className="choose-diagram-title"
                onClick={(event) => {
                  event.preventDefault();
                  if (data.diagram.id != currentlyDiagramId) {
                    onChooseDiagram(data.diagram.id);
                  } else {
                    onDismissView();
                  }
                }}
              >
                {data.diagram.title}
              </div>
              <div
                // className="choose-diagram-title"
                style={{
                  // display: "flex",
                  // flexDirection: "column", // Change "row" to "column"
                  // paddingBottom: "40px",
                  paddingLeft: "10px",
                }}
                onClick={(event) => {
                  event.preventDefault();
                  //onChooseDiagram(data.diagram.id);
                  if (data.diagram.id != currentlyDiagramId) {
                    onChooseDiagram(data.diagram.id);
                  } else {
                    onDismissView();
                  }
                }}
              >
                {data.diagram && data.diagram.lastUpdatedBy && (
                  <div>
                    <div>Last Update : {data.diagram.lastUpdatedBy} at</div>
                    <div>{formatDate(data.updatedAt)}</div>
                  </div>
                )}
              </div>
            </div>
            {data.diagram && data.diagram.id != currentlyDiagramId && (
              <div
                className="red-button"
                style={{ height: "24px", marginTop: "4px" }}
                onClick={() => {
                  console.log("Delete Diagram ", data.diagram);
                  setItemToDelist(data.diagram.id);
                }}
              >
                Remove{" "}
              </div>
            )}
            {data.diagram && data.diagram.id == currentlyDiagramId && (
              <div
                className="blue-button"
                style={{
                  // color: "white",
                  // borderRadius: "4px",
                  // backgroundColor: "lightgray",
                  height: "20px",
                  fontSize: "12px",
                  // display: "flex",
                  // alignItems: "center",

                  // // paddingRight: "8px",
                  // ///justifyContent: "center",
                  // marginRight: "2px",
                  // padding: "8px",
                  marginTop: "8px",
                  cursor: "pointer",
                }}
              >
                Selected
              </div>
            )}
          </div>
        </Card>
        // {/* <button
        //   className="choose-diagram-delete-button"

        //   onClick={() => {
        //     console.log("Delete Diagram ", data.diagramId);
        //     onDeleteDiagram(data.diagramId, () => {
        //       // This callback will be executed when the deletion is complete
        //       console.log("Diagram deleted successfully");
        //       searchDiagrams(userId, searchText).then((res) => {
        //         setAllDiagrams(res.data);
        //       });
        //     });
        //   }}
        // >
        //   <img src={deletesvg} alt="Icon Description" />
        // </button> */}
      );
    });
    return <div className="choose-diagram">{diagramsComponent}</div>;
  };

  const showSelectionOptions = () => {
    if (showExitingDiagrams == false && false) {
      return (
        <div className="button-container">
          <Button onClick={createNewDiagram}>Create New</Button>
          <Button onClick={chooseExistingDiagram}>Choose Existing</Button>
        </div>
      );
    } else {
      if (allDiagrams == null) {
        searchDiagrams(userId, searchText, 0, 50).then((res) => {
          setAllDiagrams(res.data);
        });
      } else {
        return (
          <div style={{ height: "100%" }} ref={popupRef}>
            <div
              style={{
                height: "64px",
                // background: "#002966",
                zIndex: 15,
                fontSize: "16px",
                fontWeight: "600",
                paddingTop: "10px", // Padding top
                paddingLeft: "10px", // Padding left
                // height: "80%",
                marginBottom: "12px",
                color: "black",
              }}
            >
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  paddingTop: "0px", // Padding top
                  paddingLeft: "0px", // Padding left
                }}
              >
                {"Select Diagram"}
              </div>

              <div
                style={{
                  fontSize: "11px",
                  fontWeight: "400",
                  paddingTop: "4px", // Padding top
                  paddingLeft: "0px", // Padding left
                }}
              >
                {
                  "You can choose from last accesed diagrams. Use the seach filed to search for any diagrams."
                }
              </div>
            </div>

            <input
              type="text"
              value={searchText}
              onChange={handleSearchChange}
              placeholder="Search..."
              className="search-input"
              style={{ margin: "20px", width: `calc(100% - 40px)` }}
            />
            {existingDiagrams()}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {currentlyDiagramId ? (
                <div
                  className="button-type-1"
                  style={{
                    marginTop: "12px",
                    height: "44px",
                    width: "128px",
                    fontSize: "14px",
                    paddingRight: "32px",
                    display: "flex", // Set display to flex
                    alignItems: "center", // Vertically center the content
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                  onClick={onDismissView}
                >
                  Dismiss
                </div>
              ) : (
                <div></div>
              )}
              <div
                className="blue-button"
                style={{
                  marginTop: "12px",
                  height: "44px",
                  width: "128px",
                  fontSize: "14px",
                  display: "flex", // Set display to flex
                  alignItems: "center", // Vertically center the content
                  justifyContent: "center",
                  textAlign: "center",
                }}
                onClick={createNewDiagram}
              >
                Create New
              </div>
            </div>
          </div>
        );
      }
    }
  };

  const removeDiagramFromCookie = (diagramId) => {
    let cookie = getCookie("recent_diagrams", true);

    debugger;
    if (cookie != null && cookie != "") {
      let recent_cookie = cookie;
      let updatedCookie = recent_cookie.filter(
        (recent) => recent.id != diagramId
      );
      setCookie("recent_diagrams", updatedCookie, 30, true);
    }
  };

  const handleDelist = () => {};

  return (
    <div className="login-popup-container">
      <div>
        <div className="login-popup-content" style={{ width: "80%" }}>
          {showSelectionOptions()}
        </div>
      </div>

      <Modal
        show={itemToDelist != null}
        onHide={() => {
          setItemToDelist(null);
        }}
      >
        <Modal.Header>
          <Modal.Title>Confirm Action</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ marginTop: "10px", fontSize: "16px" }}>
            Are you sure you want to remove this item?
          </div>
          <div style={{ marginTop: "10px" }}>
            This item will not be deleted immediately. It will be removed from
            your list but will only be permanently deleted if there are no users
            attached to the diagram within 30 days. You can still request access
            from the existing owners during this period.
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setItemToDelist(null);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              delistItem(itemToDelist, userId).then((res) => {
                console.log("Delist Response ", res);
                if (res.status == true) {
                  let updated = allDiagrams.filter(
                    (obj) => obj.diagram.id != itemToDelist
                  );
                  removeDiagramFromCookie(itemToDelist);
                  setAllDiagrams(updated);
                  setItemToDelist(null);
                } else {
                  alert("Failed to delist diagram, Please try again later");
                  setItemToDelist(null);
                }
              });
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ChooseDiagram;
