import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import { forwardRef, useImperativeHandle } from "react";
import stopIcon from "../res/stop1.svg";

import sequenceIcon from "../res/process.png";
//import playIcon from "../res/play-button.png";
//import informationIcon from "../res/icons8-detail.png";
import informationIcon from "../res/detailIcon.svg";
import { Icon, TextareaAutosize } from "@mui/material";
import playIcon from "../res/play.svg";
import forwardArrow from "../res/forwardArrow.png";

import nextIcon from "../res/next.svg";
import previousIcon from "../res/previous.svg";
import pauseIcon from "../res/pause1.svg";
import CustomTextField from "../Helper/CustomTextField";
import { MenuItem } from "@mui/material";

const FlowsPlayground = forwardRef(
  ({ allComponents, allFlows, playgroundActionhandler }, ref) => {
    const [selectedFlow, setSelectedFlow] = useState(
      allFlows.length > 0 ? allFlows.find((flow) => flow.selected) : null
    );
    const [showDropdown, setShowDropdown] = useState(false);
    const [showAllFlows, setShowAllFlows] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const [animationStep, setAnimationStep] = useState(1);
    const [animating, setAnimating] = useState(false);
    const [svgImage, setSvgImage] = useState(null);

    const [paused, setPaused] = useState(false);

    useImperativeHandle(ref, () => ({
      handleKeyDown(key) {
        updateAnimation(key);
      },
    }));

    const updateAnimation = (key) => {
      if (animating) {
        if (key === "ArrowRight") {
          // Your logic to handle the right arrow key press goes here
          console.log("Right arrow key pressed!");
          handleNextStepAnimation();
        } else if (key === "ArrowLeft") {
          // Your logic to handle the right arrow key press goes here
          console.log("Right arrow key pressed!");
          handlePreviousStepAnimation();
        } else if (key === " ") {
          // Your logic to handle the right arrow key press goes here
          console.log("Space key pressed!");
          if (animating) {
            handleStopAnimation();
          } else {
            handlePlayAnimation();
          }
        }
      } else if (key === " ") {
        handlePlayAnimation();
      }
    };

    const handleFlowSelection = (flow) => {
      setSelectedFlow(flow);
    };

    const handleStepCardClick = (step) => {
      // Handle step card click event
      console.log("Step card clicked:", step);
      setAnimationStep(step.seq);
      if (animating) {
        playgroundActionhandler({
          type: "play_flow",
          flow: selectedFlow,
          stepNumber: step.seq,
          selectedVarianrId: "default",
        });
      }
    };

    const handlePlayAnimation = () => {
      if (selectedFlow) {
        setAnimationStep(animationStep);
        setAnimating(true);

        playgroundActionhandler({
          type: "play_flow",
          flow: selectedFlow,
          stepNumber: animationStep,
          selectedVarianrId: "default",
        });
      }
    };

    const handleStopAnimation = () => {
      playgroundActionhandler({
        type: "stop_flow",
        flow: selectedFlow,
        stepNumber: animationStep,
        selectedVarianrId: "default",
      });
      setPaused(false);
      setAnimating(false);
      setAnimationStep(1);
    };

    const handlePauseAnimation = () => {
      if (!paused) {
        setAnimating(false);
        setPaused(true);
        playgroundActionhandler({
          type: "stop_flow",
          flow: selectedFlow,
          stepNumber: animationStep,
          selectedVarianrId: "default",
        });
      } else {
        setAnimating(true);
        setPaused(false);
        playgroundActionhandler({
          type: "play_flow",
          flow: selectedFlow,
          stepNumber: animationStep,
          selectedVarianrId: "default",
        });
      }
    };

    const handleNextStepAnimation = () => {
      if (animationStep < selectedFlow.steps.length) {
        playgroundActionhandler({
          type: "play_flow",
          flow: selectedFlow,
          stepNumber: animationStep + 1,
          selectedVarianrId: "default",
        });
        setAnimationStep(animationStep + 1);
      }
    };

    const handlePreviousStepAnimation = () => {
      if (animationStep > 1 && selectedFlow.steps.length > 1) {
        playgroundActionhandler({
          type: "play_flow",
          flow: selectedFlow,
          stepNumber: animationStep - 1,
          selectedVarianrId: "default",
        });
        setAnimationStep(animationStep - 1);
      }
    };

    const handleCloseButton = () => {
      handleStopAnimation();
      // Handle step card click event
      playgroundActionhandler({ type: "closeplayground" });
    };

    const handlePlayButtonClick = () => {
      // Handle play button click event
      console.log("Play button clicked");
    };

    const getTitle = () => {
      return showDropdown
        ? searchTerm
        : selectedFlow != null
        ? selectedFlow.title
        : "";
    };

    const handleSelectOption = (option) => {
      setSelectedFlow(option);
      setShowDropdown(false);
    };

    // const flowList = () => {
    //   if (showDropdown) {
    //     return (
    //       <div className="playground-search-dropdown-list-container">
    //         {filteredOptions().length > 0 &&
    //           filteredOptions().map((flow) => (
    //             <div
    //               className="flow-search-dropdown-list-card"
    //               key={flow.id}
    //               onClick={(event) => {
    //                 event.preventDefault();
    //                 handleSelectOption(flow);
    //               }}
    //             >
    //               {flow.title}
    //             </div>
    //           ))}
    //       </div>
    //     );
    //   }
    // };

    const isFlowPlayable = (flow) => {
      let isPlayable = true;
      if (!flow.steps) {
        isPlayable = false;
      }
      flow.steps.map((step) => {
        if (step.from == null || step.to == null) {
          isPlayable = false;
        }
      });
      return isPlayable;
      // else if (flow.steps.length == 0) {
      //   return false;
      // }
      // else if (flow.steps.length == 1) {

      // }
    };

    const flowList = () => {
      return filteredOptions().map((flow) => (
        <MenuItem
          key={flow.id}
          value={flow.title}
          disabled={!isFlowPlayable(flow)}
          onClick={(event) => {
            event.preventDefault();
            let isFlowPlayables = isFlowPlayable(flow);
            if (isFlowPlayables) {
              handleSelectOption(flow);
            }
          }}
        >
          {flow.title}
        </MenuItem>
      ));
    };

    const filteredOptions = () => {
      let flows = [];
      if (showAllFlows) {
        flows = allFlows;
      } else {
        flows = allFlows.filter((flow) =>
          flow.title.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }

      return flows;
    };

    const actionButtons = () => {
      return (
        <div>
          {!animating && !paused ? (
            <div className="controls-container">
              <div
                className="blue-button"
                style={{
                  marginRight: "20px",
                  width: "94px",
                  height: "36px",
                  display: "flex", // Set display to flex
                  alignItems: "center", // Vertically center the content
                  justifyContent: "center",
                  textAlign: "center",
                  fontFamily: "Roboto, sans-serif",
                  fontSize: "14px",
                  opacity: selectedFlow ? 1 : 0.5,
                  fontWeight: 600,
                }}
                onClick={handlePlayAnimation}
              >
                Play
              </div>
            </div>
          ) : (
            <div className="controls-container">
              <button
                className="control-button"
                onClick={handlePreviousStepAnimation}
              >
                <img
                  src={previousIcon}
                  alt="Previous"
                  className="button-image"
                />
              </button>
              <span className="step-text">
                Step {animationStep} of {selectedFlow.steps.length}
              </span>
              <button
                className="control-button"
                onClick={handleNextStepAnimation}
              >
                <img src={nextIcon} alt="Next" className="button-image" />
              </button>
              <button className="control-button" onClick={handlePauseAnimation}>
                <img
                  src={paused ? playIcon : pauseIcon}
                  alt="Pause"
                  className="button-image"
                />
              </button>
              <button className="control-button" onClick={handleStopAnimation}>
                <img src={stopIcon} alt="Stop" className="button-image" />
              </button>
            </div>
          )}
        </div>
      );
    };

    useEffect(() => {
      const svgContent = `
      <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0C3.59 0 0 3.59 0 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zm1 12H7V7h2v5zm0-7H7V5h2v2z"
        fill="#000000"
      />
    </svg>
    `;
      const svgDataUrl = `data:image/svg+xml;base64,${btoa(svgContent)}`;

      const img = new window.Image();
      img.src = svgDataUrl;
      img.onload = () => {
        setSvgImage(img);
      };
    }, []);

    const openSelectedFlowDetails = () => {
      playgroundActionhandler({
        type: "show_flow_details",
        flow: selectedFlow,
        selectedVarianrId: "default",
      });
    };

    const flowDropDown = () => {
      return (
        <div>
          <div
            style={{
              display: "flex",
              height: "72px",
              alignItems: "center",
              borderTopLeftRadius: "4px",
              borderTopRightRadius: "4px",
              borderLeft: "1px solid black", // Set the left border color to blue
              borderTop: "1px solid black", // Set the top border color to blue
              borderRight: "1px solid black", // Set the right border color to blue
              background: "white",
            }}
          >
            <CustomTextField
              placeHolder="Flow Title"
              style={{ marginLeft: "10px", width: "331px", height: "40px" }}
              value={getTitle()}
              child={flowList()}
            />

            {selectedFlow && (
              <img
                style={{ height: "30px", width: "30px", marginRight: "20px" }}
                onClick={() => {
                  openSelectedFlowDetails();
                }}
                src={informationIcon}
              ></img>
            )}

            {actionButtons()}
          </div>
        </div>
      );
    };

    const nonAnimatingActionView = () => {
      return (
        <div className="playground-action-view">
          <img
            onClick={handlePlayAnimation}
            className="playground-action-icon"
            src={playIcon}
            alt="Play Icon"
          ></img>
        </div>
      );
    };

    // const animatingActionView = () => {
    //   return (
    //     <div
    //       style={{
    //         display: "flex",
    //         marginLeft: "20px",
    //         width: "100%",
    //       }}
    //     >
    //       <img
    //         onClick={handlePreviousStepAnimation}
    //         disabled={animationStep === 0}
    //         className="playground-action-icon"
    //         src={prevIcon}
    //       ></img>
    //       <div style={{ marginLeft: "10px", marginRight: "10px" }}>
    //         {"Step " + animationStep + " of " + selectedFlow.steps.length}
    //       </div>
    //       <img
    //         onClick={handleNextStepAnimation}
    //         disabled={animationStep === selectedFlow.steps.length - 1}
    //         className="playground-action-icon"
    //         src={nextIcon}
    //       ></img>

    //       <div
    //         style={{
    //           display: "flex",
    //           marginLeft: "50px",
    //         }}
    //       >
    //         {/* <img
    //               onClick={handlePauseAnimation}
    //               style={{ width: "16px", height: "16px", marginRight: "16px" }}
    //               src={pauseIcon}
    //             ></img> */}

    //         <img
    //           onClick={handleStopAnimation}
    //           className="playground-action-icon"
    //           src={stopIcon}
    //         ></img>
    //       </div>
    //     </div>
    //   );
    // };

    const descriptionView = () => {
      if (
        animationStep > 0 &&
        selectedFlow != null &&
        selectedFlow.steps.length > 0 &&
        selectedFlow.steps.length >= animationStep
      ) {
        // debugger;
        let description = selectedFlow.steps[animationStep - 1].desc;
        if (!description || description.length == 0) {
          let from = allComponents
            .filter(
              (component) =>
                component.id === selectedFlow.steps[animationStep - 1].from.id
            )
            .map((component) => component.text);
          let to = allComponents
            .filter(
              (component) =>
                component.id === selectedFlow.steps[animationStep - 1].to.id
            )
            .map((component) => component.text);
          return (
            <div
              className="plaground-step-description"
              style={{
                color: "#C76811",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              {`No description available for selected step`}
              <br />
              {`Step Number: ${animationStep}`}
              <br />
              {`From: ${from}`}
              <br />
              {`To: ${to}`}
            </div>
          );
        } else {
          return (
            <div className="plaground-step-description">
              <div
                style={{
                  width: "100%",
                  height: "90%",
                  maxHeight: "200px",
                  overflowY: "scroll",
                  border: "1.5px solid #C76811",
                  padding: "4px",
                  boxSizing: "border-box",
                  borderRadius: "10px", // Adding rounded corners for the card look
                }}
              >
                {/* <div style={{ whiteSpace: "normal", fontSize: "16px" }}>
                  {description}
                </div> */}
                <TextareaAutosize
                  className="select-box"
                  editable="false"
                  minRows={4}
                  style={{
                    width: "100%",
                    textAlignLast: "left",
                    resize: "none",
                    fontSize: 13,
                    height: "100px",
                    marginTop: "8px",
                    overflowY: "auto",
                  }}
                  value={description}
                  placeholder="Step Description Here"
                ></TextareaAutosize>
              </div>
            </div>
          );
        }
      } else {
        return <div>{""}</div>;
      }
    };

    const stepJSX = (step) => {
      return (
        <div key={step.id}>
          {step.from && step.to && (
            <div
              className={`playground-step-card ${
                animationStep === step.seq ? "active-step" : ""
              }`}
              onClick={() => handleStepCardClick(step)}
            >
              <div
                className={`step-number ${
                  animationStep === step.seq ? "active-step-number" : ""
                }`}
              >
                {step.seq}
              </div>
              <div
                className={`from-title${
                  animationStep === step.seq ? "-active-step" : ""
                }`}
              >
                {allComponents
                  .filter((component) => component.id === step.from.id)
                  .map((component) => component.text)}
              </div>
              <div className="arrow">
                <img style={{ width: "16px" }} src={forwardArrow} alt="" />
              </div>
              <div
                className={`to-title${
                  animationStep === step.seq ? "-active-step" : ""
                }`}
              >
                {allComponents
                  .filter((component) => component.id === step.to.id)
                  .map((component) => component.text)}
              </div>
            </div>
          )}
        </div>
      );
    };

    return (
      <div
        className="playground-container"
        // onKeyDown={handleKeyDown}
        tabIndex="0"
      >
        {allFlows.length > 0 && (
          <div className="playground-content">
            <div className="playground-flow-interaction-menu">
              <div>{flowDropDown()}</div>
              {/* <div>
              <Button
                className="playground-close-button"
                onClick={() => handleCloseButton()}
              >
                X
              </Button>
            </div> */}
            </div>

            {allFlows.length > 0 && (
              <div className="playground-select-steps">
                {/* <div style={{ paddingLeft: "10px" }}>Steps</div> */}
                {selectedFlow != null && (
                  <div className="selected-flow-steps-details">
                    {selectedFlow && selectedFlow.steps.length > 0 && (
                      <div className="playground-steps-wrapper">
                        <div className="playground-steps">
                          {selectedFlow.steps.map((step) => stepJSX(step))}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}

            {allFlows.length > 0 && <div>{descriptionView()}</div>}
          </div>
        )}
      </div>
    );
  }
);

export default FlowsPlayground;
