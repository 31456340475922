import { Link } from "react-router-dom";
import React, { Component, useRef, useState } from "react";

function TOS() {
  return (
    <React.Fragment>
      <div style={{ margin: "20px" }}>
        <h1>Terms of Use</h1>

        <p>
          These Terms of Use ("Terms") govern your use of the drawfuse.com
          website and any related services provided by drawfuse ("we," "us," or
          "our"). By accessing or using our website, you agree to comply with
          and be bound by these Terms. If you do not agree to these Terms,
          please do not use our website.
        </p>

        <h2>1. Acceptance of Terms</h2>
        <p>
          By using our website, you agree to these Terms of Use. If you do not
          agree to these terms, please do not use our website.
        </p>

        <h2>2. User Registration</h2>
        <p>
          In order to access certain features of our website, you may be
          required to register an account. You agree to provide accurate and
          complete information during the registration process.
        </p>

        <h2>3. User Content</h2>
        <p>
          By using our services, you may submit and store content, including
          flow diagrams, descriptions, and other materials ("User Content"). You
          retain ownership of your User Content, but you grant us a
          non-exclusive, worldwide, royalty-free license to use, reproduce, and
          display your User Content for the purpose of providing our services.
          You represent and warrant that you have the necessary rights to grant
          us this license.
        </p>

        <h2>4. Privacy</h2>
        <p>
          We collect and store email addresses for user registration and
          communication purposes. We are committed to protecting your privacy
          and handling your data in accordance with our{" "}
          <a href="privacy-policy">Privacy Policy</a>. By using our website, you
          consent to the collection and use of your information as described in
          our Privacy Policy.
        </p>

        <h2>5. Prohibited Conduct</h2>
        <p>
          You agree not to engage in any prohibited conduct, including but not
          limited to:
        </p>
        <ul>
          <li>Violating any applicable laws or regulations</li>
          <li>Impersonating any person or entity</li>
          <li>Attempting to interfere with our website's security</li>
          <li>Uploading or transmitting malicious code or content</li>
          <li>Harassing, threatening, or harming other users</li>
          <li>
            Engaging in any activity that disrupts or damages our website or
            services
          </li>
        </ul>

        <h2>6. Termination</h2>
        <p>
          We may terminate or suspend your account and access to our services at
          our discretion, with or without cause. If your account is terminated,
          you may lose access to your User Content and other information
          associated with your account.
        </p>

        <h2>7. Intellectual Property</h2>
        <p>
          All content and materials on our website, including but not limited to
          text, graphics, logos, and software, are the property of Drawfuse and
          are protected by copyright and other intellectual property laws. You
          may not use, reproduce, or distribute our content without our written
          permission.
        </p>

        <h2>8. Changes to Terms</h2>
        <p>
          We reserve the right to modify or update these Terms of Use at any
          time. Any changes will be effective immediately upon posting on our
          website. Please review these Terms periodically for changes.
        </p>

        <h2>9. Limitation of Liability</h2>
        <p>
          Drawfuse and its affiliates, officers, directors, and employees shall
          not be liable for any direct, indirect, incidental, special, or
          consequential damages resulting from your use of our website or
          services. You use our website and services at your own risk.
        </p>

        <h2>10. Contact Us</h2>
        <p>
          If you have any questions or concerns about these Terms of Use, please
          contact us at support@drawfuse.com
        </p>

        <p>Last updated: 27 Jan 2024</p>
      </div>
    </React.Fragment>
  );
}

export default TOS;
