import React from "react";
import "../CSS/App.css";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { predefinedShapes } from "../Models/Constants";
import Images from "../Models/Images";
import minusSVG from "../res/minus.svg";
import plusSVG from "../res/plus.svg";
import rectSVG from "../res/rect.svg";
import textSVG from "../res/text.svg";
import photoSVG from "../res/photos.svg";
import shareSVG from "../res/share.svg";

export const LeftPanel = ({
  creatNewComponent,
  uploadNewImage,
  handleShare,
  showPlaygroundAction,
  scaleUp,
  scaleDown,
  resetScale,
  scale,
}) => {
  const numberOfItemsPerRow = 1;

  const onClickNewCompponent = (type) => {
    creatNewComponent(type);
  };

  const imageCollection = {
    rect: Images.rect,
    circle: Images.circle,
    editableText: Images.sql,
    arrow: Images.arrow,
    component_list: Images.component_list,
    new_component: Images.new,
  };

  const zoomInAction = () => {};

  const zoomOutAction = () => {};

  const resetZoomAction = () => {};

  const internalComps = (components) => {
    var row = 1;
    var shapesJSX = [];
    var row_items = [];

    // components.forEach((component, index) => {
    //   row_items.push(
    //     <div key={component.key} className="left-options-box">
    //       <img
    //         key={component.type}
    //         onMouseUp={() => onClickNewCompponent(component.type)}
    //         //onMouseDown={() => onClickNewCompponent(component.type)}
    //         className="component-left-review"
    //         src={imageCollection[component.type]}
    //       />
    //     </div>
    //   );
    // });

    row_items.push(
      <div key={"rect"} className="left-options-box">
        <img
          key={"rect"}
          onMouseUp={() => onClickNewCompponent("rect")}
          className="component-left-review"
          src={rectSVG}
        />
      </div>
    );

    // row_items.push(
    //   <div key={"queue"} className="left-options-box">
    //     <img
    //       key={"queue"}
    //       onMouseUp={() => onClickNewCompponent("queue")}
    //       className="component-left-review"
    //       src={rectSVG}
    //     />
    //   </div>
    // );

    row_items.push(
      <div
        key={"left-options-box-new-component-div"}
        className="left-options-box"
      >
        <img
          key={"left-options-box-new-component-img"}
          onMouseUp={() => onClickNewCompponent("text")}
          //onMouseDown={() => onClickNewCompponent(component.type)}
          className="component-left-review"
          src={textSVG}
        />
      </div>
    );

    row_items.push(
      <div key={"left-options-box-new-image-div"} className="left-options-box">
        <img
          key={"left-options-box-new-component-img"}
          onMouseUp={() => uploadNewImage()}
          //onMouseDown={() => onClickNewCompponent(component.type)}
          className="component-left-review"
          src={photoSVG}
        />
      </div>
    );

    row_items.push(
      <div
        key="zoom-value"
        style={{
          backgroundColor: "#F1F1F1",
        }}
      >
        <div
          className="left-options-box"
          style={{
            display: "flex",
            alignItems: "center",
            margin: "5px",
          }}
        >
          <img
            key={"component-left-review-scale-up"}
            onClick={scaleDown}
            //onMouseDown={() => onClickNewCompponent(component.type)}
            className="component-left-review"
            style={{ backgroundColor: "#F5F5F5", padding: "10px" }}
            src={minusSVG}
          />

          <div>
            {Math.floor(scale * 100)}
            {"%"}
          </div>
          <img
            key={"component-left-review-scale-down"}
            onClick={scaleUp}
            //onMouseDown={() => onClickNewCompponent(component.type)}
            className="component-left-review"
            style={{ backgroundColor: "#F5F5F5", padding: "10px" }}
            src={plusSVG}
          />
        </div>
      </div>
    );

    row_items.push(
      <div
        key={"reset-scale"}
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#F1F1F1",
        }}
      >
        {/* <div
          key="reset-zoom"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            backgroundColor: "#F1F1F1",
            height: "32px",
          }}
        > */}
        <div
          className="button-type-1"
          style={{
            marginTop: "12px",
            height: "32px",
            width: "101px",
            display: "flex", // Set display to flex
            alignItems: "center", // Vertically center the content
            justifyContent: "center",
            textAlign: "center",
            border: "1px solid blue", // Set the border color to blue
          }}
          onClick={resetScale}
          // onClick={props.viewFlows}
        >
          Reset Zoom
        </div>
        {/* </div> */}

        <img
          key={"component-left-review-share"}
          // onMouseUp={() => onClickNewCompponent("text")}
          //onMouseDown={() => onClickNewCompponent(component.type)}
          className="component-left-review"
          onClick={handleShare}
          style={{
            backgroundColor: "#F5F5F5",
            marginTop: "12px",
            height: "32px",
            padding: "10px",
            marginLeft: "12px",
            marginRight: "12px",
            cursor: "pointer",
          }}
          src={shareSVG}
        />

        <div
          className="blue-button"
          style={{
            marginTop: "12px",
            height: "32px",
            display: "flex", // Set display to flex
            alignItems: "center", // Vertically center the content
            justifyContent: "center",
            textAlign: "center",
          }}
          onClick={showPlaygroundAction}
        >
          View Flow
        </div>
      </div>
    );

    shapesJSX.push(
      <div className="left-options-container" key={row}>
        {row_items}
      </div>
    );

    return shapesJSX;
  };

  // const internalComps = (components) => {

  //   var row = 1;
  //   var shapesJSX = [];
  //   var row_items = [];
  //   components.forEach((component, index) => {
  //     if (index % numberOfItemsPerRow == 0) {
  //       shapesJSX.push(<Row key={row}>{row_items}</Row>);
  //       row = row + 1;
  //       row_items = [];
  //     }
  //     row_items.push(
  //       <Col key={component.type}>
  //         <img
  //           key={component.type}
  //           onClick={() => onClickNewCompponent(component.type)}
  //           className="component-left-review"
  //           src={imageCollection[component.type]}
  //         />
  //       </Col>
  //     );
  //   });
  //   shapesJSX.push(<Row key={row}>{row_items}</Row>);

  //   return shapesJSX;
  // };

  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    // alignItems: "center",
    // height: "100vh",
    textAlign: "center",
  };

  const leftAlignedStyle = {
    textAlign: "left",
  };
  return (
    <div style={containerStyle}>
      <div className="left-pane-internal">
        <Card>
          {/* <Card.Body> */}
          {/* <Card.Title variant="light"></Card.Title> */}
          {/* <Container fluid="md"> */}
          {internalComps(predefinedShapes)}
          {/* </Container> */}
          {/* </Card.Body> */}
        </Card>
      </div>
    </div>
  );
};
