import React, { useState, useEffect } from "react";
import { Rect, Transformer, Group, Image } from "react-konva";
import { EditableText } from "./TextComponents/EditableText";
import { Circle } from "react-konva";
import uuid from "react-uuid";
import { getDotSize } from "../Helper/Common";
import { Info } from "@mui/icons-material";

const Queue = ({
  shapeProps,
  zIndex,
  scale,
  isSelected,
  onSelect,
  onDragStartShape,
  onDragMoveShape,
  onDragEndShape,
  onToggleEdit,
  onClickArrowDraw,
  onDoubleClick,
  onTextChange,
  onResize,
  connectableDot,
  dragHoveringShapes,
  showPlayground,
  enableStageEditing,
  onInfoClick,
  animate,
  disabled,
}) => {
  const rectRef = React.useRef();
  const trRef = React.useRef();

  const [update, setUpdate] = useState(0);
  const [mouseHover, setMouseHover] = useState(false);
  const topDotId = shapeProps.id + "-top-dot";
  const bottomDotId = shapeProps.id + "-bottom-dot";
  const leftDotId = shapeProps.id + "-left-dot";
  const rightDotId = shapeProps.id + "-right-dot";
  const dotType = "connectingDot";

  const [svgImage, setSvgImage] = useState(null);
  const [animationRequestId, setAnimationRequestId] = useState(null);
  const [textPosition, setTextPosition] = useState({ x: 0, y: 0 });

  const animateRect = () => {
    const rect = rectRef.current;
    if (rect) {
      const dashOffset = (rect.dashOffset() + 0.5) % 20; // Adjust the speed and dash pattern as needed
      rect.dashOffset(dashOffset);
      rect.getLayer().batchDraw();
    }

    setAnimationRequestId(requestAnimationFrame(animateRect));
  };

  useEffect(() => {
    if (animate) {
      if (!animationRequestId) {
        setAnimationRequestId(requestAnimationFrame(animateRect));
      }
    } else {
      if (animationRequestId) {
        cancelAnimationFrame(animationRequestId);
        setAnimationRequestId(null);
      }
    }
  }, [animate]);

  // Load SVG image as a data URL
  useEffect(() => {
    const svgContent = `
      <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0C3.59 0 0 3.59 0 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zm1 12H7V7h2v5zm0-7H7V5h2v2z"
        fill="#000000"
      />
    </svg>
    `;
    const svgDataUrl = `data:image/svg+xml;base64,${btoa(svgContent)}`;

    const img = new window.Image();
    img.src = svgDataUrl;
    img.onload = () => {
      setSvgImage(img);
    };
  }, []);

  const dot = (x, y, id) => {
    //if (connectableDot != null)

    if (!shapeProps.isDragging) {
      return (
        <Group>
          <Circle
            x={x}
            y={y}
            id={id}
            shapeId={shapeProps.id}
            shapeType={shapeProps.type}
            type={dotType}
            radius={getDotSize() / 2}
            fill={connectableDot == id ? "#32CD32E6" : "#646161E6"}
            draggable={false}
            onMouseDown={() => {
              onClickArrowDraw(shapeProps, { x: x, y: y });
            }}
            // onMouseOver={() => {
            //   setMouseHover(true);
            // }}
            // onMouseOut={() => {
            //   setMouseHover(false);
            // }}
          />
        </Group>
      );
    }
  };

  const points = () => {
    const x = shapeProps.x;
    const y = shapeProps.y;
    const width = shapeProps.width;
    const height = shapeProps.height;
    const topDot = dot(x + width / 2, y, topDotId);
    const leftDot = dot(x, y + height / 2, leftDotId);
    const bottomDot = dot(x + width / 2, y + height, bottomDotId);
    const rigtDot = dot(x + width, y + height / 2, rightDotId);
    // console.log("Showing Points ", topDot, leftDot, bottomDot, rigtDot);

    return (
      <Group>
        {topDot}
        {leftDot}
        {bottomDot}
        {rigtDot}
      </Group>
    );
  };

  React.useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([rectRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected, zIndex]);

  const propsForText = () => {
    return shapeProps;
    console.log(textPosition.x, textPosition.y);
    let props = { ...shapeProps };
    props.x = 0; //textPosition.x;
    props.y = textPosition.y;

    return props;
  };

  const textComp = (x) => {
    return (
      <EditableText
        shapeProps={propsForText()}
        draggable={false}
        onDoubleClick={onDoubleClick}
        // onDoubleClick={(e) => console.log(e)}
        onClick={(e) => {
          //e.stopPropagation()
          console.log("Text click");
          onSelect(shapeProps.id);
        }}
        listening={false}
        text={shapeProps.text}
        isEditing={shapeProps.isEditingText}
        onTextChange={onTextChange}
        onSelect={(e) => {
          console.log("Text click");
          //e.stopPropagation()
          onSelect(shapeProps.id);
        }}
        onToggleEdit={(model) => {
          console.log("Toggle edit ", model);
          onToggleEdit(model);
        }}
        onDragStartShape={onDragStartShape}
        onDragMoveShape={(shape) => {
          setTextPosition({ x: shape.x, y: shape.y }); // Adjust the position as needed
          onDragMoveShape(shape);
        }}
        onDragEndShape={onDragEndShape}
      />
    );
  };

  const transformer = () => {
    return (
      <Transformer
        ref={trRef}
        // zIndex={20}
        listening={enableStageEditing}
        rotateEnabled={false}
        centeredScaling={false}
        keepRatio={false}
        enabledAnchors={[
          "top-left",
          "top-right",
          "bottom-left",
          "bottom-right",
        ]}
        boundBoxFunc={(oldBox, newBox) => {
          // limit resize
          if (newBox.width < 5 || newBox.height < 5) {
            return oldBox;
          }
          return newBox;
        }}
      />
    );
  };

  const updateMouseHover = (state) => {
    if (mouseHover != state) {
      setMouseHover(state);
    }
  };

  const InfoIcon = () => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0C3.59 0 0 3.59 0 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zm1 12H7V7h2v5zm0-7H7V5h2v2z"
        fill="#000000"
      />
    </svg>
  );

  const infoIconView = (id) => {
    return (
      showPlayground &&
      shapeProps.info !== undefined &&
      Object.keys(shapeProps.info).length !== 0 && (
        <Image
          onClick={() => {
            onInfoClick(id);
          }}
          image={svgImage}
          x={shapeProps.x + shapeProps.width - 20}
          y={shapeProps.y + shapeProps.height - 20}
          width={16}
          height={16}
        />
      )
    );
  };

  const rectangleView = () => {
    return (
      <Rect
        key={shapeProps.id}
        scaleX={scale}
        scaleY={scale}
        listening={enableStageEditing}
        dash={animate ? [10, 10] : [0, 0]}
        onClick={(e) => {
          if (e.evt.button < 2) {
            onSelect(shapeProps.id);
          }
        }}
        onTap={(e) => {
          onSelect(shapeProps.id);
        }}
        ref={rectRef}
        {...shapeProps}
        draggable
        onDblClick={(e) => {
          onDoubleClick({ ...shapeProps });
        }}
        onDragMove={(e) => {
          onDragMoveShape({
            ...shapeProps,
            x: Math.round(e.target.x()),
            y: Math.round(e.target.y()),
          });
        }}
        onDragEnd={(e) => {
          onDragEndShape({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        stroke={
          shapeProps.style && shapeProps.style.border
            ? shapeProps.style.border
            : "black"
        }
        strokeWidth={
          shapeProps.style && shapeProps.style.borderWidth != null
            ? shapeProps.style.borderWidth
            : 0.5
        }
        fill={
          disabled
            ? "gray"
            : shapeProps.style && shapeProps.style.fill
            ? shapeProps.style.fill
            : "white"
        }
        fontSize={
          shapeProps.style && shapeProps.style.fontSize
            ? shapeProps.style.fontSize
            : 32
        }
        fontFamily={
          shapeProps.style && shapeProps.style.fontFamily
            ? shapeProps.style.fontFamily
            : "Arial"
        }
        fontStyle={
          shapeProps.style && shapeProps.style.fontWeight
            ? shapeProps.style.fontWeight
            : "normal"
        }
        cornerRadius={shapeProps.height / 2} // Set corner radius to half of the height
        onMouseEnter={(e) => {
          updateMouseHover(true);
          const container = e.target.getStage().container();
          container.style.cursor = "pointer";
        }}
        onMouseLeave={(e) => {
          updateMouseHover(false);
          const container = e.target.getStage().container();
          container.style.cursor = "default";
        }}
        onTransform={(e) => {
          const node = rectRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          node.scaleX(1);
          node.scaleY(1);
          onResize({
            ...shapeProps,
            x: node.x(),
            y: node.y(),
            width: Math.max(5, node.width() * scaleX),
            height: Math.max(node.height() * scaleY),
          });
        }}
        onTransformEnd={(e) => {
          const node = rectRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          node.scaleX(1);
          node.scaleY(1);
          onResize({
            ...shapeProps,
            x: node.x(),
            y: node.y(),
            width: Math.max(5, node.width() * scaleX),
            height: Math.max(node.height() * scaleY),
          });
        }}
      />
    );
  };

  return (
    <React.Fragment key={shapeProps.id + "-fragment"}>
      {shapeProps.enableConnectors && (
        <Rect
          key={shapeProps.id + "-connector"}
          perfectDrawEnabled={false}
          {...shapeProps}
          stroke="red"
          strokeWidth={1}
          listening={enableStageEditing}
        />
      )}
      {rectangleView()}
      {textComp("start")}
      {(isSelected || mouseHover || shapeProps.enableConnectors) &&
        transformer()}
      {(isSelected || mouseHover || shapeProps.enableConnectors) && points()}
      {infoIconView(shapeProps.id)}
    </React.Fragment>
  );
};

const arePropsEqual = (prevProps, nextProps) => {
  return false;
  // Compare only the variables that should trigger re-rendering
  // return (
  //    prevProps.shapeProps.x === nextProps.shapeProps.x
  //    && prevProps.shapeProps.y === nextProps.shapeProps.y
  //    && prevProps.shapeProps.width === nextProps.shapeProps.width
  //    && prevProps.shapeProps.height === nextProps.shapeProps.height
  //   // Add additional comparisons as needed
  //   // prevProps.someProp === nextProps.someProp &&
  //   // prevProps.anotherProp === nextProps.anotherProp
  // );
};

export default Queue;
// export default React.memo(Rectangle, arePropsEqual);
