import React, { useState } from "react";
import { Label, Rect, Transformer } from "react-konva";

import { EditableTextInput } from "./EditableTextInput";
import { ResizableText } from "./ResizableText";

const RETURN_KEY = 13;
const ESCAPE_KEY = 27;

export const EditableText = ({
  key,
  scale,
  enableConnectors,
  shapeProps,
  isSelected,
  draggable,
  text,
  onResize,
  isEditing,
  onSelect,
  onToggleEdit,
  onDoubleClick,
  onTextChange,
  onDragStartShape,
  onDragMoveShape,
  onDragEndShape,
  listening = false,
  opacity,
}) => {
  const shapeRef = React.useRef();
  const trRef = React.useRef();
  const etrRef = React.useRef();
  const [update, setUpdate] = useState();
  const handleEscapeKeys = (e) => {
    console.log(e);
    if ((e.keyCode === RETURN_KEY && !e.shiftKey) || e.keyCode === ESCAPE_KEY) {
      onToggleEdit(shapeProps);
    }
  };

  const handleTextChange = (model, newText) => {
    onTextChange(model, newText);
  };

  return (
    <React.Fragment>
      {isEditing && (
        <React.Fragment>
          <EditableTextInput
            {...shapeProps}
            draggable={draggable}
            shapeProps={shapeProps}
            value={text}
            isSelected={isSelected}
            stroke={"transparent"}
            fill={"transparent"}
            fontSize={
              shapeProps.style && shapeProps.style.fontSize
                ? shapeProps.style.fontSize
                : 32
            }
            fontFamily={
              shapeProps.style && shapeProps.style.fontFamily
                ? shapeProps.style.fontFamily
                : "Arial"
            }
            fontStyle={
              shapeProps.style && shapeProps.style.fontWeight
                ? shapeProps.style.fontWeight
                : "normal"
            }
            onClick={(e) => {
              e.stopPropagation();
              console.log("Selecting");
              onSelect();
            }}
            onChangeText={handleTextChange}
            // onKeyDown={handleEscapeKeys}
            onKeyDown={handleEscapeKeys}
          />
          <Transformer
            ref={etrRef}
            boundBoxFunc={(oldBox, newBox) => {
              // limit resize

              if (newBox.width < 5 || newBox.height < 5) {
                return oldBox;
              }
              return newBox;
            }}
          />
        </React.Fragment>
      )}

      {!isEditing && (
        <ResizableText
          {...shapeProps}
          isSelected={isSelected}
          enableConnectors={enableConnectors}
          onDoubleClick={onDoubleClick}
          draggable={draggable}
          shapeProps={shapeProps}
          onSelect={onSelect}
          opacity={opacity}
          onClick={(e) => {
            e.stopPropagation();
            console.log(e);
          }}
          // we will reset it back
          listening={listening}
          onDragStartShape={onDragStartShape}
          onDragMoveShape={onDragMoveShape}
          onDragEndShape={onDragEndShape}
          onResize={onResize}
          onTransformEnd={(e) => {
            // const node = e.target.current;
            // const scaleX = node.scaleX();
            // const scaleY = node.scaleY();
            // onResize({
            //   ...shapeProps,
            //   x: node.x(),
            //   y: node.y(),
            //   // set minimal value
            //   width: Math.max(5, node.width() * scaleX),
            //   height: Math.max(node.height() * scaleY),
            // });
          }}
          text={text}
        />
      )}

      {/* {enableConnectors && (
        <Rect {...shapeProps} stroke="red" strokeWidth={4} />
      )} */}
    </React.Fragment>
  );
};
