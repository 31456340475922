import React, { useState, useEffect, useRef } from "react";
import { Button, Modal, Dropdown } from "react-bootstrap";
import EmailInput from "./EmailInput";
// import { updateUserAccess } from "../api/diagramSharingService";
import "./../CSS/App.css";
import { AccessType } from "../Models/Constants";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { TextField } from "@mui/material";
import { Label } from "react-konva";
import { getAllAcessesForDiagram } from "../Helper/DataService";

function EmailInputPopup({
  title,
  url,
  diagramId,
  didMakePublicAction,
  didShareAction,
  didCloseEmailPopupAction,
  //diagramAccessList,
  didDeleteUserAccess,
}) {
  const [emails, setEmails] = useState([]);
  const [access, setAccess] = useState(AccessType.VIEW_ONLY);
  const [message, setMessage] = useState("");
  const [isPublicAccessAllowed, setIsPublicAccessAllowed] = useState(false);
  const [diagramAccessList, setDiagramAccessList] = useState([]);
  const [pendingAccessUsers, setPendingAccessUsers] = useState([]);
  const emailInputRef = useRef(null);

  useEffect(() => {
    // Your function to be called on component load
    refreshDiagramAccess();
  }, []);

  const updateEmails = (emails) => {
    setEmails(emails);
  };

  const refreshDiagramAccess = () => {
    getAllAcessesForDiagram(diagramId).then((res) => {
      let diagramAccess = res.diagramAccess.users;
      let pendingUsers = res.diagramAccess.pendingUsers;
      setEmails([]);
      setDiagramAccessList(diagramAccess);
      setPendingAccessUsers(pendingUsers);
    });
  };
  const removePublicAccess = () => {
    const handleResponse = (res) => {
      if (res.status) {
        setIsPublicAccessAllowed(false);
        // setMessage("Access updated successfully!");
      } else {
        setMessage("Error updating access.");
      }
    };
    didMakePublicAction(false, handleResponse);
  };

  const didTapOnMakePublic = () => {
    const handleResponse = (res) => {
      if (res.status) {
        setIsPublicAccessAllowed(true);
        // setMessage("Access updated successfully!");
      } else {
        setMessage("Error updating access.");
      }
    };
    didMakePublicAction(true, handleResponse);
  };

  const didTapOnShare = () => {
    const handleResponse = (res) => {
      if (res.status) {
        refreshDiagramAccess();
        //setMessage("Access updated successfully!");
      } else {
        setMessage("Error updating access.");
      }
    };

    let newEmail = emailInputRef.current.handleAddEmail(true);

    let emailsForAccess = [...emails];
    if (newEmail !== "") {
      emailsForAccess.push(newEmail);
    }
    didShareAction({ emails: emailsForAccess, access: access }, handleResponse);
  };

  const updateAccess = (email, accessLevel) => {
    const handleResponse = (res) => {
      if (res.status) {
        refreshDiagramAccess();
        //setMessage("Access updated successfully!");
      } else {
        setMessage("Error updating access.");
      }
    };

    didShareAction({ emails: [email], access: accessLevel }, handleResponse);
  };

  const handleAccessSelect = (e) => {
    setAccess(e);
    console.log(e);
  };

  const viewURL = () => {
    return url + "&access_type=viewonly";
  };

  const aceessTypeString = (type, isPending = false) => {
    if (type == AccessType.VIEW_ONLY) {
      return "Can view";
    } else if (type == AccessType.EDITOR) {
      return "Can edit";
    } else if (type == AccessType.OWNER) {
      return "Owner";
    } else if (type == AccessType.DELETED) {
      return isPending ? "Deny Access" : "Revoked";
    }
  };

  const accessUpdateAndApproval = (user, isPending) => {
    let items = [];
    if (user) {
      user.map((user, index) =>
        items.push(
          <div
            class="user-card"
            style={{
              backgroundColor:
                aceessTypeString(user.accessLevel) !== "Owner"
                  ? "white"
                  : "#F5F5F5", // Set the background color to white
              color:
                aceessTypeString(user.accessLevel) === "Owner"
                  ? "gray"
                  : "black", // Set the background color to white
            }}
            key={index}
          >
            <div class="user-info">
              <span>{user.email}</span>
            </div>
            <div class="access-dropdown">
              <Dropdown
                onSelect={(eventKey) => {
                  console.log("Selected:", eventKey);
                  if (eventKey == AccessType.VIEW_ONLY) {
                    updateAccess(user.email, eventKey);
                  } else if (eventKey == AccessType.EDITOR) {
                    updateAccess(user.email, eventKey);
                  } else if (eventKey == AccessType.OWNER) {
                    updateAccess(user.email, eventKey);
                  } else if (eventKey == AccessType.DELETED) {
                    updateAccess(user.email, eventKey);
                  }
                }}
              >
                <Dropdown.Toggle
                  variant="primary"
                  id="dropdown-basic"
                  style={{
                    backgroundColor: "white", // Set the background color to white
                    color: "black", // Set the text color to transparent
                    borderColor: "white", // Set border color to white
                  }}
                >
                  {isPending ? "Pending" : aceessTypeString(user.accessLevel)}
                </Dropdown.Toggle>

                {user.accessLevel !== AccessType.OWNER && (
                  <Dropdown.Menu>
                    <Dropdown.Item eventKey={AccessType.VIEW_ONLY}>
                      {aceessTypeString(AccessType.VIEW_ONLY, isPending)}
                    </Dropdown.Item>
                    <Dropdown.Item eventKey={AccessType.EDITOR}>
                      {aceessTypeString(AccessType.EDITOR, isPending)}
                    </Dropdown.Item>
                    {/* <Dropdown.Item eventKey={AccessType.OWNER}>
                      {aceessTypeString(AccessType.OWNER, isPending)}
                    </Dropdown.Item> */}
                    <Dropdown.Item eventKey={AccessType.DELETED}>
                      {aceessTypeString(AccessType.DELETED, isPending)}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                )}
              </Dropdown>
            </div>
          </div>
        )
      );
    }
    return items;
  };

  return (
    <div>
      <Modal
        style={{ width: "100%", height: "75%" }}
        show={true}
        onHide={didCloseEmailPopupAction}
      >
        <Modal.Header closeButton>
          <Modal.Title>Share {title}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ display: "flex" }}>
          <div
            style={{ display: "flex", alignItems: "flex-start", width: "100%" }}
          >
            <div style={{ width: "100%", marginRight: "10px" }}>
              <EmailInput ref={emailInputRef} updateEmails={updateEmails} />
            </div>
            <Dropdown>
              <Dropdown.Toggle
                variant="primary"
                id="dropdown-basic"
                style={{
                  backgroundColor: "white", // Set the background color to white
                  color: "black", // Set the text color to transparent
                  borderColor: "white", // Set border color to white
                }}
              >
                {aceessTypeString(access)}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    console.log("Selected:", AccessType.VIEW_ONLY);
                    handleAccessSelect(AccessType.VIEW_ONLY);
                  }}
                >
                  {aceessTypeString(AccessType.VIEW_ONLY)}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    console.log("Selected:", AccessType.EDITOR);
                    handleAccessSelect(AccessType.EDITOR);
                  }}
                >
                  {aceessTypeString(AccessType.EDITOR)}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    console.log("Selected:", AccessType.OWNER);
                    handleAccessSelect(AccessType.OWNER);
                  }}
                >
                  {aceessTypeString(AccessType.OWNER)}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          {message && (
            <div
              className={`message ${
                message.includes("Error") ? "error" : "success"
              }`}
            >
              {message}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "flex-start",
            // alignItems: "center",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <FormControlLabel
                control={
                  <Switch
                    checked={isPublicAccessAllowed}
                    onChange={() => {
                      isPublicAccessAllowed
                        ? removePublicAccess()
                        : didTapOnMakePublic();
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Allow public access"
              />
            </div>
            <div
              // variant="primary"
              className="flow-view-footer-view-flows-button"
              style={{
                width: "80px",
                height: "44px",
                marginRight: "5px",
                padding: "0.5rem 2rem",
                borderRadius: "4px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={didTapOnShare}
            >
              Share
            </div>
            {/* <Button
              variant="danger"
              style={{ padding: "0.5rem 2rem" }}
              onClick={didCloseEmailPopupAction}
            >
              Close
            </Button> */}
          </div>

          <div
            style={{
              width: "100%",
              // marginLeft: "20px",
              // display: "flex",
              // flexDirection: "column",
            }}
          >
            {isPublicAccessAllowed && (
              <TextField
                disabled
                value={viewURL()}
                style={{ marginTop: "1px", width: "100%" }}
              />
            )}{" "}
          </div>

          <div style={{ width: "100%" }}>
            <div style={{ marginTop: "24px", marginBottom: "8px" }}>Access</div>
            {accessUpdateAndApproval(diagramAccessList, false)}

            {pendingAccessUsers && pendingAccessUsers.length > 0 && (
              <div style={{ marginTop: "24px", marginBottom: "8px" }}>
                Pending Requests
              </div>
            )}
            {accessUpdateAndApproval(pendingAccessUsers, true)}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default EmailInputPopup;
