import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";

import TextField from "@mui/material/TextField";
import deleteIcon from "../res/delete.png";
import forwardArrow from "../res/forwardArrow.png";
import optionsIcon from "../res/dots.png";
import upArrow from "../res/up-arrow.png";
import downArrow from "../res/down-arrow.png";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import ListItemWithDropdown from "./ListItemWithDropdown";
import CustomTextField from "../Helper/CustomTextField";
import "font-awesome/css/font-awesome.min.css";
import ListSubheader from "@mui/material/ListSubheader";
import customizeIcon from "../res/wrench.png";
import invisibleIcon from "../res/invisible.png";
import visibleIcon from "../res/show.png";
import designStep from "../res/hide.png";
import playIcon from "../res/play.svg";

import { Button, Card, Dropdown } from "react-bootstrap";
import {
  Radio,
  FormControlLabel,
  FormControl,
  RadioGroup,
  TextareaAutosize,
} from "@mui/material";
import { Label } from "react-konva";
import { blue } from "@mui/material/colors";

const FlowsDropdown = forwardRef((props, ref) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedFlow, setSelectedFlow] = useState(null);
  const [animationStep, setAnimationStep] = useState(-1);
  const [showAllFlows, setShowAllFlows] = useState(true);
  const [componentSearch, setComponentSearch] = useState(null);
  const [animating, setAnimating] = useState(false);
  const [editingStep, setEditingStep] = useState(null);
  const [selectedVariantId, setSelectedVariantId] = useState("default");
  const [allFlows, setAllFlows] = useState(props.flows);
  const [anchorEl, setAnchorEl] = useState(null);
  const [idForOpenStepDropDown, setIdForOpenStepDropDown] = useState(null);
  const [isDesignInProgress, setIsDesignInProgress] = useState(false);

  useImperativeHandle(ref, () => ({
    updateAllFlowsWithSelectedFlow(flows, flowId) {
      updateAllFlowsWithSelectedFlow(flows, flowId);
    },

    updateAllFlows(flows) {
      updateAllFlows(flows);
    },
    updateSelectedFlow(flowId) {
      updateSelectedFlow(flowId);
    },
    updateEditingStep(stepId) {
      updateEditingStep(stepId);
    },
    stopPreviewAnimation() {
      stopPreviewAnimation(false);
    },
  }));

  const stopPreviewAnimation = () => {
    setAnimating(false);
    setAnimationStep(-1);
  };

  const updateAllFlows = (flows) => {
    setAllFlows(flows);

    if (selectedFlow) {
      let selectedFlowId = selectedFlow.id;
      let updatedSelectedFlow;
      // let updatedSelectedFlow = flows.find(
      //   (flow) => flow.id === selectedFlowId
      // );
      if (!updatedSelectedFlow) {
        updatedSelectedFlow = flows.find((flow) => flow.selected);
      }
      if (updatedSelectedFlow) setSelectedFlow(updatedSelectedFlow);
    }

    if (selectedFlow == null && flows.length > 0) {
      setSelectedFlow(flows[0]);
    }
  };

  useEffect(() => {
    let updatedSelectedFlow = props.flows.find((flow) => flow.selected);
    if (updatedSelectedFlow) setSelectedFlow(updatedSelectedFlow);
  }, []);

  const updateAllFlowsWithSelectedFlow = (flows, flowId) => {
    setAllFlows(flows);

    flows.forEach((flow) => {
      if (flow.id == flowId) {
        setSelectedFlow(flow);
        setShowDropdown(false);
      }
    });
  };

  // const handleKeyDown = (event) => {
  //   //if (animating) {

  //   console.log("selectedFlow ", selectedFlow);
  //   if (event.key === "ArrowLeft") {
  //     let stepNumber = Math.max(animationStep - 1, 0);
  //     handlePlayAnimation(selectedFlow, stepNumber);
  //   } else if (event.key === "ArrowRight") {
  //     let stepNumber = Math.min(
  //       animationStep + 1,
  //       selectedFlow.steps.length - 1
  //     );
  //     handlePlayAnimation(selectedFlow, stepNumber);
  //   }
  //   //}
  // };

  // useEffect(() => {
  //   document.addEventListener("keydown", handleKeyDown);
  //   return () => {
  //     document.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, []);

  const updateSelectedFlow = (flowId) => {
    if (flowId == null) {
      setSelectedFlow(null);
      setEditingStep(null);
      setShowDropdown(false);
    } else {
      allFlows.forEach((flow) => {
        if (flow.id == flowId) {
          setSelectedFlow(flow);
          setEditingStep(flow.steps[0].id);
          setShowDropdown(false);
        }
      });
    }
    props.cutomizeDiagramForStep(
      null,
      selectedFlow.id,
      selectedVariantId,
      false
    );
    setIsDesignInProgress(false);
  };

  const updateEditingStep = (stepId) => {
    setEditingStep(stepId);
    props.cutomizeDiagramForStep(
      stepId,
      selectedFlow.id,
      selectedVariantId,
      false
    );
    setIsDesignInProgress(false);
  };

  const filteredOptions = () => {
    let allAvailableFlows = props.flows.filter(
      (flow) => flow.title.trim() !== ""
    );

    if (showAllFlows) {
      return allAvailableFlows;
    } else {
      return allAvailableFlows.filter((flow) => {
        console.log(flow.title);
        flow.title.toLowerCase().includes(searchTerm.toLowerCase());
      });
    }
  };

  const handleSelectOption = (option) => {
    props.updateSelectedFlowGlobally(option);
    setSelectedFlow(option);
    setShowDropdown(false);
  };

  const handleSelectComp = (step, comp, type) => {
    props.updateComponentInStep(
      selectedFlow,
      selectedVariantId,
      comp.id,
      step,
      type
    );
  };

  const handleDeleteStep = (step, selectedVariantId, flow) => {
    props.deleteStep(step, selectedVariantId, flow);
  };

  const handlePlayAnimation = (flow, index) => {
    console.log("Start Animation");
    if (animating == false) {
      setAnimating(true);
    }
    props.playAnimation(flow, selectedVariantId, index);
    setAnimationStep(index);
  };

  const componentDropDownCell = (step, comp, type) => {
    if (comp.type != "arrow" && comp.text.trim() != "") {
      if (
        componentSearch.keyword == null ||
        (componentSearch.keyword != null &&
          comp.text
            .toLowerCase()
            .includes(componentSearch.keyword.toLowerCase()))
      ) {
        return (
          <MenuItem key={comp.id} value={comp.text}>
            {comp.text}
          </MenuItem>

          // <div
          //   className="search-dropdown-list-card"
          //   style={{ paddingLeft: "8px" }}
          //   key={comp.id}
          //   onClick={(event) => {
          //     event.preventDefault();
          //     handleSelectComp(step, comp, type);
          //   }}
          // >
          //   {comp.text}
          // </div>
        );
      }
    }
  };

  const componentSelectDropDown = (step, type) => {
    console.log("componentSelectDropDown");
    if (
      componentSearch != null &&
      componentSearch.step.id == step.id &&
      componentSearch.type == type
    ) {
      return (
        <div
          className="search-dropdown-list-container"
          style={{
            zIndex: 2,
            backgroundColor: "white",
            borderRadius: "4px",
            boxShadow:
              "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08)",
          }}
        >
          {props.components &&
            props.components.length > 0 &&
            props.components.text != "" &&
            props.components.map((comp) => {
              return componentDropDownCell(step, comp, type);
            })}
        </div>
      );
    }
  };

  const textForStepComponentBox = (step, type) => {
    if (type == "from" && step.from != null) {
      // return step.from.title;
      return props.components
        .filter((component) => component.id === step.from.id)
        .map((component) => component.text);
    } else if (type == "to" && step.to != null) {
      if (step.to.triggerCode != null) {
      }
      return props.components
        .filter((component) => component.id === step.to.id)
        .map((component) => component.text);
    } else if (type == "desc" && step.desc != null) {
      if (step.to.triggerCode != null) {
      }
      return step.desc;
    } else {
      return "";
    }
    // if (
    //   componentSearch != null &&
    //   componentSearch.step.id == step.id &&
    //   componentSearch.type == type
    // ) {
    //   //This one is actively editing, we need to show search string
    //   return componentSearch.keyword;
    // } else {
    //   if (type == "from" && step.from != null) {
    //     return step.from.title;
    //   } else if (type == "to" && step.to != null) {
    //     if (step.to.triggerCode != null) {
    //     }
    //     return step.to.title;
    //   } else if (type == "desc" && step.desc != null) {
    //     if (step.to.triggerCode != null) {
    //     }
    //     return step.desc;
    //   } else {
    //     return "";
    //   }
    // }
    // return null;
  };

  const stepType = (step) => {
    return (
      <div>
        <img
          onClick={() => {
            handleDeleteStep(step, selectedVariantId, selectedFlow);
          }}
          style={{
            position: "absolute",
            top: "8px",
            right: "8px",
            width: "16px",
            height: "16px",
          }}
          src={deleteIcon}
        ></img>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            position: "relative",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              onClick={() => {
                props.moveStepUp(step.id, selectedVariantId, selectedFlow.id);
              }}
              style={{ width: "16px", height: "16px" }}
              src={upArrow}
            ></img>
            {step.seq}
            <img
              onClick={() => {
                props.moveStepDown(step.id, selectedVariantId, selectedFlow.id);
              }}
              style={{ width: "16px", height: "16px" }}
              src={downArrow}
            ></img>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: "95%",
            }}
          >
            {/* <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="to"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="call"
                  control={
                    <Radio
                      onClick={() => {
                        props.updateStepType(selectedFlow, selectedVariantId, step, "call");
                      }}
                    />
                  }
                  label="Call"
                />
                <FormControlLabel
                  value="reply"
                  control={
                    <Radio
                      onClick={() => {
                        props.updateStepType(selectedFlow,selectedVariantId, step, "reply");
                      }}
                    />
                  }
                  label="Reply"
                />
                <FormControlLabel
                  value="self"
                  control={
                    <Radio
                      onClick={() => {
                        props.updateStepType(selectedFlow,selectedVariantId, step, "self");
                      }}
                    />
                  }
                  label="Self"
                />
              </RadioGroup>
            </FormControl> */}
          </div>
        </div>
      </div>
    );
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);

    console.log(event);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (action, step) => {
    // Perform your action here
    console.log(`Action: ${action}`);
    console.log(`Step: ${step}`);

    if (action == "CREATE_NEW_STEP_BELOW") {
      props.createNewStep(selectedFlow, selectedVariantId, step.seq);
    } else if (action == "CREATE_NEW_STEP_ABOVE") {
      props.createNewStep(
        selectedFlow,
        selectedVariantId,
        Math.max(0, step.seq - 1)
      );
    } else if (action == "MOVE_UP") {
      props.moveStepUp(step.id, selectedVariantId, selectedFlow.id);
    } else if (action == "MOVE_DOWN") {
      props.moveStepDown(step.id, selectedVariantId, selectedFlow.id);
    } else if (action == "DELETE_STEP") {
      props.deleteStep(step, selectedVariantId, selectedFlow);
    } else if (action == "MANAGE_STATE") {
      props.manageState(step, selectedVariantId, selectedFlow);
    } else if (action == "ANIMATE_STEP") {
      handlePlayAnimation(selectedFlow, step.seq);
    }
    // Close the menu

    handleMenuClose();
  };

  const playAnimation = (index) => {
    handlePlayAnimation(selectedFlow, index);
  };

  const nonEditingStepJSX = (step) => {
    let isAnimating = false;
    if (animating && step.seq == animationStep) {
      isAnimating = true;
    }
    return (
      <div key={step.id} style={{ position: "relative" }}>
        {step.from != null && step.to != null && (
          <img
            cursor={"pointer"}
            src={playIcon}
            onClick={() => {
              playAnimation(step.seq);
            }}
            style={{
              position: "absolute",
              bottom: 4,
              padding: "4px",
              left: 0,
              zIndex: 2,
              width: "24px",
              height: "24px",
              cursor: "pointer",
            }}
          />
        )}
        <Card
          key={step.id}
          style={{
            margin: "2px",
            marginBottom: "0px",
            width: "100%",
            padding: "8px",
            position: "relative",
            cursor: "pointer",

            // background: "#F4F4F4",
            borderRadius: "4px", // Border radius, adjust as needed

            // borderColor: "#C8C8C8",
            borderColor: animationStep == step.seq ? "#C76811" : "#4c3fdc",
          }}
          onClick={() => {
            setEditingStep(step.id);
            props.cutomizeDiagramForStep(
              step.id,
              selectedFlow.id,
              selectedVariantId,
              false
            );
          }}
        >
          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                flex: 1,
                alignItems: "center", // Vertically center content within the Card
                // padding: "0 8px",
              }}
            >
              <div
                style={{
                  // background: "#E3E3E3",
                  //background: "#4c3fdc",
                  background: animationStep == step.seq ? "#C76811" : "#4c3fdc",
                  color: "white",
                  width: "40px",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  height: "40px",
                  fontWeight: "600", // Apply bold font style
                  fontSize: "16px", // Set the font size to 16px (adjust as needed)
                }}
              >
                {step.seq}
              </div>

              <div
                style={{
                  flex: 1, // Allow this element to take up remaining space
                  marginLeft: "16px",
                  alignItems: "center",
                  display: "flex",
                  fontWeight: "bold", // Apply bold font style
                  fontSize: "16px", // Set the font size to 16px (adjust as needed)
                }}
              >
                {step.from != null &&
                step.from.id != null &&
                step.from.id !== ""
                  ? props.components
                      .filter((component) => component.id === step.from.id)
                      .map((component) => component.text)
                  : "Select"}
              </div>

              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <img
                  style={{
                    width: "16px",
                    height: "16px",
                  }}
                  src={forwardArrow}
                  alt=""
                />
              </div>

              <div
                style={{
                  flex: 1,
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "flex-end", // Right-align the content
                  fontWeight: "600", // Apply bold font style
                  fontSize: "16px", // Set the font size to 16px (adjust as needed)
                }}
              >
                {step.to != null && step.to.id != null && step.to.id !== ""
                  ? props.components
                      .filter((component) => component.id === step.to.id)
                      .map((component) => component.text)
                  : "Select"}
              </div>
            </div>
            {/* <ListItemWithDropdown
              key={step.id}
              handleMenuItemClick={handleMenuItemClick}
              optionsIcon={optionsIcon} // Pass the optionsIcon for this item
              step={step}
            /> */}
          </div>
        </Card>

        {moreoptionsView(step, false)}
      </div>
    );
  };

  const arrowExistsBetweenRects = (from, toId, type) => {
    // console.log("arrowExistsBetweenRects ", type);
    if (type == "from" || from.id == toId) return true;
    let arrowExists = false;
    props.components.map((comp) => {
      if (comp.type == "arrow") {
        if (
          (comp.head.id == from.id && comp.tail.id == toId) ||
          (comp.tail.id == from.id && comp.head.id == toId)
        ) {
          arrowExists = true;
        }
      }
    });

    return arrowExists;
  };

  const grayBackground = {
    height: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start", // Align text to the left
    paddingLeft: "16px", // Optional padding for left alignment

    backgroundColor: "rgba(0, 0, 0, 0.1)", // Gray background color
  };

  const dropDownData = (step, type) => {
    // console.log("Get ddrop down data ", type);
    let comps = [];

    // if (type == "from" || step.from) {
    //   props.components &&
    //     props.components.length > 0 &&
    //     props.components.map((comp) => {
    //       comp.type == "rect" &&
    //         arrowExistsBetweenRects(step.from, comp.id, type) &&
    //         comps.push(
    //           <MenuItem
    //             key={comp.id}
    //             value={comp.text}
    //             onClick={(event) => {
    //               event.preventDefault();

    //               handleSelectComp(step, comp, type);
    //             }}
    //           >
    //             {comp.text}
    //           </MenuItem>
    //         );
    //     });
    //   console.log("comps ", comps);
    // }

    if (type == "from") {
      props.components &&
        props.components.length > 0 &&
        props.components.map((comp) => {
          if (
            comp.type == "rect" ||
            (comp.type == "image" && comp.text && comp.text.length > 0)
          ) {
            comps.push(
              <MenuItem
                key={comp.id}
                value={comp.text}
                onClick={(event) => {
                  event.preventDefault();
                  handleSelectComp(step, comp, type);
                }}
              >
                {comp.text}
              </MenuItem>
            );
          }
        });

      return comps;
    }

    if (type == "to") {
      if (step.from) {
        let connectedComps = [];
        let nonConnectedComps = [];
        props.components &&
          props.components.length > 0 &&
          props.components.map((comp) => {
            let toArrowExist = arrowExistsBetweenRects(
              step.from,
              comp.id,
              type
            );
            if (toArrowExist) {
              connectedComps.push(comp);
            } else {
              if (comp.type == "rect") {
                nonConnectedComps.push(comp);
              }
            }
          });

        comps.push(
          <ListSubheader sx={grayBackground}>
            Connected Components
          </ListSubheader>
        );
        connectedComps.map((comp) => {
          comps.push(
            <MenuItem
              key={comp.id}
              value={comp.text}
              onClick={(event) => {
                event.preventDefault();
                handleSelectComp(step, comp, type);
              }}
            >
              {comp.text}
            </MenuItem>
          );
        });

        if (nonConnectedComps && nonConnectedComps.length > 0) {
          comps.push(
            <ListSubheader sx={grayBackground}>
              Disconnected Components
            </ListSubheader>
          );
        }

        nonConnectedComps.map((comp) => {
          comps.push(
            <MenuItem
              key={comp.id}
              disabled
              value={comp.text}
              onClick={(event) => {
                event.preventDefault();
                handleSelectComp(step, comp, type);
              }}
            >
              {comp.text}
            </MenuItem>
          );
        });
      }
      return comps;
    }

    // if (type == "from" || step.from) {
    //   props.components &&
    //     props.components.length > 0 &&
    //     props.components.map((comp) => {
    //       let toArrowExist = arrowExistsBetweenRects(step.from, comp.id, type)
    //       comp.type == "rect" ?
    //         comps.push(
    //           <MenuItem

    //             key={comp.id}
    //             value={comp.text}
    //             onClick={(event) => {
    //               event.preventDefault();
    //               if (toArrowExist) {
    //                 handleSelectComp(step, comp, type);
    //               }
    //             }}
    //           >
    //             {comp.text}
    //           </MenuItem>
    //         )

    //     });
    //   console.log("comps ", comps);
    // }

    return comps;
  };

  const editingStepJSX = (step) => {
    let isAnimating = false;
    if (animating && step.seq == animationStep) {
      isAnimating = true;
    }

    return (
      <div key={step.id} style={{ position: "relative" }}>
        {step.from != null && step.to != null && (
          <img
            cursor={"pointer"}
            src={playIcon}
            onClick={() => {
              playAnimation(step.seq);
            }}
            style={{
              position: "absolute",
              bottom: 4,
              padding: "4px",
              left: 0,
              zIndex: 2,
              width: "24px",
              height: "24px",
              cursor: "pointer",
            }}
          />
        )}

        <Card
          key={step.id}
          style={{
            margin: "2px",
            padding: "8px",
            marginBottom: "12px",
            background:
              props.customizingStep && props.customizingStep.stepId == step.id
                ? "#D6E4EB"
                : "white",
            cursor: "pointer",
            borderColor: isAnimating ? "green" : "#4c3fdc",
          }}
        >
          <div
            className="flow-card"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                // background: "#E3E3E3",
                background: animationStep == step.seq ? "#C76811" : "#4c3fdc",
                color: "white",
                marginBottom: "14px",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                height: "40px",
                fontWeight: "600", // Apply bold font style
                fontSize: "16px", // Set the font size to 16px (adjust as needed)
              }}
              onClick={() => {
                setEditingStep(null);

                props.cutomizeDiagramForStep(
                  step.id,
                  selectedFlow.id,
                  selectedVariantId,
                  false
                );
                setIsDesignInProgress(false);
              }}
            >
              {"Step " + step.seq}
            </div>

            {/* <div
            className="semi-bold-text-16"
            onClick={() => {
              setEditingStep(null);
            }}
          >
            {"Step " + step.seq}{" "}
          </div> */}
            <div
              style={{
                flex: "1 1 0%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div style={{ flex: "0 0 auto" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",

                    //alignItems: "center",
                  }}
                >
                  <div style={{ flex: "1 0 0", color: "red" }}>
                    <CustomTextField
                      placeHolder="From"
                      disabled={props.components.length == 0}
                      style={{
                        width: "100%",
                        maxWidth: "175px",
                        margin: "0px",
                      }}
                      value={textForStepComponentBox(step, "from")}
                      child={dropDownData(step, "from")}
                    />
                  </div>

                  <div
                    style={{
                      flex: "0 0 auto",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      {step.to && step.to.triggerCode != null && (
                        <div>{step.to.triggerCode}</div>
                      )}
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      ></div>
                    </div>
                  </div>

                  <div style={{ flex: "1 0 0" }}>
                    <CustomTextField
                      placeHolder="To"
                      disabled={props.components.length == 0}
                      style={{
                        width: "100%",
                        maxWidth: "175px",
                        margin: "0px",
                      }}
                      value={textForStepComponentBox(step, "to")}
                      child={dropDownData(step, "to")}
                    />

                    <div>{step.toTriggerCode}</div>
                  </div>
                </div>

                {/* <div
                style={{
                  display: "flex",
                  padding: "8px",
                  width: "100%",
                }}
              > */}
                {/* {stepType(step)} */}
                <div>
                  <TextareaAutosize
                    className="select-box"
                    minRows={3}
                    style={{
                      width: "100%",
                      textAlignLast: "left",
                      resize: "none",
                      fontSize: 13,
                      height: "100px",
                      marginTop: "8px",
                      overflowY: "auto",
                      // margin: "8px",
                    }}
                    value={textForStepComponentBox(step, "desc")}
                    onChange={(event) => {
                      console.log(selectedFlow);
                      props.updateStepDescription(
                        selectedFlow,
                        selectedVariantId,
                        step,
                        event.currentTarget.value
                      );
                    }}
                    placeholder="Enter description here"
                  ></TextareaAutosize>
                </div>
                {/* </div> */}
              </div>
            </div>

            {/* <div style={{padding: '5px 20px', display: "flex", justifyContent: "space-between" }}>
            <img 
            cursor={"pointer"}
              src={insertIcon}
              onClick={() => {
                props.addRemoveChildFlow(
                  selectedFlow,
                  selectedVariantId,
                  step
                );
              }}
              style={{ 
                width: "24px", 
                height: "24px", 
                cursor: "pointer" 
              }}
            
            />

            <img
              src={minimizeIcon}
              onClick={(event) => {
                event.stopPropagation();
                setEditingStep(null)
              }}
              style={{ 
                width: "24px", 
                height: "24px", 
                cursor: "pointer" 
              }}
            />

          </div> */}
          </div>
          {moreoptionsView(step, true)}
        </Card>
      </div>
    );
  };

  const addMoreStepsButton = (step) => {
    let id = step ? step.id : "new-step";
    return (
      <div
        key={"add-more" + id}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "10px",
          marginLeft: "16px",
          marginRight: "10px",
        }}
      >
        <div
          style={{
            flex: "1",
            borderBottom: "1.5px solid #4c3fdc",
          }}
        ></div>
        <button
          style={{
            backgroundColor: "transparent",
            border: "none",
            display: "flex",
            justifyContent: "center",
            color: "#4c3fdc",
            alignItems: "center",
            margin: "0 10px",
            fontWeight: "bold",
            cursor: "pointer",
          }}
          onClick={() => {
            props.createNewStep(
              selectedFlow,
              selectedVariantId,
              step ? step.seq : 0
            );
          }}
        >
          <span
            style={{
              display: "inline-block",
              width: "24px",
              height: "24px",
              borderRadius: "50%",
              backgroundColor: "#4c3fdc",
              marginRight: "5px",
              color: "#ffffff",
              textAlign: "center",
              fontWeight: "bold",
              lineHeight: "21px",
            }}
          >
            +
          </span>
          New Step
        </button>
        <div
          style={{
            flex: "1",
            borderBottom: "1.5px solid #4c3fdc",
          }}
        ></div>
      </div>
    );
  };

  const moreoptionsView = (step, showCustomizeOption) => {
    return (
      // <div className="container">

      <div style={{ display: "flex", alignItems: "center" }}>
        {/* {showCustomizeOption && (
          <div
            style={{ display: "flex", alignItems: "center" }}
            onClick={() => {
              props.cutomizeDiagramForStep(
                step.id,
                selectedFlow.id,
                selectedVariantId,
                !isDesignInProgress
              );
              setIsDesignInProgress(!isDesignInProgress);
              console.log("Design Step");
            }}
          >
            <img
              src={designStep}
              style={{ width: "16px", marginRight: "8px", height: "16px" }}
            />
            <div>Customize State for this step</div>
          </div>
        )} */}

        <div className="step-more-actions">
          {/* <div className="flex-container"> */}
          {/* <div className="spacer"></div> */}

          <ListItemWithDropdown
            // key={step.id}
            style={{
              width: "120px",
              marginRight: "24px",
              height: "20px",
              // marginBottom: "40px",
            }}
            handleMenuItemClick={handleMenuItemClick}
            optionsIcon={customizeIcon} // Pass the optionsIcon for this item
            step={step}
          />
        </div>
      </div>
    );
  };

  const stepJSX = () => {
    let steps = [];

    if (selectedFlow != null) {
      if (selectedFlow.steps != null && selectedFlow.steps.length > 0) {
        selectedFlow.steps.map((step) => {
          if (editingStep != null && editingStep == step.id) {
            steps.push(editingStepJSX(step));
            steps.push(addMoreStepsButton(step));
          } else {
            steps.push(nonEditingStepJSX(step));
            steps.push(addMoreStepsButton(step));
          }
        });
      } else {
        steps.push(addMoreStepsButton());
      }
    }

    // Add separator div with button

    return steps;
  };

  const flowFooterJSX = () => {
    return (
      selectedFlow != null && (
        <div
          style={{
            height: "24px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <div
            className="flow-view-footer-create-step"
            onClick={() => {
              props.createNewStep(selectedFlow, selectedVariantId);
            }}
          >
            + New Step
          </div> */}

          {/* <div
            style={{
              margin: "10px",
              padding: "10px",
              cursor: "pointer",
              border: "1px solid lightgray",
            }}
            onClick={() => {
              props.createNewStep(selectedFlow, selectedVariantId);
            }}
          >
            <img
              src={plusIcon}
              style={{ width: "16px", marginRight: "8px", height: "16px" }}
            />
            {"New Step"}
          </div> */}
        </div>
      )
    );
  };

  // const createNewFlowButton = () => {
  //   return (
  //     <div
  //       className="search-dropdown-list-card"
  //       key="new"
  //       onClick={props.createNewFlowAction}
  //       style={{ background: "#F4FEE9" }}
  //     >
  //       <img
  //         src={plusIcon}
  //         style={{ width: "16px", marginRight: "8px", height: "16px" }}
  //       />
  //       Create new flow
  //     </div>
  //   );
  // };

  const flowList = () => {
    if (showDropdown) {
      return (
        <div
          className="search-dropdown-list-container"
          style={{
            ...props.style,
            // position: "absolute",
            zIndex: 2,
            backgroundColor: "white",
            borderRadius: "4px",
            boxShadow:
              "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08)",
          }}
        >
          {/* {createNewFlowButton()} */}
          {filteredOptions().length > 0 &&
            filteredOptions().map((flow) => (
              <div
                className="search-dropdown-list-card"
                key={flow.id}
                onClick={(event) => {
                  event.preventDefault();
                  handleSelectOption(flow);
                }}
              >
                <div>{flow.title}</div> {/* Title on the left */}
                <div
                  onClick={(e) => {
                    e.preventDefault();

                    props.renameFlow(flow);
                  }}
                  style={{
                    color: "blue",
                    width: "16px",
                    height: "16px",
                  }}
                >
                  Edit {/* "Edit" text on the right */}
                </div>
              </div>
            ))}
        </div>
      );
    }
  };

  const getTitle = () => {
    return showDropdown
      ? searchTerm
      : selectedFlow != null
      ? selectedFlow.title
      : props.flows.length > 0
      ? props.flows[0].title // Preselect the first flow in the list
      : "";
  };

  const handleVariantSelect = (variant) => {
    console.log(variant);
  };

  return (
    <div
      style={{
        height: `calc(100% - 170px)`,
      }}
      onClick={() => {
        // if (showDropdown == true) {
        //   setShowDropdown(false);
        // }
        if (componentSearch != null) {
          setComponentSearch(null);
        }
      }}
    >
      {props.flows.length > 0 ? (
        <div style={{ alignItems: "center", marginTop: "10px" }}>
          {/* <TextField
                style={{ marginTop: "10px", marginLeft: "10px", width: "95%" }}
                margin="dense"
                id="popup-text-field"
                label="Search"
                type="title"
                fullWidth
                autoComplete="off"
                variant="standard"
                value={getTitle()}
                onChange={(e) => {
                  setShowDropdown(true);
                  setSearchTerm(e.target.value);
                  setShowAllFlows(false);
                }}
                onClick={(event) => {
                  event.preventDefault();
                  setShowAllFlows(true);
                  setShowDropdown(true);
                }}
              /> */}

          <div
            style={{
              height: "64px",
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                fontSize: "9px",
                height: "10px",
                paddingLeft: "5px",
                left: "16px",
                zIndex: 2,
                position: "relative",
                backgroundColor: "white",
                width: "50px",
              }}
            >
              Flow List
            </div>

            <div
              style={{
                position: "relative",
                top: "-25px",
                zIndex: "99px",
                backgroundColor: "white",
                border: "1px solid #ccc",
                borderRadius: "4px",
                cursor: "pointer",
                marginLeft: "4px",
                // marginRight: "20px",
                marginTop: "20px",
              }}
            >
              <button
                onClick={() => {
                  setShowDropdown(!showDropdown);
                  setShowAllFlows(true);
                }}
                style={{
                  marginTop: "10px",
                  backgroundColor: "white",
                  marginLeft: "0px",
                  border: "0px",
                  width: "95%",
                  padding: "10px 20px",
                  display: "flex", // Use flex display
                  justifyContent: "space-between", // Align content horizontally
                  alignItems: "center", // Center items vertically
                }}
              >
                {selectedFlow ? selectedFlow.title : ""}
                <img
                  style={{
                    position: "relative",
                    right: "-20px",
                    height: "16px",
                    width: "16px",
                  }}
                  src={downArrow}
                ></img>
              </button>

              <div>{flowList()}</div>
            </div>
          </div>
        </div>
      ) : (
        <div
          onClick={props.createNewFlowAction}
          className="warning-message"
          style={{ cursor: "pointer" }}
        >
          No flows defined. Click here to create your first flow.
        </div>
      )}

      <div
        id="right-panel"
        key="right-panel"
        className="right-panel-components"
      >
        <div
          id="steps-jsx"
          key="steps-jsx"
          style={{
            marginLeft: "4px",
            marginRight: "4px",
            // width: `calc(100% - 4px)`,
          }}
        >
          {stepJSX()}
        </div>
        {flowFooterJSX()}
      </div>
      <div className="flow-view-footer-custom">
        {/* <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "0 20px",
          }}
        >
          <div
            className="flow-view-footer-create-step"
            onClick={() => {
              props.createNewStep(selectedFlow, selectedVariantId);
            }}
          >
            + New Step
          </div>

          <div
            className="flow-view-footer-create-flow"
            onClick={props.createNewFlowAction}
          >
            + Create Flow
          </div>
        </div> */}
        {/* <div className="flow-view-footer-share-save-container">
          <div
            // className="flow-view-footer-create-flow"
            className="flow-view-footer-share-button"
            onClick={props.createNewFlowAction}
          >
            + Flow
          </div>
          <div className="flow-view-footer-share-button">Share</div>
          <div className="flow-view-footer-share-button">Save</div>
        </div> */}
        <div
          className="flow-view-footer-view-flows-button"
          onClick={props.createNewFlowAction}
        >
          Create New Flow
        </div>
      </div>
    </div>
  );
});

export default FlowsDropdown;

//selectedVariantId
// const moveStepDown = (stepId, selectedVarianrId,flowId)
