import React, { useState, useRef, useEffect } from "react";
import Konva from "konva";
import { format } from "react-string-format";
import { Line, Text } from "react-konva";
import { EditableTextInput } from "./TextComponents/EditableTextInput";

import { Circle, Group } from "react-konva";
import { Arrow } from "react-konva";
import { getConnectingPoints } from "./../Helper/PointsLogic";
const EArrow = ({
  scale,
  shapeProps,
  onSelect,
  isSelected,
  onArrowDragMove,
  onArrowDragEnd,
  onChange,
  animateHeadToTails,
  animateTailToHead,
  arrowDotMoved,
  pointerPosition,
  enableStageEditing,
  disabled,
  updateArrowDesc,
  updateArrowPoints,
  isCustomizingStep,
  shouldHide,
  showPlayground,
  getComponentDetails,
}) => {
  const startRef = React.useRef();
  const midRef = React.useRef();
  const endRef = React.useRef();
  const [update, setUpdate] = useState(0);
  const [totalPoints, setTotalPoints] = useState(4);
  const [updatedShapePros, setUpdatedShapeProps] = useState(shapeProps);
  const [text, setText] = useState("Double click to edit");
  const [isEditing, setIsEditing] = useState(false);
  const [textWidth, setTextWidth] = React.useState(0);
  const [dashOffset, setDashOffset] = React.useState(0);
  const arrowRef = useRef(null);
  const lineRefs = useRef([]);

  const [forwardAnimationRequestId, setForwardAnimationRequestId] =
    useState(null);
  const [backwardAnimationRequestId, setBackwardAnimationRequestId] =
    useState(null);

  useEffect(() => {
    setUpdatedShapeProps({
      ...updatedShapePros,
      style: shapeProps.style,
    });
  }, [shapeProps.style]);

  useEffect(() => {
    setUpdatedShapeProps({
      ...updatedShapePros,
      points: shapeProps.points,
    });
  }, [shapeProps.points]);

  const opacity = () => {
    if (shouldHide) {
      return showPlayground ? 0.0 : 0.2;
    } else {
      if (shapeProps && shapeProps.style && shapeProps.style.alpha < 1) {
        return shapeProps.style.alpha;
      } else {
        return 1;
      }

      // return updatedShapePros.style && updatedShapePros.style.alpha != null
      //   ? updatedShapePros.style.alpha
      //   : 1;
    }
  };

  const animateForwardArrow = () => {
    const arrow = arrowRef.current;
    if (arrow) {
      const dashOffset = (arrow.dashOffset() - 0.5) % 20; // Adjust the speed and dash pattern as needed
      arrow.dashOffset(dashOffset);
      arrow.getLayer().batchDraw();
    }

    lineRefs.current.forEach((lineRef, index) => {
      const line = lineRef;
      if (line) {
        const dashOffset = (line.dashOffset() - 0.5) % 20; // Adjust the speed and dash pattern as needed
        line.dashOffset(dashOffset);
        line.getLayer().batchDraw();
      }
    });
    setForwardAnimationRequestId(requestAnimationFrame(animateForwardArrow));
  };

  const animateBackwardArrow = () => {
    const arrow = arrowRef.current;
    if (arrow) {
      const dashOffset = (arrow.dashOffset() + 0.5) % 20; // Adjust the speed and dash pattern as needed
      arrow.dashOffset(dashOffset);
      arrow.getLayer().batchDraw();
    }

    lineRefs.current.forEach((lineRef, index) => {
      const line = lineRef;
      if (line) {
        const dashOffset = (line.dashOffset() + 0.5) % 20; // Adjust the speed and dash pattern as needed
        line.dashOffset(dashOffset);
        line.getLayer().batchDraw();
      }
    });

    setBackwardAnimationRequestId(requestAnimationFrame(animateBackwardArrow));
  };

  useEffect(() => {
    if (animateHeadToTails) {
      if (!forwardAnimationRequestId) {
        setForwardAnimationRequestId(
          requestAnimationFrame(animateForwardArrow)
        );
      }
      if (backwardAnimationRequestId) {
        cancelAnimationFrame(backwardAnimationRequestId);
        setBackwardAnimationRequestId(null);
      }
    } else if (animateTailToHead) {
      if (!backwardAnimationRequestId) {
        setBackwardAnimationRequestId(
          requestAnimationFrame(animateBackwardArrow)
        );
      }
      if (forwardAnimationRequestId) {
        cancelAnimationFrame(forwardAnimationRequestId);
        setForwardAnimationRequestId(null);
      }
    } else {
      if (forwardAnimationRequestId) {
        cancelAnimationFrame(forwardAnimationRequestId);
        setForwardAnimationRequestId(null);
      }
      if (backwardAnimationRequestId) {
        cancelAnimationFrame(backwardAnimationRequestId);
        setBackwardAnimationRequestId(null);
      }
    }
  }, [animateHeadToTails, animateTailToHead]);

  const handleTextDoubleClick = () => {
    setIsEditing(true);
  };

  const handleTextInput = (event) => {
    setText(event.target.value);
  };

  const handleTextBlur = () => {
    setIsEditing(false);
  };

  const animate = (frame) => {
    setDashOffset((dashOffset) => dashOffset - 2); // Adjust the speed as needed
  };

  const handlePointDragMove = (index, event) => {
    // const { x, y } = pointerPosition();
    let stage = event.target.getStage();
    const pointerPosition = {
      x: stage.getPointerPosition().x / scale,
      y: stage.getPointerPosition().y / scale,
    };
    const { x, y } = pointerPosition;

    let allPoints = formattedPoints();
    allPoints[2 * index] = x;
    allPoints[2 * index + 1] = y;
    let updatedShapePros = { ...shapeProps, points: allPoints };
    updateArrowPoints(
      updatedShapePros,
      index == 0,
      index == allPoints.length / 2 - 1
    );

    // // console.log(x, y);
    // const newPoints = [...updatedShapePros.points];
    // newPoints[index * 2] = x;
    // newPoints[index * 2 + 1] = y;
    // updatedShapePros.points = allPoints;
    setUpdatedShapeProps(updatedShapePros);
    setUpdate(update + 1);

    //Second and Second Last Point only

    if (index == 0 || index == updatedShapePros.points.length / 2 - 1) {
      arrowDotMoved({
        arrowId: shapeProps.id,
        type: index == 0 ? "tail" : "head",
        points: allPoints,
      });
    }
  };

  const updateArrowHead = (x, y) => {
    updatedShapePros.points = [
      x,
      y,
      updatedShapePros.points[totalPoints - 2],
      updatedShapePros.points[totalPoints - 1],
    ];
    setUpdate(update + 1);
  };

  const updateArrowMid = (x, y) => {
    updatedShapePros.points = [
      updatedShapePros.points[0],
      updatedShapePros.points[1],
      x,
      y,
      updatedShapePros.points[4],
      updatedShapePros.points[5],
    ];
    setUpdate(update + 1);
  };

  const updateArrowTail = (x, y) => {
    updatedShapePros.points = [
      updatedShapePros.points[0],
      updatedShapePros.points[1],
      x,
      y,
    ];
    setUpdate(update + 1);
  };

  const handleArrowClick = (event) => {
    let stage = event.target.getStage();
    const clickPosition = {
      x: stage.getPointerPosition().x / scale,
      y: stage.getPointerPosition().y / scale,
    };

    const pointerPosition = clickPosition;
    // Calculate the nearest point index
    const nearestPointIndex = fitPointBetween(
      updatedShapePros.points,
      pointerPosition
    );

    // Insert a new point next to the nearest point
    const newPoints = [...updatedShapePros.points];

    newPoints.splice(
      nearestPointIndex + 2,
      0,
      pointerPosition.x,
      pointerPosition.y
    );

    updatedShapePros.points = newPoints;
    setUpdate(update + 1);
  };

  const fitPointBetween = (points, newPoint) => {
    var point = 0;
    var minDistance = 100000;
    for (let i = 0; i < points.length - 2; i += 2) {
      let distance = doesPointLieOnLine(
        { x: points[i], y: points[i + 1] },
        { x: points[i + 2], y: points[i + 3] },
        newPoint
      );
      if (distance < minDistance) {
        point = i;
        minDistance = distance;
      }
    }
    return point;
  };

  const doesPointLieOnLine = (pointA, pointB, newPoint) => {
    // Check if the points are vertically aligned
    if (Math.abs(pointA.y - pointB.y) < 1) {
      return Math.abs(newPoint.y - pointA.y) < 1;
    }

    // Check if the points are horizontally aligned
    if (Math.abs(pointA.x - pointB.x) < 1) {
      return Math.abs(newPoint.x - pointA.x) < 1;
    }

    // Calculate the slopes of the lines formed by pointA and pointB with the new point
    const slopeX = (pointB.x - pointA.x) / (pointB.y - pointA.y);
    const slopeY = (pointB.y - pointA.y) / (pointB.x - pointA.x);

    // Calculate the expected x and y coordinates of the new point on the line
    const expectedX = pointA.x + slopeX * (newPoint.y - pointA.y);
    const expectedY = pointA.y + slopeY * (newPoint.x - pointA.x);

    // Check if the new point's x and y coordinates are within the threshold of the expected coordinates on the line
    const threshold = 5;

    // return Math.abs(newPoint.x - expectedX) < threshold && Math.abs(newPoint.y - expectedY) < threshold;
    return Math.abs(newPoint.x - expectedX) + Math.abs(newPoint.y - expectedY);
  };

  const updatedPoints = () => {
    // if (scale) {

    // return newPoints
    // }
    // else {
    return updatedShapePros.points;
    // }
  };

  const getComponentBoundary = (compId) => {
    return getComponentDetails({ compId: compId, type: "bounds" });
  };

  // const formattedPoints = () => {
  //   if (
  //     shapeProps.head &&
  //     shapeProps.head.id &&
  //     shapeProps.tail &&
  //     shapeProps.tail.id &&
  //     updatedShapePros.points.length == 4
  //   ) {
  //     const points = [...updatedShapePros.points]; // copy the points array
  //     let headBounds = getComponentBoundary(shapeProps.head.id);
  //     let tailBounds = getComponentBoundary(shapeProps.tail.id);

  //     // Ensure that the lines are either horizontal or vertical
  //     for (let i = 0; i < points.length - 1; ) {
  //       const x1 = points[i];
  //       const y1 = points[i + 1];
  //       const x2 = points[i + 2];
  //       const y2 = points[i + 3];

  //       if (x1 !== x2 && y1 !== y2) {
  //         // Add an additional point
  //         points.splice(i + 2, 0, x1 + (x2 - x1) / 2, y1);
  //         points.splice(i + 4, 0, x1 + (x2 - x1) / 2, y2);
  //         i = i + 8;
  //       } else {
  //         i = i + 4;
  //       }
  //     }

  //     return points;
  //   } else {
  //     return updatedShapePros.points;
  //   }
  // };

  const getNextPoint = (
    currentX,
    currentY,
    targetX,
    targetY,
    rect1,
    rect2,
    allowedDirections,
    edge2
  ) => {
    const dx = targetX - currentX;
    const dy = targetY - currentY;

    let newPoints = getConnectingPoints(
      rect1,
      allowedDirections[0],
      rect2,
      edge2,
      currentX,
      currentY,
      targetX,
      targetY,
      dx,
      dy
    );
    return newPoints;
  };

  const ensureLineAvoidsRectangles = (points, rect1, rect2) => {
    const [x1, y1, x2, y2] = points;
    //const result = [];

    // Add the initial point
    //result.push(x1, y1);

    let edge1 = "";
    if (x1 === rect1.x) edge1 = "west";
    else if (x1 === rect1.x + rect1.width) edge1 = "east";
    else if (y1 === rect1.y) edge1 = "north";
    else if (y1 === rect1.y + rect1.height) edge1 = "south";

    let edge2 = "";
    if (x2 === rect2.x) edge2 = "west";
    else if (x2 === rect2.x + rect2.width) edge2 = "east";
    else if (y2 === rect2.y) edge2 = "north";
    else if (y2 === rect2.y + rect2.height) edge2 = "south";

    let newlyAddedPoints = getNextPoint(
      x1,
      y1,
      x2,
      y2,
      rect1,
      rect2,
      [edge1],
      edge2
    );

    let res = [x1, y1];
    if (newlyAddedPoints && newlyAddedPoints.length > 0) {
      res.push(...newlyAddedPoints);
    }
    res.push(x2, y2);
    return res;

    // Calculate the horizontal and vertical distances between the points
  };

  const formattedPoints = () => {
    return updatedShapePros.points;
    //return updatedShapePros.points.map((point) => point * scale);

    //Removing Zigzag arrow for now
    if (
      shapeProps.head &&
      shapeProps.head.id &&
      shapeProps.tail &&
      shapeProps.tail.id &&
      updatedShapePros.points.length == 4
    ) {
      let route = [...updatedShapePros.points]; // copy the points array
      let rectangle1 = getComponentBoundary(shapeProps.head.id);
      let rectangle2 = getComponentBoundary(shapeProps.tail.id);

      if (rectangle1 && rectangle2) {
        const points = ensureLineAvoidsRectangles(
          route,
          rectangle2,
          rectangle1
        );

        return points;
      }
    }
    let p = updatedShapePros.points.map((point) => point * scale);
    return p;
  };

  const getArrow = () => {
    let items = [];
    let allPoints = formattedPoints();

    let pointSets = [];

    for (let i = 0; i < allPoints.length - 2; i += 2) {
      pointSets.push(allPoints.slice(i, i + 4));
    }

    for (let i = 0; i < pointSets.length - 1; i = i + 1) {
      items.push(
        <Line
          perfectDrawEnabled={false}
          stroke="111"
          opacity={opacity()}
          listening={enableStageEditing}
          fill="111"
          resizeEnabled="true"
          type={"arrow"}
          pointerWidth={5}
          pointerLength={5}
          strokeWidth={2}
          lineJoin="round"
          dash={animateHeadToTails | animateTailToHead ? [5, 5] : [(0, 0)]}
          ref={(ref) => (lineRefs.current[i] = ref)}
          onDblClick={(e) => {
            handleArrowClick(e);
          }}
          onClick={(e) => {
            onSelect(shapeProps.id);
          }}
          points={pointSets[i]}
          onMouseEnter={(e) => {
            const container = e.target.getStage().container();
            container.style.cursor = "pointer";
          }}
          onMouseLeave={(e) => {
            const container = e.target.getStage().container();
            container.style.cursor = "default";
          }}
        />
      );
    }

    items.push(
      <Arrow
        ref={arrowRef}
        perfectDrawEnabled={false}
        stroke="111"
        opacity={opacity()}
        listening={enableStageEditing}
        fill="111"
        resizeEnabled="true"
        type={"arrow"}
        pointerWidth={5}
        pointerLength={5}
        strokeWidth={2}
        lineJoin="round"
        // opacity={disabled ? 0.1 : 1}
        dash={animateHeadToTails | animateTailToHead ? [5, 5] : [(0, 0)]}
        onDblClick={(e) => {
          //onSelect(shapeProps.id);
          handleArrowClick(e);
        }}
        onClick={(e) => {
          onSelect(shapeProps.id);
          //handleArrowClick(e)
          // handleArrowClick(e)
        }}
        points={pointSets[pointSets.length - 1]}
        onMouseEnter={(e) => {
          const container = e.target.getStage().container();
          container.style.cursor = "pointer";
        }}
        onMouseLeave={(e) => {
          const container = e.target.getStage().container();
          container.style.cursor = "default";
        }}
        onDragEnd={(e) => {
          onSelect();
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
      />
    );
    return items;
  };

  return (
    <React.Fragment>
      <Group>
        <Text
          x={(updatedPoints()[0] + updatedPoints()[2]) / 2}
          y={(updatedPoints()[1] + updatedPoints()[3]) / 2}
          // width={100}
          // height={100}
          draggable={false}
          // shapeProps={{
          //   x: (updatedPoints()[0] + updatedPoints()[2]) / 2,
          //   y: (updatedPoints()[1] + updatedPoints()[3]) / 2,
          //   isArrowDesc: true,
          // }}
          text={shapeProps.arrowDesc ? shapeProps.arrowDesc : ""}
          fontSize={16}
          fill={
            shapeProps.style && shapeProps.style.fill
              ? shapeProps.style.fill
              : "black"
          }
          align="center"
          // style={{ cursor: "pointer" }}
          onMouseEnter={(e) => {
            e.target.getStage().container().style.cursor = "pointer";
          }}
          onMouseLeave={(e) => {
            e.target.getStage().container().style.cursor = "default";
          }}
          onClick={(e) => {
            onSelect(shapeProps.id);
          }}
          onDblClick={(e) => {
            //onSelect(shapeProps.id);
            updateArrowDesc(shapeProps.id);
          }}
        />
        {getArrow()}
      </Group>

      {formattedPoints().map((point, index) => {
        // Render the circle for every second index
        if (index % 2 === 0) {
          return (
            <Circle
              perfectDrawEnabled={false}
              key={index}
              x={point}
              y={formattedPoints()[index + 1]}
              radius={index == formattedPoints().length - 1 ? 1 : 5}
              visible={isSelected}
              fill="blue"
              draggable={!isCustomizingStep}
              onDragMove={(event) => handlePointDragMove(index / 2, event)}
            />
          );
        }
        return null; // Skip rendering for odd indices
      })}
    </React.Fragment>
  );
};

export default EArrow;
