import "./CSS/App.css";
import "./CSS/Accordion.css";

import { MainCanvas } from "./MainCanvas/MainCanvas";
import React, { Component, useRef, useState } from "react";
import { LeftPanel } from "./leftPanel/leftPanel";
import { AutohideSnackbar } from "./Views/AutohideSnackbar";
//import { MenuBar } from "./MenuBar/MenuBar";
import { MenuBar } from "./MenuBar/MenuBar";
import { RightPanel } from "./rightPanel/rightPanel";
import { ComponentInfoPopup } from "./Views/ComponentInfoPopup";
import SearchChildComp from "./Views/SearchChildComp";
import SearchTriggerCode from "./Views/SearchTriggerCode";
import EmailInputPopup from "./Views/EmailInputPopup";
import VariantEditor from "./Views/VariantsEditor";
import { debounce, get } from "lodash";
import ImageUploader from "./Views/ImageUploader";
import Slide from "@mui/material/Slide";
import html2canvas from "html2canvas";
import mongoSVG from "../src/res/insertableimages/mongo.svg";
import postgresSVG from "../src/res/insertableimages/postgres.svg";
import pythonSVG from "../src/res/insertableimages/python.svg";
import sqlSVG from "../src/res/insertableimages/sql.svg";
import userSVG from "../src/res/insertableimages/user.svg";
import UsefulLinks from "./Views/UsefulLinks";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Footer from "./Views/Footer";
import CustomTextField from "./Helper/CustomTextField";
import { insertableImages } from "./freq-used-icons/icons";
import Drawer from "@mui/material/Drawer";
import { MenuItem } from "@mui/material";
import { IconButton } from "@mui/material";
// import Alert from "@mui/material/Alert";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import chevronRight from "../src/res/chevron-right.svg";
import chevronLeft from "../src/res/chevron-left.svg";

import {
  createDiagram,
  saveDiagram,
  updateDiagram,
  getDiagramDetails,
  deleteDiagram,
  saveFlows,
  updateFlows,
  getFlows,
  updateUserAccess,
  getUserAccess,
  requestAccess,
  getAccessRequestDetails,
  getAllAcessesForDiagram,
  makeDiagramPublic,
  uploadFileToServer,
  delistItem,
} from "./Helper/DataService";
import { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { createNewComponentObject } from "./DataManagers/CurrentDiagramDataHandler";
import { style } from "@mui/system";
import { Icon, TextareaAutosize } from "@mui/material";
import Login from "./Views/Login";
import ChooseDiagram from "./Views/ChooseDiagram";
import uuid from "react-uuid";
import {
  setCookie,
  getCookie,
  deleteCookie,
  updateLoginStatus,
  getLoginCookie,
} from "./Helper/CookieManager";
import { getDiagrams } from "./Helper/DataService";
import DraggableDialog from "./Helper/DraggableDialog";
import ConfirmationPopupView from "./Views/ConfirmationPopupView";
import VariablesViewModel from "./Views/VariablesViewModel";
import { AccessType } from "./Models/Constants";
import AlertPopup from "./Views/AlertPopup";
import AccessRequestApprovalPopUp from "./Views/AccessRequestApprovalPopUp";
import FlowsPlayground from "./Views/FlowsPlayground";
// import { Drawer, IconButton } from "@material-ui/core";
// import ChevronRightIcon from "@material-ui/icons/ChevronRight";
// import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const App = () => {
  const mainCanvas = useRef(null);
  const rightPanelRef = useRef(null);
  const autohideSnackbarRef = useRef(null);

  let [allFlows, setAllFlows] = useState([]);
  let [components, setComponents] = useState([]);
  let [variables, setVariables] = useState([]);
  let [openStep, setOpenStep] = useState(null);
  let [newImageURL, setNewImageURL] = useState(null);
  const [nextBoxNumber, setNextBoxNumber] = useState(1);
  let [openInputPopupView, setOpenInputPopupView] = useState(null);
  let [consentPopUp, setConsentPopUp] = useState(null);
  const popupRef = React.createRef();
  const popupRefDesc = React.createRef();
  const [groupId, setGroupId] = useState(1);
  const [update, setUpdate] = useState(0);
  const [diagramTitle, setDiagramTitle] = useState("");
  const [diagramDescription, setDiagramDescription] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showSelectDiagram, setShowSelectDiagram] = useState(false);
  const [showImageUploadWindow, setShowImageUploadWindow] = useState(false);
  const [showUsefulLinks, setShowUsefulLinks] = useState(false);
  const [showExitingDiagrams, setShowExitingDiagrams] = useState(false);
  const [descriptionDialogText, setDescriptionDialogText] = useState(null);
  const interval = 10000; // 10 seconds
  const [lastSavedComps, setLastSavedComps] = useState({
    lastUpdatedTime: new Date(),
    comps: [],
  });
  const [openShapeToAttachRef, setOpenShapeToAttachRef] = useState(null);
  const [opentriggerCodeSelection, setOpentriggerCodeSelection] =
    useState(null);
  const [openSharePanel, setOpenSharePanel] = useState(false);
  const [openVariablesView, setOpenVariablesView] = useState(false);
  const [openFlowVariationsView, setOpenFlowVariationsView] = useState(false);
  const [showPlayground, setShowPlayground] = useState(false);
  const [userAccessType, setUserAccessType] = useState(null);
  const [accessApprovalRequest, setAccessApprovalRequest] = useState(null);
  const [visibleCompInfo, setVisibleCompInfo] = useState(null);
  const [showCompInfo, setShowCompInfo] = useState(false);
  const flowPlaygroudRef = React.useRef();
  const [diagramAccessList, setDiagramAccessList] = useState(null);
  const fileInputRef = useRef(null);
  const dialogueRef = useRef(null);
  const [open, setOpen] = React.useState(false);
  const [isPublic, setIsPublic] = React.useState(false);
  const [copyStepsFromExistingFlow, setCopyStepsFromExistingFlow] =
    React.useState(false);
  const [flowToCopySteps, setFlowToCopySteps] = React.useState(null);
  const [customizingStep, setCustomizingStep] = React.useState(null);
  const [componentsToHide, setComponentsToHide] = React.useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [alertDetail, setAlertDetail] = React.useState(null);
  const MySwal = withReactContent(Swal);

  let popupWindow;

  let padding = 10;
  let [scale, setScale] = useState(1);

  const Transition = React.forwardRef(function Transition(props, dialogueRef) {
    return <Slide direction="up" ref={dialogueRef} {...props} />;
  });

  // const openFilePickerWindow = () => {
  //   // Open a new window
  //   popupWindow = window.open("", "File Picker", "width=400,height=200");

  //   // Create an input element in the new window
  //   const input = popupWindow.document.createElement("input");
  //   input.type = "file";
  //   input.accept = "image/*";

  //   // Append the input to the window's document
  //   popupWindow.document.body.appendChild(input);

  //   // Trigger the click event on the input to open the file selection dialog
  //   input.click();

  //   // Handle file selection change
  //   input.addEventListener("change", handleFileInputChange);
  // };

  const handleFileInputChange = async (event) => {
    const file = event.target.files[0];

    uploadFileToServer(file);
    console.log("Uploaded Image ", file);
    if (file) {
      // You can trigger the API call here to upload the image and get the URL
      try {
        // const imageUrl = await uploadImageAndGetUrl(file);
        // Pass the received imageUrl to the parent component or function
        // onImageUploadSuccess(imageUrl);
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }

    // Close the popup window after file selection
    // popupWindow.close();
  };

  useEffect(() => {
    const showErrorPopUp = (event) => {
      const { errorMessage } = event.detail;
      console.log("Message:", errorMessage);
      MySwal.fire({
        title: "Error",
        text: errorMessage,
        icon: "warning",
        confirmButtonText: "OK",
      });
    };

    // Add event listener for login status changes
    window.addEventListener("showErrorPopup", showErrorPopUp);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("showErrorPopup", showErrorPopUp);
    };
  }, []);

  const resetDiagramData = () => {
    setAllFlows([]);
    setComponents([]);
    setLastSavedComps({
      lastUpdatedTime: new Date(),
      comps: [],
    });
  };

  const handleKeyDown = (event) => {
    if (showPlayground) {
      flowPlaygroudRef.current.handleKeyDown(event.key);
    }
  };

  const closePlayground = () => {
    setShowPlayground(false);
  };

  // useEffect(() => {
  //   saveCompleteDiagram();
  // }, [diagramTitle]);

  useEffect(() => {
    // Disable scrolling on mount
    document.documentElement.style.overflow = "hidden";
    document.body.style.overflow = "hidden";

    // Enable scrolling on unmount
    return () => {
      document.documentElement.style.overflow = "";
      document.body.style.overflow = "";
    };
  }, []);

  useEffect(() => {
    if (mainCanvas.current) {
      mainCanvas.current.setStageEditing(!showPlayground);
    }
  }, [showPlayground]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      console.log(event);
      event.preventDefault();
      event.returnValue = "";
      const confirmationMessage = "Are you sure you want to leave this page?";
      return confirmationMessage;
    };

    const handlePopstate = (event) => {
      // console.log("Back or forward button clicked!");

      if (isLoggedIn) {
        const urlParams = new URLSearchParams(window.location.search);
        const diagramId = urlParams.get("diagram_id");
        resetDiagramData();
        if (diagramId) {
          setShowSelectDiagram(false);
          getDiagramData(getDigramIdentifier());
        } else {
          setShowSelectDiagram(true);
        }
      }
      // if (event.state) {
      // } else {
      //   console.log("Back Arrow");

      //   if (!diagramId) {
      //     setShowSelectDiagram(true);
      //   }
      // }

      // Perform actions based on the state or URL change
    };

    // window.addEventListener("beforeunload", handleBeforeUnload);
    // window.addEventListener("popstate", handlePopstate);

    return () => {
      // window.removeEventListener("beforeunload", handleBeforeUnload);
      // window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  const getDelta = (comps) => {
    if (showPlayground) {
      return;
    }

    comps = components;
    let currentTime = new Date();
    let lastUpdatedTime = lastSavedComps.lastUpdatedTime;
    const differenceInSeconds = (currentTime - lastUpdatedTime) / 1000;
    if (differenceInSeconds > 30) {
      // console.log("new Loop LastSavedComps ", lastSavedComps);
      const updatedCOMPS = comps.filter((comp) => {
        // Check if the new object exists in the old array
        const oldComp = lastSavedComps.comps.find((old) => old.id === comp.id);

        // If the new object does not exist in the old array, it is a new object
        if (!oldComp) {
          return true;
        }

        // console.log("oldComp.type ", oldComp.type);
        // if (oldComp.type === "arrow") {
        //   console.log("======");
        //   console.log(oldComp);
        //   console.log(comp);
        //   console.log("*****");
        // }
        // else {
        // If the new object exists in the old array but has changed, it is an updated object
        return JSON.stringify(oldComp) !== JSON.stringify(comp);
        // }
      });

      if (updatedCOMPS.length > 0) {
        if (lastSavedComps.comps.length == 0) {
          saveDiagram(
            {
              id: getDigramIdentifier(),
              title: diagramTitle,
              desc: diagramDescription,
              components: components,
            },
            getUserId()
          );
        } else {
          console.log("Updated Components Count ", updatedCOMPS.length);
          updateDiagram(
            { components: updatedCOMPS, diagramId: getDigramIdentifier() },
            getUserId()
          ).then((res) => {
            console.log("Updated Diagram Response ", res);
          });
        }
      }

      if (lastSavedComps && lastSavedComps.flows) {
        const updatedFlows = allFlows.filter((flow) => {
          const savedFlow = lastSavedComps.flows.find(
            (saved) => saved.id === flow.id
          );
          if (JSON.stringify(flow) !== JSON.stringify(savedFlow)) {
            updateFlows(flow, getUserId(), getDigramIdentifier());
          }
        });
      } else if (allFlows.length > 0) {
        saveDiagramFlows(allFlows, false);
      }

      // console.log("Updating LastSavedComps");
      setLastSavedComps({
        diagramId: getDigramIdentifier(),
        lastUpdatedTime: new Date(),
        comps: JSON.parse(JSON.stringify(comps)),
        flows: allFlows.length
          ? JSON.parse(JSON.stringify(allFlows))
          : undefined,
      });
      // console.log("Last updated LastSavedComps ", lastSavedComps);
    }
  };

  const updateComponents = (comps, update) => {
    setComponents(comps);
    getDelta(comps);
    mainCanvas.current.updateComonents(comps, update);
  };

  const getRecentDiagramsAndCacheInCookie = () => {
    //TODO: Need to create another service to get minimal data
    getDiagrams(getUserId(), 0, 5).then((res) => {
      let allDiagrams = res.data;
      var cachebleData = [];
      allDiagrams.forEach((data) => {
        cachebleData.push(
          cachebleData.push({ id: data.diagramId, label: data.diagram.title })
        );
      });
      setCookie("recent_diagrams", cachebleData, 30, true);
    });
  };

  useEffect(() => {
    getRecentDiagramsAndCacheInCookie();
  }, []);

  useEffect(() => {
    const handleResize = debounce(() => {
      setUpdate(update + 1);
      console.log("handleResize");
    }, 200); // Adjust the delay time (in milliseconds) as per your requirements

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleLogout = (event) => {
    deleteCookie("acessToken");
    deleteCookie("email");
    deleteCookie("userId");
    setIsLoggedIn(false);
  };

  const didSelectComponents = (selectCompIds) => {
    if (rightPanelRef && rightPanelRef.current)
      rightPanelRef.current.updateView();
    //rightPanelRef.current.updateComonents(components);
  };

  const getUserId = () => {
    //let userToken = getCookie("email", false);
    let userId = getCookie("userId", false);
    return userId;
  };

  useEffect(() => {
    const idToken = getCookie("acessToken", false);

    let hasLoggedInCookie = getLoginCookie();
    if (idToken != null && hasLoggedInCookie == "true") {
      // if (idToken) {
      setIsLoggedIn(true);
      let diagramId = getDigramIdentifier();
      if (diagramId == null) {
        setShowSelectDiagram(true);
      } else {
        let requestId = getDigramRequestIdentifier();
        if (requestId == null) {
          setShowSelectDiagram(false);
          setShowExitingDiagrams(false);
        } else {
          setAccessApprovalRequest({ requestId: requestId });
          getApprovalRequestDetails(requestId, diagramId);
        }
      }
    } else {
      // The cookie doesn't exist, so the user isn't logged in
      setIsLoggedIn(false);
    }
  }, []);

  const getApprovalRequestDetails = (requestId, diagramId) => {
    getAccessRequestDetails(getUserId(), requestId, diagramId).then((res) => {
      setAccessApprovalRequest({ requestId: requestId, accessRequest: res });
    });
  };

  const getSelectedComponents = () => {
    if (mainCanvas.current) {
      let selectedComponents = mainCanvas.current.getSelectedComponents();
      // if (selectedComponents && selectedComponents.length > 0) {
      //   console.log("Selected Component Appjs ", selectedComponents[0].text);
      // }
      return selectedComponents;
    }
  };

  const handleLogin = (accessToken) => {
    setCookie("accessToken", accessToken, 30, false);
    setIsLoggedIn(true);
  };

  const saveDiagramFlows = (allFlows, forceSave) => {
    saveFlows(allFlows, getUserId(), getDigramIdentifier());
  };

  const handleSave = async () => {
    saveCompleteDiagram();
  };

  useEffect(() => {
    const idToken = getCookie("acessToken", false);

    if (idToken) {
      let diagramId = getDigramIdentifier();
      let access_type = getAccessType();

      if (diagramId) {
        getDiagramData(getDigramIdentifier(), access_type);
      } else {
        setShowSelectDiagram(true);
      }
    }
  }, []);

  const creatNewComponent = (type) => {
    addNewComponent(type, { label: titleForNewComponent() }, false);
  };

  // const onChooseDiagram = (diagramId) => {

  //   // window.location.href = "/?diagram_id=" + diagramId;
  //   resetDiagramData();
  //   const newUrl = `/?diagram_id=${diagramId}`;

  //   //window.history.pushState({ diagramId }, "", newUrl);
  //   //getDiagramData(getDigramIdentifier());

  //   //const currentSubdomain = window.location.hostname.split(".")[0];
  //   //const newUrl = `?diagram_id=${id}`;

  //   console.log("Trying to open ", newUrl);
  //   window.open(newUrl, "_blank", "noopener,noreferrer"); //to open new page
  //   setShowSelectDiagram(false);
  // };

  const onChooseDiagram = (diagramId) => {
    // Reset diagram data
    resetDiagramData();

    const newUrl = `/?diagram_id=${diagramId}`;
    const currentUrl = window.location.href;

    // Check if the current URL already contains the diagramId
    const urlParams = new URLSearchParams(window.location.search);
    const currentDiagramId = urlParams.get("diagram_id");

    if (!currentDiagramId) {
      // If current URL does not have the diagramId, refresh the current page with the new URL
      window.location.href = newUrl;
    } else {
      // If current URL already has the diagramId, open the diagram in a new tab
      window.open(newUrl, "_blank", "noopener,noreferrer");
    }

    // Hide the diagram selection dialog
    setShowSelectDiagram(false);
  };

  const mapChildComponent = (shapeId) => {
    setOpenShapeToAttachRef(shapeId);
  };

  const uploadNewImage = () => {
    // openFilePickerWindow();
    setShowImageUploadWindow(true);
    // fileInputRef.current.click();
  };

  const addNewComponent = (type, data, isTempComponent) => {
    var [left, top] = mainCanvas.current.getContentOffset();
    let coordinates = { x: left, y: top };
    if (data == null) {
      data = coordinates;
    } else if (type != "self_arrow") {
      data = { ...data, ...coordinates };
    }

    //setGroupId(groupId + 1);
    let newCompData = createNewComponentObject(
      type,
      groupId,
      0,
      data,
      getDigramIdentifier()
    );

    if (!isTempComponent) {
      if (components && components.length > 0) {
        let updatedComps = [...components, newCompData];
        updateComponents(updatedComps);
        mainCanvas.current.updateComonents(updatedComps, false);
        rightPanelRef.current.updateComonents(updatedComps);
      } else {
        let updatedComps = [newCompData];
        updateComponents(updatedComps);
        mainCanvas.current.updateComonents(updatedComps, false);
        rightPanelRef.current.updateComonents(updatedComps);
      }
    }

    // setFrontZ(frontZ + 1);
    // setUpdate(update + 1);
    return newCompData;
  };

  const createNewBoxAndSetStartBox = (action, label) => {
    let componentData = addNewComponent("rect", { label: label }, false);

    updateBoxInFlow(componentData, action.flowId, action);
    setAllFlows(allFlows);
    //setUpdate(update + 1);
    setNextBoxNumber(nextBoxNumber + 1);
  };

  const getDigramIdentifier = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let diagramId = params.get("diagram_id");
    return diagramId;
  };

  const getAccessType = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let access_type = params.get("access_type");
    return access_type;
  };

  const getDigramRequestIdentifier = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let requestId = params.get("requestId");
    return requestId;
  };

  const componentsUpdated = (
    comps,
    updateMainCanvas,
    updateRightPanel,
    saveComponents
  ) => {
    updateComponents(comps);
    if (updateMainCanvas) mainCanvas.current.updateComonents(comps, true);

    if (updateRightPanel) rightPanelRef.current.updateComonents(comps);

    if (saveComponents) {
      saveDiagram(
        {
          id: getDigramIdentifier(),
          title: diagramTitle,
          desc: diagramDescription,
          components: comps,
        },
        getUserId()
      );
    }
  };

  const updateComponentInStep = (modelId, flowId, openStep) => {
    var selectedObj = components.filter((compObj) => {
      return compObj.id == modelId;
    });

    allFlows.map((flow) => {
      if (flow.id == flowId) {
        flow.steps.map((step) => {
          if (step.id == openStep.step.id) {
            if (openStep.type == "start") {
              step.from = { id: selectedObj[0].id, title: selectedObj[0].text };
            } else if (openStep.type == "end") {
              step.to = { id: selectedObj[0].id, title: selectedObj[0].text };
            }
            return step;
          }
        });
      }
    });
    setAllFlows(allFlows);
    rightPanelRef.current.updateFlows(allFlows);

    //setUpdate(update + 1);
  };

  const updateStepType = (stepType, selectedStep, flowId) => {
    allFlows.map((flow) => {
      if (flow.id == flowId) {
        flow.steps.map((step) => {
          if (step.id == selectedStep.id) {
            step.stepType = stepType;
            return step;
          }
        });
      }
    });
    setAllFlows(allFlows);
  };

  const updateStepDescription = (description, selectedStep, flowId) => {
    allFlows.map((flow) => {
      if (flow.id == flowId) {
        flow.steps.map((step) => {
          if (step.id == selectedStep.id) {
            step.desc = description;
            return step;
          }
        });
      }
    });
    setAllFlows(allFlows);

    rightPanelRef.current.updateFlows(allFlows);
  };

  const newStep = (seq) => {
    return {
      id: uuid(),
      seq: seq,
      title: null,
      desc: null,
      from: null,
      to: null,
    };
  };

  const addStep = (flowid, index) => {
    let stepId = "";

    allFlows.map((flow) => {
      if (flow.id == flowid) {
        var steps = flow.steps;
        if (steps && steps.length > 0) {
          var max_seq = Math.max(...steps.map((o) => o.seq));
          let step = newStep(index + 1);
          stepId = step.id;
          // steps.push(step);
          steps.splice(index, 0, step);
          const updatedSteps = steps.map((step, index) => ({
            ...step,
            seq: index + 1,
          }));
          flow.steps = updatedSteps;
        } else {
          let step = newStep(1);
          stepId = step.id;
          flow.steps = [step];
        }
      }
    });

    setAllFlows(allFlows);
    rightPanelRef.current.updateFlows(allFlows);
    // rightPanelRef.current.updateEditingStep(stepId);
  };

  const deleteStep = (action) => {
    allFlows.map((flow) => {
      if (flow.id == action.data.flowId) {
        let updatedSteps = flow.steps.filter(
          (step) => step.id !== action.data.stepId
        );
        updatedSteps = updatedSteps.map((step, index) => {
          return {
            ...step, // Copy all existing properties from the original step
            seq: index + 1, // Set the index property to index + 1
          };
        });

        flow.steps = updatedSteps;
      }
    });

    setAllFlows(allFlows);
    rightPanelRef.current.updateFlows(allFlows);
  };

  // const manageState = (action) => {
  //   setUpdatingStepCustomizations(action);
  // };

  const newFlow = (uid) => {
    return {
      id: uid,
      title: "New Flow",
      variants: [{ label: "default", id: uid + "-variant1" }],
      isExpanded: true,
    };
  };

  const updateFlowTitle = (flowid, title, desc) => {
    allFlows.map((flow) => {
      if (flow.id == flowid) {
        flow.title = title;
        flow.desc = desc;
      }
    });
  };

  const setStepTriggerCode = (flowid, stepId, code) => {
    allFlows.map((flow) => {
      if (flow.id == flowid) {
        flow.steps.map((s) => {
          if (s.id == stepId) {
            s.toTriggerCode = code;
          }
        });
      }
    });
    setAllFlows(allFlows);
    rightPanelRef.current.updateFlows(allFlows);
  };

  const addFlow = (uid) => {
    allFlows.push(newFlow(uid));
    updateSelectedFlowGlobally(allFlows, uid);
    setAllFlows(allFlows);
    rightPanelRef.current.updateFlows(allFlows);
    ///setUpdate(update + 1);
    // console.log(allFlows);
  };

  const titleForNewComponent = () => {
    return "";
    return (
      "Unit " +
      components.filter((comp) => {
        return comp.type == "rect";
      }).length
    );
  };

  const onDelistItem = (diagramId) => {
    return delistItem(diagramId, getUserId());
  };

  const rightPanelAction = (action) => {
    if (action.type == "startBox") {
      setOpenStep({ flowId: action.flowId, step: action.step, type: "start" });
    } else if (action.type == "endBox") {
      setOpenStep({ flowId: action.flowId, step: action.step, type: "end" });
    } else if (action.type == "createStartBox") {
      setOpenInputPopupView({
        type: "addBox",
        action: action,
        prevText: titleForNewComponent(),
      });
    } else if (action.type == "createEndBox") {
      setOpenInputPopupView({
        type: "addBox",
        action: action,
        prevText: titleForNewComponent(),
      });
    } else if (action.type == "updateComponentInStep") {
      let data = action.data;
      updateComponentInStep(data.modelId, data.flowId, data);
    } else if (action.type == "updateStepType") {
      let data = action.data;
      updateStepType(data.stepType, data.step, data.flowId);
    } else if (action.type == "updateStepDescription") {
      let data = action.data;
      updateStepDescription(data.description, data.step, data.flowId);
    } else if (action.type == "addStep") {
      addStep(action.data.flowId, action.data.index);
    } else if (action.type == "deleteStep") {
      deleteStep(action);
      saveCompleteDiagram();
    } else if (action.type == "flow") {
      let uid = uuid();
      addFlow(uid);
      addStep(uid, 0);

      setOpenInputPopupView({
        type: "rename_flow",
        flowId: uid,
      });
    } else if (action.type == "play_flow") {
      playFlow(action);
    } else if (action.type == "stop_flow") {
      stopFlow(action);
    } else if (action.type == "go_to_children") {
      loadChildren(action);
    } else if (action.type == "go_to_parent") {
      loadParent(action);
    } else if (action.type == "create_children") {
      createNewChildPage(action);
    } else if (action.type == "deleteFlow") {
      deleteFlow(action.flowId);
      saveDiagramFlows(allFlows, false);
    } else if (action.type == "move_step_up") {
      console.log("up");
      moveStepUp(action.data.flowId, action.data.stepId);
    } else if (action.type == "move_step_down") {
      console.log("udown");
      moveStepDown(action.data.flowId, action.data.stepId);
    } else if (action.type == "delete_flow") {
      deleteFlow(action.data.flowId);
    } else if (action.type == "update_selected_flow") {
      let flow = action.data.flow;
      updateSelectedFlowGlobally(allFlows, flow.id);
    } else if (action.type == "update_flow_trigger_code") {
      updateFlowTriggerCode(action);
    } else if (action.type == "update_step_trigger_code") {
      updateStepTriggerCode(action);
    } else if (action.type == "edit_reference") {
      let selectedShapes = mainCanvas.current.getSelectedComponents();
      if (selectedShapes.length == 1) {
        mapChildComponent(selectedShapes[0].id);
      }
    } else if (action.type == "style") {
      // console.log(action.value);

      let selectedShapes = mainCanvas.current.getSelectedComponents();
      let selectedShapeIds = selectedShapes.map((shape) => shape.id);
      updateComponentStyle(selectedShapeIds, action.style);
    } else if (action.type == "add_remove_child_flow") {
      addRemoveChildFlow(action.step, action.flowId);
      // let selectedShapes = mainCanvas.current.getSelectedComponents()
      // selectedShapes.map((shape => {
      //   shape.fill = action.color
      // }))
      // setUpdate(update + 1)
    } else if (action.type == "open_variants_action") {
      setOpenFlowVariationsView({
        flowId: action.flowId,
        selectedVariant: action.selectedVariant,
      });
    } else if (action.type == "create_variants_action") {
      let newVariantId = action.newVariantId;
      addFlowVariant(action.flowId, newVariantId);
    } else if (action.type == "rename_flow") {
      setOpenInputPopupView({
        type: "rename_flow",
        flowId: action.data.flowId,
        prevText: action.data.label,
        desc: action.data.flowDesc,
      });
    } else if (action.type == "saveComponentInfo") {
      updateComponentInfo(action.componentId, action.info);
    } else if (action.type == "run_flow_start") {
      setShowPlayground(true);
    } else if (action.type == "customize_diagram_for_step") {
      const hiddenShapeIds = [];
      allFlows.forEach((flow) => {
        if (flow.id === action.data.flowId) {
          flow.steps.forEach((step) => {
            if (step.id === action.data.stepId && step.hiddenShapeIds) {
              hiddenShapeIds.push(...step.hiddenShapeIds);
            }
          });
        }
      });

      setCustomizingStep({ ...action.data, hiddenShapeIds });
    } else if (action.type == "stop_customize_diagram_for_step") {
      setCustomizingStep(null);
    } else if (action.type == "savComponentTag") {
      saveComponentTag(action.componentId, action.tag);
    }
  };

  const showPlaygroundAction = () => {
    // setShowPlayground(true);
    saveCompleteDiagram();
    const extraParam = "viewonly";
    const currentPageUrl = window.location.href;
    const urlWithParams = `${currentPageUrl}&access_type=${extraParam}`; // Combine with the new query parameter
    window.open(urlWithParams, "_blank");
  };

  const updateComponentInfo = (compId, info) => {
    const updatedComponents = components.map((component) => {
      if (component.id === compId) {
        console.log(component);
        component["info"] = info;
      }
      return component;
    });

    updateComponents(updatedComponents);
    saveCompleteDiagram();
  };

  const saveComponentTag = (compId, tag) => {
    const updatedComponents = components.map((component) => {
      if (component.id === compId) {
        component.tags = tag;
      }
      return component;
    });

    updateComponents(updatedComponents);
  };

  const updateComponentStyle = (compIds, style) => {
    const updatedComponents = components.map((component) => {
      if (compIds.includes(component.id)) {
        component.style = { ...component.style, ...style };
      }
      return component;
    });

    updateComponents(updatedComponents);
  };

  const addFlowVariant = (flowId, variantId) => {
    let variant = null;

    allFlows.map((flow) => {
      if (flow.id == flowId.flowId) {
        variant = {
          label: "default" + flow.variants.length + 1,
          id: variantId,
        };
        flow.variants.push(variant);
      } else {
        console.log("Not found");
        console.log(flow.id, flowId);
      }
    });

    setAllFlows(allFlows);
    rightPanelRef.current.updateFlows(allFlows);
    setOpenFlowVariationsView({ flowId: flowId, selectedVariant: variant });
  };

  const addRemoveChildFlow = (inputStep, flowId) => {
    let stepId = inputStep.id;
    //remove steps in flow if children already expanded
    if (
      inputStep.childrenExpanded != null &&
      inputStep.childrenExpanded == true
    ) {
      allFlows.map((flow) => {
        if (flow.id == flowId) {
          flow.steps = flow.steps.filter(
            (step) => step.parentStepId !== stepId
          );
          flow.steps.map((step) => {
            if (step.id == stepId) {
              step.childrenExpanded = false;
            }
          });
        }
      });
      setAllFlows(allFlows);
      rightPanelRef.current.updateFlows(allFlows);
    } else {
      getTriggerCodeDetails(flowId, stepId, (flows) => {
        let chileFlowToBeInserted = flows.find(
          (f) => f.triggerCode == inputStep.to.triggerCode
        );
        chileFlowToBeInserted.steps.map((item) => {
          item.parentStepId = stepId;
        });
        allFlows.map((selectedFlow) => {
          if (selectedFlow.id == flowId) {
            const stepIndex = selectedFlow.steps.findIndex(
              (s) => s.id === stepId
            );
            if (stepIndex >= 0) {
              selectedFlow.steps.splice(
                stepIndex + 1,
                0,
                ...chileFlowToBeInserted.steps
              );
              selectedFlow.steps.map((s) => {
                if (s.id == stepId) {
                  s.childrenExpanded = true;
                }
              });
            }
          }
        });
        setAllFlows(allFlows);
        rightPanelRef.current.updateFlows(allFlows);
      });
    }
  };

  const updateFlowTriggerCode = (action) => {
    let flowId = action.flowId;
    let code = action.code;
    allFlows.map((f) => {
      if (f.id === flowId) {
        f.triggerCode = code;
      }
    });
    rightPanelRef.current.updateFlows(allFlows);
    // const flow = allFlows.find((f) => f.id === flowId);
    // flow.triggerCode = code
  };

  const getTriggerCodeDetails = (flowId, stepId, onCompletion) => {
    const flow = allFlows.find((f) => f.id === flowId);
    const step = flow.steps.find((s) => s.id === stepId);
    let toCompId = step.to.id;
    let comp = components.find((c) => c.id == toCompId);
    if (comp.isInstanceOf != null && comp.isInstanceOf.id != null) {
      getFlows(getUserId(), comp.isInstanceOf.id).then((res) => {
        let data = res.data;
        if (data && data.flows != null) {
          let flows = data.flows;
          if (flows) {
            onCompletion(flows);
          }
        }
      });
    }
  };

  const updateStepTriggerCode = (action) => {
    getTriggerCodeDetails(action.flowId, action.stepId, (flows) => {
      //type is not used as of now, added just in case
      setOpentriggerCodeSelection({
        flowId: action.flowId,
        stepId: action.stepId,
        flows: flows,
        type: "to",
      });
    });
  };

  const deleteFlow = (flowId) => {
    let updatedFlows = allFlows.filter((f) => {
      return f.id != flowId;
    });
    setAllFlows(updatedFlows);
    rightPanelRef.current.updateFlows(updatedFlows);
  };

  const updateStepSequence = (data, flowId, stepId, up) => {
    const flow = data.find((f) => f.id === flowId);
    if (!flow) {
      console.error("Flow not found");
      return;
    }

    const stepIndex = flow.steps.findIndex((step) => step.id === stepId);
    if (stepIndex === -1) {
      console.error("Step not found");
      return;
    }

    if (up && stepIndex > 0) {
      // Move step up
      const temp = flow.steps[stepIndex - 1];
      flow.steps[stepIndex - 1] = flow.steps[stepIndex];
      flow.steps[stepIndex] = temp;

      // Update seq
      flow.steps[stepIndex - 1].seq--;
      flow.steps[stepIndex].seq++;
    } else if (!up && stepIndex < flow.steps.length - 1) {
      // Move step down
      const temp = flow.steps[stepIndex + 1];
      flow.steps[stepIndex + 1] = flow.steps[stepIndex];
      flow.steps[stepIndex] = temp;

      // Update seq
      flow.steps[stepIndex + 1].seq++;
      flow.steps[stepIndex].seq--;
    }
  };

  const moveStepUp = (flowId, stepId) => {
    updateStepSequence(allFlows, flowId, stepId, true);
    rightPanelRef.current.updateFlows(allFlows);
  };

  const moveStepDown = (flowId, stepId) => {
    updateStepSequence(allFlows, flowId, stepId, false);
    rightPanelRef.current.updateFlows(allFlows);
  };

  const updateBoxInFlow = (selectedObj, flowId, openStep) => {
    allFlows.map((flow) => {
      if (flow.id == flowId) {
        flow.steps.map((step) => {
          if (step.id == openStep.step.id) {
            if (openStep.type == "createStartBox") {
              step.from = { id: selectedObj.id, title: selectedObj.text };
            } else if (openStep.type == "createEndBox") {
              step.to = { id: selectedObj.id, title: selectedObj.text };
            }
            return step;
          }
        });
      }
    });
    setAllFlows(allFlows);
    //setUpdate(update + 1);
  };

  const didClickInfoIcon = (componentId) => {
    let comp = components.find((c) => c.id == componentId);
    if (comp != null) {
      setVisibleCompInfo(comp);
      setShowCompInfo(true);
    }
  };

  const onSelect = (modelId) => {
    if (openStep != null) {
      updateComponentInStep(modelId, openStep.flowId, openStep);
      setAllFlows(allFlows);
      rightPanelRef.current.updateFlows(allFlows);
    }

    rightPanelRef.current.updateView();
  };

  const expandInNewPage = (shapeId) => {
    createNewChildPage(shapeId);
  };

  const resizeUpwards = (currentComponents, oneLevelUpParentShapeId) => {
    let oneLevelUpParentComponent = currentComponents.find(
      (c) => c.id == oneLevelUpParentShapeId
    );
    let hasChilds = currentComponents.find(
      (comp) => comp.parenComponentId == oneLevelUpParentShapeId
    );

    let newWidth = 0;
    let newHeight = 0;

    if (hasChilds) {
      //For the first one we did resize and prodvided actual width height since we are putting it based on if it doesn't has any child
      //Now we are one level up and we need to resize the current shapeId
      //width and height of it should be (padding + max x + max width) & (padding + max Y + max height)

      newWidth = Math.max(
        ...currentComponents.map((child) => {
          if (
            child.parenComponentId == oneLevelUpParentShapeId &&
            child.type == "rect"
          ) {
            return (
              child.x + child.width + padding - oneLevelUpParentComponent.x
            );
          } else return 0;
        })
      );
      newHeight = Math.max(
        ...currentComponents.map((child) => {
          if (
            child.parenComponentId == oneLevelUpParentShapeId &&
            child.type == "rect"
          ) {
            return (
              child.y + child.height + padding - oneLevelUpParentComponent.y
            );
          } else return 0;
        })
      );
    } else {
      newWidth = oneLevelUpParentComponent.actualWidth;
      newHeight = oneLevelUpParentComponent.actualHeight;
    }
    let deltaWidth = oneLevelUpParentComponent.width - newWidth;
    let deltaHeight = oneLevelUpParentComponent.height - newHeight;

    currentComponents.map((comp) => {
      if (comp.id === oneLevelUpParentShapeId) {
        comp.width = newWidth;
        comp.height = newHeight;
      }
    });

    //reposition all peers
    currentComponents.map((comp) => {
      // if (comp.viewLevel == oneLevelUpParentComponent.viewLevel && comp.type == "rect") {
      //Removing this viewLevel check. Since I am having the feeling, ideally all the view should be shifted not just the ones on same view level
      if (
        comp.viewLevel == oneLevelUpParentComponent.viewLevel &&
        comp.type == "rect"
      ) {
        if (
          comp.x >
          oneLevelUpParentComponent.x + oneLevelUpParentComponent.width
        ) {
          comp.x -= deltaWidth;
        }
        if (
          comp.y >
          oneLevelUpParentComponent.y + oneLevelUpParentComponent.height
        ) {
          comp.y -= deltaHeight;
        }
      }
    });

    if (oneLevelUpParentComponent.viewLevel == 0) {
      mainCanvas.current.updateComonents(currentComponents);
      mainCanvas.current.updateAllArrowsToCenter(currentComponents);
    } else {
      let newParent = currentComponents.find(
        (c) => c.id == oneLevelUpParentComponent.parenComponentId
      );
      resizeUpwards(currentComponents, newParent.id);
    }
  };

  const removeChildComponents = (currentComponents, shapeId) => {
    // 1. Find the component with the given shapeId
    const component = currentComponents.find(
      (component) => component.id === shapeId
    );

    if (!component) {
      console.error(`Component with id ${shapeId} not found!`);
      return;
    }

    // if (removeChildComponents == true) {
    // 2. Find the children components based on the shapeId
    const childrenIds = currentComponents
      .filter((comp) => comp.parenComponentId === shapeId)
      .map((comp) => comp.id);

    // 3. Remove the children components from the current components array
    currentComponents = currentComponents.filter(
      (comp) => !childrenIds.includes(comp.id)
    );
    // }

    currentComponents.forEach((comp) => {
      if (comp.id !== component.id && comp.viewLevel === component.viewLevel) {
        // Only move components that are below or to the right of the removed components
        if (comp.x >= component.x + component.width) {
          // Shift the component left or up to fill in the gap left by the removed components
          comp.x -= component.width - component.actualWidth;
        }

        if (comp.y >= component.y + component.height) {
          // Shift the component left or up to fill in the gap left by the removed components
          comp.y -= component.height - component.actualHeight;
        }
      }
    });

    currentComponents.forEach((comp) => {
      if (comp.id === component.id) {
        comp.width = component.actualWidth;
        comp.height = component.actualHeight;
      }
    });

    if (component.viewLevel == 0) {
      mainCanvas.current.updateComonents(currentComponents);
      mainCanvas.current.updateAllArrowsToCenter(currentComponents);
    } else {
      let newParent = currentComponents.find(
        (c) => c.id == component.parenComponentId
      );
      resizeUpwards(currentComponents, newParent.id);
    }
  };

  const addChildComponents = (
    currentComponents,
    parent,
    children,
    shouldInsert
  ) => {
    //0 First of all, if it's called for first time, measn shoudlinsert is true, we'll cache this width
    //Should Insert is called only for leaf component
    if (shouldInsert) {
      currentComponents.map((comp) => {
        if (comp.id == parent.id) {
          comp.actualWidth = comp.width;
          comp.actualHeight = comp.height;
        }
      });
    }

    //1. Get all Rects which will define size of parent
    let allChildRects = children.filter((comp) => {
      return comp.type == "rect";
    });

    //2. get minX and minY so we can trim all whitespace from diagram
    const smallestX = Math.min(...allChildRects.map((child) => child.x));
    const smallestY = Math.min(...allChildRects.map((child) => child.y));

    //3. get magin values, which needs to be deducted from each child
    //This is the final value calculated, which will be deducted from each child so that they appear inside box
    // a. child component - smallesX/Y => will take it to x/y if if the diagram was starting from x/y 0
    // b. Step a + parent.x/y, will shift component relative to parent
    let xMargin = parent.x - smallestX + padding;
    let yMargin = parent.y - smallestY + padding;
    // console.log("Margin ", xMargin, yMargin)

    const updatedChildComponents = children.map((child) => {
      child.viewLevel = parent.viewLevel + 1;
      child.parenComponentId = parent.id;

      if (child.type == "arrow") {
        const points = child.points.map((point, index) => {
          if (index % 2 === 0) {
            return point + xMargin;
          } else {
            return point + yMargin;
          }
        });
        return { ...child, points };
      } else {
        return {
          ...child,
          x: child.x + xMargin,
          y: child.y + yMargin,
        };
      }
    });

    //Now that children are placed on correct geomatry, we need to increase size of parent
    // we will take x+width or y+height and since it's relatve to component, we will deduct parent x & y so that we get the new height needed
    // we need to do this with all children and get the maxwidth & maxHeight
    let maxWidth = 0;
    let maxHeight = 0;
    updatedChildComponents.forEach((child) => {
      if (child.type == "rect") {
        maxWidth = Math.max(maxWidth, child.x + child.width - parent.x);
        maxHeight = Math.max(maxHeight, child.y + child.height - parent.y);
      }
    });

    // debugger

    //Now we have max height and width for parent component, we can directly increase the width but that way we'll not be able to reposition peers of parent
    // we need initial width/height and the updated width/height. This difference is what needs to be added to all uncles
    // Now we need to find all peers/uncles (All the rects with same viewLevel to parent)
    currentComponents.map((c) => {
      if (c.viewLevel == parent.viewLevel && c.type == "rect") {
        if (c.x > parent.x + parent.width) {
          c.x += maxWidth - parent.width;
        }
        if (c.y > parent.y + +parent.height) {
          c.y += maxHeight - parent.height;
        }
      }
    });

    //Now we will just set new height/width of parent
    currentComponents.map((c) => {
      if (c.id == parent.id) {
        c.width = maxWidth + padding;
        c.height = maxHeight + padding;
      }
    });

    let updated = [];
    if (shouldInsert) {
      updated = [...currentComponents, ...updatedChildComponents];
    } else {
      updated = currentComponents;
    }

    if (parent.viewLevel == 0) {
      mainCanvas.current.updateComonents(updated);
      mainCanvas.current.updateAllArrowsToCenter(updated);
    } else {
      let newParent = updated.find((c) => c.id == parent.parenComponentId);
      let allChildsOfNewParentCompoennt = updated.filter(
        (comp) => comp.parenComponentId == newParent.id
      );
      addChildComponents(
        updated,
        newParent,
        allChildsOfNewParentCompoennt,
        false
      );
    }
  };

  const expandAction = (shapeId) => {
    let childOfCurrent = components.filter((comp) => {
      return comp.parenComponentId == shapeId;
    });

    if (childOfCurrent && childOfCurrent.length > 0) {
      removeChildComponents(components, shapeId, true);
      return;
    }

    let comp = components.find((c) => c.id === shapeId);
    let diagramId = comp.isInstanceOf.id;

    getDiagramDetails(getUserId(), diagramId).then((res) => {
      let diagramData = res.data;
      if (diagramData) {
        comp.isInstanceOf.diagramDetails = diagramData;
        comp.isInstanceOf.isLoaded = true;

        addChildComponents(
          components,
          comp,
          diagramData.diagram.components,
          true
        );
        //let updatedComponents = components.concat(diagramData.diagram.components)
        //mainCanvas.current.updateComonents(updatedComponents)
      }
    });
  };

  const loadChildren = (shapeId) => {};

  const loadParent = (shapeId) => {};

  const openDiagram = (id) => {
    const currentSubdomain = window.location.hostname.split(".")[0];
    const newUrl = `?diagram_id=${id}`;

    console.log("Trying to open ", newUrl);
    window.open(newUrl, "_blank", "noopener,noreferrer"); //to open new page
  };

  const createNewChildPage = (shapeId) => {
    components.map((comp) => {
      if (comp.id == shapeId) {
        openDiagram(getDigramIdentifier() + "-" + comp.id);
      }
    });
  };

  const switchToViewOnly = () => {
    const url = new URL(window.location);
    url.searchParams.set("access_type", "viewonly");

    window.history.pushState({}, "", url);
  };

  const getDiagramData = (diagramId, access_type = null) => {
    setIsPublic(false);
    getDiagramDetails(getUserId(), diagramId).then((res) => {
      if (
        (res.userAccess && res.userAccess.accessLevel != "deleted") ||
        res.public
      ) {
        setIsPublic(res.public);
        if (res.userAccess) {
          setUserAccessType(res.userAccess.accessLevel);
          if (
            res.userAccess.accessLevel == "viewonly" ||
            access_type == "viewonly" ||
            (res.userAccess.accessLevel == "deleted" && res.public)
          ) {
            switchToViewOnly();
            setShowPlayground(true);
          }
        } else if (res.public == true) {
          setIsPublic(true);
          switchToViewOnly();
          setShowPlayground(true);
        } else {
          setUserAccessType(AccessType.NO_ACCESS);
        }
        setUpdate(update + 1);

        // getDiagramDetails(getUserId(), diagramId).then((res) => {
        let diagramData = res.data;
        if (diagramData) {
          if (diagramData.diagram.components) {
            updateComponents(diagramData.diagram.components);
          }

          if (diagramData.diagram.title == "Unnamed diagram") {
            setDiagramTitle(diagramData.diagram.title);
            setDiagramDescription(diagramData.diagram.desc);
            setOpenInputPopupView({
              type: "rename_diagram",
              prevText: "",
            });
          } else {
            setDiagramTitle(diagramData.diagram.title);
            setDiagramDescription(diagramData.diagram.desc);
          }
          setVariables(diagramData.diagram.variables);

          mainCanvas.current.updateComonents(
            diagramData.diagram.components,
            false
          );

          getFlows(getUserId(), diagramId).then((res) => {
            let data = res.data;
            if (data && data.flows != null) {
              let flows = data.flows;
              if (flows) {
                setAllFlows(flows);
                rightPanelRef.current.updateFlows(flows);
              }
            }
          });
        }
      } else if (res.userAccess && res.userAccess.accessLevel == "deleted") {
        setUserAccessType(AccessType.DELETED);
      } else if (res.status === 401) {
        setIsLoggedIn(false);
      } else if (res.status === 403) {
        setUserAccessType(AccessType.NO_ACCESS);
      } else {
        setUserAccessType(AccessType.NO_ACCESS);
      }
    });
  };

  const linkedChildFlows = (flows) => {
    for (let i = 0; i < flows.length; i++) {
      for (let j = 0; j < flows[i].steps.length; j++) {
        if (flows[i].steps[j].parentStepId !== null) {
          return true;
        }
      }
    }
    return false;
  };

  const saveCompleteDiagram = (title, desc) => {
    let expandedComponentsAvailable = components.filter((comp) => {
      return comp.viewLevel > 0;
    });

    if (linkedChildFlows == true || expandedComponentsAvailable.length > 0) {
      alert(
        "You can not save current state, since you have included another diagram/flows"
      );
      return;
    }

    // const updatedFlows = allFlows.map(flow => {
    //   const rootSteps = flow.steps.filter(step => step.parentStepId === null);

    //   const updatedSteps = rootSteps.map(step => {
    //     return { ...step, childrenExpanded: false };
    //   });

    //   return { ...flow, steps: updatedSteps };
    // });

    // const expandedFlowsAvailable = allFlows.map(flow => {
    //   const rootSteps = flow.steps.filter(step => step.parentStepId === null);

    //   const updatedSteps = rootSteps.map(step => {
    //     return { ...step, childrenExpanded: false };
    //   });

    //   return { ...flow, steps: updatedSteps };

    // })

    let diagramsToSave = components.map((comp) => {
      comp.isDragging = false;
      comp.isSelected = false;
      if (comp.type == "arrow") {
        comp.animateForward = false;
        comp.animateBackward = false;
      }
    });
    saveDiagram(
      {
        id: getDigramIdentifier(),
        title: title ?? diagramTitle,
        desc: desc ?? diagramDescription,
        components: components,
        flows: allFlows,
        variables: variables,
      },
      getUserId()
    ).then((res) => {
      if (res.status == true) {
        autohideSnackbarRef.current.showToast("Diagram saved successfully");
        saveDiagramFlows(allFlows, true);
      } else {
        autohideSnackbarRef.current.showToast("Error saving diagram");
      }
    });
  };

  const topMenuAction = (action) => {
    if (!action) return;

    if (action.type == "new") {
      createDiagram({ title: "Unnamed diagram" }, getUserId()).then((res) => {
        let diagram = res.data;
        let uid = diagram.diagramId;
        const currentSubdomain = window.location.hostname.split(".")[0];
        const newUrl = `?diagram_id=${uid}`;
        console.log("Trying to open ", newUrl);
        window.open(newUrl, "_blank", "noopener,noreferrer"); //to open new page
      });
    } else if (action.type == "save") {
      saveCompleteDiagram();
    } else if (action.type == "open") {
      getDiagramData(getDigramIdentifier());
    } else if (action.type == "open-diagram") {
      openDiagram(action.pageId);
    } else if (action.type == "rename_diagram") {
      // openDiagram(action.pageId);
      setOpenInputPopupView({
        type: "rename_diagram",
        prevText: action.label,
        desc: diagramDescription,
      });
    } else if (action.type == "open-exiting-diagram") {
      setShowSelectDiagram(true);
      setShowExitingDiagrams(true);
    } else if (action.type == "expand") {
      setShowSelectDiagram(true);
      setShowExitingDiagrams(true);
    } else if (action.type == "expand_in_new_page") {
      setShowSelectDiagram(true);
      setShowExitingDiagrams(true);
    } else if (action.type == "run_flow_start") {
      // mainCanvas.current.setStageEditing(false)
      setShowPlayground(true);
    } else if (action.type == "run_flow_stop") {
      // mainCanvas.current.setStageEditing(true)
      setShowPlayground(false);
    } else if (action.type == "reset_scale") {
      mainCanvas.current.setCanvasScale(1);
    } else if (action.type == "usefulLinks") {
      setShowUsefulLinks(true);
    } else if (action.type == "alert") {
      debugger;
      setAlertDetail(action.alertDetail);
      //  topMenuAction({
      //    type: "alert",
      //    message: "You don't have access to edit",
      //  });
    }
  };

  const stopFlow = () => {
    components.map((comp) => {
      if (comp.type == "arrow") {
        comp.animateForward = false;
        comp.animateBackward = false;
      } else if (comp.animate == true) {
        comp.animate = false;
      }
    });
    //updateComonents(components, false);
    updateComponents(components);
    setDescriptionDialogText(null);
    mainCanvas.current.updateComonents(components, false);
    setComponentsToHide([]);
    rightPanelRef.current.stopPreviewAnimation();
    // setUpdate(update + 1);
  };

  const playFlow = (action) => {
    //runanimation

    let flow = action.flow;
    let stepNumber = action.stepNumber;
    let step = flow.steps[stepNumber - 1];
    if (step != null && step.hiddenShapeIds != null)
      setComponentsToHide(step.hiddenShapeIds);
    var headId = step.to.id;
    var tailId = step.from.id;

    let allComnponentsInFlow = {};
    let arrowInFlow = {};

    flow.steps.map((step) => {
      allComnponentsInFlow[step.from.id] = 1;
      allComnponentsInFlow[step.to.id] = 1;
      arrowInFlow[step.from.id + "|" + step.to.id] = 1;
    });

    //Animate Rect
    components.map((comp) => {
      if (comp.id == headId && comp.id == tailId) {
        comp.animate = true;
      } else {
        comp.animate = false;
      }
      if (comp.type == "arrow") {
        comp.animateForward = false;
        comp.animateBackward = false;
      }
    });

    //Animate Arrow if no rect is animating

    let isFirstComponentAnimated = false;
    if (step.to.id != step.from.id) {
      components.map((comp) => {
        if (comp.type == "arrow") {
          if (comp.head.id == headId && comp.tail.id == tailId) {
            if (isFirstComponentAnimated == false) {
              console.log("Animating Forward ", comp.id);
              comp.animateForward = true;
              comp.animateBackward = false;
              isFirstComponentAnimated = true;
            } else {
              console.log("Updating alpha .5");
              comp.style = { ...comp.style, alpha: 0.5 };
            }
          } else if (comp.head.id == tailId && comp.tail.id == headId) {
            if (isFirstComponentAnimated == false) {
              console.log("Animating Backwards");
              comp.animateForward = false;
              comp.animateBackward = true;
              isFirstComponentAnimated = true;
            } else {
              console.log("Updating alpha .5");
              comp.style = { ...comp.style, alpha: 0.5 };
            }
          } else {
            if (comp.animateForward || comp.animateBackward)
              console.log("Stopping Animation");
            comp.animateForward = false;
            comp.animateBackward = false;
            comp.style = { ...comp.style, alpha: 1 };
          }
        }
      });
    }
    setDescriptionDialogText(step.desc);
    updateComponents(components);
    mainCanvas.current.updateComonents(components, false);
    mainCanvas.current.refreshCanvas();
    // updateComonents(components, false);
    setUpdate(update + 1);
  };

  const handleClose = () => {
    setOpenInputPopupView(null);
    setCopyStepsFromExistingFlow(false);
    setFlowToCopySteps(null);
  };

  const playgroundActionhandler = (action) => {
    // console.log(action);

    if (action.type === "closeplayground") {
      setShowPlayground(false);
    } else if (action.type == "play_flow") {
      playFlow(action);
    } else if (action.type == "stop_flow") {
      stopFlow(action);
    } else if (action.type == "show_flow_details") {
      setOpenInputPopupView({
        type: "show_flow_details",
        action: action,
        prevText: action.flow.desc,
        readOnly: true,
      });
    }
  };

  const savePopUp = () => {
    let input = popupRef.current.value;
    if (input.length > 0) {
      if (openInputPopupView.type == "rename_diagram") {
        setDiagramTitle(input);
        setDiagramDescription(openInputPopupView.desc);
        setOpenInputPopupView(null);
        saveCompleteDiagram(input, openInputPopupView.desc);
      }
      if (openInputPopupView.type == "rename_flow") {
        let input = popupRef.current.value;
        updateFlowTitle(
          openInputPopupView.flowId,
          input,
          openInputPopupView.desc
        );
        setOpenInputPopupView(null);
        rightPanelRef.current.updateSelectedFlow(openInputPopupView.flowId);
        if (flowToCopySteps) {
          const copyStepsToSelectedFlow = () => {
            const selectedFlowId = allFlows.find((flow) => flow.selected).id;
            const selectedFlow = allFlows.find(
              (flow) => flow.id === selectedFlowId
            );
            const stepsToCopy = flowToCopySteps.steps;

            selectedFlow.steps = [...stepsToCopy];

            setAllFlows([...allFlows]);
          };

          copyStepsToSelectedFlow();
        }
      } else if (openInputPopupView.type == "addBox") {
        createNewBoxAndSetStartBox(openInputPopupView.action, input);
        setOpenInputPopupView(null);
      } else if (openInputPopupView.type == "add_arrow_desc") {
        components.map((comp) => {
          if (comp.id == openInputPopupView.selectedArrow) {
            comp.arrowDesc = input;
          }
        });
        setComponents(components);
        mainCanvas.current.updateComonents(components, false);
        rightPanelRef.current.updateComonents(components);
        setOpenInputPopupView(null);
      } else if (openInputPopupView.type == "update_step_trigger_code") {
        let flowId = openInputPopupView.action.flowId;
        let stepId = openInputPopupView.action.stepId;
        setStepTriggerCode(flowId, stepId, input);
        setOpenInputPopupView(null);
      }
    }

    setCopyStepsFromExistingFlow(false);
    setFlowToCopySteps(null);
  };

  const updateSelectedFlowGlobally = (flowList, flowId) => {
    flowList.map((flow) => {
      if (flowId == flow.id) {
        flow.selected = true;
      } else {
        flow.selected = false;
      }
    });
    setAllFlows(flowList);
  };

  const handleDelete = () => {
    let flowToDelete = openInputPopupView.flowId;
    const updatedFlows = allFlows.filter((flow) => flow.id !== flowToDelete);
    setAllFlows(updatedFlows);
    if (updatedFlows.length > 0) {
      let newFlowId = updatedFlows[0].id;
      updateSelectedFlowGlobally(updatedFlows, newFlowId);
      rightPanelRef.current.updateSelectedFlow(newFlowId);
    } else {
      rightPanelRef.current.updateSelectedFlow(null);
    }
    rightPanelRef.current.updateFlows(updatedFlows);
    setOpenInputPopupView(null);
    // saveFlows(updatedFlows, getUserId(), getDigramIdentifier());
  };

  const popUpHeaderTitle = (type, action) => {
    if (type == "new_diagram" || "rename_diagram") {
      return "Diagram";
    } else if (type == "rename_flow" || type == "show_flow_details") {
      return "Flow";
    } else if (type == "addBox") {
      return "Component";
    }
  };

  const popUpContextText = (type, action) => {
    if (type == "new_diagram" || type == "rename_diagram") {
      return "Diagram Details";
    } else if (type == "rename_flow") {
      return "Flow Details";
    } else if (type == "addBox") {
      return "Component Details";
    } else if (type == "show_flow_details") {
      return action.flow.title;
    }
  };

  const popUpContextSubText = (type, action) => {
    if (type == "new_diagram" || type == "rename_diagram") {
      return "Please provide title for Diagram";
    } else if (type == "rename_flow") {
      return "Please provide title for flow";
    } else if (type == "addBox") {
      return "Please provide title for Component";
    } else if (type == "show_flow_details") {
      return "Details of Flow";
    }
  };

  const onDeleteDiagram = (diagramId, callback) => {
    setConsentPopUp({
      message: "Are you sure you want to delete diagram?",
      confirmText: "Yes",
      cancelText: "No",
      action: {
        type: "deleteDiagram",
        diagramId: diagramId,
        callback: callback,
      },
    });
  };

  const onDragNewComponent = (type) => {};

  const showCopyStepsOption = (type) => {
    if (type == "rename_flow") {
      let alreadyCreatedSteps = false;
      if (allFlows.length > 0) {
        allFlows.map((flow) => {
          if (flow.selected == true) {
            if (flow && flow.steps && flow.steps.length < 2) {
              flow.steps.map((step) => {
                if (step.from || step.to) {
                  alreadyCreatedSteps = true;
                }
              });
            } else {
              alreadyCreatedSteps = true;
            }
          }
        });
      }
      return !alreadyCreatedSteps;
    } else {
      return false;
    }
  };

  const flowsAvailableForCopySteps = () => {
    return allFlows.map((flow) => {
      const disabled =
        !flow.steps ||
        flow.steps.length < 2 ||
        flow.steps.every((step) => !step.from && !step.to);
      return (
        <MenuItem
          key={flow.id}
          value={flow.title}
          disabled={disabled}
          onClick={(event) => {
            event.preventDefault();
            setFlowToCopySteps(flow);
          }}
        >
          {flow.title}
        </MenuItem>
      );
    });
  };
  const newInputPopup = () => {
    if (openInputPopupView) {
      return (
        <div>
          <Dialog open={true} onClose={handleClose}>
            {/* <DialogTitle>
              {popUpHeaderTitle(openInputPopupView.type)}
            </DialogTitle> */}
            <DialogContent
              style={{
                width: "600px",
                // height:
                //   openInputPopupView.type == "rename_flow" ? "250px" : "275px",
                padding: "0", // Remove top padding
                margin: "0", // Remove top margin
              }}
            >
              <DialogContentText>
                <div
                  style={{
                    height: "64px",
                    // background: "#002966",
                    fontSize: "16px",
                    fontWeight: "600",
                    paddingTop: "10px", // Padding top
                    paddingLeft: "10px", // Padding left

                    marginBottom: "12px",
                    color: "black",
                  }}
                >
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "600",
                      paddingTop: "0px", // Padding top
                      paddingLeft: "0px", // Padding left
                    }}
                  >
                    {popUpContextText(
                      openInputPopupView.type,
                      openInputPopupView.action
                    )}
                  </div>

                  {!showPlayground && (
                    <div
                      style={{
                        fontSize: "11px",
                        fontWeight: "400",
                        paddingTop: "4px", // Padding top
                        paddingLeft: "0px", // Padding left
                      }}
                    >
                      {popUpContextSubText(
                        openInputPopupView.type,
                        openInputPopupView.action
                      )}
                    </div>
                  )}
                </div>
              </DialogContentText>
              {openInputPopupView.readOnly != true && (
                <TextField
                  inputRef={popupRef}
                  autoFocus
                  autoComplete="off"
                  margin="dense"
                  id="popup-text-field"
                  label="Title"
                  type="title"
                  style={{
                    marginLeft: "10px",
                    width: "550px",
                  }}
                  InputProps={{
                    readOnly: showPlayground,
                  }}
                  variant="standard"
                  value={openInputPopupView.prevText}
                  onChange={(event) =>
                    setOpenInputPopupView({
                      ...openInputPopupView,
                      prevText: event.target.value,
                    })
                  }
                />
              )}
              {openInputPopupView.readOnly == true && (
                <div
                  style={{
                    marginLeft: "10px",

                    width: "550px",
                  }}
                >
                  {openInputPopupView.prevText}
                </div>
              )}
              {(openInputPopupView.type == "rename_flow" ||
                openInputPopupView.type == "rename_diagram") && (
                <TextareaAutosize
                  inputRef={popupRefDesc}
                  className="select-box"
                  minRows={3}
                  readOnly={showPlayground}
                  style={{
                    marginLeft: "10px",
                    width: "570px",

                    textAlignLast: "left",
                    resize: "none",
                    fontSize: 13,
                    height: "300px",
                    marginTop: "12px",
                    marginBottom: "12px",
                    overflow: "auto",
                  }}
                  value={openInputPopupView.desc}
                  onChange={(event) => {
                    setOpenInputPopupView({
                      ...openInputPopupView,
                      desc: event.target.value,
                    });
                  }}
                  placeholder="Enter description here"
                ></TextareaAutosize>
              )}
            </DialogContent>
            <DialogActions style={{ justifyContent: "space-between" }}>
              <div
                style={{
                  width: "100%",
                }}
              >
                {showCopyStepsOption(openInputPopupView.type) && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "44px",
                      marginBottom: "36px",
                      width: "100%",
                    }}
                  >
                    <input
                      type="checkbox"
                      style={{ marginRight: "8px", width: "20px" }}
                      checked={copyStepsFromExistingFlow}
                      onChange={(event) => {
                        setCopyStepsFromExistingFlow(event.target.checked);
                        if (event.target.checked == false) {
                          setFlowToCopySteps(null);
                          console.log(flowToCopySteps);
                        }
                      }}
                    />
                    <span style={{ whiteSpace: "nowrap", marginRight: "20px" }}>
                      Copy steps from existing flow
                    </span>
                    <div
                      style={{
                        width: "100%",
                      }}
                    >
                      {copyStepsFromExistingFlow && (
                        <CustomTextField
                          placeHolder="Choose flow"
                          style={{
                            marginLeft: "10px",
                            width: "331px",
                            height: "40px",
                          }}
                          value={
                            flowToCopySteps ? flowToCopySteps.title : "Select"
                          }
                          child={flowsAvailableForCopySteps()}
                        />
                        // <select
                        //   style={{
                        //     paddingLeft: "10px",
                        //     position: "relative",
                        //     minWidth: "50%",
                        //     right: "10px",
                        //   }}
                        //   value={
                        //     flowToCopySteps ? flowToCopySteps.title : "Select"
                        //   }
                        //   onChange={(event) =>
                        //     setFlowToCopySteps(event.target.value)
                        //   }
                        // >
                        //   {allFlows.map(
                        //     (flow) =>
                        //       flow.steps.length > 0 && (
                        //         <option value={JSON.stringify(flow)}>
                        //           {flow.title}
                        //         </option>
                        //       )
                        //   )}
                        // </select>
                      )}
                    </div>
                  </div>
                )}
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {openInputPopupView.type == "rename_flow" ? (
                    <div>
                      <div
                        className="red-button"
                        style={{
                          marginTop: "12px",
                          height: "44px",
                          width: "88px",
                          fontSize: "14px",
                          display: "flex", // Set display to flex
                          alignItems: "center", // Vertically center the content
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                        onClick={() => handleDelete()}
                      >
                        Delete
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {openInputPopupView.type == "rename_diagram" &&
                    openInputPopupView.prevText == "" ? (
                      ""
                    ) : (
                      <div
                        className="button-type-1"
                        style={{
                          marginTop: "12px",
                          height: "44px",
                          width: "88px",
                          fontSize: "14px",
                          paddingRight: "32px",
                          display: "flex", // Set display to flex
                          alignItems: "center", // Vertically center the content
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                        onClick={handleClose}
                      >
                        Close
                      </div>
                    )}
                    {!openInputPopupView.readOnly && !showPlayground && (
                      <div
                        className="blue-button"
                        style={{
                          marginTop: "12px",
                          height: "44px",
                          width: "88px",
                          fontSize: "14px",
                          display: "flex", // Set display to flex
                          alignItems: "center", // Vertically center the content
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                        onClick={savePopUp}
                      >
                        Save
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </DialogActions>
          </Dialog>
        </div>
      );
    }
  };

  const rightPanel = (showPlayground) => {
    return (
      <div
        className={
          showPlayground ? "right-panel-open-playground" : "right-panel"
        }
      >
        <RightPanel
          initialFlows={allFlows}
          handleRightPanelAction={rightPanelAction}
          components={components}
          getSelectedComponents={getSelectedComponents}
          ref={rightPanelRef}
          customizingStep={customizingStep}
        />
      </div>
    );
  };

  const updateArrowDesc = (arrowId) => {
    let arrowtext = "";
    components.filter((comp) => {
      if (comp.id == arrowId) {
        arrowtext = comp.arrowDesc;
      }
    });
    setOpenInputPopupView({
      type: "add_arrow_desc",
      selectedArrow: arrowId,
      prevText: arrowtext,
    });
  };

  const updatedPointsInArrow = (shapeProps) => {
    let updated = components.map((comp) => {
      if (comp.id == shapeProps.id) {
        return {
          ...comp,
          points: shapeProps.points,
        };
      }
      return comp;
    });
    setComponents(updated);
    mainCanvas.current.updateComonents(updated, false);
    rightPanelRef.current.updateComonents(updated);
  };

  const updateVisibilityForStep = (selectedShapeIds, visibility) => {
    let updatedHiddenShapes = [];
    const updatedFlows = allFlows.map((flow) => {
      const updatedSteps = flow.steps.map((step) => {
        if (step.id === customizingStep.stepId) {
          let hiddenShapeIds = step.hiddenShapeIds || []; // Initiate with blank array if undefined

          if (visibility) {
            hiddenShapeIds = hiddenShapeIds.filter(
              (shapeId) => !selectedShapeIds.includes(shapeId)
            );
          } else {
            selectedShapeIds.forEach((shapeId) => {
              if (
                !hiddenShapeIds.some(
                  (hiddenshapeId) => hiddenshapeId === shapeId
                )
              ) {
                hiddenShapeIds.push(shapeId);
              }
            });
          }
          updatedHiddenShapes = hiddenShapeIds;
          return {
            ...step,
            hiddenShapeIds: hiddenShapeIds,
          };
        }

        return step;
      });
      return {
        ...flow,
        steps: updatedSteps,
      };
    });
    setAllFlows(updatedFlows);

    setCustomizingStep({
      ...customizingStep,
      hiddenShapeIds: updatedHiddenShapes,
    });
  };

  const hiddenComponentIds = () => {
    if (!showPlayground) {
      return customizingStep && customizingStep.hiddenShapeIds;
    } else {
      return componentsToHide;
    }
  };

  const editingModecanvasComponent = (showPlayground) => {
    return (
      <div className="not-scrollable">
        <div className="top-menu">
          <MenuBar
            topMenuAction={topMenuAction}
            handleLogout={handleLogout}
            handleSave={handleSave}
            showPlayground={showPlayground}
            handleOpenVariables={handleOpenVariables}
            flowTitle={diagramTitle}
            userAccessType={userAccessType}
            canvasScale={scale}
            customizingStep={customizingStep}
          />
        </div>
        <div className="parent-container">
          {/* <div> */}
          {!showPlayground && (
            <div className="left-panel">
              <LeftPanel
                creatNewComponent={creatNewComponent}
                onDragNewComponent={onDragNewComponent}
                handleShare={handleShare}
                showPlaygroundAction={showPlaygroundAction}
                scaleUp={scaleUp}
                scaleDown={scaleDown}
                resetScale={resetScale}
                scale={scale}
                uploadNewImage={uploadNewImage}
              />
            </div>
          )}
          <div className={showPlayground ? "canvas-view-mode" : "canvas"}>
            <MainCanvas
              parentPageId={getDigramIdentifier()}
              allFlows={allFlows}
              components={components}
              componentsUpdated={componentsUpdated}
              onSelect={onSelect}
              onScaleChange={onScaleChange}
              addNewComponent={addNewComponent}
              expandInNewPage={expandInNewPage}
              updateVisibilityForStep={updateVisibilityForStep}
              expandAction={expandAction}
              mapChildComponent={mapChildComponent}
              didSelectComponents={didSelectComponents}
              didClickInfoIcon={didClickInfoIcon}
              ref={mainCanvas}
              showPlayground={showPlayground}
              closePlayground={closePlayground}
              updateArrowDesc={updateArrowDesc}
              updatedPointsInArrow={updatedPointsInArrow}
              customizingStep={customizingStep}
              hiddenComponentIds={hiddenComponentIds()}
            />

            {showPlayground && (
              <div style={{ position: "relative" }}>
                <div
                  style={{
                    position: "absolute",
                    top: "128px",
                    width: "100%",
                  }}
                >
                  <FlowsPlayground
                    ref={flowPlaygroudRef}
                    // title={diagramTitle}
                    allComponents={components}
                    allFlows={allFlows}
                    playgroundActionhandler={playgroundActionhandler}
                  />
                </div>
              </div>
            )}
          </div>

          {/* {rightPanel(showPlayground)} */}
          {!showPlayground && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                zIndex: 20,
              }}
            >
              <Drawer
                variant="permanent"
                anchor="right"
                open={drawerOpen}
                PaperProps={{
                  style: {
                    width: drawerOpen ? 450 : 50, // adjust 'auto' to the width of your right panel if necessary
                    overflow: "hidden",
                    marginTop: 60,
                    background: "transparent",
                    border: "none", // Add this line to remove the border
                    padding: 0, // Add this line to remove all padding
                    transition: "width .2s", // Add this line to animate the width
                  },
                }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div style={{ height: "90%" }}></div>
                      <div onClick={() => setDrawerOpen(!drawerOpen)}>
                        <div
                          style={{
                            marginTop: 5,
                            height: 75,
                            width: 50,
                            backgroundColor: "#4c3fdc",
                            borderRadius: "50% 0 0 50%", // make left edge round
                            display: "flex", // use flexbox
                            justifyContent: "center", // center horizontally
                            alignItems: "center", // center vertically
                          }}
                        >
                          {drawerOpen ? (
                            <img
                              src={chevronRight}
                              style={{
                                width: "50%",
                                height: "50%",
                                filter: "invert(1)",
                              }}
                            />
                          ) : (
                            <img
                              src={chevronLeft}
                              style={{
                                width: "50%",
                                height: "50%",
                                filter: "invert(1)",
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {rightPanel(showPlayground)}
                </div>
              </Drawer>
            </div>
          )}
          {/* </div> */}
        </div>

        {newInputPopup()}
      </div>
    );
  };

  const onScaleChange = (scale) => {
    setScale(scale);
  };

  const scaleUp = () => {
    mainCanvas.current.scaleUp();
  };

  const scaleDown = () => {
    mainCanvas.current.scaleDown();
  };

  const resetScale = () => {
    mainCanvas.current.resetScale();
  };

  const closeDescriptionBox = () => {
    setDescriptionDialogText(null);
  };

  const onConfirmConfirmationPopup = (action) => {
    if (action.type == "deleteDiagram") {
      deleteDiagram(action.diagramId, getUserId());
      action.callback();
    }

    setConsentPopUp(null);
  };

  const onCancelConfirmationPopup = () => {
    setConsentPopUp(null);
  };

  const onCloseComponentSearch = () => {
    setOpenShapeToAttachRef(null);
  };

  const onSelectComponentSearchDiagram = (shapeId, diagram) => {
    components.map((comp) => {
      if (comp.id == shapeId) {
        comp.isInstanceOf = {
          id: diagram.diagramId,
          title: diagram.diagram.title,
        };
      }
    });

    setComponents(components);
    mainCanvas.current.updateComonents(components, false);

    rightPanelRef.current.updateComonents(components);
    setOpenShapeToAttachRef(null);
  };

  const onCloseTriggerCodeSelection = () => {
    setOpentriggerCodeSelection(null);
  };

  const onSelectTriggerCode = (flowId, stepId, triggerCode) => {
    allFlows.map((flow) => {
      if (flow.id == flowId) {
        flow.steps.map((step) => {
          if (step.id == stepId) {
            step.to.triggerCode = triggerCode;
            getTriggerCodeDetails(flowId, stepId, (flows) => {
              let flow = flows.find((f) => f.triggerCode == triggerCode);
              step.childFlow = flow;
              setAllFlows(allFlows);
              rightPanelRef.current.updateFlows(allFlows);
              setOpentriggerCodeSelection(null);
            });
          }
        });
      }
    });
  };

  const handleShare = () => {
    setOpenSharePanel(true);
    getAllAcessesForDiagram(getDigramIdentifier()).then((res) => {
      let diagramAccess = res.diagramAccess.users;
      setDiagramAccessList(diagramAccess);
    });
  };

  const handleOpenVariables = () => {
    setOpenVariablesView(true);
  };

  const didShareAction = (request, handleResponse) => {
    let emails = request.emails;
    let access = request.access;

    updateUserAccess(getUserId(), getDigramIdentifier(), emails, access).then(
      (res) => {
        handleResponse(res);
      }
    );
    // setOpenSharePanel(false)
  };

  const didMakePublicAction = (publicAccess, handleResponse) => {
    makeDiagramPublic(publicAccess, getDigramIdentifier()).then((res) => {
      handleResponse(res);
    });
  };

  const didDeleteUserAccess = (email) => {};

  const didCloseEmailPopupAction = (request) => {
    setOpenSharePanel(false);
  };

  const dismissVariablesView = () => {
    setOpenVariablesView(false);
  };

  const saveVariablesAction = (variables) => {
    setVariables(variables);

    dismissVariablesView();
  };

  const onCloseVariantEditor = () => {
    setOpenFlowVariationsView(null);
  };

  const onSaveVariantEditor = (flowId, variantId, variantVariableList) => {
    allFlows.map((flow) => {
      if (flow.id == flowId) {
        flow.variants.map((v) => {
          if (v.id == variantId) {
            v.variables = variantVariableList;
          }
        });
      }
    });
    setAllFlows(allFlows);
    rightPanelRef.current.updateFlows(allFlows);
    onCloseVariantEditor();
  };

  const onDismissView = () => {
    setShowSelectDiagram(false);
  };

  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
    updateLoginStatus(true);
    let diagramId = getDigramIdentifier();
    if (diagramId) {
      setShowSelectDiagram(false);
      getDiagramData(getDigramIdentifier());
    } else {
      setShowSelectDiagram(true);
    }
    //setUpdate(update + 1)
  };

  const hasAccess = () => {
    return (
      isPublic ||
      (userAccessType != AccessType.NO_ACCESS &&
        userAccessType != AccessType.ACCESS_REQUESTED &&
        userAccessType != AccessType.DELETED)
    );

    // let acceess = (userAccessType != null || userAccessType === AccessType.EDITOR ||  userAccessType === AccessType.VIEW_ONLY || userAccessType === AccessType.OWNER)
    // console.log("accessType ",userAccessType)
    // console.log("Owner ",AccessType.OWNER)
    // console.log("Owner1 ",AccessType)
    // console.log("Has  ",acceess)

    // return acceess
  };

  const hasRequestedAccess = () => {
    return userAccessType == AccessType.ACCESS_REQUESTED;
  };

  const hasRequestedEditAccess = () => {
    return userAccessType == AccessType.EDIT_ACCESS_REQUESTED;
  };

  const accessRevoked = () => {
    return userAccessType == AccessType.DELETED;
  };

  const showApprovalPopup = () => {
    return accessApprovalRequest != null;
  };

  const requestDiagramAccess = () => {
    requestAccess(getUserId(), getDigramIdentifier()).then((res) => {
      if (res.access != null) {
        setUserAccessType(res.access.accessLevel);
      } else {
        if (res.errors && res.errors.length > 0) {
          let error = res.errors[0].message;
          //alert(error);
          MySwal.fire({
            title: "Error",
            text: error,
            icon: "warning",
            confirmButtonText: "OK",
          });

          console.log("Request Aceess failed");
        }
      }
    });
  };

  const requestEditAccess = () => {
    requestAccess(getUserId(), getDigramIdentifier()).then((res) => {
      setUserAccessType(AccessType.EDIT_ACCESS_REQUESTED);
    });
  };

  const removeRequestIdParam = () => {
    setAccessApprovalRequest(null);
    const { pathname, search } = window.location;
    const urlSearchParams = new URLSearchParams(search);
    urlSearchParams.delete("requestId");

    const newSearch = urlSearchParams.toString();
    const newUrl = `${pathname}${newSearch ? `?${newSearch}` : ""}`;

    window.history.replaceState(null, null, newUrl);
    window.location.reload();
  };

  const onImageUploadSuccess = (image) => {
    setShowImageUploadWindow(false);
  };

  const onImageUploadCancel = () => {
    setShowImageUploadWindow(false);
  };

  const iconSelectionDialogue = () => {
    // const insertableImages = [
    //   {
    //     title: "user",
    //     url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/user.svg",
    //   },
    //   {
    //     title: "mongo",
    //     url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/mongo.svg",
    //   },
    //   {
    //     title: "postgres",
    //     url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/postgres.svg",
    //   },
    //   {
    //     title: "python",
    //     url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/python.svg",
    //   },
    //   {
    //     title: "sql",
    //     url: "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/sql.svg",
    //   },
    // ];
    let images = insertableImages();
    return (
      <div>
        <Dialog
          open={showImageUploadWindow}
          TransitionComponent={Transition}
          // keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          maxWidth={false}
        >
          <DialogTitle style={{ fontWeight: "bold", fontSize: "20px" }}>
            {"Please choose icon"}
          </DialogTitle>
          <DialogContent style={{ width: "100%", maxWidth: "100%" }}>
            {Object.entries(images).map(([category, images]) => (
              <div key={category}>
                <div className="choose-icon-title">{category}</div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "flex-start",
                  }}
                >
                  {images.map((image, index) => (
                    <div
                      key={index}
                      style={{
                        width: "5%", // Set to 12.5% for 8 icons in each row
                        boxSizing: "border-box",
                        padding: "10px",
                        textAlign: "center",
                      }}
                    >
                      <img
                        style={{ width: "50px", height: "50px" }}
                        src={image.url}
                        alt={image.title}
                        onClick={() => {
                          addNewComponent(
                            "image",
                            { imageUrl: image.url, text: image.title },
                            false
                          );
                          setShowImageUploadWindow(false);
                        }}
                      />
                      <div>{image.title}</div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
            {/* <TextField
              margin="dense"
              id="popup-text-field"
              label="Custom Icon URL"
              type="Image Url"
              fullWidth
              value={newImageURL}
              onChange={(e) => setNewImageURL(e.target.value)}
              style={{ margin: "20px", paddingRight: "40px" }}
              variant="standard"
            /> */}
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => {
                console.log(newImageURL);
                addNewComponent("image", { imageUrl: newImageURL }, false);
                setShowImageUploadWindow(false);
              }}
            >
              Select
            </Button>
            <Button
              onClick={() => {
                setShowImageUploadWindow(false);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const onChooseLink = (action) => {
    let finalUrl = action.url;

    window.open(finalUrl, "_blank");
    setShowUsefulLinks(false);
  };

  const animationInProgress = () => {
    let animating = false;
    components.forEach((comp) => {
      if (comp.type === "arrow") {
        if (comp.animateForward || comp.animateBackward) {
          animating = true;
          return true;
        }
      } else if (comp.animate) {
        animating = true;
        return true;
      }
    });
    return animating;
  };

  return (
    <div onKeyDown={handleKeyDown}>
      {/* {isLoggedIn && !showSelectDiagram && hasAccess() && !showPlayground ? editingModecanvasComponent() : viewModecanvasComponent()} */}
      {isLoggedIn &&
        // !showSelectDiagram &&
        getDigramIdentifier() &&
        hasAccess() &&
        editingModecanvasComponent(showPlayground)}
      {isLoggedIn && !hasAccess() && !hasRequestedAccess() && (
        <AlertPopup
          message={
            accessRevoked()
              ? "Your access has been removed. Please request again."
              : "You do not have sufficient permission to view this diagram."
          }
          buttonTitle1="Request Access"
          buttonTitle2="Create New Diagram"
          button1Action={() => {
            console.log("Request Access");
            requestDiagramAccess();
          }}
          button2Action={() => {
            topMenuAction({ type: "new" });
            console.log("Create New");
          }}
        ></AlertPopup>
      )}
      {alertDetail && alertDetail.alertType == "request_edit_access" && (
        <AlertPopup
          message={alertDetail.message}
          buttonTitle1={"Request Edit access"}
          buttonTitle2={"Cancel"}
          button1Action={() => {
            requestEditAccess();
            setAlertDetail(null);
          }}
          button2Action={() => {
            setAlertDetail(null);
          }}
        ></AlertPopup>
      )}
      {isLoggedIn && !hasAccess() && hasRequestedAccess() && (
        <AlertPopup
          message="Request Sent."
          buttonTitle2="Create New Diagram"
          button2Action={() => {
            topMenuAction({ type: "new" });
            console.log("Create New");
          }}
        ></AlertPopup>
      )}
      {isLoggedIn && hasRequestedEditAccess() && (
        <AlertPopup
          message="Reqeust for edit access sent. You can start editing once the request has been approved."
          buttonTitle1="Sure, Thanks"
          button1Action={() => {
            setUserAccessType(AccessType.VIEW_ONLY);
            console.log("Create New");
          }}
        ></AlertPopup>
      )}
      {isLoggedIn && hasAccess() && showApprovalPopup() && (
        <AccessRequestApprovalPopUp
          requestObj={accessApprovalRequest}
          approveAction={(email, access) => {
            console.log("Approve");
            updateUserAccess(
              getUserId(),
              getDigramIdentifier(),
              [email],
              access
            ).then((res) => {
              removeRequestIdParam();
            });
          }}
          rejectAction={(email) => {
            removeRequestIdParam();
            console.log("Reject");
          }}
        ></AccessRequestApprovalPopUp>
      )}
      {!isLoggedIn && !showSelectDiagram && (
        <div>
          {" "}
          <Login
            onLogin={handleLogin}
            onLoginSuccess={handleLoginSuccess}
          />{" "}
        </div>
      )}
      {showSelectDiagram && (
        <div>
          {" "}
          <ChooseDiagram
            userId={getUserId()}
            chooseFromExisting={showExitingDiagrams}
            onChooseDiagram={onChooseDiagram}
            delistItem={onDelistItem}
            onDismissView={onDismissView}
            currentlyDiagramId={getDigramIdentifier()}
          />{" "}
        </div>
      )}
      {consentPopUp && (
        <ConfirmationPopupView
          consentPopUp={consentPopUp}
          onConfirmConfirmationPopup={onConfirmConfirmationPopup}
          onCancelConfirmationPopup={onCancelConfirmationPopup}
        />
      )}
      {openShapeToAttachRef != null && (
        <SearchChildComp
          onClose={onCloseComponentSearch}
          onSelectDiagram={onSelectComponentSearchDiagram}
          shapeId={openShapeToAttachRef}
          userId={getUserId()}
        />
      )}
      {opentriggerCodeSelection != null && (
        <SearchTriggerCode
          onCloseTriggerCodeSelection={onCloseTriggerCodeSelection}
          onSelectTriggerCode={onSelectTriggerCode}
          stepId={opentriggerCodeSelection.stepId}
          flowId={opentriggerCodeSelection.flowId}
          childFlows={opentriggerCodeSelection.flows}
        />
      )}
      {openSharePanel && (
        <EmailInputPopup
          title={diagramTitle}
          url={window.location.href}
          diagramId={getDigramIdentifier()}
          didMakePublicAction={didMakePublicAction}
          didShareAction={didShareAction}
          didCloseEmailPopupAction={didCloseEmailPopupAction}
          diagramAccessList={diagramAccessList}
          didDeleteUserAccess={didDeleteUserAccess}
        />
      )}
      {openVariablesView && (
        <VariablesViewModel
          initialVariables={variables}
          saveVariablesAction={saveVariablesAction}
          dismissVariablesView={dismissVariablesView}
        />
      )}
      {openFlowVariationsView && (
        <VariantEditor
          flowId={openFlowVariationsView.flowId}
          selectedVariant={openFlowVariationsView.selectedVariant}
          variables={variables}
          onCloseVariantEditor={onCloseVariantEditor}
          onSaveVariantEditor={onSaveVariantEditor}
        ></VariantEditor>
      )}
      {showCompInfo && (
        <ComponentInfoPopup
          component={visibleCompInfo}
          onCloseVisibleCompInfo={() => {
            setShowCompInfo(false);
          }}
        ></ComponentInfoPopup>
      )}
      {showUsefulLinks && (
        <UsefulLinks
          onChooseLink={onChooseLink}
          onDismissView={() => setShowUsefulLinks(false)}
        >
          {" "}
        </UsefulLinks>
      )}
      {showImageUploadWindow && iconSelectionDialogue()}
      <AutohideSnackbar ref={autohideSnackbarRef} />
      {animationInProgress() && (
        <div
          className="button-type-1"
          onClick={() => stopFlow()}
          style={{
            left: 20,
            top: "80px",
            height: "32px",
            width: "150px",
            display: "flex", // Set display to flex
            alignItems: "center", // Vertically center the content
            justifyContent: "center",
            textAlign: "center",
            position: "absolute",
            zIndex: 100,
            border: "1px solid blue", // Set the border color to blue
          }}
        >
          Stop Animation
        </div>
      )}
    </div>
  );
};

export default App;
