import React, { useState } from "react";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

export const ComponentsPopUp = ({component, componentsPopUpAction}) => {
    const [label, setLabel] = useState(null)
    const [desc, setDesc] = useState(null)

    const handleClose = () => {
        componentsPopUpAction({type:"close"});
    }

    const handleSave = () => {
        
        if (label.length > 0){
            let compData =  {"label":label, "desc": desc}
            componentsPopUpAction({type:"save","data":compData});
        }
    }
    
    return (<div>
        <Modal show={true} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="newComp.title">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Title for component"
                autoFocus
                onChange={e => this.setLabel(e.target.value)}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="newComp.descrition"
            >
              <Form.Label>Enter description here</Form.Label>
              <Form.Control as="textarea" rows={3} onChange={e => this.setDesc(e.target.value)}/>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      </div>)

}