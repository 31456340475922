import uuid from "react-uuid";

export const newComponentTemplate = (
  type,
  id,
  groupId,
  key,
  zIndex,
  data,
  parentPageId
) => {
  let rand = Math.random() * 200 + 50;

  if (type == "circle") {
    return {
      type: "circle",
      x: 300 + rand,
      y: 300 + rand,
      radius: 50,
      width: 100,
      height: 100,
      fill: "white",
      stroke: "black",
      strokeWidth: 0.5,
      enableConnectors: false,
      connectedArrows: [],
      isEditingText: false,
      id: id,
      text: "",
      zIndex: 0,
      groupId: groupId,
      fontSize: 16,
      uniqueId: uuid(),
      childPageId: null,
      parentPageId: parentPageId,
    };
  }
  if (type == "rect") {
    return {
      type: "rect",
      x: data && data.x ? data.x + rand : 50 + rand,
      y: data && data.y ? data.y + rand : 50 + rand,
      width: 100,
      height: 50,
      stroke: "gray",
      strokeWidth: 0.5,
      zIndex: 0,
      isEditingText: false,
      initialHeight: 100,

      enableConnectors: false,
      connectedArrows: [],
      // fontSize: 11,
      id: id,
      text: data && data.label ? data.label : "",
      groupId: groupId,
      fill: "white",
      uniqueId: uuid(),
      isEditingText: false,
      childPageId: null,
      parentPageId: parentPageId,
      viewLevel: 0,
      style: {
        fontFamily: "Arial",
        fontSize: 11,
        fontWeight: "normal",
        borderWidth: 0.5,
        fill: "white",
        fontColor: "black",
        borderColor: "black",
        multipleInstance: false,
        textAlignment: "center",
        textHorizontalAlignment: "center",
        whiteSpace: "normal",
        wordWrap: "break-word",
      },
      // shadowOffsetX:1,
      // shadowOffsetY:1,
      // shadowBlur:10,
      // shadowColor:"#999"
    };
  }

  if (type == "image") {
    return {
      type: "image",
      x: data && data.x ? data.x + rand : 50 + rand,
      y: data && data.y ? data.y + rand : 50 + rand,
      width: 100,
      height: 100,
      stroke: "gray",
      strokeWidth: 0.5,
      zIndex: 0,
      initialHeight: 100,
      enableConnectors: false,
      connectedArrows: [],
      id: id,
      groupId: groupId,
      fill: "white",
      uniqueId: uuid(),
      isEditingText: false,
      style: { textHeight: 32 },
      childPageId: null,
      parentPageId: parentPageId,
      viewLevel: 0,
      text: data.text,
      imageUrl:
        data && data.imageUrl
          ? data.imageUrl
          : "https://diagram-flow-images.s3.ap-south-1.amazonaws.com/fail.svg",
    };
  }

  if (type == "text") {
    debugger;
    return {
      type: "rect",
      x: data && data.x ? data.x + rand : 50 + rand,
      y: data && data.y ? data.y + rand : 50 + rand,
      width: 64,
      height: 32,
      stroke: "gray",
      strokeWidth: 0.5,
      zIndex: 0,
      isEditingText: false,
      initialHeight: 100,
      enableConnectors: false,
      connectedArrows: [],
      fontSize: 32,
      id: id,
      text: data && data.label ? data.label : "",
      groupId: groupId,
      fill: "white",
      uniqueId: uuid(),
      isEditingText: false,
      childPageId: null,
      parentPageId: parentPageId,
      viewLevel: 0,
      style: {
        fontFamily: "Arial",
        fontSize: 12,
        fontWeight: "normal",
        borderWidth: 0,
        fill: "white",
        fontColor: "black",
        borderColor: "white",
      },
      // shadowOffsetX:1,
      // shadowOffsetY:1,
      // shadowBlur:10,
      // shadowColor:"#999"
    };
  }

  if (type == "selection_rect") {
    return {
      type: "rect",
      x: data.x,
      y: data.y,
      width: data.width,
      height: data.height,
      stroke: "blue",
      strokeWidth: 0.5,
      // zIndex: 0,
      isEditingText: false,
      enableConnectors: false,
      connectedArrows: [],
      fontSize: 16,
      id: id,
      text: "",
      groupId: groupId,
      fill: "#FFFF0080",
      uniqueId: uuid(),
      isEditingText: false,
      childPageId: null,
      selfArrows: [],
      parentPageId: parentPageId,
      style: {
        fill: "#4c3fdc40",
      },
    };
  }

  if (type == "self_arrow") {
    if (data == null || data.points == null || data.componentId == null) {
      console.log("Invalid request");
    } else {
      return {
        type: "self_arrow",
        points: data && data.points ? data.points : [200, 200, 400, 400],
        componentId: data.componentId,
        id: id,
        text: "",
        zIndex: 0,
        groupId: groupId,
        animateForward: false,
        animateBackward: false,
        uniqueId: uuid(),
        childPageId: null,
        parentPageId: parentPageId,
        viewLevel: 0,
      };
    }
  }

  if (type == "arrow") {
    return {
      type: "arrow",
      points: data && data.points ? data.points : [200, 200, 400, 400],
      enableConnectors: false,
      head: {
        id: data && data.head && data.head.id ? data.head.id : null,
        type: data && data.head && data.head.type ? data.head.type : null,
        dotId: data && data.head && data.head.dotId ? data.head.dotId : null,
      },
      tail: {
        id: data && data.tail && data.tail.id ? data.tail.id : null,
        type: data && data.tail && data.tail.type ? data.tail.type : null,
        dotId: data && data.tail && data.tail.dotId ? data.tail.dotId : null,
      },
      id: id,
      text: "",
      zIndex: 0,
      groupId: groupId,
      animateForward: false,
      animateBackward: false,
      uniqueId: uuid(),
      childPageId: null,
      parentPageId: parentPageId,
      viewLevel: 0,
    };
  }

  if (type == "editableText") {
    return {
      type: "editableText",
      x: Math.random() % 50,
      y: Math.random() % 50,
      width: 300,
      height: 50,
      enableConnectors: false,
      id: id,
      zIndex: 0,
      isEditing: false,
      text: "Input your text",
      groupId: groupId,
      uniqueId: uuid(),
      childPageId: null,
      parentPageId: parentPageId,
    };
  }
};
