import React from "react";
import ReactDOM from "react-dom/client";
import "./CSS/index.css";
import Home from "./Home";
import TOS from "./TOS";
import PrivacyPolicy from "./PrivacyPolicy";
import AboutUsPage from "./Views/Aboutus";
import ContactUsPage from "./Views/ContactUsPage";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Switch,
} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <Routes>
      <Route path="terms-of-use" element={<TOS />} />
      <Route path="privacy-policy" element={<PrivacyPolicy />} />
      <Route path="about-us" element={<AboutUsPage />} />
      <Route path="contact-us" element={<ContactUsPage />} />

      <Route path="/" element={<Home />} />
      {/* <Route path="/"> element={<Home />}</Route> */}
      {/* <Route path="/terms-of-use">
        <TOS />
      </Route> */}
    </Routes>
    {/* </React.StrictMode> */}
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
