import React, { useState } from "react";
import { AccessType } from "../Models/Constants";
import { Dropdown } from "react-bootstrap";

const AccessRequestApprovalPopUp = ({
  requestObj,
  approveAction,
  rejectAction,
}) => {
  const [access, setAccess] = useState(AccessType.VIEW_ONLY);

  const popupMessage = () => {
    return detailsLoaded()
      ? "Approve access for " + requestObj.accessRequest.access.email
      : "Please wait getting details";
  };

  const detailsLoaded = () => {
    return (
      requestObj &&
      requestObj.accessRequest &&
      requestObj.accessRequest.access.email
    );
  };

  const getEmail = () => {
    return requestObj.accessRequest.access.email;
  };

  return (
    <div className="confirmation-popup-container">
      <div className="confirmation-popup">
        <p>{popupMessage()}</p>
        <div className="confirmation-popup-buttons">
          {detailsLoaded() && (
            <div>
              <Dropdown>
                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                  {access} access
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onSelect={() => setAccess(AccessType.VIEW_ONLY)}
                  >
                    View Only
                  </Dropdown.Item>

                  <Dropdown.Item onSelect={() => setAccess(AccessType.EDITOR)}>
                    Editor
                  </Dropdown.Item>
                  <Dropdown.Item onSelect={() => setAccess(AccessType.OWNER)}>
                    Owner
                  </Dropdown.Item>
                  <Dropdown.Item onSelect={() => setAccess(AccessType.DELETED)}>
                    Revoke
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <button
                className="confirmation-popup-confirm"
                onClick={() => approveAction(getEmail(), access)}
              >
                {"Approve"}
              </button>

              <button
                className="confirmation-popup-cancel"
                onClick={() => rejectAction(getEmail())}
              >
                {"Reject"}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccessRequestApprovalPopUp;
