import React, { useState, useEffect } from "react";
import { Button, Modal, Dropdown } from "react-bootstrap";
import EmailInput from "./EmailInput";
import "./../CSS/App.css";

const VariablesViewModel = ({
  initialVariables,
  saveVariablesAction,
  dismissVariablesView,
}) => {
  const [variables, setVariables] = useState(initialVariables);

  const handleAddVariable = () => {
    setVariables([...variables, { name: "", type: "String" }]);
  };

  useEffect(() => {
    console.log(initialVariables);
  });

  const handleDropdownSelect = (index, eventKey) => {
    const newVariables = [...variables];
    newVariables[index].type = eventKey;
    setVariables(newVariables);
  };

  const handleVariableNameChange = (index, name) => {
    const newVariables = [...variables];
    newVariables[index].name = name;
    setVariables(newVariables);
  };

  const handleVariableTypeChange = (index, type) => {
    const newVariables = [...variables];
    newVariables[index].type = type;
    setVariables(newVariables);
  };

  const handleRemoveVariable = (index) => {
    const newVariables = [...variables];
    newVariables.splice(index, 1);
    setVariables(newVariables);
  };

  return (
    <Modal show={true} onHide={dismissVariablesView}>
      <Modal.Header closeButton>
        <Modal.Title>Variables</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {variables &&
          variables.map((variable, index) => (
            <div key={index} className="d-flex align-items-center mb-2">
              <input
                type="text"
                value={variable.name}
                className="form-control me-2"
                placeholder="Name"
                onChange={(e) =>
                  handleVariableNameChange(index, e.target.value)
                }
              />

              <Dropdown
                onSelect={(eventKey) => handleDropdownSelect(index, eventKey)}
              >
                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                  {variable.type}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item eventKey="String">String</Dropdown.Item>
                  <Dropdown.Item eventKey="Integer">Integer</Dropdown.Item>
                  <Dropdown.Item eventKey="Boolean">Boolean</Dropdown.Item>
                  <Dropdown.Item eventKey="Other">Other</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <button
                type="button"
                className="btn btn-danger ms-auto"
                onClick={() => handleRemoveVariable(index)}
              >
                Remove
              </button>
            </div>
          ))}
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleAddVariable}
        >
          Add Variable
        </button>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={dismissVariablesView}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            saveVariablesAction(variables);
          }}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default VariablesViewModel;
