import React, { useState } from 'react';


function SelectVariables({ items, onSelection }) {
  const [selectedItems, setSelectedItems] = useState([]);

  function handleItemClick(item) {
    setSelectedItems([...selectedItems, item]);
  }

  function handleSelection() {
    onSelection(selectedItems);
  }

  return (
    <div className="dropdown-search">
      <div className="variable-select-dropdown">
  <input type="text" placeholder="Search..." />
  <ul>
    {items.map((item) => (
      <li
        key={item.id}
        onClick={() => handleItemClick(item)}
        className="variable-select-dropdown-li"
      >
        {item.name}
      </li>
    ))}
  </ul>
</div>
      <div className="selected-items">
        {selectedItems.map((item) => (
          <div key={item.id} className="selected-item">
            {item.name}
          </div>
        ))}
      </div>
      <button className="submit-button" onClick={handleSelection}>
        Submit
      </button>
    </div>
  );
}

export default SelectVariables;
