import Rectangle from "../Shapes/Rectangle";
import EArrow from "../Shapes/EArrow";
import ECircle from "../Shapes/ECircle";
import { EditableText } from "../Shapes/TextComponents/EditableText";
import ImageComp from "../Shapes/ImageComp";
import Queue from "../Shapes/Queue";

export const getUIComponent = ({
  model,
  scale,
  zIndex,
  isSelected,
  onSelect,
  onDragStartShape,
  onDragMoveShape,
  onDragEndShape,
  onDoubleClick,
  onResize,
  onToggleEdit,
  onTextChange,
  onClickArrowDraw,
  connectableDot,
  dragHoveringShapes,
  pointerPosition,
  showPlayground,
  enableStageEditing,
  onInfoClick,
  disabled,
  updateArrowDesc,
  updateArrowPoints,
  isCustomizingStep,
  shouldHide,
  showConnectors,
  updateConnectableDot,
  getComponentDetails,
  creatingNewArrow,
  enableConnectorForShape,
}) => {
  if (model.type == "circle") {
    return (
      <ECircle
        key={model.uniqueId}
        scale={scale}
        enableConnectors={model.enableConnectors}
        shapeProps={model}
        zIndex={zIndex}
        isSelected={isSelected}
        onSelect={onSelect}
        onDragStartShape={onDragStartShape}
        onDragMoveShape={onDragMoveShape}
        onDragEndShape={onDragEndShape}
        onDoubleClick={onDoubleClick}
        onResize={onResize}
        onTextChange={onTextChange}
        onToggleEdit={onToggleEdit}
        connectableDot={connectableDot}
        pointerPosition={pointerPosition}
        showPlayground={showPlayground}
        enableStageEditing={enableStageEditing}
        isCustomizingStep={isCustomizingStep}
        shouldHide={shouldHide}
        updateConnectableDot={updateConnectableDot}
        getComponentDetails={getComponentDetails}
        creatingNewArrow={creatingNewArrow}
        enableConnectorForShape={enableConnectorForShape}
      />
    );
  }
  if (model.type == "rect") {
    return (
      <Rectangle
        key={model.uniqueId}
        scale={scale}
        enableConnectors={model.enableConnectors}
        shapeProps={model}
        zIndex={zIndex}
        isSelected={isSelected}
        onSelect={onSelect}
        onDragStartShape={onDragStartShape}
        onDragMoveShape={onDragMoveShape}
        onDragEndShape={onDragEndShape}
        onDoubleClick={onDoubleClick}
        onResize={onResize}
        onTextChange={onTextChange}
        onToggleEdit={onToggleEdit}
        onClickArrowDraw={onClickArrowDraw}
        connectableDot={connectableDot}
        dragHoveringShapes={dragHoveringShapes}
        pointerPosition={pointerPosition}
        showPlayground={showPlayground}
        enableStageEditing={enableStageEditing}
        onInfoClick={onInfoClick}
        animate={model.animate}
        disabled={disabled}
        isCustomizingStep={isCustomizingStep}
        shouldHide={shouldHide}
        showConnectors={showConnectors}
        updateConnectableDot={updateConnectableDot}
        getComponentDetails={getComponentDetails}
        creatingNewArrow={creatingNewArrow}
        enableConnectorForShape={enableConnectorForShape}
      />
    );
  }

  if (model.type == "queue") {
    return (
      <Queue
        key={model.uniqueId}
        scale={scale}
        enableConnectors={model.enableConnectors}
        shapeProps={model}
        zIndex={zIndex}
        isSelected={isSelected}
        onSelect={onSelect}
        onDragStartShape={onDragStartShape}
        onDragMoveShape={onDragMoveShape}
        onDragEndShape={onDragEndShape}
        onDoubleClick={onDoubleClick}
        onResize={onResize}
        onTextChange={onTextChange}
        onToggleEdit={onToggleEdit}
        onClickArrowDraw={onClickArrowDraw}
        connectableDot={connectableDot}
        dragHoveringShapes={dragHoveringShapes}
        pointerPosition={pointerPosition}
        showPlayground={showPlayground}
        enableStageEditing={enableStageEditing}
        onInfoClick={onInfoClick}
        animate={model.animate}
        disabled={disabled}
        isCustomizingStep={isCustomizingStep}
        shouldHide={shouldHide}
        showConnectors={showConnectors}
        updateConnectableDot={updateConnectableDot}
        getComponentDetails={getComponentDetails}
        creatingNewArrow={creatingNewArrow}
        enableConnectorForShape={enableConnectorForShape}
      />
    );
  }

  if (model.type == "image") {
    return (
      <ImageComp
        key={model.uniqueId}
        scale={scale}
        imageUrl={
          "https://png.pngtree.com/png-clipart/20201106/ourmid/pngtree-orange-small-colored-pencils-clipart-png-image_2388819.jpg"
        }
        enableConnectors={model.enableConnectors}
        shapeProps={model}
        zIndex={zIndex}
        isSelected={isSelected}
        onSelect={onSelect}
        onDragStartShape={onDragStartShape}
        onDragMoveShape={onDragMoveShape}
        onDragEndShape={onDragEndShape}
        onDoubleClick={onDoubleClick}
        onResize={onResize}
        onTextChange={onTextChange}
        onToggleEdit={onToggleEdit}
        onClickArrowDraw={onClickArrowDraw}
        connectableDot={connectableDot}
        dragHoveringShapes={dragHoveringShapes}
        pointerPosition={pointerPosition}
        showPlayground={showPlayground}
        enableStageEditing={enableStageEditing}
        onInfoClick={onInfoClick}
        animate={model.animate}
        disabled={disabled}
        isCustomizingStep={isCustomizingStep}
        shouldHide={shouldHide}
        showConnectors={showConnectors}
        updateConnectableDot={updateConnectableDot}
        getComponentDetails={getComponentDetails}
        creatingNewArrow={creatingNewArrow}
        enableConnectorForShape={enableConnectorForShape}
      />
    );
  }
  if (model.type == "arrow") {
    return (
      <EArrow
        key={model.uniqueId}
        scale={scale}
        enableConnectors={model.enableConnectors}
        shapeProps={model}
        x={0}
        y={0}
        disabled={disabled}
        animateHeadToTails={model.animateForward}
        animateTailToHead={model.animateBackward}
        onSelect={() => {
          // setSelectedShapeIds(selectedShapeIds.concat(model.id));
          //setSelectedShapeIds([model.id]);
        }}
        updateArrowDesc={updateArrowDesc}
        updateArrowPoints={updateArrowPoints}
        isCustomizingStep={isCustomizingStep}
        shouldHide={shouldHide}
        showConnectors={showConnectors}
        onChange={(newAttrs) => {
          //   setComponents(
          //     components.map((obj, index) => {
          //       return selectedShapeIds.includes(obj.id) ? newAttrs : obj;
          //     })
          //   );
        }}
        getComponentDetails={getComponentDetails}
        creatingNewArrow={creatingNewArrow}
      />
    );
  }
  if (model.type == "editableText") {
    return (
      <EditableText
        getComponentDetails={getComponentDetails}
        key={model.uniqueId}
        scale={scale}
        enableConnectors={model.enableConnectors}
        shapeProps={model}
        x={0}
        y={0}
        //isSelected={selectedShapeIds.includes(model.id)}
        text={model.text}
        //isEditing={selectedShapeIds.includes(model.id) && model.isEditing}
        onTextChange={(value) => {
          model.text = value;
          // setUpdate(update + 1);
        }}
        // onSelect={() => {
        //   if (shiftHeld) {
        //     if (selectedShapeIds.includes(model.id)) {
        //       // setSelectedShapeIds(
        //       //   selectedShapeIds.filter((item) => item !== model.id)
        //       // );
        //     } else {
        //       setSelectedShapeIds(selectedShapeIds.concat(model.id));
        //     }
        //   } else {
        //     setSelectedShapeIds([model.id]);
        //   }
        // }}
        // onDragStartShape={() => {}}
        // onDragMoveShape={(newAttrs) => {
        //   handleDragMoveElements(newAttrs);
        //   updateArrowsToCenter(model.id);
        //   setComponents(
        //     components.map((obj, index) => {
        //       return obj.id == model.id ? newAttrs : obj;
        //     })
        //   );
        // }}
        // onToggleEdit={() => {
        //   // const toggleEdit = () => {
        //   model.isEditing = !model.isEditing;
        //   console.log(model.isEditing);
        //   setUpdate(update + 1);
        //   // setIsEditing(!isEditing);
        //   //onTextClick(!isEditing);
        //   // };

        //   // const toggleTransforming = () => {
        //   //   setIsTransforming(!isTransforming);
        //   //   //onTextClick(!isTransforming);
        //   // };
        // }}
        // onDragEndShape={(newAttrs) => {
        //   console.log("Drag End");
        //   updateArrowsToCenter(model.id);
        //   setComponents(
        //     components.map((obj, index) => {
        //       return obj.id == model.id ? newAttrs : obj;
        //     })
        //   );
        // }}
        // onResize={(newAttrs) => {
        //   console.log("onResize ", newAttrs);
        //   updateArrowsToCenter(model.id);
        //   setComponents(
        //     components.map((obj, index) => {
        //       return obj.id == model.id ? newAttrs : obj;
        //     })
        //   );
        // }}
      />
    );
  }
};
