import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import downArrow from "../res/down-arrow.png";
import { Box } from "@mui/material";

const CustomTextField = ({
  value,
  placeHolder,
  action,
  style,
  child,
  disabled,
}) => {
  const [selectedValue, setSelectedValue] = useState(""); // Initialize to an empty string

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "25ch" },
      }}
      noValidate
      autoComplete="off"
    >
      {/* <div
        onClick={(event) => {
          event.preventDefault();
          console.log("Action");
          if (action) {
            action();
          }
        }}
      > */}
      <TextField
        style={style}
        variant="outlined"
        size="small"
        margin="dense"
        disabled={disabled}
        select
        aria-describedby="outlined-weight-helper-text"
        inputProps={{
          "aria-label": "weight",
        }}
        label={placeHolder || "Input"} // Use provided text or a default label
        type="title"
        fullWidth
        value={value}
        autoComplete="off"
        // InputProps={{
        //   endAdornment: (
        //     <InputAdornment position="end">
        //       <img
        //         style={{ height: "16px", width: "16px" }}
        //         onClick={() => {
        //           if (action) {
        //             action("downArrowClicked"); // You can pass a custom action here
        //           }
        //         }}
        //         src={downArrow}
        //         alt="Down Arrow"
        //       ></img>
        //     </InputAdornment>
        //   ),
        // }}
      >
        {child}
      </TextField>
      {/* </div> */}
    </Box>
  );
};

export default CustomTextField;
