import React from "react";
import { Html } from "react-konva-utils";
import { useRef, useEffect, useState } from "react";
import { Group, Transformer, Rect } from "react-konva";

export const EditableTextInput = ({
  shapeProps,
  scale,
  value,
  onChangeText,
  onKeyDown,
  isSelected,
  onClick,
}) => {
  const textGroupRef = useRef(null);
  const transformerRef = useRef(null);
  const rectGroupRef = useRef(null);
  // const [tvWidth, setTVWidth] = useState(width);
  // const [tvheight, setTVHeight] = useState(height);
  // const [tvX, setTVX] = useState(x);
  // const [tvY, setTVY] = useState(y);

  const getStyle = (width, height) => {
    const isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
    const baseStyle = {
      width: `${width}px`,
      height: `${height}px`,
      border: "none",
      // padding: "0px",
      // margin: "0px",
      background: shapeProps.isArrowDesc && value != "" ? "white" : "none",
      outline: "none",
      //resize: "none",
      colour: "black",
      fontSize: "12px",
      fontFamily: "Tahoma",
      backgroundColor: "transparent",
    };
    if (isFirefox) {
      return baseStyle;
    }
    return {
      ...baseStyle,
    };
  };

  useEffect(() => {
    if (isSelected && transformerRef.current !== null) {
      transformerRef.current.nodes([textGroupRef.current]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  // const handleResize = () => {
  //   if (rectGroupRef.current !== null) {
  //     const rectNode = rectGroupRef.current;

  //     const newWidth = rectNode.width() * textGroupRef.current.scaleX();
  //     const newHeight = rectNode.height() * textGroupRef.current.scaleY();

  //     setTVWidth(newWidth);
  //     setTVHeight(newHeight);
  //     setTVX(textGroupRef.current.getX());
  //     setTVY(textGroupRef.current.getY());
  //     //   textNode.setAttrs({
  //     //     width: newWidth,
  //     //     scaleX: 1,
  //     //   });
  //     //   onResize(newWidth, newHeight);
  //   }
  // };
  const style = getStyle(shapeProps.width, shapeProps.height);
  return (
    <>
      <Group>
        <Group {...shapeProps}>
          <Rect ref={rectGroupRef} {...shapeProps} x={0} y={0} />
          <Html
            groupProps={{ shapeProps }}
            divProps={{ style: { opacity: 1 } }}
          >
            <textarea
              className="textarea"
              value={value}
              display={"block"}
              onChange={(e) => {
                onChangeText(shapeProps, e.target.value);
              }}
              onClick={(e) => {
                console.log("Text Field Clicked");
                e.stopPropagation(); // Stop event propagation
              }}
              onKeyDown={(e) => {
                e.stopPropagation(); // Stop event propagation
                onKeyDown(e);
              }}
              style={{ ...style, resize: "none" }} // Set resize property to none
              color={
                shapeProps.style && shapeProps.style.fontColor
                  ? shapeProps.style.fontColor
                  : "black"
              }
              fontSize={
                shapeProps.style && shapeProps.style.fontSize
                  ? shapeProps.style.fontSize
                  : 12
              }
              fontFamily={
                shapeProps.style && shapeProps.style.fontFamily
                  ? shapeProps.style.fontFamily
                  : "Tahoma"
              }
              fontStyle={
                shapeProps.style && shapeProps.style.fontWeight
                  ? shapeProps.style.fontWeight
                  : "normal"
              }
              stroke={
                shapeProps.style && shapeProps.style.border
                  ? shapeProps.style.border
                  : "black"
              }
              strokeWidth={
                shapeProps.style && shapeProps.style.borderWidth != null
                  ? shapeProps.style.borderWidth
                  : 0.5
              }
              // opacity={disabled ? 0.1 : 1}
              fill={
                shapeProps.style && shapeProps.style.fill
                  ? shapeProps.style.fill
                  : "white"
              }
            />
          </Html>
        </Group>
      </Group>
    </>
  );
};
