import { Transformer, Group, Rect } from "react-konva";
import { getUIComponent } from "./RenderedComponents";
import { useEffect, useRef } from "react";
import React from "react";
import EArrow from "../Shapes/EArrow";
import { isEqual } from "lodash";

const ComponentGenerator = React.memo(
  ({
    model,
    isSelected,
    scale,
    zIndex,
    onSelect,
    onDragStartShape,
    onDragMoveShape,
    onDragEndShape,
    onArrowDragMove,
    onArrowDragEnd,
    onToggleEdit,
    onDoubleClick,
    onTextChange,
    onResize,
    onClickArrowDraw,
    connectableDot,
    dragHoveringShapes,
    arrowDotMoved,
    pointerPosition,
    showPlayground,
    enableStageEditing,
    onInfoClick,
    disabled,
    updateArrowDesc,
    updateArrowPoints,
    isCustomizingStep,
    shouldHide,
    showConnectors,
    updateConnectableDot,
    getComponentDetails,
    creatingNewArrow,
    enableConnectorForShape,
  }) => {
    const shapeRef = useRef();
    const trRef = useRef();

    return (
      <React.Fragment key={model.id + "-fragment"}>
        {model.type != "arrow" && model.type != "self_arrow" ? (
          <Group key={model.id + "-group"}>
            {getUIComponent({
              model: model,
              scale: scale,
              zIndex: zIndex,
              isSelected: isSelected,
              onSelect: onSelect,
              onDragStartShape: onDragStartShape,
              onDragMoveShape: onDragMoveShape,
              onDragEndShape: onDragEndShape,
              onDoubleClick: onDoubleClick,
              onToggleEdit: onToggleEdit,
              onTextChange: onTextChange,
              onResize: onResize,
              onClickArrowDraw: onClickArrowDraw,
              connectableDot: connectableDot,
              dragHoveringShapes: dragHoveringShapes,
              pointerPosition: pointerPosition,
              showPlayground: showPlayground,
              enableStageEditing: enableStageEditing,
              disabled: disabled,
              onInfoClick,
              isCustomizingStep,
              shouldHide,
              showConnectors,
              updateConnectableDot,
              getComponentDetails,
              creatingNewArrow,
              enableConnectorForShape,
            })}
          </Group>
        ) : (
          <EArrow
            key={model.uniqueId}
            scale={scale}
            shapeProps={model}
            isSelected={isSelected}
            onSelect={onSelect}
            onArrowDragMove={onArrowDragMove}
            onArrowDragEnd={onArrowDragEnd}
            onChange={onResize}
            animateHeadToTails={model.animateForward}
            animateTailToHead={model.animateBackward}
            arrowDotMoved={arrowDotMoved}
            pointerPosition={pointerPosition}
            showPlayground={showPlayground}
            enableStageEditing={enableStageEditing}
            disabled={disabled}
            updateArrowDesc={updateArrowDesc}
            updateArrowPoints={updateArrowPoints}
            isCustomizingStep={isCustomizingStep}
            shouldHide={shouldHide}
            getComponentDetails={getComponentDetails}
            creatingNewArrow={creatingNewArrow}
            enableConnectorForShape={enableConnectorForShape}
          />
        )}
      </React.Fragment>
    );
  },
  (prevProps, nextProps) => {
    // if (prevProps.model.type === "arrow") {
    //   console.log("prevProps:", prevProps);
    //   console.log("nextProps:", nextProps);
    // }

    return (
      isEqual(prevProps.model, nextProps.model) &&
      nextProps.model.showConnectors !== nextProps.model.showConnectors &&
      prevProps.model.type !== "arrow" &&
      prevProps.model.enableConnectors !== nextProps.model.enableConnectors &&
      prevProps.model.x !== nextProps.model.x &&
      prevProps.model.isSelected !== nextProps.model.isSelected &&
      prevProps.model.y !== nextProps.model.y &&
      prevProps.model.isSelected !== nextProps.model.isSelected &&
      prevProps.model.style !== nextProps.model.style &&
      prevProps.model.enableConnectorForShape !==
        nextProps.model.enableConnectorForShape
    );
  }
);

export default ComponentGenerator;
