import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export const ComponentInfoPopup = ({ component, onCloseVisibleCompInfo }) => {
  return (
    <div className="confirmation-popup-container">
      <div className="confirmation-popup">
        <p>{component.text}</p>
        <div className="confirmation-popup-buttons">
          <table className="component-info-table">
            <tbody>
              {component.info.map((pair, index) => (
                <tr key={index}>
                  <td className="component-info-key">{pair.key}</td>
                  <td className="component-info-value">{pair.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <button
          className="component-info-close ml-auto"
          onClick={onCloseVisibleCompInfo}
        >
          {"Close"}
        </button>
      </div>
    </div>
  );
};
