import React, { useState, useEffect, useRef } from "react";
import uuid from "react-uuid";
import { Button, Card } from "react-bootstrap";
import { Label } from "react-konva";

const UsefulLinks = ({ onChooseLink, onDismissView }) => {
  const links = [
    {
      text: "Sample Create Diagram Video",
      url: "https://drawfuse.s3.ap-south-1.amazonaws.com/How+to+draw+and+define+flows.mp4",
      type: "video",
    },
    // { text: "Terms of Use", url: "/terms-of-use", type: "link" },
    // { text: "Privacy Policy", url: "/privacy-policy", type: "link" },
  ];

  return (
    <div className="login-popup-container">
      <div>
        <div
          className="login-popup-content"
          style={{ width: "50%", height: "50%" }}
        >
          <div
            style={{
              fontSize: "16px",
              fontWeight: "600",
              paddingBottom: "32px", // Padding top
              marginBottom: "32px", // Padding top
            }}
          >
            {"Available links"}
            <div style={{ marginTop: "32px" }}>
              <ul>
                {links.map((link, index) => (
                  <li key={index}>
                    <div
                      onClick={() => {
                        onChooseLink(link);
                      }}
                      style={{
                        cursor: "pointer",
                        color: "blue",
                        textDecoration: "underline",
                      }}
                    >
                      {link.text}{" "}
                      {/* Assuming each link object has a 'text' property */}
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            <div
              style={{
                fontSize: "10px",
                cursor: "pointer",
                color: "blue",
                textDecoration: "underline",
                position: "absolute",
                bottom: "12px",
                left: "12px",
              }}
              onClick={() =>
                onChooseLink({
                  text: "Terms of Use",
                  url: "/terms-of-use",
                  type: "link",
                })
              }
            >
              Terms of Use
            </div>

            <div
              className="red-button"
              style={{
                position: "absolute",
                bottom: "0",
                right: "0",
                margin: "12px",
                height: "44px",
                // width: "128px",
                fontSize: "14px",
                paddingRight: "32px",
                paddingLeft: "32px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
              onClick={onDismissView}
            >
              Dismiss
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsefulLinks;
