import { Link } from "react-router-dom";
import React, { Component, useRef, useState } from "react";

function PrivacyPolicy() {
  return (
    <React.Fragment>
      <div style={{ margin: "20px" }}>
        <h1>Privacy Policy</h1>

        <p>
          Your privacy is important to us. It is drawfuse's policy to respect
          your privacy regarding any information we may collect from you across
          our website, drawfuse.com, and other sites we own and operate.
        </p>

        <h2>Information We Collect</h2>

        <p>
          We only ask for personal information when we truly need it to provide
          a service to you. We collect it by fair and lawful means, with your
          knowledge and consent. We also let you know why we’re collecting it
          and how it will be used.
        </p>

        <p>We may collect the following types of information:</p>

        <ul>
          <li>Contact Information (e.g., name, email address, phone number)</li>
          <li>Usage Data (e.g., pages visited, time spent on the website)</li>
          <li>
            Device Information (e.g., IP address, browser type, device type)
          </li>
        </ul>

        <h2>How We Use Your Information</h2>

        <p>We use the information we collect in various ways, including to:</p>

        <ul>
          <li>Provide, maintain, and improve our services</li>
          <li>Communicate with you, including for customer service</li>
          <li>Send you updates, newsletters, and marketing materials</li>
          <li>Monitor and analyze usage patterns and trends</li>
        </ul>

        <h2>Security</h2>

        <p>
          We value your trust in providing us your personal information, thus we
          are striving to use commercially acceptable means of protecting it.
          But remember that no method of transmission over the internet, or
          method of electronic storage is 100% secure and reliable, and we
          cannot guarantee its absolute security.
        </p>

        <h2>Changes to This Privacy Policy</h2>

        <p>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page.
        </p>

        <p>
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </p>

        <h2>Contact Us</h2>

        <p>
          If you have any questions or suggestions about our Privacy Policy, do
          not hesitate to contact us at support@drawfuse.com.
        </p>
      </div>
    </React.Fragment>
  );
}

export default PrivacyPolicy;
