import React, { useState } from "react";
import "./MyNavbar.css"; // import the CSS file
import downIcon from "../res/down.svg";
import { getCookie } from "../Helper/CookieManager";
import HistoryIcon from "@mui/icons-material/History";
import DatasetLinkedSharpIcon from "@mui/icons-material/DatasetLinkedSharp";
// import ResourcesIcon from "../res/guide-book.png";
import dflog from "../res/df.webp";
import editIcon from "../res/editIcon.svg";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBNavbarBrand,
  MDBDropdownLink,
} from "mdb-react-ui-kit";

import { TopMenuItems } from "../Models/TopMenuItems";
import { Button } from "react-bootstrap";

export const MenuBar = ({
  topMenuAction,
  showPlayground,
  handleLogout,
  handleSave,
  handleShare,
  handleOpenVariables,
  flowTitle,
  canvasScale,
  customizingStep,
  userAccessType,
}) => {
  const [showBasic, setShowBasic] = useState(false);
  const [update, setUpdate] = useState(0);
  const [scale, setScale] = useState(canvasScale);

  const handleViewFlow = () => {
    topMenuAction({ type: "run_flow_start" });
  };

  const handleResetScale = () => {
    topMenuAction({ type: "reset_scale" });
    setScale(1);
  };

  const getL3Menu = (items) => {
    var comp = [];
    if (items != undefined) {
      items.map((i) => {
        comp.push(
          // <MDBDropdownItem key={i.id} style={{ cursor: "default" }} onClick={() => {topMenuAction(i.action)}}>
          <MDBDropdownItem key={i.id} style={{ cursor: "default" }}>
            <MDBDropdownLink
              onClick={() => {
                topMenuAction(i.action);
              }}
            >
              {i.label}
            </MDBDropdownLink>
          </MDBDropdownItem>
        );
      });
    }
    return comp;
  };

  const recentDiagrams = () => {
    let diagrams = [];
    let cookie = getCookie("recent_diagrams", true);
    if (cookie != null && cookie != "") {
      let recent_cookie = cookie;
      recent_cookie.map((recent) => {
        let recentObj = {
          label: recent.label,
          id: recent.id,
          action: { type: "open-diagram", pageId: recent.id },
        };
        diagrams.push(recentObj);
      });
    }

    let recentObj = {
      label: "More",
      id: "more",
      action: { type: "open-exiting-diagram" },
    };
    diagrams.push(recentObj);

    return diagrams;
  };

  const hasEditAccess = () => {
    return userAccessType == "owner" || userAccessType == "editor";
  };

  const getL3MenuWithListing = (items, parentId) => {
    let l3Menu = [];
    if (parentId == "file-open-recent") {
      l3Menu = recentDiagrams();
    } else {
      l3Menu = items;
    }

    var comp = [];
    if (l3Menu != undefined) {
      comp.push(
        <ul key={parentId} className="dropdown-menu dropdown-submenu">
          {getL3Menu(l3Menu)}
        </ul>
      );
    }
    return comp;
  };

  const getL2Menu = (items) => {
    var comp = [];

    if (items != undefined) {
      items.map((i) => {
        comp.push(
          // <MDBDropdownItem key={i.id} style={{ cursor: "default" }} onClick={() => {topMenuAction(i.action)}}>
          <MDBDropdownItem
            className="custom-dropdown-item"
            key={i.id}
            dataActive={false}
            style={{
              cursor: "default",
            }}
          >
            <MDBDropdownLink
              style={{ cursor: "default", padding: "12px" }}
              onClick={() => {
                topMenuAction(i.action);
              }}
            >
              <div className="horizontal-container">
                {i.label}
                {i.children && <div>{">"}</div>}
              </div>
            </MDBDropdownLink>
            {getL3MenuWithListing(i.children, i.id)}
          </MDBDropdownItem>
        );
      });
    }
    return comp;
  };

  const getL1Menu = (items) => {
    var comp = [];
    items.map((item) => {
      comp.push(
        <MDBNavbarItem key={item.id}>
          <MDBDropdown>
            <MDBDropdownToggle
              tag="a"
              className="nav-link"
              style={{
                cursor: "default",
                margin: "5px",
                background: "#F5F5F5",
                borderRadius: "4px",
                border: "1px solid #DDDDDD",
                padding: "6px 16px 6px 10px", // Top, Right, Bottom, Left
              }}
            >
              {item.label}
            </MDBDropdownToggle>
            <MDBDropdownMenu
              style={{
                margin: "0px",
                padding: "0px",
                background: "white",
              }}
            >
              {getL2Menu(item.children)}
            </MDBDropdownMenu>
          </MDBDropdown>
        </MDBNavbarItem>
      );
    });
    return comp;
  };

  const getIcon = () => {
    return getCookie("displayName", false).charAt(0).toUpperCase(); // Using charAt method
  };

  return (
    // <div style={{ display: "flex", background: "#FDFCFC" }}>
    customizingStep != null ? (
      <div className="top-menu-container">
        <div
          className="top-menu-left-view"
          style={{ fontSize: "16px", fontWeight: "600" }}
        >
          <img
            src={dflog}
            style={{
              marginLeft: "20px",
              width: "50px",
              height: "50px",
              marginRight: "20px",
            }}
          />
          {
            "Please configure state of diagram. You can configure which components should be visible for this step. Select the component and right click to update visibility"
          }
        </div>
        {/* <div className="top-menu-diagram-title-text">
          <div style={{ display: "flex", flexDirection: "row" }}>
            
          </div>
        </div> */}
      </div>
    ) : (
      <div className="top-menu-container">
        <div className="top-menu-left-view">
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="logo-div" style={{ marginRight: "20px" }}>
              <img src={dflog} style={{ width: "50px", height: "50px" }} />
              {/* <h1>FLO</h1> */}
            </div>
            {/* {!showPlayground && ( */}
            {
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  className="button-type-1"
                  style={{
                    // marginTop: "12px",
                    // height: "32px",
                    paddingLeft: "16px",
                    paddingRight: "16px",
                    display: "flex", // Set display to flex
                    alignItems: "center", // Vertically center the content
                    justifyContent: "center",
                    textAlign: "center",
                    border: "1px solid blue", // Set the border color to blue
                    marginRight: "12px",
                  }}
                  onClick={() => {
                    topMenuAction({ type: "new" });
                  }}
                  // onClick={props.viewFlows}
                >
                  <div
                    style={{
                      color: "#4c3fdc",
                      borderRadius: "12px",
                      backgroundColor: "white",
                      marginRight: "8px",
                      width: "16px",
                      height: "20px",
                      fontSize: "20px",
                      display: "flex",
                      alignItems: "center",
                      paddingRight: "8px",

                      justifyContent: "center",
                    }}
                  >
                    <AddCircleIcon />
                  </div>
                  New Diagram
                </div>

                <div
                  className="button-type-1"
                  style={{
                    // marginTop: "12px",
                    // height: "32px",
                    paddingLeft: "16px",
                    paddingRight: "16px",
                    display: "flex", // Set display to flex
                    alignItems: "center", // Vertically center the content
                    justifyContent: "center",
                    textAlign: "center",
                    border: "1px solid blue", // Set the border color to blue
                  }}
                  onClick={() => {
                    topMenuAction({ type: "open-exiting-diagram" });
                  }}
                  // onClick={props.viewFlows}
                >
                  <div
                    style={{
                      color: "#4c3fdc",
                      borderRadius: "12px",
                      backgroundColor: "white",
                      marginRight: "8px",
                      width: "16px",
                      height: "20px",
                      fontSize: "20px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <HistoryIcon />
                  </div>
                  Open Recent
                </div>

                {/* <MDBNavbar
                expand="lg"
                light
                bgColor="#FDFCFC"
                style={{
                  // paddingLeft: "-20px",
                  // paddingBottom: "-30px",
                  width: "100%",
                }}
              >
                <MDBContainer>
                  <MDBNavbarNav>{getL1Menu(TopMenuItems)}</MDBNavbarNav>
                </MDBContainer>
              </MDBNavbar> */}
              </div>
            }
          </div>
        </div>

        <div className="top-menu-diagram-title-text">
          <div style={{ display: "flex", flexDirection: "row" }}>
            <p
              style={{
                textAlign: "center",
                fontFamily: "Roboto",
                paddingRight: "12px",
              }}
              onClick={() => {
                topMenuAction({ type: "rename_diagram", label: flowTitle });
              }}
            >
              {flowTitle}
              {<img src={downIcon} />}
            </p>
            {showPlayground && (
              <button
                className="control-button"
                onClick={() => {
                  console.log("Edit");
                  if (hasEditAccess()) {
                    const currentUrl = new URL(window.location.href);
                    // Remove the 'access_type' query parameter
                    currentUrl.searchParams.delete("access_type");
                    // Open the new URL in a new tab
                    window.open(
                      currentUrl.toString(),
                      "_blank",
                      "noopener,noreferrer"
                    );
                  } else {
                    // alert("You don't have access to edit");

                    topMenuAction({
                      type: "alert",
                      alertDetail: {
                        alertType: "request_edit_access",
                        message:
                          "You don't have access to edit. Click on reqeust access to get edit access.",
                      },
                    });
                  }
                }}
              >
                <img
                  src={editIcon}
                  alt="Edit"
                  style={{ opacity: hasEditAccess() ? 1 : 0.5 }}
                  className="button-image"
                />
              </button>
            )}

            {!showPlayground && (
              <div
                className="blue-button"
                style={{
                  marginLeft: "12px",
                  fontWeight: "600",
                  fontSize: "14px",
                  width: "97px",
                  height: "36px",
                  display: "flex", // Set display to flex
                  alignItems: "center", // Vertically center the content
                  justifyContent: "center",
                  textAlign: "center",
                }}
                onClick={handleSave}
              >
                Save
              </div>
            )}
          </div>
        </div>

        <div className="top-menu-right-view">
          {!showPlayground && (
            <div
              className="button-type-1"
              style={{
                // marginTop: "12px",
                // height: "32px",
                paddingLeft: "16px",
                paddingRight: "16px",
                display: "flex", // Set display to flex
                alignItems: "center", // Vertically center the content
                justifyContent: "center",
                textAlign: "center",
                border: "1px solid blue", // Set the border color to blue
                marginRight: "12px",
              }}
              onClick={() => {
                topMenuAction({ type: "usefulLinks" });
              }}
              // onClick={props.viewFlows}
            >
              <div
                style={{
                  color: "#4c3fdc",
                  borderRadius: "12px",
                  backgroundColor: "white",
                  // marginRight: "8px",
                  width: "16px",
                  height: "16px",
                  fontSize: "20px",
                  display: "flex",
                  alignItems: "center",
                  paddingRight: "8px",

                  justifyContent: "center",
                }}
              >
                <DatasetLinkedSharpIcon />
              </div>
              Links and Resources
            </div>
          )}

          <div className="top-right-icon">
            <div className="top-right-circular-icon">{getIcon()}</div>
          </div>
          <div className="top-right-content">
            {/* <div>{getCookie("displayName", false)}</div> */}
            <div>{getCookie("email", false)}</div>
            <button onClick={handleLogout} className="top-right-link-button">
              Logout
            </button>
          </div>
        </div>
      </div>
    )
  );
};
