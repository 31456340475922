import { Link } from "react-router-dom";
import React, { Component, useRef, useState } from "react";
import App from "./App";

function Home() {
  return (
    <React.Fragment>
      <App />
    </React.Fragment>
  );
}

export default Home;
