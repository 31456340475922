import React from "react";
import "../CSS/App.css";
import uuid from "react-uuid";
import { useRef, useState, useEffect, useCallback, useMeasure } from "react";
import { createNewComponentObject } from "../DataManagers/CurrentDiagramDataHandler";
import { Stage, Layer, Text, Line } from "react-konva";
import { saveDiagram, getDiagram } from "../Helper/DataService";
import { useGesture } from "react-use-gesture";
import { forwardRef, useImperativeHandle } from "react";
import debounce from "lodash/debounce"; // Assuming the debounce library is imported
import html2canvas from "html2canvas";
import { setRenderedComps, getRenderedComps } from "../Helper/GlobalVariables";

import ComponentGenerator from "../UIManagers/ComponentGenerator";
import {
  closestPoint,
  getEdgeRects,
  haveIntersection,
  getSelectedEntities,
  updateComponentsScale,
  getDotLocation,
} from "../Helper/IntesectionLogic";
import { RightPanel } from "../rightPanel/rightPanel";
import Slide from "@mui/material/Slide";
import { ComponentsPopUp } from "../Views/ComponentsPopUp";
import { getDotSize } from "../Helper/Common";
import { render } from "@testing-library/react";
import { set } from "lodash";

export const MainCanvas = forwardRef((props, ref) => {
  let viewRef = useRef();
  let stageRef = useRef();
  let containerRef = useRef();
  let layerRef = useRef();
  let largeContinerRef = useRef();
  let [components, setComponents] = useState([]);
  let [scale, setScale] = useState(1);
  const [selectedShapeIds, setSelectedShapeIds] = React.useState([]);
  const [update, setUpdate] = useState(0);
  let [tempComponents, setTempComponents] = useState([]);
  let [activatedForConnect, setActivatedForConnect] = useState([]);
  let [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
  let [show, setShow] = useState(false); // hide menu
  let [shiftHeld, setShiftHeld] = useState(false);
  let [totalWidth, setTotalWidth] = useState(window.innerWidth);
  let [totalHeight, setTotalHeight] = useState(window.innerHeight);
  let [frontZ, setFrontZ] = useState(0);
  let [backZ, setBackZ] = useState(0);
  let [copiedShapes, setCopiedShapes] = useState([]);
  const headerHeight = 60;
  let [shapePasteMargin, setShapePasteMargin] = useState(10);
  let [selectionRect, setSelectionRect] = useState(null);
  const [updateSelectionRect, setUpdateSelectionRect] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [showComponentPopup, setShowComponentPopup] = useState(false);
  const [opeations, setOperations] = useState([]);
  let [drawingArrowByDrag, setDrawingArrowByDrag] = useState(null);
  let [connectableDot, setConnectableDot] = useState(null);
  const stagePadding = window.innerHeight;
  const stagePaddingX = window.innerWidth;
  const [maxX, setMaxX] = useState(window.innerWidth - 550);
  const [maxY, setMaxY] = useState(window.innerHeight - 155);
  const scrollRef = useRef(null);
  const [enableStageEditing, setEnableStageEditing] = useState(true);
  const [dragHoveringShapes, setDragHoveringShapes] = useState([]);
  const [verticallyAlignedRects, setVerticallyAlignedRects] = useState([]);
  const [horizontallyAlignedRects, setHorizontallyAlignedRects] = useState([]);
  const playgroundHeight = 200;
  const [cachedStageView, setCachedStageView] = useState(null);
  const [arrowConnectorType, setArrowConnectorType] = useState(null);
  const [mouseMoveListernerAdded, setMouseMoveListernerAdded] = useState(false);

  const [speed, setSpeed] = useState(1);
  const drawingArrowByDragRef = useRef(drawingArrowByDrag);
  const tempComponentsRef = useRef(tempComponents);
  const connectableDotRef = useRef(connectableDot);
  const componentsRef = useRef(components);
  const updateHeadOrTail = useRef(null);
  let enableConnectorForShape = useRef(null);

  const getStageImage = () => {
    // const canvas = await html2canvas(stageRef.current.container());
    // const base64Image = canvas.toDataURL("image/png");
    // return base64Image;
  };

  const bottomViewHeight = () => {
    if (props.allFlows && props.allFlows.length > 0)
      return props.showPlayground ? playgroundHeight : 0;
    else return 0;
  };

  useImperativeHandle(ref, () => ({
    createNewComponent(type) {
      if (type == "new_component") {
        setShowComponentPopup(true);
      } else {
        props.addNewComponent(type, null, false);
      }
    },
    getStageImage() {
      getStageImage();
    },
    updateComonents(comps, needRedo) {
      updateComonents(comps, needRedo, false);
    },
    refreshCanvas() {
      refreshCanvas();
    },
    setStageEditing(status) {
      setStageEditing(status);
    },
    getSelectedComponents() {
      return getSelectedComponents();
    },
    getContentOffset() {
      const scrollContainer = stageRef.current.container();
      const offsetLeft = scrollContainer.scrollLeft + 50;
      const offsetTop = scrollContainer.scrollTop + 50;
      return [offsetLeft, offsetTop];
    },
    updateAllArrowsToCenter(comps) {
      updateAllArrowsToCenter(comps);
    },
    setCanvasScale(scale) {
      setCanvasScale(scale);
    },
    scaleUp() {
      scaleUp();
    },
    scaleDown() {
      scaleDown();
    },
    resetScale() {
      resetScale();
    },
  }));

  const setCanvasScale = (scale) => {
    setScale(scale);
  };
  const handleContextMenu = (event) => {
    console.log(components);
    event.preventDefault();
    event.stopPropagation();
    if (selectedShapeIds.length == 0) {
      const hoverTargets = intersections(event.offsetX, event.offsetY);
      hoverTargets.forEach((element) => {
        selectedShapeIds.push(element.id());
      });

      setSelectedShapeIds(selectedShapeIds);
    }
    setAnchorPoint({ x: event.pageX, y: event.pageY });
    setShow(true);
  };

  const refreshCanvas = () => {
    setUpdate(update + 1);
  };

  useEffect(() => {
    props.didSelectComponents(selectedShapeIds);
  }, [selectedShapeIds]);

  const allComponents = () => {
    var allComponentsToRender = [];
    if (components != null && componentsRef.current.length > 0) {
      allComponentsToRender = components;

      if (
        tempComponentsRef.current != null &&
        tempComponentsRef.current.length > 0
      ) {
        allComponentsToRender = [...components, ...tempComponentsRef.current];
      }
    }
    return allComponentsToRender;
  };

  const disableAllEditing = (e) => {
    if (e.detail == 1) {
      const hoverTargets = intersections(
        stageRef.current.getPointerPosition().x,
        stageRef.current.getPointerPosition().y
      );

      var hoverTargetIds = hoverTargets.map((t) => t.id);

      components.map((comp) => {
        if (comp.isEditingText) {
          if (!hoverTargetIds.includes(comp.id)) {
            onToggleEdit(comp);
          }
        }
      });
    }
  };

  const handleResize = useCallback((e) => {
    setTotalWidth(window.innerWidth);
    setTotalHeight(window.innerHeight);
  });

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    // document.addEventListener("click", handleClick);
    document.addEventListener("contextmenu", handleContextMenu);

    return () => {
      // document.removeEventListener("click", handleClick);
      document.removeEventListener("contextmenu", handleContextMenu);
      window.removeEventListener("resize", handleResize);
    };
  });

  const downHandler = (e) => {
    if ((e.metaKey || e.ctrlKey) && e.key === "a") {
      // Handle Ctrl+A or Cmd+A key combination here
      e.preventDefault(); // Prevent the default browser behavior (e.g., selecting all text)
      setSelectedShapeIds(components.map((comp) => comp.id));
    }

    if (e.key == "Shift" || e.key == "Meta") {
      setShiftHeld(true);
    } else if (e.key == "Backspace") {
      deleteHandler(e);
    } else if (e.key === "z" && (e.ctrlKey || e.metaKey) && e.shiftKey) {
      //redo
    } else if (e.key === "z" && (e.ctrlKey || e.metaKey)) {
      if (opeations.length > 0) {
        let newComps = opeations.at(-1).newComps;
        updateComonents(newComps, false);
        // updateAllArrowsToCenter(newComps);
        setUpdate(update + 1);
        opeations.pop();
      } else {
        // setComponents([]);
        // setUpdate(update + 1);
      }
    } else if (
      e.key == "ArrowUp" ||
      e.key == "ArrowDown" ||
      e.key == "ArrowRight" ||
      e.key == "ArrowLeft"
    ) {
      e.preventDefault();
      arrowHandler(e);
    } else if ((e.ctrlKey || e.metaKey) && e.key === "c") {
      copyShapeAction(e);
    } else if ((e.ctrlKey || e.metaKey) && e.key === "v") {
      pasteShapeAction(e);
    }
  };

  const upHandler = (e) => {
    e.preventDefault();
    if (e.key == "Shift" || e.key == "Meta") {
      setShiftHeld(false);
    }
    setSpeed(1);
  };

  const updateComponentsInCanvas = (comps) => {
    components = comps;
    props.componentsUpdated(comps, true, true, false);
    setUpdate(update + 1);
  };

  const updateComonents = (
    updatedComps,
    needUndo,
    updateParentComponent = true,
    saveDiagram = false
  ) => {
    // console.log("updateComonents111 ", updatedComps, Math.random() * 1000);
    if (needUndo) {
      let copy = updatedComps.map((comp) => {
        if (comp.type == "arrow") {
          let points = [...comp.points];
          comp.points = points;
        }
        return { ...comp };
      });

      let newOperation = { newComps: copy };
      opeations.push(newOperation);
      setOperations(opeations);
    }

    updateMaxValues(updatedComps);

    //setUpdate(update + 1)

    // setComponents(updatedComps);

    setComponents([...updatedComps]);
    if (updateParentComponent)
      props.componentsUpdated(updatedComps, false, true, saveDiagram);
    else {
      //console.log("updateComonents ", updatedComps);
    }
  };

  useEffect(() => {
    componentsRef.current = components;
  }, [components]);

  const updateMaxValues = (updatedComps) => {
    updatedComps.map((comp) => {
      if (comp.x + comp.width + stagePaddingX > maxX) {
        setMaxX((comp.x + comp.width + stagePaddingX) / scale);
      }

      if (comp.y + comp.height + stagePadding > maxY) {
        setMaxY((comp.y + comp.height + stagePadding) / scale);
      }
    });
  };

  const deleteHandler = (e) => {
    var componentsToDelete = componentsRef.current.filter((comp) => {
      return selectedShapeIds.includes(comp.id);
    });

    componentsToDelete.forEach((compToDelete) => {
      if (compToDelete.type == "arrow") {
        let head = compToDelete.head;
        let tail = compToDelete.tail;

        if (head != null) {
          components.map((comp) => {
            if (comp.id == head.id) {
              var connectedArrows = comp.connectedArrows;
              let updatedArrows = connectedArrows.filter((arw) => {
                return arw.arrowId != compToDelete.id;
              });
              comp.connectedArrows = updatedArrows;
            }
          });
        }

        if (tail != null) {
          components.map((comp) => {
            if (comp.id == tail.id) {
              var connectedArrows = comp.connectedArrows;
              let updatedArrows = connectedArrows.filter((arw) => {
                return arw.arrowId != compToDelete.id;
              });
              comp.connectedArrows = updatedArrows;
            }
          });
        }
      } else {
        if (
          compToDelete.connectedArrows != null &&
          compToDelete.connectedArrows.length > 0
        ) {
          components.map((comp) => {
            if (comp.type == "arrow") {
              if (comp.head != null && compToDelete.id == comp.head.id) {
                comp.head = null;
              }
              if (comp.tail != null && compToDelete.id == comp.tail.id) {
                comp.tail = null;
              }
            }
          });
        }
      }
    });

    updateComonents(
      componentsRef.current.filter((comp) => {
        return comp.isEditingText || !selectedShapeIds.includes(comp.id);
      }),
      true,
      true
    );
  };

  const printComponents = () => {
    console.log(componentsRef.current);
  };

  // setInterval(printComponents, 5000);

  const copyShapeAction = (e) => {
    var copiedShapes = [];
    selectedShapeIds.map((shapeId) => {
      componentsRef.current.filter((comp) => {
        if (comp.id == shapeId) {
          var newMap = { ...comp };
          copiedShapes.push(newMap);
        }
      });
    });
    setCopiedShapes(copiedShapes);
    setShapePasteMargin(10);
  };

  const pasteShapeAction = (e) => {
    var newShapes = copiedShapes.map((comp) => {
      var newId = uuid();
      var newMap = { ...comp };
      newMap.x += shapePasteMargin;
      newMap.y += shapePasteMargin;
      newMap.id = newId;
      newMap.uniqueId = newId;
      return newMap;
    });

    updateComonents(componentsRef.current.concat(newShapes), true);

    setShapePasteMargin(shapePasteMargin + 10);
    setSelectedShapeIds(newShapes.map((comp) => comp.id));
  };

  const arrowHandler = (e) => {
    let deltaX = 0;
    let deltaY = 0;
    setSpeed(speed * 1.2);

    if (e.key == "ArrowUp") {
      deltaY = -1 * speed;
    }
    if (e.key == "ArrowDown") {
      deltaY = 1 * speed;
    }
    if (e.key == "ArrowRight") {
      deltaX = 1 * speed;
    }
    if (e.key == "ArrowLeft") {
      deltaX = -1 * speed;
    }
    components.map((comp) => {
      if (selectedShapeIds.includes(comp.id)) {
        comp.x += deltaX;
        comp.y += deltaY;
        // handleRectMove(comp, true);
      }
    });

    updateComonents(components, true);
    selectedShapeIds.forEach((shapeId) => {
      updateArrowsToCenter(shapeId, false, null);
    });

    // setUpdate(update + 1);
  };

  const setStageEditing = (status) => {
    setEnableStageEditing(status);
  };

  const updateTempComponents = (x, y) => {
    const updatedTempComponents = tempComponentsRef.current.map((comp) => {
      if (comp.id === drawingArrowByDragRef.current.arrowId) {
        return {
          ...comp,
          points: [comp.points[0], comp.points[1], x, y],
          x: x - (comp.points[2] - comp.points[0]) / 2,
          y: y - (comp.points[3] - comp.points[1]) / 2,
        };
      }
      return comp;
    });
    setTempComponents(updatedTempComponents);
    setUpdate(update + 1);
  };

  const updateTempComponentsHeadDotId = (dotId) => {
    setDrawingArrowByDrag({
      ...drawingArrowByDrag,
      dotId: dotId,
    });
  };

  const convertTempArrowToPermanent = (x, y) => {
    // console.log("convertTempArrowToPermanent");

    setArrowConnectorType(null);
    const hoverTargets = intersections(x, y);

    enableConnectorForShape.current = null;
    if (hoverTargets && hoverTargets.length > 0) {
      let target = hoverTargets[hoverTargets.length - 1];
      const updatedTempComponents = tempComponentsRef.current.map((comp) => {
        if (
          comp.id === drawingArrowByDragRef.current.arrowId &&
          comp.head.id != comp.tail.id
        ) {
          let [centerX, centerY] = [0, 0];

          let dotId = drawingArrowByDragRef.current.dotId;

          if (dotId) {
            [centerX, centerY] = getDotLocation(target, dotId);
          } else {
            [centerX, centerY] = closestPoint(target, comp.points, "head");
          }
          return {
            ...comp,
            points: [comp.points[0], comp.points[1], centerX, centerY],
            head: {
              id: target.id,
              type: target.type,
              dotId: dotId,
            },
          };
        }
        return comp;
      });

      setTempComponents([]);
      let updatedComps = [
        ...componentsRef.current,
        {
          ...updatedTempComponents[0],
          id: updatedTempComponents[0].id,
        },
      ];

      const newComponents = updatedComps.map((comp) => {
        if (
          comp.id == target.id ||
          comp.id == drawingArrowByDragRef.current.tailCompId
        ) {
          console.log(
            "addingConnectedArrow ",
            comp.text,
            comp.id == target.id ? "head" : "tail"
          );
          return {
            ...comp,
            connectedArrows: [
              ...comp.connectedArrows,
              {
                arrowId: drawingArrowByDragRef.current.arrowId,
                type: comp.id == target.id ? "head" : "tail",
              },
            ],
            enableConnectors: false,
          };
        }
        return comp;
      });

      setConnectableDot(null);
      setDrawingArrowByDrag(null);
      setComponents(newComponents);
      props.componentsUpdated(newComponents, false, true, false);
      // setUpdate(update + 1);
      // console.log("5 ", components);
    } else {
      setConnectableDot(null);
      setDrawingArrowByDrag(null);
      setTempComponents([]);
    }
  };

  useEffect(() => {
    const getConnetablePointsData = (x, y, width, height, shapeId) => {
      const topDotId = shapeId + "-top-dot";
      const bottomDotId = shapeId + "-bottom-dot";
      const leftDotId = shapeId + "-left-dot";
      const rightDotId = shapeId + "-right-dot";
      const topDot = { x: x + width / 2, y: y, id: topDotId, shapeId: shapeId };
      const leftDot = {
        x: x,
        y: y + height / 2,
        id: leftDotId,
        shapeId: shapeId,
      };
      const bottomDot = {
        x: x + width / 2,
        y: y + height,
        id: bottomDotId,
        shapeId: shapeId,
      };
      const rigtDot = {
        x: x + width,
        y: y + height / 2,
        id: rightDotId,
        shapeId: shapeId,
      };
      return [topDot, leftDot, bottomDot, rigtDot];
    };

    const eligibleDotForConnection = (hoverTargets, x, y) => {
      let eligibleTargets = [];
      hoverTargets.forEach((target) => {
        let allAvailableDots = getConnetablePointsData(
          target.x,
          target.y,
          target.width,
          target.height,
          target.id
        );
        let connnectedablePoints = allAvailableDots.filter((dot) => {
          if (x > dot.x - getDotSize() && x < dot.x + getDotSize()) {
            if (y > dot.y - getDotSize() && y < dot.y + getDotSize()) {
              return true;
            }
          }
          return false;
        });
        if (connnectedablePoints.length > 0) {
          console.log(connnectedablePoints);
          eligibleTargets = connnectedablePoints;
        } else {
          console.log("Fallback");
          eligibleTargets = allAvailableDots;

          let arrow = tempComponentsRef.current.find(
            (c) => c.id == drawingArrowByDrag.arrowId
          );
          let tempArrowTailX = arrow.points[0];
          let tempArrowTailY = arrow.points[1];
          let minDistance = 1000;
          allAvailableDots.forEach((dot) => {
            const distance = Math.sqrt(
              Math.pow(dot.x - tempArrowTailX, 2) +
                Math.pow(dot.y - tempArrowTailY, 2)
            );
            if (distance < minDistance) {
              minDistance = distance;
              eligibleTargets = [dot];
            }
          });
        }
      });

      if (eligibleTargets && eligibleTargets.length > 0) {
        return {
          id: eligibleTargets[0].id,
          x: eligibleTargets[0].x,
          y: eligibleTargets[0].y,
          shapeId: eligibleTargets[0].shapeId,
        };
      } else {
        // setDrawingArrowByDrag({"arrowId":arrow.id,"tailCompId":shape.id});
      }
      return null;
    };

    const updateEnableConnectors = (type) => {
      let currentX = stageRef.current.getPointerPosition().x / scale;
      let currentY = stageRef.current.getPointerPosition().y / scale;

      const hoverTargets = intersections(currentX, currentY);

      if (hoverTargets.length > 0) {
        // Find the hoverTarget with the highest zindex
        let highestZIndexTarget = hoverTargets.reduce((prev, current) => {
          return prev.zindex > current.zindex ? prev : current;
        });

        enableConnectorForShape.current = {
          ...enableConnectorForShape.current,
          id: highestZIndexTarget.id,
          x: currentX,
          y: currentY,
          type: type,
        };
      }
      return [currentX, currentY];
    };

    const handleMouseMove = (event) => {
      // console.log("Moving ", drawingArrowByDragRef.current);
      if (drawingArrowByDragRef.current != null) {
        let [currentX, currentY] = updateEnableConnectors("head");
        updateTempComponents(currentX, currentY);
      } else if (enableConnectorForShape.current) {
        //handle head or tail re adjustment

        updateEnableConnectors(enableConnectorForShape.current.type);
      } else if (selectedShapeIds.length > 0) {
        setSelectionRect(null);
        setIsDragging(false);
      } else {
      }
    };

    const handleMouseUp = (event) => {
      if (props.showPlayground) {
        return;
      }

      if (
        selectionRect &&
        selectionRect.width > 0 &&
        selectionRect.height > 0
      ) {
        setSelectedShapeIds(getSelectedEntities(selectionRect, components));

        setSelectionRect(null);
        setUpdateSelectionRect(Math.random() * 500);
      }
      if (drawingArrowByDragRef.current != null) {
        let currentX = stageRef.current.getPointerPosition().x;
        let currentY = stageRef.current.getPointerPosition().y;

        handleArrowDrag(
          (event, "head", drawingArrowByDragRef.current.arrowId, "ended")
        );

        const stage = stageRef.current;
        const hoverTargets = intersections(
          stage.getPointerPosition().x,
          stage.getPointerPosition().y
        );

        if (arrowConnectorType != null) {
          if (arrowConnectorType.type == "head") {
            const updatedComponents = components.map((comp) => {
              // Use map to create a new array with updated components
              if (comp.id == arrowConnectorType.shape.id) {
                let head = { ...comp.head, dotId: drawingArrowByDrag.dotId };
                return { ...comp, head: head };
              }
              return comp;
            });
            updateComonents(updatedComponents, true);
          }

          if (arrowConnectorType.type == "tail") {
            const updatedComponents = components.map((comp) => {
              // Use map to create a new array with updated components
              if (comp.id == arrowConnectorType.shape.id) {
                let tail = { ...comp.tail, dotId: drawingArrowByDrag.dotId };
                return { ...comp, tail: tail };
              }
              return comp;
            });
            updateComonents(updatedComponents, true);
          }
        }
        convertTempArrowToPermanent(currentX, currentY);
        setDragHoveringShapes([]);
      } else if (enableConnectorForShape.current != null) {
        //Handle Head or tail re adjustment

        let previousRectId = undefined;
        if (enableConnectorForShape.current.dotId != null) {
          const updatedComponents = componentsRef.current.map((comp) => {
            // Use map to create a new array with updated components

            if (comp.id == enableConnectorForShape.current.arrowId) {
              if (enableConnectorForShape.current.type == "head") {
                previousRectId = comp.head.id;
                let head = {
                  ...comp.head,
                  id: enableConnectorForShape.current.id,
                  dotId: enableConnectorForShape.current.dotId,
                };
                if (previousRectId != enableConnectorForShape.current.id) {
                }
                return { ...comp, head: head };
              } else if (enableConnectorForShape.current.type == "tail") {
                previousRectId = comp.tail.id;
                let tail = {
                  ...comp.tail,
                  id: enableConnectorForShape.current.id,
                  dotId: enableConnectorForShape.current.dotId,
                };
                if (previousRectId != enableConnectorForShape.current.id) {
                }
                return { ...comp, tail: tail };
              }
            }
            return comp;
          });

          updateComonents(updatedComponents, true, false);

          // if (previousRectId)
          //   updateArrowsToCenter(
          //     updatedComponents.find((x) => x.id == previousRectId),
          //     false,
          //     null
          //   );
          // updateArrowsToCenter(
          //   updatedComponents.find(
          //     (x) => x.id == enableConnectorForShape.current.id
          //   ),
          //   false,
          //   null
          // );
        }
        enableConnectorForShape.current = null;
      }
    };

    // if (isDragging || drawingArrowByDragRef.current != null) {
    // if (mouseMoveListernerAdded == false && isDragging) {
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
    // setMouseMoveListernerAdded(true);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
      // setMouseMoveListernerAdded(false);
    };
    // }
    // }
  }, [isDragging]);

  const handleMouseDown = (event) => {
    if (props.showPlayground) {
      return;
    }
    let eventX = stageRef.current.getPointerPosition().x / scale;
    let eventY = stageRef.current.getPointerPosition().y / scale;

    if (!didClickOnComponent(eventX, eventY)) {
      // console.log(components)
      const container = stageRef.current.container();
      if (container.style.cursor == "default") {
        setIsDragging(true);
        setStartX(eventX);
        setStartY(eventY);

        let data = {
          x: eventX,
          y: eventY,
          width: 0,
          height: 0,
        };
        let rect = createNewComponentObject("selection_rect", uuid(), 1, data);
        setSelectionRect(rect);
      }

      disableAllEditing(event);
      setShow(false);
    }
  };

  const didClickOnComponent = (x, y) => {
    if (components) {
      for (let i = 0; i < componentsRef.current.length; i++) {
        const component = components[i];
        if (
          component.x < x &&
          component.x + component.width > x &&
          component.y < y &&
          component.y + component.height > y - 60
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const scaleUp = () => {
    if (scale <= 2) {
      let newScale = scale + 0.1;
      setScale(newScale);
      props.onScaleChange(newScale);
    }
  };

  const scaleDown = () => {
    if (scale > 0.5) {
      let newScale = scale - 0.1;
      setScale(newScale);
      props.onScaleChange(newScale);
    }
  };

  const resetScale = () => {
    setScale(1);
    props.onScaleChange(1);
  };

  const bind = useGesture(
    {
      onPinch: ({ offset: [dX, dY], vdva }) => {
        const stage = stageRef.current;

        //const vdvaMagnitude = Math.sqrt(vdva[0] * vdva[0] + vdva[1] * vdva[1]);
        const vdvaMagnitude = vdva[0];

        const scaleBy = vdvaMagnitude !== 0 ? 1 + vdvaMagnitude / 100 : 1;

        // Get current stage scale
        const oldScale = scale;

        // Compute new scale
        let newScale = oldScale * scaleBy;

        // const zoomAmount = event.deltaY * 0.01; // Adjust the zoom factor as needed
        // const newScale = +(scale + zoomAmount).toFixed(2); // Round to 2 decimal places

        // Limit the scale to a reasonable range
        newScale = Math.max(0.5, Math.min(newScale, 2));

        let optimizedScale = parseFloat(newScale.toFixed(2));
        if (Math.abs(optimizedScale - oldScale) < 0.03) {
          return;
        }
        // console.log(optimizedScale)

        // Compute the new position of the stage
        const pointerPosition = stage.getPointerPosition();
        const newPos = {
          x: (pointerPosition.x - stage.x()) / oldScale,
          y: (pointerPosition.y - stage.y()) / oldScale,
        };
        const newAbsPos = {
          x: pointerPosition.x - newPos.x * optimizedScale,
          y: pointerPosition.y - newPos.y * optimizedScale,
        };

        // Set the new scale and position of the stage
        setScale(optimizedScale);
        props.onScaleChange(optimizedScale);

        //TODO: Only need to make sure, newAbsPos is smooth
        // stage.position(newAbsPos);

        // const offset = {
        //   x: newAbsPos.x ,
        //   y: newAbsPos.y
        // };

        // const container = containerRef.current;
        // const { scrollLeft, scrollTop } = container;

        // const newScrollLeft = (scrollLeft + offset.x );
        // const newScrollTop = (scrollTop + offset.y ) ;

        // console.log(newScrollLeft)
        // console.log(newScrollTop)

        // container.scrollTo(newAbsPos.x, newAbsPos.y);

        // stage.batchDraw();
      },
    },
    {
      domTarget: scrollRef,
      eventOptions: { passive: false },
    }
  );

  const checkDeselect = (e) => {
    // console.log(e.evt.clientX, e.evt.clientY);
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      setSelectedShapeIds([]);
      if (componentsRef.current.length > 0) {
        componentsRef.current.forEach((comp) => {
          comp.isEditing = false;
          comp.isEditingText = false;
          comp.enableConnectors = false;
        });
      }
    }
  };

  const arrowHeadCord = (connectedShape, arrowComp, type) => {
    var centerX = 0;
    var centerY = 0;
    if (connectedShape.type == "rect" || connectedShape.type == "image") {
      if (type === "head") {
        if (arrowComp.head.dotId) {
          [centerX, centerY] = getDotLocation(
            connectedShape,
            arrowComp.head.dotId
          );
        } else {
          [centerX, centerY] = closestPoint(
            connectedShape,
            arrowComp.points,
            type
          );
        }
      } else if (type === "tail") {
        if (arrowComp.tail.dotId) {
          [centerX, centerY] = getDotLocation(
            connectedShape,
            arrowComp.tail.dotId
          );
        } else {
          [centerX, centerY] = closestPoint(
            connectedShape,
            arrowComp.points,
            type
          );
        }
      }
    } else if (connectedShape.type == "circle") {
      [centerX, centerY] = closestPoint(connectedShape, arrowComp.points, type);
    }
    return [centerX, centerY];
  };

  function debounce(func, delay) {
    let timeoutId;

    return function (...args) {
      clearTimeout(timeoutId);

      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }

  const arrowDragEnded = (e, type, arrowId) => {
    const hoverTargets = intersections(
      stageRef.current.getPointerPosition().x,
      stageRef.current.getPointerPosition().y
    );

    hoverTargets.map((target, index) => {
      if (target.attrs.type != "transformer" && target.attrs.type != "arrow") {
        let shapeId = target.attrs.id;

        if (shapeId) {
          var shape = allComponents().find((s) => {
            return s.id == shapeId;
          });
          allComponents().filter((arrow) => {
            if (arrow.id == arrowId) {
              shape.connectedArrows.push({ arrowId: arrowId, type: type });
              shape.enableConnectors = false;
              const [centerX, centerY] = arrowHeadCord(shape, arrow, type);
              var array = arrow.points;
              var totalPoints = array.length;

              if (type == "head") {
                array[totalPoints - 2] = centerX;
                array[totalPoints - 1] = centerY;
                arrow.head.id = shapeId;
              } else {
                array[0] = centerX;
                array[1] = centerY;
                arrow.tail.id = shapeId;
              }

              arrow.points = array;
            }
          });
        }
      }
    });
  };

  const handleArrowDrag = (e, type, arrowId, progress) => {
    if (progress == "ended") {
      arrowDragEnded(e, type, arrowId);
    } else {
      let allComps = allComponents();
      activatedForConnect.map((activatedId, index) => {
        allComps.filter((comp) => {
          if (comp.id == activatedId) {
            comp.enableConnectors = false;
          }
        });
      });

      const stage = stageRef.current;
      const hoverTargets = intersections(
        stage.getPointerPosition().x,
        stage.getPointerPosition().y
      );

      let act = [];

      hoverTargets.map((target, index) => {
        if (target.type != "transformer" && target.type != "arrow") {
          let shapeId = target.id;

          allComps.filter((comp) => {
            if (comp.id == shapeId) {
              act.push(shapeId);
              comp.enableConnectors = true;
            }
          });
        }
      });
      setActivatedForConnect(act);
    }
  };

  const updateAllArrowsToCenter = (updatedComonents) => {
    var arrowIdsAndTypes = [];
    let allComps = updatedComonents;

    let arrowUpdated = false;

    console.log("updateAllArrowsToCenter ", updatedComonents);

    allComps.forEach((shape) => {
      if (shape.type != "arrow") {
        arrowIdsAndTypes = shape.connectedArrows;
        if (arrowIdsAndTypes != null) {
          arrowIdsAndTypes.map((map, index) => {
            var arrowId = map.arrowId;

            var arrow = allComps.find((s) => {
              return s.id === arrowId;
            });
            var pointsArray = arrow.points;
            if (arrow.head != null && arrow.head.id != null) {
              var connectedShapeAtHead = allComps.find((s) => {
                return s.id == arrow.head.id;
              });

              const [centerX, centerY] = arrowHeadCord(
                connectedShapeAtHead,
                arrow,
                "head"
              );

              var totalPoints = pointsArray.length;
              pointsArray[totalPoints - 2] = centerX;
              pointsArray[totalPoints - 1] = centerY;
            }

            if (arrow.tail != null && arrow.tail.id != null) {
              var connectedShapeAtTail = allComps.find((s) => {
                return s.id == arrow.tail.id;
              });

              const [centerX, centerY] = arrowHeadCord(
                connectedShapeAtTail,
                arrow,
                "tail"
              );
              pointsArray[0] = centerX;
              pointsArray[1] = centerY;
            }
            arrow.points = pointsArray;
            arrowUpdated = true;
          });
        }
      }
    });
    updateComonents(allComps, true);
  };

  const updateArrowsToCenter = (
    shapeIdentifier,
    gestureEnded,
    updatedComonents
  ) => {
    var arrowIdsAndTypes = [];
    let allComps =
      updatedComonents == null ? allComponents() : updatedComonents;

    let arrowUpdated = false;
    let shapesToInvestigate = [shapeIdentifier];

    shapesToInvestigate.forEach((shapeId) => {
      //Fetch all the arrows connected to shape, which needs to be moved with shape
      allComps.filter((shape) => {
        if (shape.id == shapeId) {
          arrowIdsAndTypes = shape.connectedArrows;

          if (arrowIdsAndTypes != null) {
            arrowIdsAndTypes.map((map, index) => {
              var arrowId = map.arrowId;
              var arrow = allComps.find((s) => {
                return s.id === arrowId;
              });

              if (arrow) {
                var pointsArray = arrow.points;
                if (arrow.head != null && arrow.head.id != null) {
                  var connectedShapeAtHead = allComps.find((s) => {
                    return s.id == arrow.head.id;
                  });

                  let [centerX, centerY] = arrowHeadCord(
                    connectedShapeAtHead,
                    arrow,
                    "head"
                  );

                  var totalPoints = pointsArray.length;
                  pointsArray[totalPoints - 2] = centerX;
                  pointsArray[totalPoints - 1] = centerY;
                }

                if (arrow.tail != null && arrow.tail.id != null) {
                  var connectedShapeAtTail = allComps.find((s) => {
                    return s.id == arrow.tail.id;
                  });

                  const [centerX, centerY] = arrowHeadCord(
                    connectedShapeAtTail,
                    arrow,
                    "tail"
                  );

                  pointsArray[0] = centerX;
                  pointsArray[1] = centerY;
                }
                arrow.points = pointsArray;
                arrowUpdated = true;
              }
            });
          }
        }
      });
    });
    // setUpdate(update + 1);
    if (gestureEnded) {
      updateComonents(allComps, true);
    }
  };

  const getSelectedComponents = () => {
    const comps = [];
    componentsRef.current.forEach((element) => {
      if (selectedShapeIds.includes(element.id)) {
        comps.push(element);
      }
    });
    return comps;
  };

  const updateSelectedComponents = (action) => {
    components.map((element) => {
      if (selectedShapeIds.includes(element.id)) {
        if (action.type == "updateZIndex") {
          element.zIndex = action.zIndex;
        }
      }
    });
    props.componentsUpdated(componentsRef.current, true, true, false);

    console.log(components);
  };

  const selectMenuOption = (e) => {
    e.preventDefault();
    if (e == "to-back") {
      updateSelectedComponents({ type: "updateZIndex", zIndex: backZ - 1 });
      setBackZ(backZ - 1);
    } else if (e == "to-front") {
      updateSelectedComponents({ type: "updateZIndex", zIndex: frontZ + 1 });
      setFrontZ(frontZ + 1);
    } else if (e == "group") {
    } else if (e == "join") {
      var selectedShapes = [];
      selectedShapeIds.map((shapeId) => {
        selectedShapes.push(
          componentsRef.current.filter((comp) => {
            return comp.id == shapeId;
          })[0]
        );
      });
      //setGroupId(groupId + 1);
      var data = {
        tail: { id: selectedShapes[0].id, type: selectedShapes[0].type },
        head: { id: selectedShapes[1].id, type: selectedShapes[0].type },
      };
      let sourceShape = selectedShapes[0];
      let targetShape = selectedShapes[1];

      if (sourceShape.x + sourceShape.width <= targetShape.x) {
        //right edge to left edge
        data["points"] = [
          selectedShapes[0].x + selectedShapes[0].width,
          selectedShapes[0].y + selectedShapes[0].height / 2,
          selectedShapes[1].x,
          selectedShapes[1].y + selectedShapes[1].height / 2,
        ];
      } else if (targetShape.x + targetShape.width <= sourceShape.x) {
        //left edge to right edge
        data["points"] = [
          selectedShapes[0].x,
          selectedShapes[0].y + selectedShapes[0].height / 2,
          selectedShapes[1].x + selectedShapes[1].width,
          selectedShapes[1].y + selectedShapes[1].height / 2,
        ];
      } else if (targetShape.y + sourceShape.height <= sourceShape.y) {
        //top edge to bottom edge
        data["points"] = [
          selectedShapes[0].x + selectedShapes[0].width / 2,
          selectedShapes[0].y,
          selectedShapes[1].x + selectedShapes[1].width / 2,
          selectedShapes[1].y + selectedShapes[1].height,
        ];
      } else if (sourceShape.y + sourceShape.height <= sourceShape.y) {
        //bottom edge to top edge
        data["points"] = [
          selectedShapes[0].x + selectedShapes[0].width / 2,
          selectedShapes[0].y + selectedShapes[0].height,
          selectedShapes[1].x + selectedShapes[1].width / 2,
          selectedShapes[1].y,
        ];
      }
      // data["points"] = [
      //   selectedShapes[0].x + selectedShapes[0].width / 2,
      //   selectedShapes[0].y + selectedShapes[0].height / 2,
      //   selectedShapes[1].x + selectedShapes[1].width / 2,
      //   selectedShapes[1].y + selectedShapes[1].height / 2,
      // ];

      let arrow = props.addNewComponent("arrow", data, false);
      selectedShapes[1].connectedArrows.push({
        arrowId: arrow.id,
        type: "head",
      });
      selectedShapes[0].connectedArrows.push({
        arrowId: arrow.id,
        type: "tail",
      });
      setUpdate(update + 1);
    }
  };

  const selfArrowPoints = (comp) => {
    const arrowOffset = 20;
    let x = comp.x;
    let y = comp.y;
    let width = comp.width;
    let height = comp.height;

    const arrowPoints = [
      x + width - 10,
      y,
      x + width,
      y - 50,
      x + 10,
      y - 50,
      x + 10,
      y,
    ];

    return arrowPoints;
  };

  const onClickContextMenu = (event, event_type) => {
    event.stopPropagation();
    setShow(false);

    if (event_type == "self_arrow") {
      if (selectedShapeIds.length == 1) {
        const selectedId = selectedShapeIds[0];
        const selectedComponent = componentsRef.current.find(
          (component) => component.id === selectedId
        );
        props.addNewComponent("self_arrow", {
          points: selfArrowPoints(selectedComponent),
          componentId: selectedId,
        });
      } else {
        //TODO
        console.log("Invalid request");
      }
    } else if (event_type == "to_back") {
      updateSelectedComponents({ type: "updateZIndex", zIndex: backZ - 1 });
      setBackZ(backZ - 1);
    } else if (event_type == "to_front") {
      updateSelectedComponents({ type: "updateZIndex", zIndex: frontZ + 1 });
      setFrontZ(frontZ + 1);
    } else if (event_type == "update_arrow_desc") {
      props.updateArrowDesc(selectedShapeIds[0]);
      setFrontZ(frontZ + 1);
    } else if (event_type == "join") {
      var selectedShapes = [];
      selectedShapeIds.map((shapeId) => {
        selectedShapes.push(
          componentsRef.current.filter((comp) => {
            return comp.id == shapeId;
          })[0]
        );
      });
      //setGroupId(groupId + 1);
      var data = {
        tail: { id: selectedShapes[0].id, type: selectedShapes[0].type },
        head: { id: selectedShapes[1].id, type: selectedShapes[0].type },
      };
      let sourceShape = selectedShapes[0];
      let targetShape = selectedShapes[1];

      if (sourceShape.x + sourceShape.width <= targetShape.x) {
        //right edge to left edge
        data["points"] = [
          selectedShapes[0].x + selectedShapes[0].width,
          selectedShapes[0].y + selectedShapes[0].height / 2,
          selectedShapes[1].x,
          selectedShapes[1].y + selectedShapes[1].height / 2,
        ];
      } else if (targetShape.x + targetShape.width <= sourceShape.x) {
        //left edge to right edge
        data["points"] = [
          selectedShapes[0].x,
          selectedShapes[0].y + selectedShapes[0].height / 2,
          selectedShapes[1].x + selectedShapes[1].width,
          selectedShapes[1].y + selectedShapes[1].height / 2,
        ];
      } else if (targetShape.y + targetShape.height <= sourceShape.y) {
        //top edge to bottom edge
        data["points"] = [
          selectedShapes[0].x + selectedShapes[0].width / 2,
          selectedShapes[0].y,
          selectedShapes[1].x + selectedShapes[1].width / 2,
          selectedShapes[1].y + selectedShapes[1].height,
        ];
      } else if (sourceShape.y + sourceShape.height <= targetShape.y) {
        //bottom edge to top edge
        data["points"] = [
          selectedShapes[0].x + selectedShapes[0].width / 2,
          selectedShapes[0].y + selectedShapes[0].height,
          selectedShapes[1].x + selectedShapes[1].width / 2,
          selectedShapes[1].y,
        ];
      }
      // data["points"] = [
      //   selectedShapes[0].x + selectedShapes[0].width / 2,
      //   selectedShapes[0].y + selectedShapes[0].height / 2,
      //   selectedShapes[1].x + selectedShapes[1].width / 2,
      //   selectedShapes[1].y + selectedShapes[1].height / 2,
      // ];

      let arrow = props.addNewComponent("arrow", data, false);
      selectedShapes[1].connectedArrows.push({
        arrowId: arrow.id,
        type: "head",
      });
      selectedShapes[0].connectedArrows.push({
        arrowId: arrow.id,
        type: "tail",
      });
      setUpdate(update + 1);
    } else if (event_type == "expand") {
      props.expandAction(selectedShapeIds[0]);
    } else if (event_type == "expand_in_new_tab") {
      if (selectedShapeIds.length == 1) {
        props.expandInNewPage(selectedShapeIds[0]);
      }
    } else if (event_type == "map_child") {
      if (selectedShapeIds.length == 1) {
        props.mapChildComponent(selectedShapeIds[0]);
      }
    } else if (event_type == "show_component_this_step") {
      props.updateVisibilityForStep(selectedShapeIds, true);
    } else if (event_type == "hide_component_this_step") {
      props.updateVisibilityForStep(selectedShapeIds, false);
    }
  };

  const rightClickOptions = () => {
    console.log("Components ", components);
    let menuItems;
    if (!props.customizingStep) {
      menuItems = [
        // { text: 'Self-Arrow', action: 'self_arrow' },
        { text: "Send Back", action: "to_back" },
        { text: "Bring to Front", action: "to_front" },
        { text: "Join", action: "join" },
        { text: "Delete", action: "delete" },
        //   { text: 'Group', action: 'group' },
        //   { text: 'Undo', action: 'undo' },
        //   { text: 'Redo', action: 'redo' },
        // { text: "Map Child", action: "map_child" },
        // { text: "Expand/Collapse", action: "expand" },
      ];
    } else {
      menuItems = [
        // { text: 'Self-Arrow', action: 'self_arrow' },
        {
          text: "Show this component in this step",
          action: "show_component_this_step",
        },
        {
          text: "Hide this component in this step",
          action: "hide_component_this_step",
        },

        //   { text: 'Group', action: 'group' },
        //   { text: 'Undo', action: 'undo' },
        //   { text: 'Redo', action: 'redo' },
        // { text: "Map Child", action: "map_child" },
        // { text: "Expand/Collapse", action: "expand" },
      ];
    }

    if (selectedShapeIds.length == 1) {
      components.map((comp) => {
        if (comp.id == selectedShapeIds[0]) {
          if (comp.type == "arrow") {
            menuItems.push({
              text: "Update Arrow Description",
              action: "update_arrow_desc",
            });
          }
        }
      });
    }

    return (
      <div className="context-menu">
        <ul>
          {menuItems.map((item, index) => (
            <React.Fragment key={index}>
              <li
                onMouseDown={(e) => {
                  onClickContextMenu(e, item.action);
                }}
              >
                {item.text}
              </li>
              {index < menuItems.length - 1 && <hr />}
            </React.Fragment>
          ))}
        </ul>
      </div>
    );
  };

  const onSelect = (modelId) => {
    // console.log("Clicked on Component ", modelId);
    if (shiftHeld) {
      console.log("Shift Held");
      console.log("Sleected Shapes ", selectedShapeIds);
      if (selectedShapeIds.includes(modelId)) {
        console.log("New Shape already in Selected Shaped ", modelId);
        setSelectedShapeIds(
          selectedShapeIds.filter((item) => item !== modelId)
        );
      } else {
        console.log("Adding new shape in Selected ", modelId);
        let newShapes = selectedShapeIds.concat(modelId);
        setSelectedShapeIds(newShapes);
        console.log("Final ", selectedShapeIds);
      }
    } else {
      // console.log("Shift Not Held");
      setSelectedShapeIds([modelId]);
    }
    console.log("onSelect Complted");
  };

  // useEffect(() => {
  //   console.log("selectedShapeIds changed:", selectedShapeIds);
  //   props.onSelect(selectedShapeIds);
  // }, [selectedShapeIds]);

  const isVerticallyAligned = (rect1, rect2) => {
    const rect1CenterX = rect1.x + rect1.width / 2;
    const rect2CenterX = rect2.x + rect2.width / 2;
    return Math.abs(rect1CenterX - rect2CenterX) < 1; // Adjust the tolerance as needed
  };

  // Function to check if two rectangles are horizontally aligned (centered)
  const isHorizontallyAligned = (rect1, rect2) => {
    const rect1CenterY = rect1.y + rect1.height / 2;
    const rect2CenterY = rect2.y + rect2.height / 2;
    return Math.abs(rect1CenterY - rect2CenterY) < 1; // Adjust the tolerance as needed
  };

  // Example usage when moving a rectangle
  const handleRectMove = (movedRect, autoDisappearLines) => {
    let _verticallyAlignedRects = [];
    let _horizontallyAlignedRects = [];

    componentsRef.current.forEach((comp) => {
      if (
        (comp.type == "rect" || comp.type == "image") &&
        comp.id != movedRect.id
      ) {
        if (isVerticallyAligned(movedRect, comp)) {
          _verticallyAlignedRects.push(comp);
        }

        if (isHorizontallyAligned(movedRect, comp)) {
          _horizontallyAlignedRects.push(comp);
        }
      }
    });

    if (_verticallyAlignedRects.length > 0) {
      _verticallyAlignedRects.push(movedRect);
    }

    if (_horizontallyAlignedRects.length > 0) {
      _horizontallyAlignedRects.push(movedRect);
    }

    setVerticallyAlignedRects(_verticallyAlignedRects);
    setHorizontallyAlignedRects(_horizontallyAlignedRects);
    // if (_verticallyAlignedRects.length > 0) {
    //   console.log("Vertically aligned rectangles:", _verticallyAlignedRects);
    // }
    // if (_horizontallyAlignedRects.length > 0) {
    //   console.log(
    //     "Horizontally aligned rectangles:",
    //     _horizontallyAlignedRects
    //   );
    // }
    if (
      autoDisappearLines == true &&
      (_verticallyAlignedRects.length > 0 ||
        _horizontallyAlignedRects.length > 0)
    ) {
      setTimeout(() => {
        setVerticallyAlignedRects([]);
        setHorizontallyAlignedRects([]);
      }, 500);
    }
  };

  // const onDragStartShape = (newAttrs) => {

  // };
  const onDragStartShape = () => {
    // console.log(components);
  };

  const onDragMoveShape = (newAttrs) => {
    // console.log("onDragMoveShape ", newAttrs);
    if (!selectedShapeIds.includes(newAttrs.id)) {
      setSelectedShapeIds([newAttrs.id]);
      onDragEndShape(newAttrs);
    } else {
      //it'll always have one object only, the one which is dragged
      var oldStateOfDraggedObjs = componentsRef.current.filter((compObj) => {
        return compObj.id == newAttrs.id;
      });

      if (oldStateOfDraggedObjs.length > 0) {
        var currentDraggedComponent = oldStateOfDraggedObjs[0];

        if (currentDraggedComponent != null) {
          let deltaX = newAttrs.x - currentDraggedComponent.x;
          let deltaY = newAttrs.y - currentDraggedComponent.y;

          componentsRef.current.forEach((comp) => {
            if (comp.type == "rect" || comp.type == "image") {
              if (selectedShapeIds.includes(comp.id)) {
                comp.y = comp.y + deltaY;
                comp.isDragging = true;
                comp.x = comp.x + deltaX;
              }
            } else {
              if (
                comp.type == "arrow" &&
                (selectedShapeIds.includes(comp.head.id) ||
                  selectedShapeIds.includes(comp.tail.id))
              ) {
                // let points = comp.points;
                // let updatedPoints = points.map((value, index) => {
                //   if (index % 2 === 0) {
                //     return value + deltaX;
                //   } else {
                //     return value + deltaY;
                //   }
                // });
                // comp.points = updatedPoints;
              }
            }
          });

          updateComonents(components, false);
          setUpdate(update + 1);
        }
      }

      handleRectMove(newAttrs, false);
      selectedShapeIds.forEach((shapeId) => {
        updateArrowsToCenter(shapeId, false, null);
      });
    }
  };

  const onDragEndShape = (newAttrs) => {
    setHorizontallyAlignedRects([]);
    setVerticallyAlignedRects([]);

    let updatedComponents = componentsRef.current.map((obj, index) => {
      if (obj.id == newAttrs.id) {
        newAttrs.isDragging = false;
        return newAttrs;
      } else return obj;
    });
    updateComonents(updatedComponents, false);

    updateArrowsToCenter(newAttrs.id, true, updatedComponents);
  };

  const onResize = (newAttrs) => {
    let updatedComponents = components.map((obj, index) => {
      return obj.id == newAttrs.id ? newAttrs : obj;
    });
    // updateComonents(
    //   componentsRef.current.map((obj, index) => {
    //     return obj.id == newAttrs.id ? newAttrs : obj;
    //   }),
    //   true
    // );
    updateArrowsToCenter(newAttrs.id, true, updatedComponents);
    //setUpdate(update + 1);
  };

  const checkIfShapeSelected = (comp) => {
    return selectedShapeIds.length == 0
      ? false
      : comp != null && comp.id != null
      ? selectedShapeIds.includes(comp.id)
      : false;
  };

  const onArrowDragEnd = (e, type, model) => {
    handleArrowDrag(e, type, model.id, "ended");
  };

  const onArrowDragMove = (e, type, model) => {
    handleArrowDrag(e, type, model.id, "moving");
  };

  const onDoubleClick = (model) => {
    props.componentsUpdated(
      components.map((obj, index) => {
        if (obj.id == model.id) {
          console.log(obj.id + " Double clicked");
          obj.isEditingText = true;
          return obj;
        } else return obj;
      }),
      true,
      true,
      false
    );
  };

  const onToggleEdit = (model) => {
    props.componentsUpdated(
      components.map((obj, index) => {
        if (obj.id == model.id) {
          obj.isEditingText = false;
          return obj;
        } else return obj;
      }),
      true,
      true,
      false
    );
  };

  const onTextChange = (model, newText) => {
    props.componentsUpdated(
      components.map((obj, index) => {
        if (obj.id == model.id) {
          obj.text = newText;
          return obj;
        } else return obj;
      }),
      true,
      true,
      false
    );
  };

  const enableEditing = (model) => {};

  const onClickArrowDraw = (shape, id, tailCoordinates) => {
    // console.log("onClickArrowDraw");
    var data = {
      tail: { id: shape.id, type: shape.type, dotId: id },
    };
    data["points"] = [
      tailCoordinates.x,
      tailCoordinates.y,
      tailCoordinates.x,
      tailCoordinates.y,
    ];

    let arrow = props.addNewComponent("arrow", data, true);

    setDrawingArrowByDrag({ arrowId: arrow.id, tailCompId: shape.id });

    // tempComponents.push(arrow);
    // console.log("Arrow Temapppppp ", arrow);
    setTempComponents([arrow]);
  };

  useEffect(() => {
    drawingArrowByDragRef.current = drawingArrowByDrag;
  }, [drawingArrowByDrag]);

  useEffect(() => {
    // if (tempComponents.length > 0) {
    if (tempComponents.length > 0) {
      setArrowConnectorType("head");
    } else {
      // setArrowConnectorType(null);
    }
    tempComponentsRef.current = tempComponents;
    // } else {
    //   tempComponentsRef.current = null;
    // }
  }, [tempComponents]);

  const getConnectableDotId = () => {
    return connectableDotRef.current != null
      ? connectableDotRef.current.id
      : null;
  };

  const arrowDotMoved = (data) => {
    // updateHeadOrTail.current = data;
    let currentX = stageRef.current.getPointerPosition().x / scale;
    let currentY = stageRef.current.getPointerPosition().y / scale;

    const hoverTargets = intersections(currentX, currentY);

    if (hoverTargets.length > 0) {
      // Find the hoverTarget with the highest zindex
      let highestZIndexTarget = hoverTargets.reduce((prev, current) => {
        return prev.zindex > current.zindex ? prev : current;
      });

      if (enableConnectorForShape.current == null) {
        enableConnectorForShape.current = {
          arrowId: data.arrowId,
          id: highestZIndexTarget.id,
          x: currentX,
          y: currentY,
          type: data.type,
        };
      }
    }
    //enableConnectorForShape

    //Not sure of this implementaiton so commenting
    // components.map((c) => {
    //   if (c.id == data.id) {
    //     c.points = data.points;
    //   }
    // });
    // let arrow = componentsRef.current.find((c) => c.id == data.id);
    // let connectedBox = data.type == "head" ? arrow.head : arrow.tail;

    // updateArrowsToCenter(connectedBox.id, false, components);
  };

  const onInfoClick = (id) => {
    props.didClickInfoIcon(id);
  };

  const getPointerPosition = () => {
    const stage = stageRef.current;
    return stage.getPointerPosition();
  };

  const renderedSelection = () => {
    var results = [];
    if (selectionRect) {
      results.push(<ComponentGenerator model={selectionRect} />);
    }
    return results;
  };

  const intersections = (x, y) => {
    // console.log("Checking Intersections");
    // console.log(components);
    var eligibleRects = componentsRef.current.filter((comp) => {
      if (comp.type == "rect" || comp.type == "image") {
        // console.log(comp.x, comp.y, comp.width, comp.height);
        // console.log(x, y);
        if (x >= comp.x - 10 && x <= comp.x + comp.width + 10) {
          if (y >= comp.y - 10 && y <= comp.y + comp.height + 10) {
            return true;
          }
        }
      }
    });
    return eligibleRects;
  };

  const getComponentDetails = ({ compId, type }) => {
    if (type == "bounds") {
      let component = components.find((c) => c.id == compId);
      if (component != null) {
        return {
          x: component.x,
          y: component.y,
          width: component.width,
          height: component.height,
        };
      }
    }
    return null;
  };

  const renderedComponents = () => {
    var results = [];

    componentsRef.current.sort((a, b) => a.zIndex - b.zIndex);

    if (components != null && components.length > 0) {
      var allComponentsToRender = components;

      if (tempComponents.length > 0) {
        allComponentsToRender = [...components, ...tempComponentsRef.current];
      }

      allComponentsToRender.map((comp, index) => {
        results.push(
          <ComponentGenerator
            key={comp.uniqueId || `generated_key_${index}`}
            model={comp}
            isSelected={checkIfShapeSelected(comp)}
            scale={comp.type == "arrow" ? scale : 1} //Putting a hack since it's creating issue for rect
            zIndex={1}
            onSelect={onSelect}
            onDragStartShape={onDragStartShape}
            onDragMoveShape={onDragMoveShape}
            onDragEndShape={onDragEndShape}
            onArrowDragEnd={onArrowDragEnd}
            onArrowDragMove={onArrowDragMove}
            onToggleEdit={onToggleEdit}
            onDoubleClick={onDoubleClick}
            onTextChange={onTextChange}
            onResize={onResize}
            onClickArrowDraw={onClickArrowDraw}
            connectableDot={getConnectableDotId()}
            arrowDotMoved={arrowDotMoved}
            creatingNewArrow={drawingArrowByDrag != null ? true : false}
            dragHoveringShapes={dragHoveringShapes}
            pointerPosition={getPointerPosition}
            showPlayground={props.showPlayground}
            enableStageEditing={enableStageEditing}
            enableConnectorForShape={enableConnectorForShape.current}
            getComponentDetails={getComponentDetails}
            shouldHide={
              props.hiddenComponentIds &&
              props.hiddenComponentIds.includes(comp.id)
            }
            isCustomizingStep={props.customizingStep == null ? false : true}
            onInfoClick={onInfoClick}
            disabled={
              props.showPlayground && comp.disabled ? comp.disabled : false
            }
            updateArrowDesc={(shapeId) => props.updateArrowDesc(shapeId)}
            updateArrowPoints={(data, tail, head) => {
              props.updatedPointsInArrow(data);
              if (head) {
                setArrowConnectorType({ shape: data, type: "head" });
              }
              if (tail) {
                setArrowConnectorType({ shape: data, type: "tail" });
              }
            }}
            showConnectors={arrowConnectorType == null ? false : true}
            updateConnectableDot={(connetableDotId) => {
              console.log("Update Connectable Dot ", connetableDotId);
              if (drawingArrowByDrag != null) {
                setDrawingArrowByDrag({
                  ...drawingArrowByDrag,
                  dotId: connetableDotId,
                });
              } else if (enableConnectorForShape.current != null) {
                enableConnectorForShape.current = {
                  ...enableConnectorForShape.current,
                  dotId: connetableDotId,
                };
              }
              //updateTempComponentsHeadDotId(connetableDotId);
            }}
          />
        );
      });
    }

    //setRenderedComps(results);
    // console.log("Rendered Components ", results);

    return results;
  };
  //   , [
  //   components,
  //   tempComponents,
  //   scale,
  //   onSelect,
  //   onDragStartShape,
  //   onDragMoveShape,
  //   onDragEndShape,
  //   onArrowDragEnd,
  //   onArrowDragMove,
  //   onToggleEdit,
  //   onDoubleClick,
  //   onTextChange,
  //   onResize,
  //   onClickArrowDraw,
  //   getConnectableDotId,
  //   arrowDotMoved,
  //   dragHoveringShapes,
  //   getPointerPosition,
  //   props.showPlayground,
  //   enableStageEditing,
  //   onInfoClick,
  //   props.updateArrowDesc,
  // ]);

  // React.useEffect(() => {
  //   setPreviouslyRenderedComponents(renderedComponents);
  // }, [renderedComponents]);

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const rightClickMenu = () => {
    if (show && !props.showPlayground) {
      return (
        <div
          style={{
            position: "fixed",
            top: anchorPoint.y,
            left: anchorPoint.x,
          }}
        >
          {rightClickOptions()}
        </div>
      );
    } else {
      return <></>;
    }
  };

  // const patternImage = new window.Image();
  // patternImage.src = 'https://source.unsplash.com/random/800x600';

  const createHorizontalRuler = () => {
    if (horizontallyAlignedRects.length > 0) {
      let minX = Infinity;
      let maxX = -Infinity;
      const dashedLinePattern = [5, 3]; // Adjust the pattern as desired

      let y =
        horizontallyAlignedRects[0].y + horizontallyAlignedRects[0].height / 2;
      horizontallyAlignedRects.forEach((rect) => {
        minX = Math.min(minX, rect.x) - 10;
        maxX = Math.max(maxX, rect.x + rect.width) + 10;
      });

      return (
        <Line
          key="hor-ruler"
          points={[minX, y, maxX, y]}
          stroke="blue"
          strokeWidth={0.5}
          dash={dashedLinePattern}
        ></Line>
      );
    }
  };

  const createVerticalRuler = () => {
    if (verticallyAlignedRects.length > 0) {
      let minY = Infinity;
      let maxY = -Infinity;
      const dashedLinePattern = [5, 1]; // Adjust the pattern as desired

      let x = verticallyAlignedRects[0].x + verticallyAlignedRects[0].width / 2;
      verticallyAlignedRects.forEach((rect) => {
        minY = Math.min(minY, rect.y) - 10;
        maxY = Math.max(maxY, rect.y + rect.height) + 10;
      });

      return (
        <Line
          key="ver-ruler"
          points={[x, minY, x, maxY]}
          stroke="blue"
          strokeWidth={0.5}
          dash={dashedLinePattern}
        ></Line>
      );
    }
  };

  React.useEffect(() => {
    const stage = document.getElementsByTagName("canvas")[0];
    stage.style.transform = "translateZ(0)";
  }, []);

  //   const getStageContainerWidth = () => {
  //      return stageRef.current  ? stageRef.current.width()  : maxX
  //   }

  //   const getStageContainerHeight = () => {
  //     return stageRef.current  ?  stageRef.current.height() : maxY
  //  }
  const closeButton = () => {
    return (
      <div
        className="button-type-1"
        style={{
          position: "fixed",
          right: "10px",
          top: "90px",
          marginTop: "12px",
          height: "32px",
          width: "101px",
          display: "flex", // Set display to flex
          alignItems: "center", // Vertically center the content
          justifyContent: "center",
          textAlign: "center",
          border: "1px solid blue", // Set the border color to blue
        }}
        onClick={props.closePlayground}
      >
        Close
      </div>
    );
  };

  const editingStageStyle = {
    width: window.innerWidth,
    height: window.innerHeight,
    marginLeft: "2px",
    marginTop: "2px",
    marginRight: "px",
    marginBottom: "2px",
    overflow: "auto",
    // border: "1px solid darkgray",
  };

  const viewingStageStyle = {
    width: window.innerWidth,
    height: window.innerHeight - bottomViewHeight(),

    overflow: "auto",
    border: "1px solid darkgray",
  };

  const stageStyle = () => {
    if (props.showPlayground) return viewingStageStyle;
    else return editingStageStyle;
  };

  const stageView = () => {
    return (
      // <div  ref={scrollRef} style={{ width: maxX, height: maxY, overflow: 'none' }}>
      <div key="parent-stage" ref={scrollRef} style={stageStyle()}>
        {/*     <div style={{ width: '100vw', height: '100vh', overflow: 'auto' }}>
         */}
        {/* <div ref={containerRef} style={{ width: (maxX * Math.max(scale,1)), height: (maxY* Math.max(scale,1)), overflow: 'auto',border: '2px solid red'}}> */}
        <Stage
          ref={stageRef}
          key="stage"
          onClick={checkDeselect}
          className="stage1"
          id="stage"
          x={0}
          y={0}
          width={maxX * Math.max(scale, 1)}
          height={maxY * Math.max(scale, 1)}
          resizeMode="repeat"
          scaleX={scale}
          scaleY={scale}
          draggable={false}
          //listening={enableStageEditing}
          style={
            ({ overflow: "scroll" },
            {
              backgroundColor:
                props.customizingStep != null ? "#D6E4EB" : "white",
            })
          }
          pixelRatio={1}
        >
          <Layer key="layer" ref={layerRef} style={{ overflow: "none" }}>
            {/* {renderedComponents()} */}
            {/* {typeof renderedComponents === "function" && renderedComponents()} */}
            {renderedComponents()}

            {createHorizontalRuler()}
            {createVerticalRuler()}
          </Layer>
          {isDragging && (
            <Layer name="selection-layer">{renderedSelection()}</Layer>
          )}
        </Stage>
        {/* </div> */}
      </div>
    );
  };

  const componentsPopUpAction = (action) => {
    if (action.type == "close") {
      setShowComponentPopup(false);
    } else if (action.type == "save") {
      props.addNewComponent("rect", action.data, false);
      setShowComponentPopup(false);
    }
  };

  const newComponentPopUpView = () => {
    if (showComponentPopup) {
      return (
        <ComponentsPopUp
          component={components}
          componentsPopUpAction={componentsPopUpAction}
        />
      );
    }
  };

  let methodCallCount = 0; // Counter variable

  // console.log("Rendering Stagee ", componentsRef.current);
  return (
    <div
      ref={viewRef}
      id="main-canvas-parent"
      style={{
        width: "100%",
        height: `calc(100% - ${bottomViewHeight()}px)`, // Subtract the footer height
        touchAction: "none",
        display: "flex",
      }}
    >
      <div
        tabIndex={0}
        ref={largeContinerRef}
        id="scroll-container"
        className="scroll-container"
        onKeyDown={downHandler}
        onKeyUp={upHandler}
        onMouseDown={handleMouseDown}
        style={{
          width: totalWidth,
          height: totalHeight - headerHeight - 4 - bottomViewHeight(),
        }}
      >
        {stageView()}
        {rightClickMenu()}
        {/* {props.showPlayground && closeButton()} */}
      </div>
      {/* {rightPanel()} */}
      {/* {newInputPopup()} */}
      {newComponentPopUpView()}
    </div>
  );
});
