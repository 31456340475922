import React from "react";

function ContactUsPage() {
  return (
    <div style={{ margin: "20px" }}>
      <header>
        {/* Include your website's header/navigation component here */}
      </header>
      <main>
        <section id="contact-us">
          <h1>Contact Us</h1>
          <p>
            We would love to hear from you! Feel free to get in touch with us
            using the following contact information:
          </p>

          <h2>Contact Information</h2>
          <ul>
            <li>
              <strong>Email:</strong> support@drawfuse.com
            </li>
            {/* <li>
              <strong>Phone:</strong> +1 (123) 456-7890
            </li>
            <li>
              <strong>Address:</strong> 123 Main Street, City, Country
            </li> */}
          </ul>

          {/* <h2>Contact Form</h2> */}
          {/* Include your contact form component here */}
        </section>
      </main>
      <footer>{/* Include your website's footer component here */}</footer>
    </div>
  );
}

export default ContactUsPage;
