import React, { useState } from "react";

const ConfirmationPopupView = ({ consentPopUp , onConfirmConfirmationPopup, onCancelConfirmationPopup }) => {
  

  const handleConfirm = () => {
    onConfirmConfirmationPopup(consentPopUp.action);
  };

  const handleCancel = () => {
    onCancelConfirmationPopup()
  };

  return  (
    <div className="confirmation-popup-container">
      <div className="confirmation-popup">
        <p>{consentPopUp.message}</p>
        <div className="confirmation-popup-buttons">
          <button className="confirmation-popup-confirm" onClick={handleConfirm}>
            {consentPopUp.confirmText}
          </button>
          <button className="confirmation-popup-cancel" onClick={handleCancel}>
            {consentPopUp.cancelText}
          </button>
        </div>
      </div>
    </div>
  ) 
};

export default ConfirmationPopupView;

