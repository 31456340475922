import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
} from "react";
import SettingIcon from "../res/setting.png";
import "../CSS/App.css";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import { EditText, EditTextarea } from "react-edit-text";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import Images from "../Models/Images";
import Accordion from "react-bootstrap/Accordion";
import Dropdown from "react-bootstrap/Dropdown";
import { CustomMenu } from "../Helper/SearchableDropDown";
import { CustomToggle } from "../Helper/SearchableDropDown";
import TextFormatDropdown from "./TextFormatDropdown";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { Label } from "react-konva";
import SearchBox from "../Views/SearchBox";
import SelectVariables from "../Views/SelectVariables";
import FlowsDropdown from "./FlowsDropdown";
import KeyValueForm from "./KeyValueForm";
import MultiselectDropdown from "./MultiselectDropdown";

//export const RightPanel = ({ handleRightPanelAction, style, initialFlows, components}) => {
export const RightPanel = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [animationStep, setAnimationStep] = useState(0);
  const [animatingFlowId, setAnimatedFlowId] = useState(null);
  const [selectedFlow, setSelectedFlow] = useState(null);
  const [tabValue, setTabValue] = React.useState("flow");
  const [flows, setFlows] = useState(props.initialFlows);
  const [components, setComponents] = useState(props.components);
  const [update, setUpdate] = useState(0);
  const [searchFlowInput, setSearchFlowInput] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const flowsDropdownRef = useRef(null);
  const keyValueRef = useRef(null);
  const [activeTab, setActiveTab] = useState("tab1");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useImperativeHandle(ref, () => ({
    updateFlows(flows) {
      updateFlows(flows);
    },
    updateComonents(comps) {
      updateComonents(comps);
      setUpdate(update + 1);
    },
    updateSelectedFlow(flowId) {
      updateSelectedFlow(flowId);
    },
    updateEditingStep(stepId) {
      updateEditingStep(stepId);
    },
    updateView() {
      updateView();
    },
    stopPreviewAnimation() {
      stopPreviewAnimation();
    },
  }));

  const stopPreviewAnimation = () => {
    flowsDropdownRef.current.stopPreviewAnimation();
  };

  useEffect(() => {
    setComponents(props.components);
  }, [props.components]);

  const updateView = () => {
    setUpdate(update + 1);
  };

  const getSelectedComponents = () => {
    let selectedComp = props.getSelectedComponents();

    return selectedComp;
  };

  const updateEditingStep = (stepId) => {
    flowsDropdownRef.current.updateEditingStep(stepId);
  };

  const updateComonents = (comps) => {
    setComponents(comps);
  };

  const updateFlows = (flows) => {
    setFlows(flows);
    let selectedFlow = flows.find((flow) => flow.selected);

    if (selectedFlow == null && flows != null && flows.length > 0) {
      setSelectedFlow(selectedFlow);
      flowsDropdownRef.current.updateAllFlowsWithSelectedFlow(
        flows,
        selectedFlow.id
      );
      // flowsDropdownRef.current.updateAllFlows(flows)
      // flowsDropdownRef.current.updateSelectedFlow(flows[0].id)
    } else {
      flowsDropdownRef.current.updateAllFlows(flows);
    }
    setUpdate(update + 1);
  };

  const addFlow = (type) => {
    props.handleRightPanelAction(type);
  };

  const addStep = (type) => {
    props.handleRightPanelAction(type);
  };

  const toggleFlow = (flow) => {};

  const stepJSX = (flow) => {
    let steps = flow.steps;
    var stepsData = [];
    if (steps) {
      steps.forEach((step, index) => {
        stepsData.push(
          <div style={{ marginLeft: "20px" }}>
            <Card style={{ padding: "20px" }}>
              <div style={{ width: "100%", display: "flex" }}>
                {/* <label>{index + 1 + ". "}</label> */}
                <br />
                <TextareaAutosize
                  className="select-box-no-border"
                  placeholder="Select Title"
                  style={{
                    textAlignLast: "left",
                    resize: "none",
                    paddingLeft: "20px",
                  }}
                  value={step.title}
                ></TextareaAutosize>
              </div>
              <br />
              <div>
                <Label>From: </Label>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{selectComponentDropDown(flow, step, "from")}</div>

                  <div
                    className="select-box"
                    onClick={() =>
                      props.handleRightPanelAction({
                        type: "createStartBox",
                        step: step,
                        flowId: flow.id,
                      })
                    }
                  >
                    <img
                      style={{ width: 20, height: 20 }}
                      src={Images.plus_icon}
                    />
                  </div>

                  <div
                    className="select-box"
                    onClick={() =>
                      props.handleRightPanelAction({
                        type: "startBox",
                        step: step,
                        flowId: flow.id,
                      })
                    }
                  >
                    <img
                      style={{ width: 20, height: 20 }}
                      src={Images.select_icon}
                    />
                  </div>
                </div>
                {/* <label>{" ----> "}</label> */}
                <Label>To: </Label>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{selectComponentDropDown(flow, step, "to")}</div>

                  <div
                    className="select-box"
                    onClick={() =>
                      props.handleRightPanelAction({
                        type: "createEndBox",
                        step: step,
                        flowId: flow.id,
                      })
                    }
                  >
                    <img
                      style={{ width: 20, height: 20 }}
                      src={Images.plus_icon}
                    />
                  </div>

                  <div
                    className="select-box"
                    onClick={() =>
                      props.handleRightPanelAction({
                        type: "endBox",
                        step: step,
                        flowId: flow.id,
                      })
                    }
                  >
                    <img
                      style={{ width: 20, height: 20 }}
                      src={Images.select_icon}
                    />
                  </div>
                </div>

                <div />
              </div>
              <br />
              <label>{"Description"}</label>
              <TextareaAutosize
                className="select-box"
                minRows={3}
                style={{
                  width: "90%",
                  textAlignLast: "left",
                  resize: "none",
                }}
                placeholder="Enter description"
              ></TextareaAutosize>
            </Card>
            <br />
          </div>
        );
      });
    }
    return stepsData;
  };

  const createNewComponentFromStep = (flow, selectedVarianrId, step, type) => {
    if (type == "from") {
      props.handleRightPanelAction({
        type: "createStartBox",
        step: step,
        flowId: flow.id,
        selectedVarianrId: selectedVarianrId,
      });
    }

    if (type == "to") {
      props.handleRightPanelAction({
        type: "createEndBox",
        step: step,
        flowId: flow.id,
        selectedVarianrId: selectedVarianrId,
      });
    }
  };

  const updateFlowTriggerCode = (flowId, selectedVarianrId, code) => {
    props.handleRightPanelAction({
      type: "update_flow_trigger_code",
      code: code,
      flowId: flowId,
      selectedVarianrId: selectedVarianrId,
    });
  };

  const updateTriggerCode = (flowId, selectedVarianrId, stepId, compType) => {
    props.handleRightPanelAction({
      type: "update_step_trigger_code",
      stepId: stepId,
      flowId: flowId,
      compType: compType,
      selectedVarianrId: selectedVarianrId,
    });
  };

  const addRemoveChildFlow = (selectedFlow, selectedVarianrId, step) => {
    props.handleRightPanelAction({
      type: "add_remove_child_flow",
      step: step,
      flowId: selectedFlow.id,
      selectedVarianrId: selectedVarianrId,
    });
  };

  const opneVariantsAction = (selectedFlow, selectedVariant) => {
    props.handleRightPanelAction({
      type: "open_variants_action",
      flowId: selectedFlow.id,
      selectedVariant: selectedVariant,
    });
  };

  const createNewVariant = (selectedFlow, newVariantId) => {
    props.handleRightPanelAction({
      type: "create_variants_action",
      flowId: selectedFlow.id,
      newVariantId: newVariantId,
    });
  };

  const updateComponentInStep = (
    flow,
    selectedVarianrId,
    compId,
    step,
    type
  ) => {
    console.log(compId, step, type);
    let boxType = type == "from" ? "start" : "end";
    props.handleRightPanelAction({
      type: "updateComponentInStep",
      data: {
        type: boxType,
        step: step,
        modelId: compId,
        flowId: flow.id,
        selectedVarianrId: selectedVarianrId,
      },
    });
  };

  const updateStepType = (flow, selectedVarianrId, step, stepType) => {
    props.handleRightPanelAction({
      type: "updateStepType",
      data: {
        stepType: stepType,
        step: step,
        flowId: flow.id,
        selectedVarianrId: selectedVarianrId,
      },
    });
  };

  const updateStepDescription = (
    flow,
    selectedVarianrId,
    step,
    description
  ) => {
    props.handleRightPanelAction({
      type: "updateStepDescription",
      data: {
        description: description,
        step: step,
        flowId: flow.id,
        selectedVarianrId: selectedVarianrId,
      },
    });
  };

  const createNewStep = (flow, selectedVarianrId, index) => {
    props.handleRightPanelAction({
      type: "addStep",
      data: {
        flowId: flow.id,
        selectedVarianrId: selectedVarianrId,
        index: index,
      },
    });
  };

  const deleteStep = (step, selectedVarianrId, flow) => {
    console.log("DeleteStep");
    props.handleRightPanelAction({
      type: "deleteStep",
      data: {
        stepId: step.id,
        flowId: flow.id,
        selectedVarianrId: selectedVarianrId,
      },
    });
  };

  const playAnimation = (flow, selectedVarianrId, stepNumber) => {
    props.handleRightPanelAction({
      type: "play_flow",
      flow: flow,
      stepNumber: stepNumber,
      selectedVarianrId: selectedVarianrId,
    });
  };

  const stopAnimation = () => {
    props.handleRightPanelAction({
      type: "stop_flow",
    });
  };

  const moveStepUp = (stepId, selectedVarianrId, flowId) => {
    props.handleRightPanelAction({
      type: "move_step_up",
      data: {
        stepId: stepId,
        flowId: flowId,
        selectedVarianrId: selectedVarianrId,
      },
    });
  };

  const cutomizeDiagramForStep = (stepId, flowId, selectedVarianrId, state) => {
    props.handleRightPanelAction({
      type:
        state == true
          ? "customize_diagram_for_step"
          : "stop_customize_diagram_for_step",
      data: {
        stepId: stepId,
        flowId: flowId,
        selectedVarianrId: selectedVarianrId,
      },
    });
  };

  const moveStepDown = (stepId, selectedVarianrId, flowId) => {
    props.handleRightPanelAction({
      type: "move_step_down",
      data: {
        stepId: stepId,
        flowId: flowId,
        selectedVarianrId: selectedVarianrId,
      },
    });
  };

  const deleteFlow = (flowId) => {
    props.handleRightPanelAction({
      type: "delete_flow",
      data: { flowId: flowId },
    });
  };

  const renameFlow = (flow) => {
    props.handleRightPanelAction({
      type: "rename_flow",
      data: { flowId: flow.id, label: flow.title, flowDesc: flow.desc },
    });
  };

  const viewFlows = () => {
    props.handleRightPanelAction({
      type: "run_flow_start",
    });
  };

  const updateSelectedFlowGlobally = (flow) => {
    props.handleRightPanelAction({
      type: "update_selected_flow",
      data: { flow: flow },
    });
  };

  const selectComponentDropDown = (flow, step, type) => {
    return (
      <Dropdown
        onSelect={(compId) => updateComponentInStep(flow, compId, step, type)}
      >
        <Dropdown.Toggle
          as={CustomToggle}
          id="dropdown-custom-components"
          className="btn btn-primary dropdown-toggle"
        >
          {type == "from"
            ? step.from != null && step.from.id != null
              ? props.components
                  .filter((component) => component.id === step.from.id)
                  .map((component) => component.text)
              : "Select"
            : step.to != null && step.to.id != null
            ? props.components
                .filter((component) => component.id === step.to.id)
                .map((component) => component.text)
            : "Select"}
        </Dropdown.Toggle>
        <Dropdown.Menu as={CustomMenu}>
          {selectComponentDropDownData(flow, step, components)}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const selectComponentDropDownData = (flow, step, components) => {
    var componentsData = [];
    if (components) {
      components.forEach((comp, index) => {
        componentsData.push(
          <Dropdown.Item key={comp.id} eventKey={comp.id}>
            {comp.text}
          </Dropdown.Item>
        );
      });
    }
    return componentsData;
  };

  const updateSelectedFlow = (flowid) => {
    flowsDropdownRef.current.updateSelectedFlow(flowid);
  };

  const flowComponent1 = () => {
    return (
      <div style={{ width: "100%", height: "100%" }}>
        <FlowsDropdown
          flows={flows}
          ref={flowsDropdownRef}
          components={components}
          style={{ overflow: "auto", height: "100%" }}
          updateSelectedFlowGlobally={updateSelectedFlowGlobally}
          createNewFlowAction={() => {
            addFlow({ type: "flow" });
          }}
          createNewComponentFromStep={createNewComponentFromStep}
          updateComponentInStep={updateComponentInStep}
          updateFlowTriggerCode={updateFlowTriggerCode}
          updateStepType={updateStepType}
          updateStepDescription={updateStepDescription}
          createNewStep={createNewStep}
          deleteStep={deleteStep}
          playAnimation={playAnimation}
          moveStepUp={moveStepUp}
          cutomizeDiagramForStep={cutomizeDiagramForStep}
          moveStepDown={moveStepDown}
          deleteFlow={deleteFlow}
          updateTriggerCode={updateTriggerCode}
          addRemoveChildFlow={addRemoveChildFlow}
          opneVariantsAction={opneVariantsAction}
          createNewVariant={createNewVariant}
          renameFlow={renameFlow}
          viewFlows={viewFlows}
          customizingStep={props.customizingStep}
        />
      </div>
    );
  };

  const onEditReference = () => {
    props.handleRightPanelAction({ type: "edit_reference" });
  };

  // const referenceCardView = (comp) => {
  //   return (
  //     <div style={{ margin: "20px" }}>
  //       <div className="ReferenceCardView__Container">
  //         <div className="ReferenceCardView__Header">
  //           <div className="ReferenceCardView__Title">{"Reference"}</div>
  //           <div
  //             className="ReferenceCardView__EditButton"
  //             onClick={() => onEditReference(comp[0].id)}
  //           >
  //             Edit
  //           </div>
  //         </div>
  //         <div className="ReferenceCardView__Content">
  //           {/* <div className="ReferenceCardView__Description">{label}</div> */}
  //           <div
  //             className="ReferenceCardView__Button"
  //             onClick={() => onEditReference(comp[0].id)}
  //           >
  //             {comp[0].isInstanceOf ? comp[0].isInstanceOf.title : "Select"}
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  const handleNewKeyValueSave = (info) => {
    console.log("Saving new Key Value Pair");
    let selected = getSelectedComponents();
    if (selected.length == 1) {
      let selectedComponent = selected[0];
      props.handleRightPanelAction({
        type: "saveComponentInfo",
        componentId: selectedComponent.id,
        info: info,
      });
    }
  };

  const updateTag = (tag) => {
    console.log("Update Tag ", tag);
    let selectedComp = getSelectedComponents();
    console.log("selectedComp ", selectedComp);
    if (selectedComp && selectedComp.length == 1) {
      let selectedComponent = selectedComp[0];
      props.handleRightPanelAction({
        type: "savComponentTag",
        componentId: selectedComponent.id,
        tag: tag,
      });
    }
  };

  const availableTags = [
    { type: "JAVA", title: "Java Service", iconName: "java.webp" },
    { type: "DB", title: "Database", iconName: "db.webp" },
    { type: "KAFKA_TOPIC", title: "Kafka Topic", iconName: "kafka-topic.webp" },
    {
      type: "KAFKA_PRODUCER",
      title: "Kafka Producer",
      iconName: "kafka-producer.webp",
    },
    {
      type: "KAFKA_CONSUMER",
      title: "Kafka Consumer",
      iconName: "kakfa-consumer.webp",
    },
    { type: "API_GATEWAY", title: "API Gateway", iconName: "api_gateway.webp" },
    { type: "KUBE", title: "Kube", iconName: "kube.webp" },
    { type: "SQL", title: "SQL", iconName: "sql.webp" },
    { type: "PYTHON", title: "Python", iconName: "python.webp" },
    { type: "NODE", title: "Nodejs", iconName: "nodejs.webp" },
    { type: "REDIS", title: "Redis", iconName: "redis.webp" },
    { type: "MONGO", title: "Mongo", iconName: "mongo.webp" },
  ];

  const variableSelectView = () => {
    if (isSingleRectSelected()) {
      let selectedComponent = getSelectedComponents()[0];
      console.log("Selected ", selectedComponent);
      // console.log("Updating variableSelectView ", selectedComponent);
      return selectedComponent ? (
        <div style={{ height: "100%" }}>
          <h4
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "8px",
              // paddingBottom: "6px",
              paddingBottom: "12px",
              // marginTop: "20px",
              // marginLeft: "20px",
              // marginRight: "20px",
              background: "#F0F0F0",
              // border: "1px solid gray",
              // borderRadius: "8px",
              fontFamily: "Roboto, sans-serif",
              fontWeight: 600,
              fontSize: "16px",
            }}
          >
            {selectedComponent.text}
          </h4>

          <MultiselectDropdown
            options={availableTags}
            selectedItems={selectedComponent.tags}
            onSelect={updateTag}
          />
          <div
            style={{
              background: "#d4ebf2",
              borderRadius: "16px",
              marginLeft: "40px",
              marginRight: "40px",
              marginTop: "20px",
              padding: "4px",
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "10px",
              color: "black",
              boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)", // Add shadow here
            }}
          >
            Add information about the component
          </div>

          <div
            style={{
              marginLeft: "8px",
              marginRight: "8px",
              marginTop: "4px",
              padding: "4px",
              textAlign: "center",
              fontSize: "9px",
              color: "black",
            }}
          >
            You can add info for a component. it can be Purpose, DB Schema,
            sample object, or any other information which viewers can view to
            understand the component better.
          </div>

          {!selectedComponent.info ||
            (selectedComponent.info && selectedComponent.info.length == 0 && (
              <div>
                <div className="warning-message">
                  No details added yet. Click new button to start adding
                </div>

                {/* <div class="key-container container">
                  <div class="key-line line"></div>
                  <div class="key-button-container button-container">
                    <button
                      onClick={() => {
                        keyValueRef.current.addRow();
                      }}
                      class="key-button button"
                    >
                      Add
                    </button>
                  </div>
                </div> */}
              </div>
            ))}
          <div
            id="key-value-card-parent"
            style={{
              margin: "20px",
              overflow: "scroll",
              height: `calc(100% - 80px)`,
            }}
          >
            {/* <div className="ReferenceCardView__Container"> */}
            {/* <SelectVariables items={[{"id":"1","name":"hgchg"}]} onSelection={() => {}} /> */}

            <KeyValueForm
              ref={keyValueRef}
              initialValues={selectedComponent.info || []}
              onSave={handleNewKeyValueSave}
            />
            {/* </div> */}
          </div>
        </div>
      ) : (
        <div className="warning-message">
          Please choose atleast one component to add details
        </div>
      );
    } else {
      return (
        <div className="warning-message">
          Please choose atleast one component to add details
        </div>
      );
    }
  };

  const isSingleRectSelected = () => {
    let selected = getSelectedComponents();
    return (
      selected != null &&
      selected.length == 1 &&
      (selected[0].type == "rect" || selected[0].type == "image")
    );
  };

  const handleStyleChange = (style) => {
    props.handleRightPanelAction({
      type: "style",
      style: style,
    });
  };

  const moreView = () => {
    return (
      <div className="more-view-right-panel">
        {/* {isSingleRectSelected() && referenceCardView(getSelectedComponents())} */}

        {variableSelectView()}
      </div>
    );
  };

  const getCurrentSelectedComponentsStyle = () => {
    if (isSingleRectSelected()) {
      let selected = getSelectedComponents()[0];
      // console.log("Selected Component Right ", selected.text);
      return selected.style;
    }
  };

  const formatView = () => {
    return getSelectedComponents() && getSelectedComponents().length > 0 ? (
      <div style={{ height: "100%" }}>
        <TextFormatDropdown
          currentStyle={getCurrentSelectedComponentsStyle()}
          handleStyleChange={handleStyleChange}
        />
      </div>
    ) : (
      <div className="warning-message">
        Please choose atleast one component to update style
      </div>
    );
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div style={{ height: window.innerHeight }}>
      <TabContext style={{ height: "100%" }} value={tabValue}>
        <TabList onChange={handleChange} aria-label="">
          <Tab
            className={tabValue == "flow" ? "tab" : "tab-active"}
            value="flow"
            label="Flows"
            style={{
              fontWeight: "bold",
              color: tabValue == "flow" ? "#4C3FDC" : "black",
              backgroundColor: tabValue == "flow" ? "#4C3FDC22" : "white",
            }}
          />
          <Tab
            className={tabValue == "format" ? "tab" : "tab-active"}
            value="format"
            label="Format"
            style={{
              fontWeight: "bold",
              color: tabValue == "format" ? "#4C3FDC" : "black",
              backgroundColor: tabValue == "format" ? "#4C3FDC22" : "white",

              // pointerEvents: props.customizingStep ? "none" : "auto", // Disable interaction
              opacity: props.customizingStep ? 0.5 : 1, // Adjust opacity to indicate disabled state
            }}
          />
          <Tab
            className={tabValue == "more" ? "tab" : "tab-active"}
            value="more"
            label="Info"
            style={{
              fontWeight: "bold",
              color: tabValue == "more" ? "#4C3FDC" : "black",
              backgroundColor: tabValue == "more" ? "#4C3FDC22" : "white",

              // pointerEvents: props.customizingStep ? "none" : "auto", // Disable interaction
              opacity: props.customizingStep ? 0.5 : 1, // Adjust opacity to indicate disabled state
            }}
          />
        </TabList>

        <div style={{ height: "1px", background: "lightgray" }}></div>
        <TabPanel
          className="muitabpanel-root-custom"
          style={{ height: "100%" }}
          value="format"
          sx={{ p: 0, m: 0 }}
        >
          {formatView()}
        </TabPanel>
        <TabPanel
          className="muitabpanel-root-custom"
          style={{ height: "100%" }}
          value="flow"
          sx={{ p: 0, m: 0 }}
        >
          {flowComponent1()}
        </TabPanel>
        <TabPanel
          className="muitabpanel-root-custom"
          style={{ height: "100%" }}
          value="more"
          sx={{ p: 0, m: 0 }}
        >
          {moreView()}
        </TabPanel>
      </TabContext>
    </div>
  );
});
