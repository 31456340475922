import { useState, useEffect } from 'react';

function SearchTriggerCode({flowId, stepId, childFlows, onCloseTriggerCodeSelection, onSelectTriggerCode }) {
  const [query, setQuery] = useState('');

  function handleQueryChange(event) {
    setQuery(event.target.value);
  }

  const getTopTriggerCodes = () => {
    let triggerCodes = []
    childFlows.map((f) => {
        if (f.triggerCode != null) {
            triggerCodes.push(f.triggerCode)
        }
    })
    return triggerCodes
  }

const filterTriggerCodes = () => {
    const queryRegex = new RegExp(
      query.replace(/[\W_]+/g, '')
        .split('')
        .map(c => `${c}.*`)
        .join(''),
      'i'
    );
    const filtered = childFlows
      .filter((flow) => flow.triggerCode && queryRegex.test(flow.triggerCode))
      .map((flow) => flow.triggerCode);
    return filtered.slice(0, 5);
  };
  

  return (
    <div className="SearchChildComp__Overlay">
      <div className="SearchChildComp">
        <div className="SearchChildComp__InputWrapper">
          <input
            placeholder="Search"
            value={query}
            onChange={handleQueryChange}
            className="SearchChildComp__Input"
          />
        </div>
        {query && (
          <ul className="SearchChildComp__Results">
            {filterTriggerCodes().map((triggerCode) => (
              <div key={triggerCode} className="SearchChildComp__ResultCard" onClick={() => onSelectTriggerCode(flowId,stepId, triggerCode)}>
                <div className="SearchChildComp__ResultCardTitle">{triggerCode}</div>
              </div>
            ))}
            {filterTriggerCodes().length === 0 && (
              <div className="SearchChildComp__Empty">
                <p>No results found</p>
              </div>
            )}
          </ul>
        )}
        {!query && (
          <ul className="SearchChildComp__Results">
            {getTopTriggerCodes().slice(0, 5).map((triggerCode) => (
              <div key={triggerCode} className="SearchChildComp__ResultCard" onClick={() => onSelectTriggerCode(flowId, stepId, triggerCode)}>
                <div className="SearchChildComp__ResultCardTitle">{triggerCode}</div>
              </div>
            ))}
            {getTopTriggerCodes().length === 0 && (
              <div className="SearchChildComp__Empty">
                <p>No results found</p>
              </div>
            )}
          </ul>
        )}
        <button className="SearchChildComp__CloseButton" onClick={onCloseTriggerCodeSelection}>
          Close
        </button>
      </div>
    </div>
  );
}

export default SearchTriggerCode;
