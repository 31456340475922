// export const createDiagram = (jsonData, userId) => {

import { getCookie, deleteCookie, updateLoginStatus } from "./CookieManager";

// let baseUrl = "http://localhost:5001";
let baseUrl = "https://api.drawfuse.com";
// let baseUrl = "https://13.232.73.250:5001";
// let baseUrl = "https://quiz-server-env.eba-xdmnkaxx.ap-south-1.elasticbeanstalk.com"

export const login = async (token) => {
  const res = await fetch(baseUrl + "/draw-auth-service/ve1/google-login", {
    headers: new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "https://drawfuse.com",
      "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
      "Access-Control-Allow-Headers":
        "Content-Type, Authorization, X-Requested-With",
    }),
    method: "POST",
    mode: "cors",
    body: JSON.stringify({ token: token }),
  });
  const resJson = await res.json();
  return resJson;
};

const fetchData = async (url, options) => {
  if (getCookie("acessToken")) {
    const commonHeaders = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
      "Access-Control-Allow-Headers":
        "Content-Type, Authorization, X-Requested-With",
      accessToken: getCookie("acessToken"),
      userId: getCookie("userId"),
    };

    const mergedOptions = {
      ...options,
      mode: "cors",
      headers: {
        ...commonHeaders,
        ...options.headers,
      },
    };

    const res = await fetch(baseUrl + url, mergedOptions);

    if (res.status == 401) {
      deleteCookie("acessToken");
      deleteCookie("email");
      deleteCookie("userId");
      updateLoginStatus(false);
      // setIsLoggedIn(false);
    } else if (res.status == 404) {
      const data = await res.json();
      debugger;
      const errorMessage =
        data.errors && data.errors.length > 0 ? data.errors[0].message : "";
      if (errorMessage === "User not found") {
        deleteCookie("acessToken");
        deleteCookie("email");
        deleteCookie("userId");
        updateLoginStatus(false);
      }
    }

    if (res.status != 200) {
      const resJson = await res.json();
      if (resJson.errors && resJson.errors.length > 0) {
        let errorMessage = resJson.errors[0].message;

        const event = new CustomEvent("showErrorPopup", {
          detail: { errorMessage },
        });
        window.dispatchEvent(event);
      }
    }
    const resJson = await res.json();
    return resJson;
  } else {
    deleteCookie("acessToken");
    deleteCookie("email");
    deleteCookie("userId");
    return null;
  }
};

export const uploadFileToServer = async (file) => {
  try {
    const formData = new FormData();
    formData.append("image", file);
    console.log(file);
    const commonHeaders = {
      // "Content-Type": "multipart/form-data",
      // "Access-Control-Allow-Origin": "*",
      // "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
      // "Access-Control-Allow-Headers":
      //   "Content-Type, Authorization, X-Requested-With",
      accessToken: getCookie("acessToken"),
      userId: getCookie("userId"),
    };

    const mergedOptions = {
      method: "POST",
      body: formData,
      headers: commonHeaders,
      // Add other headers or configurations as needed
    };

    const res = await fetch(
      baseUrl + "/utility-service/ve1/upload-image",
      mergedOptions
    );
    console.log("File uploaded successfully:", res.data);
  } catch (error) {
    console.error("Error uploading file:", error);
  }
};

export const createDiagram = async (jsonData, userId) => {
  const options = {
    method: "POST",
    body: JSON.stringify(jsonData),
    headers: {
      userId: userId,
    },
  };

  return fetchData("/utility-service/ve1/create-new-diagram", options);
};

export const delistItem = async (diagramId, userId) => {
  const options = {
    method: "POST",
    headers: {
      userId: userId,
      diagramId: diagramId,
    },
  };

  return fetchData("/utility-service/ve1/delist-diagram", options);
};

export const saveDiagram = async (jsonData, userId) => {
  jsonData["lastUpdatedBy"] = getCookie("email", false);

  const options = {
    method: "POST",
    // mode: "cors",
    body: JSON.stringify(jsonData),
    headers: {
      userId: userId,
    },
  };

  return fetchData("/utility-service/ve1/save-diagram", options);
};

export const updateDiagram = async (jsonData, userId) => {
  const options = {
    method: "POST",
    // mode: "cors",
    body: JSON.stringify(jsonData),
    headers: {
      userId: userId,
    },
  };

  return fetchData("/utility-service/ve1/update-diagram", options);
};

export const deleteDiagram = async (diagramId, userId) => {
  const options = {
    method: "GET",
    // mode: "cors",
    // body: JSON.stringify(jsonData),
    headers: {
      diagramId: diagramId,
    },
  };

  return fetchData("/utility-service/ve1/delete-diagram", options);
};

export const saveFlows = async (jsonData, userId, diagramId) => {
  const options = {
    method: "POST",
    // mode: "cors",
    body: JSON.stringify(jsonData),
    headers: {
      diagramId: diagramId,
    },
  };

  return fetchData("/utility-service/ve1/save-flows", options);
};

export const updateFlows = async (jsonData, userId, diagramId) => {
  const options = {
    method: "POST",
    // mode: "cors",
    body: JSON.stringify(jsonData),
    headers: {
      diagramId: diagramId,
    },
  };

  return fetchData("/utility-service/ve1/update-flows", options);
};

export const getDiagrams = async (userId, pageNo, pageSize) => {
  // const url = `${baseUrl}/utility-service/ve1/get-diagrams?page=${pageNo}&size=${pageSize}`;

  // const res = await fetch(url, {
  //   method: "GET",
  //   mode: "cors",
  //   headers: {
  //     userId: userId,
  //     "Content-Type": "application/json",
  //   },
  // });

  // const resJson = await res.json();
  // return resJson;

  const options = {
    method: "GET",
    // mode: "cors",
    // body: JSON.stringify(jsonData),
    // headers: {
    //   userId: userId,
    // },
  };

  return fetchData(
    `/utility-service/ve1/get-diagrams?page=${pageNo}&size=${pageSize}`,
    options
  );
};

export const getAllAcessesForDiagram = async (diagramId) => {
  const options = {
    method: "GET",
    // mode: "cors",
  };

  return fetchData(
    `/diagram-sharing-service/ve1/get-diagrams-access?diagramId=${diagramId}`,
    options
  );
};

export const searchDiagrams = async (userId, query, page, size) => {
  const options = {
    method: "GET",
    // mode: "cors",
  };

  return fetchData(
    `/utility-service/ve1/search-diagrams?searchString=${encodeURIComponent(
      query
    )}&page=${page}&size=${size}`,
    options
  );
};

export const getDiagramDetails = async (userId, diagramId) => {
  const options = {
    method: "GET",
    // mode: "cors",
    headers: {
      diagramId: diagramId,
    },
  };

  return fetchData("/utility-service/ve1/get-diagrams-detail", options);
};

export const getFlows = async (userId, diagramId) => {
  const options = {
    method: "GET",
    // mode: "cors",
    headers: {
      diagramId: diagramId,
    },
  };

  return fetchData("/utility-service/ve1/get-flows", options);
};

export const makeDiagramPublic = async (publicAccess, diagramId) => {
  const requests = {
    diagramId: diagramId,
    allowPublicView: publicAccess,
  };
  const options = {
    method: "POST",
    body: JSON.stringify(requests),
    headers: {
      diagramId: diagramId,
    },
  };

  return fetchData(
    "/diagram-sharing-service/ve1/update-public-view-access",
    options
  );
};

export const updateUserAccess = async (userId, diagramId, emailIds, access) => {
  const requests = emailIds.map((email) => ({
    email: email,
    diagramId: diagramId,
    accessLevel: access,
  }));

  // const res = await fetch(
  //   baseUrl + "/diagram-sharing-service/ve1/update-access",
  //   {
  //     method: "POST",
  //     mode: "cors",
  //     body: JSON.stringify(requests),
  //     headers: new Headers({
  //       userId: userId,
  //       accessToken: "tes",
  //       diagramId: diagramId,
  //       "Content-Type": "application/json",
  //     }),
  //   }
  // );
  // const resJson = await res.json();
  // return resJson;

  const options = {
    method: "POST",
    // mode: "cors",
    body: JSON.stringify(requests),
    headers: {
      diagramId: diagramId,
    },
  };

  return fetchData("/diagram-sharing-service/ve1/update-access", options);
};

export const getUserAccess = async (userId, diagramId) => {
  // const res = await fetch(
  //   baseUrl + "/diagram-sharing-service/ve1/check-access",
  //   {
  //     method: "GET",
  //     mode: "cors",
  //     headers: new Headers({
  //       userId: userId,
  //       accessToken: "test",
  //       diagramId: diagramId,
  //       "Content-Type": "application/json",
  //     }),
  //   }
  // );
  // const resJson = await res.json();
  // return resJson;

  const options = {
    method: "GET",
    // mode: "cors",
    headers: {
      diagramId: diagramId,
    },
  };

  return fetchData("/diagram-sharing-service/ve1/check-access", options);
};

export const requestAccess = async (userId, diagramId) => {
  const options = {
    method: "GET",
    // mode: "cors",
    headers: {
      diagramId: diagramId,
    },
  };

  return fetchData("/diagram-sharing-service/ve1/request-access", options);
};

export const getAccessRequestDetails = async (userId, requestId, diagramId) => {
  const requests = {
    requestId: requestId,
    diagramId: diagramId,
  };
  // const res = await fetch(
  //   baseUrl + "/diagram-sharing-service/ve1/access-request-detail",
  //   {
  //     method: "POST",
  //     mode: "cors",
  //     body: JSON.stringify(requests),
  //     headers: new Headers({
  //       userId: userId,
  //       accessToken: "test",
  //       diagramId: diagramId,
  //       "Content-Type": "application/json",
  //     }),
  //   }
  // );
  // const resJson = await res.json();
  // return resJson;

  const options = {
    method: "POST",
    // mode: "cors",
    body: JSON.stringify(requests),
    headers: {
      diagramId: diagramId,
    },
  };

  return fetchData(
    "/diagram-sharing-service/ve1/access-request-detail",
    options
  );
};
