import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Form from "react-bootstrap/Form";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import MenuItem from "@mui/material/MenuItem";

const MultiselectDropdown = ({ options, selectedItems, onSelect }) => {
  const [selectedTags, setSelectedTags] = useState(selectedItems || []);

  useEffect(() => {
    console.log("useEffect ", selectedItems);

    if (selectedItems) {
      setSelectedTags(selectedItems);
    } else {
      setSelectedTags([]);
    }
  }, [selectedItems]);

  const handleSelect = (eventKey) => {
    const item = eventKey;

    console.log("selectedTags ", selectedTags);
    if (selectedTags && selectedTags.includes(item)) {
      let tags = selectedTags.filter((selectedItem) => selectedItem !== item);
      tags = tags.filter((tag) => typeof tag === "string");
      setSelectedTags(tags);
      onSelect(tags);
    } else {
      let tags = [...selectedTags, item];
      tags = tags.filter((tag) => typeof tag === "string");
      setSelectedTags(tags);
      onSelect(tags);
    }
  };

  const getTitle = (type) => {
    console.log("type ", type);
    const option = options.find((option) => option.type === type);
    return option ? option.title : "";
  };

  const dropdownButtonStyle = {
    width: "200px",
    backgroundColor: "#007bff",
    color: "white",
  };

  const dropdownMenuStyle = {
    width: "100%",
  };

  const dropdownItemStyle = {
    display: "flex",
    alignItems: "center",
  };

  const formCheckLabelStyle = {
    marginLeft: "0.5rem",
  };

  const componentSelectDropDown = () => {
    return (
      <div
        className="search-dropdown-list-container"
        style={{
          zIndex: 2,
          backgroundColor: "white",
          borderRadius: "4px",
          boxShadow:
            "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08)",
        }}
      >
        {options &&
          options.length > 0 &&
          options.text != "" &&
          options.map((option) => {
            <MenuItem key={option.type} value={option.title}>
              {option.title}
            </MenuItem>;
          })}
      </div>
    );
  };

  return (
    <div>
      {componentSelectDropDown()}
      <Form.Group>
        <Form.Label style={{ marginLeft: "12px", marginRight: "24px" }}>
          Choose Tags:{" "}
        </Form.Label>

        <DropdownButton
          title="Select Tags"
          className="d-inline mx-2"
          class="email-bubble"
          id="dropdown-menu-align-right"
          onSelect={handleSelect}
          style={dropdownButtonStyle}
        >
          {options.map((option) => (
            <Dropdown.Item
              as="button"
              key={option.type}
              eventKey={option.type}
              active={selectedTags && selectedTags.includes(option.type)}
            >
              <Form.Check
                type="checkbox"
                label={option.title}
                checked={selectedTags && selectedTags.includes(option.type)}
                onChange={() => handleSelect(option.type)}
              />
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </Form.Group>

      <div>
        {selectedTags &&
          selectedTags.map((item) => (
            <div key={item} className="email-bubble">
              <span>{getTitle(item)}</span>
              <button
                type="button"
                className="remove-button"
                onClick={() => handleSelect(item)}
              >
                x
              </button>
            </div>
          ))}
      </div>
    </div>
  );

  //   return (
  //     <div>
  //       <div style={{ position: "relative" }}>
  //         {options.map((option) => (
  //           <Dropdown.Item
  //             key={option.title}
  //             // onClick={() => handleSuggestionClick(email)}
  //           >
  //             {option.title}
  //           </Dropdown.Item>
  //         ))}
  //       </div>

  //       <div>
  //         {selectedTags &&
  //           selectedTags.map((item) => (
  //             <div key={item.id} className="email-bubble">
  //               <span>{item.title}</span>
  //               <button
  //                 type="button"
  //                 className="remove-button"
  //                 //onClick={() => handleRemoveEmail(email)}
  //               >
  //                 x
  //               </button>
  //             </div>
  //           ))}
  //       </div>
  //     </div>
  //   );
};

export default MultiselectDropdown;
