import React, { useState, useEffect } from "react";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color"; // Use SketchPicker for a user-friendly color picker

const TextFormatDropdown = ({ currentStyle, handleStyleChange }) => {
  const [font, setFont] = useState("Arial");
  const [fontSize, setFontSize] = useState(12);
  const [textHeight, setTextHeight] = useState(10);
  const [fontWeight, setFontWeight] = useState("normal");
  const [borderWidth, setBorderWidth] = useState(0.5);
  const [fillColor, setFillColor] = useState("white");
  const [fontColor, setFontColor] = useState("black");
  const [borderColor, setBorderColor] = useState("black");
  const [showFontColorPicker, setShowFontColorPicker] = useState(false);
  const [showFillColorPicker, setShowFillColorPicker] = useState(false);
  const [showBorderColorPicker, setShowBorderColorPicker] = useState(false);
  const [multipleInstances, setMultipleInstances] = useState(false);
  const [textAlignment, setTextAlignment] = useState("center");
  const [textHorizontalAlignment, setTextHorizontalAlignment] =
    useState("center");

  useEffect(() => {
    if (currentStyle) {
      setFont(currentStyle.fontFamily || "Arial");
      setFontSize(currentStyle.fontSize || 12);
      setFontWeight(currentStyle.fontWeight || "normal");
      setBorderWidth(currentStyle.borderWidth || 0.5);
      setFillColor(currentStyle.fill || "white");
      setFontColor(currentStyle.fontColor || "black");
      setBorderColor(currentStyle.border || "black");
      setTextHeight(currentStyle.textHeight || 10);
      setMultipleInstances(currentStyle.multipleInstances || false);
      setTextAlignment(currentStyle.textAlignment || "center");
      setTextHorizontalAlignment(
        currentStyle.textHorizontalAlignment || "center"
      );
    }
  }, [currentStyle]);

  const handleFontColorChangeComplete = (color) => {
    setFontColor(color.hex);
    handleStyleChange({ fontColor: color.hex });
  };

  const handleFillColorChangeComplete = (color) => {
    setFillColor(color.hex);
    handleStyleChange({ fill: color.hex });
  };

  const handleBorderColorChangeComplete = (color) => {
    setBorderColor(color.hex);
    handleStyleChange({ border: color.hex });
  };

  const handleFontChange = (event) => {
    setFont(event.target.value);
    handleStyleChange({ fontFamily: event.target.value });
  };

  const handleBorderWidthChange = (event) => {
    const newBorderWidth = event.target.value;
    let actualBorderWidth = parseFloat(newBorderWidth);
    if (actualBorderWidth >= 0 && actualBorderWidth < 3) {
      setBorderWidth(newBorderWidth);
      handleStyleChange({ borderWidth: parseFloat(newBorderWidth) });
    }
  };

  const handleFontSizeChange = (event) => {
    const newFontSize = event.target.value;
    setFontSize(newFontSize);
    handleStyleChange({ fontSize: parseInt(newFontSize) });
  };

  const handleFontWeightChange = (event) => {
    setFontWeight(event.target.value);
    handleStyleChange({ fontWeight: event.target.value });
  };

  const handleFontColorChange = (color) => {
    setFontColor(color);
    handleStyleChange({ fontColor: color.hex });
  };

  const handleFillColorChange = (color) => {
    setFillColor(color.hex);
    handleStyleChange({ fill: color.hex });
  };

  const handleBorderColorChange = (color) => {
    setBorderColor(color.hex);
    handleStyleChange({ border: color.hex });
  };

  const handleFontColorPickerClick = (color) => {
    setShowFontColorPicker(!showFontColorPicker);
  };

  const handleFillColorPickerClick = () => {
    setShowFillColorPicker(!showFillColorPicker);
  };

  const handleBorderColorPickerClick = () => {
    setShowBorderColorPicker(!showBorderColorPicker);
  };

  const handleColorPickerApply = () => {
    setShowFillColorPicker(false);
    setShowBorderColorPicker(false);
    setShowFontColorPicker(false);
  };

  const handleTextHeightChange = (event) => {
    setTextHeight(event.target.value);

    handleStyleChange({ textHeight: event.target.value });
  };

  const handleTextAlignmentChange = (event) => {
    setTextAlignment(event.target.value);

    handleStyleChange({ textAlignment: event.target.value });
  };

  const handleTextHorizontalAlignmentChange = (event) => {
    setTextHorizontalAlignment(event.target.value);

    handleStyleChange({ textHorizontalAlignment: event.target.value });
  };

  const handleCheckboxChange = (event) => {
    const { checked } = event.target;
    setMultipleInstances(checked);
    handleStyleChange({ multipleInstance: checked });
  };

  const styles = reactCSS({
    default: {
      fontColorPicker: {
        marginTop: "10px",
        display: showFontColorPicker ? "block" : "none",
      },
      colorPicker: {
        marginTop: "10px",
        display: showFillColorPicker ? "block" : "none",
      },
      borderColorPicker: {
        marginTop: "10px",
        display: showBorderColorPicker ? "block" : "none",
      },
    },
  });

  return (
    <div className="text-format-dropdown-container">
      <div className="text-format-dropdown-group">
        <label htmlFor="border-color-picker">Font Color:</label>
        <button
          className="color-picker-button"
          onClick={handleFontColorPickerClick}
        >
          <div
            className="color-preview"
            style={{ backgroundColor: fontColor }}
          ></div>
        </button>
        <div style={styles.fontColorPicker} className="color-picker-container">
          <SketchPicker
            color={fontColor}
            onChange={handleFontColorChange}
            onChangeComplete={handleFontColorChangeComplete}
          />
          <button
            className="apply-color-button"
            onClick={handleColorPickerApply}
          >
            Apply
          </button>
        </div>
      </div>

      <div className="text-format-dropdown-group">
        <label htmlFor="font-select">Font:</label>
        <select id="font-select" value={font} onChange={handleFontChange}>
          <option value="Arial">Arial</option>
          <option value="Helvetica">Helvetica</option>
          <option value="Times New Roman">Times New Roman</option>
          <option value="Courier New">Courier New</option>
          <option value="Verdana">Verdana</option>
          <option value="Tahoma">Tahoma</option>
          <option value="Georgia">Georgia</option>
          <option value="Palatino Linotype">Palatino Linotype</option>
        </select>
      </div>

      <div className="text-format-dropdown-group">
        <label htmlFor="font-size-select">Font Size:</label>
        <input type="number" value={fontSize} onChange={handleFontSizeChange} />
      </div>

      <div className="text-format-dropdown-group">
        <label htmlFor="font-weight-select">Font Weight:</label>
        <select
          id="font-weight-select"
          value={fontWeight}
          onChange={handleFontWeightChange}
        >
          <option value="normal">Regular</option>
          <option value="bold">Bold</option>
        </select>
      </div>

      <div className="text-format-dropdown-group">
        <label htmlFor="fill-color-picker">Fill:</label>
        <button
          className="color-picker-button"
          onClick={handleFillColorPickerClick}
        >
          <div
            className="color-preview"
            style={{ backgroundColor: fillColor }}
          ></div>
        </button>
        <div style={styles.colorPicker} className="color-picker-container">
          <SketchPicker
            color={fillColor}
            onChange={handleFillColorChange}
            onChangeComplete={handleFillColorChangeComplete}
          />
          <button
            className="apply-color-button"
            onClick={handleColorPickerApply}
          >
            Apply
          </button>
        </div>
      </div>

      <div className="text-format-dropdown-group">
        <label htmlFor="border-color-picker">Border:</label>
        <button
          className="color-picker-button"
          onClick={handleBorderColorPickerClick}
        >
          <div
            className="color-preview"
            style={{ backgroundColor: borderColor }}
          ></div>
        </button>
        <div
          style={styles.borderColorPicker}
          className="color-picker-container"
        >
          <SketchPicker
            color={borderColor}
            onChange={handleBorderColorChange}
            onChangeComplete={handleBorderColorChangeComplete}
          />
          <button
            className="apply-color-button"
            onClick={handleColorPickerApply}
          >
            Apply
          </button>
        </div>
      </div>

      <div className="text-format-dropdown-group">
        <label htmlFor="border-size-select">Border Width:</label>
        <input
          type="number"
          step="0.1"
          value={borderWidth}
          onChange={handleBorderWidthChange}
        />
      </div>

      <div className="text-format-dropdown-group">
        <label htmlFor="border-size-select">Text Height:</label>
        <input
          type="number"
          step="1"
          value={textHeight}
          onChange={handleTextHeightChange}
        />
      </div>

      <div className="text-format-dropdown-group">
        <label htmlFor="font-select">Text Alignment(Vertical) :</label>
        <select
          id="font-select"
          value={textAlignment}
          onChange={handleTextAlignmentChange}
        >
          <option value="top">Top</option>
          <option value="center">Center</option>
          <option value="bottom">Bottom</option>
        </select>
      </div>

      <div className="text-format-dropdown-group">
        <label htmlFor="font-select">Text Alignment(Horizontal):</label>
        <select
          id="font-select"
          value={textHorizontalAlignment}
          onChange={handleTextHorizontalAlignmentChange}
        >
          <option value="left">Left</option>
          <option value="center">Center</option>
          <option value="right">Right</option>
        </select>
      </div>

      {/* <--Removin below feature-->
      <div className="text-format-dropdown-group">
        <label htmlFor="multiple-instances-checkbox">Multiple Instances:</label>
        <input
          type="checkbox"
          id="multiple-instances-checkbox"
          checked={multipleInstances}
          onChange={handleCheckboxChange}
        />
      </div> */}
    </div>
  );
};

export default TextFormatDropdown;
