import { newComponentTemplate } from "./TemplateDataHandler";
import uuid from "react-uuid";

export const createNewComponentObject = (type, groupId, frontZ, data) => {
  return newComponentTemplate(
    type,
    uuid(),
    String(groupId),
    String(groupId),
    frontZ + 1,
    data
  );
};
