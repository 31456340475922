
import { useState, useEffect } from 'react';
import Autosuggest from 'react-autosuggest';
import { searchDiagrams } from '../Helper/DataService';


function SearchChildComp({ shapeId, userId, onClose, onSelectDiagram }) {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    let cancelRequest = false;

    async function fetchResults() {
      setIsLoading(true);

      try {
        const res = await searchDiagrams(userId, query);
        if (!cancelRequest) {
          const diagrams = res.data;
          setResults(diagrams);
        }
      } catch (err) {
        console.error(err);
      }

      setIsLoading(false);
    }

    // call the fetchResults function if the query string is not empty
    if (query.trim() !== '') {
      fetchResults();
    } else {
      setResults([]);
    }

    // cleanup function to cancel fetch request if component unmounts before request completes
    return () => {
      cancelRequest = true;
    };
  }, [query]);

  function handleQueryChange(event, { newValue }) {
    setQuery(newValue);
  }

  function handleSuggestionsFetchRequested({ value }) {
    
    // // fetch suggestions from the API and update the state
    // // setSuggestions([...Array(10).keys()].map((i) => ({ label: `Suggestion ${i}`, value: `suggestion${i}` })));
    // searchDiagrams(userId, value).then((res) => {
    //     let diagrams = res.data;
    //     setSuggestions(diagrams)

        

    // })
  }

  function handleSuggestionsClearRequested() {
    // clear the suggestions from the state
    // setSuggestions([]);
  }

  function handleSuggestionSelected(event, { suggestionValue }) {
    // // set the query to the selected suggestion
    // setQuery(suggestionValue.diagram.title);

  }

  function renderSuggestion(suggestion) {
    // render each suggestion item
    // return <div>{suggestion.diagram.title}</div>;
  }

  return (
    <div className="SearchChildComp__Overlay">
      <div className="SearchChildComp">
        <div className="SearchChildComp__InputWrapper">
          <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
            onSuggestionsClearRequested={handleSuggestionsClearRequested}
            getSuggestionValue={(suggestion) => suggestion.diagram.title}
            renderSuggestion={renderSuggestion}
            inputProps={{
              placeholder: 'Search',
              value: query,
              onChange: handleQueryChange,
              className: 'SearchChildComp__Input',
            }}
            theme={{
              container: 'SearchChildComp__AutosuggestContainer',
              suggestionsContainer: 'SearchChildComp__SuggestionsContainer',
              suggestionHighlighted: 'SearchChildComp__SuggestionHighlighted',
            }}
            onSuggestionSelected={handleSuggestionSelected}
            highlightFirstSuggestion={true}
          />
        </div>
        {isLoading ? (
          <div className="SearchChildComp__Loading">
            <p>Loading...</p>
          </div>
        ) : results.length > 0 ? (
            <ul className="SearchChildComp__Results">
            {results.map((result) => (
              <div key={result.diagramId} className="SearchChildComp__ResultCard" onClick={() => onSelectDiagram(shapeId,result)}>
                <div className="SearchChildComp__ResultCardTitle">{result.diagram.title}</div>
                {/* <div className="SearchChildComp__ResultCardDescription">{result.diagram.title}</div> */}
              </div>
            ))}
          </ul>
        ) : (
          <div className="SearchChildComp__Empty">
            <p>No results found</p>
          </div>
        )}
        <button className="SearchChildComp__CloseButton" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
}

export default SearchChildComp;