import React from "react";

function Footer() {
  return (
    <footer>
      <div className="footer-links">
        <div className="social-media">
          <a href="/about-us" target="_blank" rel="noopener noreferrer">
            About us
          </a>
          <a href="/contact-us" target="_blank" rel="noopener noreferrer">
            Contact us
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=61556211321558"
            target="_blank"
            rel="noopener noreferrer"
          >
            Facebook
          </a>
          <a
            href="https://www.linkedin.com/company/99382425/admin/feed/posts/"
            target="_blank"
            rel="noopener noreferrer"
          >
            LinkedIn
          </a>
          {/* <a
            href="https://twitter.com/your-twitter-url"
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </a> */}
          {/* Add links to other social media platforms */}
        </div>

        <div className="legal-links">
          <a href="/terms-of-use">Terms of Use</a>
          <a href="/privacy-policy">Privacy Policy</a>
          {/* Add other legal links as needed */}
        </div>

        <div className="contact-info">
          <a href="mailto:support@drawfuse.com"> Support email</a>
        </div>
        {/* <div className="contact-info">
          <span>Contact: +91 9554345649</span>
        </div> */}
      </div>

      {/* <div className="copyright">
        &copy; {new Date().getFullYear()} Your Company Name. All rights
        reserved.
      </div> */}
    </footer>
  );
}

export default Footer;
